/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnnotationClassesListResponse } from '../models/AnnotationClassesListResponse';
import type { AnnotationExportJobSegmentsResponse } from '../models/AnnotationExportJobSegmentsResponse';
import type { AnnotationExportJobsResponse } from '../models/AnnotationExportJobsResponse';
import type { AnnotationJobSegmentsHeaderResponse } from '../models/AnnotationJobSegmentsHeaderResponse';
import type { AnnotationJobsResponse } from '../models/AnnotationJobsResponse';
import type { AnnotationSegmentListResponse } from '../models/AnnotationSegmentListResponse';
import type { AppMode } from '../models/AppMode';
import type { CreateAnnotationRequest } from '../models/CreateAnnotationRequest';
import type { CreateAnnotationResponse } from '../models/CreateAnnotationResponse';
import type { FilterDefinitionListResponse } from '../models/FilterDefinitionListResponse';
import type { FilterDistinctRequest } from '../models/FilterDistinctRequest';
import type { FilterListResponse } from '../models/FilterListResponse';
import type { FilterRequest } from '../models/FilterRequest';
import type { OrderDefinitionListResponse } from '../models/OrderDefinitionListResponse';
import type { PlotNameListResponse } from '../models/PlotNameListResponse';
import type { UpdateAnnotationHeaderRequest } from '../models/UpdateAnnotationHeaderRequest';
import type { UpdateAnnotationListRequest } from '../models/UpdateAnnotationListRequest';
import type { UpdateAnnotationSegmentRequest } from '../models/UpdateAnnotationSegmentRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AnnotationService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create Annotation
     * @param requestBody 
     * @returns CreateAnnotationResponse Successful Response
     * @throws ApiError
     */
    public createAnnotationV1AnnotationPost(
requestBody: CreateAnnotationRequest,
): CancelablePromise<CreateAnnotationResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/annotation/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Get Annotation Jobs
     * @param appMode 
     * @param archived 
     * @param limit 
     * @returns AnnotationJobsResponse Successful Response
     * @throws ApiError
     */
    public getAnnotationJobsV1AnnotationJobAllGet(
appMode?: AppMode,
archived: boolean = false,
offset: number = 0,
limit: number = 1000,
searchTerm?: string
): CancelablePromise<AnnotationJobsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/annotation/job/all',
            query: {
                'app_mode': appMode,
                'archived': archived,
                'offset': offset,
                'limit': limit,
                'search_term': searchTerm,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Get Job Segments Header
     * @param annotationId 
     * @returns AnnotationJobSegmentsHeaderResponse Successful Response
     * @throws ApiError
     */
    public getJobSegmentsHeaderV1AnnotationJobAnnotationIdSegmentsHeaderGet(
annotationId: number,
): CancelablePromise<AnnotationJobSegmentsHeaderResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/annotation/job/{annotation_id}/segments/header',
            path: {
                'annotation_id': annotationId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Update Job Segments Header
     * @param annotationId 
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateJobSegmentsHeaderV1AnnotationJobAnnotationIdSegmentsHeaderUpdatePatch(
annotationId: number,
requestBody: UpdateAnnotationHeaderRequest,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/annotation/job/{annotation_id}/segments/header/update',
            path: {
                'annotation_id': annotationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Get Job Segments
     * @param annotationId 
     * @param requestBody 
     * @param tagFilter 
     * @param itemsPerPage 
     * @param page 
     * @returns AnnotationSegmentListResponse Successful Response
     * @throws ApiError
     */
    public getJobSegmentsV1AnnotationJobAnnotationIdSegmentsPost(
annotationId: number,
requestBody: FilterRequest,
tagFilter: string = 'All',
itemsPerPage: number = 100,
page: number = 1,
): CancelablePromise<AnnotationSegmentListResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/annotation/job/{annotation_id}/segments',
            path: {
                'annotation_id': annotationId,
            },
            query: {
                'tag_filter': tagFilter,
                'items_per_page': itemsPerPage,
                'page': page,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Update Annotation Segment
     * @param annotationId 
     * @param asId 
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateAnnotationSegmentV1AnnotationJobAnnotationIdSegmentsAsIdUpdatePatch(
annotationId: number,
asId: number,
requestBody: UpdateAnnotationSegmentRequest,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/annotation/job/{annotation_id}/segments/{as_id}/update',
            path: {
                'annotation_id': annotationId,
                'as_id': asId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Update Segments Annotation
     * @param annotationId 
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateSegmentsAnnotationV1AnnotationJobAnnotationIdSegmentsUpdatePatch(
annotationId: number,
requestBody: UpdateAnnotationListRequest,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/annotation/job/{annotation_id}/segments/update',
            path: {
                'annotation_id': annotationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Get Annotation Classes
     * @param annotationId 
     * @returns AnnotationClassesListResponse Successful Response
     * @throws ApiError
     */
    public getAnnotationClassesV1AnnotationJobAnnotationIdClassesGet(
annotationId: number,
): CancelablePromise<AnnotationClassesListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/annotation/job/{annotation_id}/classes',
            path: {
                'annotation_id': annotationId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Get Segments Filter Definition
     * @param annotationId 
     * @returns FilterDefinitionListResponse Successful Response
     * @throws ApiError
     */
    public getSegmentsFilterDefinitionV1AnnotationJobAnnotationIdSegmentsFilterDefinitionGet(
annotationId: number,
): CancelablePromise<FilterDefinitionListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/annotation/job/{annotation_id}/segments/filter/definition',
            path: {
                'annotation_id': annotationId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Get Segments Order Definition
     * @param annotationId 
     * @returns OrderDefinitionListResponse Successful Response
     * @throws ApiError
     */
    public getSegmentsOrderDefinitionV1AnnotationJobAnnotationIdSegmentsOrderDefinitionGet(
annotationId: number,
): CancelablePromise<OrderDefinitionListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/annotation/job/{annotation_id}/segments/order/definition',
            path: {
                'annotation_id': annotationId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Get Segments Filters
     * @param annotationId 
     * @param filterDefinitionId 
     * @param requestBody 
     * @param tagFilter 
     * @returns FilterListResponse Successful Response
     * @throws ApiError
     */
    public getSegmentsFiltersV1AnnotationJobAnnotationIdSegmentsFilterDefinitionIdFiltersPost(
annotationId: number,
filterDefinitionId: number,
requestBody: FilterDistinctRequest,
tagFilter: string = 'All',
): CancelablePromise<FilterListResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/annotation/job/{annotation_id}/segments/{filter_definition_id}/filters',
            path: {
                'annotation_id': annotationId,
                'filter_definition_id': filterDefinitionId,
            },
            query: {
                'tag_filter': tagFilter,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Export Annotation Job
     * @param annotationId 
     * @returns AnnotationExportJobSegmentsResponse Successful Response
     * @throws ApiError
     */
    public exportAnnotationJobV1AnnotationJobAnnotationIdExportGet(
annotationId: number,
): CancelablePromise<AnnotationExportJobSegmentsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/annotation/job/{annotation_id}/export',
            path: {
                'annotation_id': annotationId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Export Annotation Job Ids By App Token
     * @param appToken 
     * @returns AnnotationExportJobsResponse Successful Response
     * @throws ApiError
     */
    public exportAnnotationJobIdsByAppTokenV1AnnotationAppTokenExportJobIdsGet(
appToken: string,
): CancelablePromise<AnnotationExportJobsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/annotation/{app_token}/export/job_ids',
            path: {
                'app_token': appToken,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Get Plot Names
     * @param annotationId 
     * @returns PlotNameListResponse Successful Response
     * @throws ApiError
     */
    public getPlotNamesV1AnnotationJobAnnotationIdPlotNamesGet(
annotationId: number,
): CancelablePromise<PlotNameListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/annotation/job/{annotation_id}/plot/names',
            path: {
                'annotation_id': annotationId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

}

import {useParams} from "react-router-dom";
import {useQuery} from "react-query";
import QueryKeys from "../../../../react-query/QueryKeys";
import {getAnnotationJobHeader} from "../../../../_metronic/helpers/backend_helper";

const useAnnotationJobHeader = (initialFetch = true) => {
    const {id} = useParams()
    const annotationId = Number(id)
    const {
        data,
        isLoading,
    } = useQuery([QueryKeys.Annotation,QueryKeys.AnnotationJobHeader, annotationId], () => getAnnotationJobHeader(annotationId), {
        enabled: initialFetch,
        retry: false,
        refetchOnMount: false,
        retryOnMount: false,
        staleTime: 60 * 1000
    })

    return {data, isLoading}
}

export default useAnnotationJobHeader

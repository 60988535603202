import React, {useEffect, useState} from 'react'
import userpic from '../../../../assets/images/defaultuser.png'
import {useDispatch, useSelector} from "react-redux";
import ChangePasswordModal from "../Modals/ChangePasswordModal";
import NewUser from '../Modals/NewUser';
import {KTSVG} from '../../../../_metronic/helpers';
import {
    deleteUser,
    getUsers,
} from "../../../../store/actions";
import {Link} from "react-router-dom";
import {Alert, Button, Col, Row } from 'react-bootstrap';
// @ts-ignore
import BootstrapTable from 'react-bootstrap-table-next';
// @ts-ignore
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
// @ts-ignore
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import SweetAlert from "react-bootstrap-sweetalert";
import '../userstable.scss'
import alertStyle from '../../../general-styles/Alerts/alert-cards.module.scss'
import {PageLink, PageTitle} from "../../../../_metronic/layout/core";
import {UserResponse} from "../../../../AUTO_GENERATED_TYPES";
import {RootState} from "../../../../store/reducers";



type UserTableProps = {

}

const UsersTable: React.FC<UserTableProps> = ({}) => {

    const dispatch = useDispatch();

    const {SearchBar} = Search;
    const {usersList} = useSelector((state: RootState) => ({
        usersList: state.users.usersList,
    }))
    const {updateRes, successMsg, errorMsg} = useSelector((state: RootState) => ({
        updateRes: state.users.updateRes,
        successMsg: state.users.successMsg,
        errorMsg: state.users.errorMsg,
    }))

    useEffect(() => {
        if (usersList.length <= 0) {
            dispatch(getUsers())
        }
    }, [])

    useEffect(() => {
        if (updateRes === true) {
            dispatch(getUsers())
        }
    }, [updateRes === true])

    const [newUserModal, setNewUserModal] = useState<boolean>(false)
    const [passwordModal, setPasswordModal] = useState<boolean>(false)
    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const [editUser, setEditUser] = useState<boolean>(false)
    const [targetUserId, setTargetUserId] = useState<string>('')
    const [userId, setUserId] = useState<string>('')
    const [targetUserName, setTargetUserName] = useState<string>('')
    const [currentUser, setCurrentUser] = useState<UserResponse | undefined | null>()
    const [targetArray, setTargetArray] = useState<(number | null)[]>([])


    const updateUser = (id: any) => {
        if (usersList.length > 0) {
            let currentUser = usersList.find((user) => user?.id === id)
            setCurrentUser(currentUser)
            setEditUser(true)
            setNewUserModal(true)
        }
    }

    function imageFormatter(cell: any, row: any) {
        if (row.username) {
            return (
                <span>
                    <p style={{marginBottom:0}}>
                        <img style={{width: '35px', height: '35px', borderRadius: "50%"}} src={userpic}
                             alt="logo"/> {cell}
                    </p>
                </span>
            );
        }
    }

    const columns = [
        {
            dataField: 'id',
            hidden: true,
            text: '#',
            sort: true,
            headerStyle: (colum: any, colIndex: any) => {
                return {width: '35px', textAlign: 'center'};
            },
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            }
        },
        {
            dataField: 'username',
            text: 'User Name',
            editable: false,
            sort: true,
            style: {
                marginLeft: '15px',
                borderBottom: '.5px solid #d0d0d0',
                verticalAlign:'middle',
            },
            headerStyle: (colum: any, colIndex: any) => {
                return {marginLeft: '15px'};
            },
            formatter: imageFormatter
        },
        {
            dataField: 'full_name',
            text: 'Full Name',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0',
                verticalAlign:'middle',

            }
        },
        {
            dataField: 'email',
            text: 'Email',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0',
                verticalAlign:'middle',

            }
        },
        {
            dataField: 'role',
            text: 'Role',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0',
                verticalAlign:'middle',

            }
        },
        {
            dataField: 'status',
            text: 'Status',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0',
                verticalAlign:'middle',
            },
        },
        {
            dataField: "actions",
            isDummyField: true,
            editable: false,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0',
                verticalAlign:'middle',
            },
            text: "Actions",
            formatter: (cellContent: any, row: any, rowIndex: any, extraData: any) => (
                <>
                    <div style={{position: "relative"}}>

                        <div className={'table_action'}>

                            <Link
                                to="#"
                                className="text-success"
                                id='menuapp'
                                onClick={() => {
                                    setUserId(row.id)
                                    setTargetUserName(row.username)
                                    setPasswordModal(true)
                                }}
                            >
                                <KTSVG
                                    path='/media/icons/duotune/general/gen051.svg'
                                    className='svg-icon-password svg-icon-1'
                                />
                            </Link>
                            <Link
                                to="#"
                                className="text-danger"
                                //@ts-ignore
                                onClick={() => {
                                    setEditUser(true)
                                    updateUser(row.id)
                                }}
                            >
                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-edit svg-icon-1'/>

                            </Link>

                            <Link
                                to="#"
                                className="link-primary"
                                onClick={() => {
                                    setTargetUserId(row.id)
                                    setTargetUserName(row.username)
                                    setDeleteModal(true)
                                }}
                            >
                                <KTSVG
                                    path='/media/icons/duotune/general/gen027.svg'
                                    className='svg-icon-del svg-icon-1'
                                />

                            </Link>
                        </div>
                    </div>
                </>
            ),
        },


    ];

    const usersBreadcrumbs: Array<PageLink> = [
        {
            title: 'Home /',
            path: '/dashboard/apps',
            isSeparator: false,
            isActive: false,
        },
    ]

    return (
        <>
            <PageTitle breadcrumbs={usersBreadcrumbs}>Users List</PageTitle>
            {/*@ts-ignore*/}
            <NewUser isEdit={editUser} currentUser={currentUser} show={newUserModal}
                     handleClose={() => setNewUserModal(false)}/>
            <ChangePasswordModal show={passwordModal} handleClose={() => setPasswordModal(false)} userId={userId}
                                 userName={targetUserName}/>

            {usersList.length > 0 ? (
                <>
                    <ToolkitProvider
                        keyField="id"
                        data={usersList}
                        columns={columns}
                        search
                        bootstrap4
                    >
                        {
                            (props: any) => (
                                <div style={{ padding: '15px'}}>
                                    <div>
                                        {/*{successMsg && updateRes &&*/}
                                        {/*    <div className={alertStyle.alert_message}>*/}
                                        {/*        /!*@ts-ignore*!/*/}
                                        {/*        <Alert className={alertStyle.banner_alert} style={{zIndex: 5}}*/}
                                        {/*               color="success" role="alert">*/}
                                        {/*            {successMsg}*/}
                                        {/*        </Alert>*/}
                                        {/*    </div>*/}
                                        {/*}*/}
                                        {/*{errorMsg &&*/}
                                        {/*    <div className={alertStyle.alert_message}>*/}
                                        {/*        /!*@ts-ignore*!/*/}
                                        {/*        <Alert className={alertStyle.banner_alert_error} style={{zIndex: 5}}  color="danger" role="alert">*/}
                                        {/*            {errorMsg}*/}
                                        {/*        </Alert>*/}
                                        {/*    </div>*/}
                                        {/*}*/}
                                        <br/>
                                        <div className={'toolbar_table'}>
                                            <div>
                                                <SearchBar {...props.searchProps} />
                                            </div>
                                            <div>
                                                <div className="filter_section">
                                                    <Button className={'add_btn'}
                                                            onClick={() => {
                                                                setEditUser(false)
                                                                setNewUserModal(true)
                                                            }}
                                                    >
                                                        {/*@ts-ignore*/}
                                                        <div style={{marginRight: "10px"}}>
                                                            <KTSVG
                                                                path='/media/icons/duotune/arrows/arr052.svg'
                                                                className='svg-icon svg-icon-2x'
                                                            />
                                                        </div>
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        <br/>
                                    </div>
                                    <Row>
                                        <Col xl='12'>
                                            <BootstrapTable
                                                {...props.baseProps}
                                                keyField='id'
                                                pagination={paginationFactory()}
                                                bordered={true}
                                                responsive
                                                stripped
                                                headerWrapperClasses={"header-class"}
                                                // selectRow={selectRow}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            )
                        }
                    </ToolkitProvider>
                </>
            ) : (
                <>
                    <p>Loading....</p>
                </>
            )
            }
            {/*@ts-ignore*/}
            <SweetAlert
                show={deleteModal}
                warning
                showCancel
                confirmBtnText="Yes"
                cancelBtnText="Cancel"
                confirmBtnBsStyle={'danger'}
                title="Delete User"
                onConfirm={() => {
                    dispatch(deleteUser(+targetUserId))
                    setDeleteModal(false)
                }}
                onCancel={() => setDeleteModal(false)}
            >
                Are you sure you want to delete the selected user <span
                style={{fontWeight: 'bold'}}>{targetUserName} ?</span>
            </SweetAlert>

        </>
    )
}

export default UsersTable

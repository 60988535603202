/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum UserRole {
    SUPERUSER = 'SUPERUSER',
    ADMIN = 'ADMIN',
    EXPLORER = 'EXPLORER',
}

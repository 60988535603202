export enum SessionStorage {
 cDetectionPerPage = "cDetectionPerPage",
 cDetectionPage = "cDetectionPage",

 cJob = "cJob",

 cSegDetails = "cSegDetails",

 annotationJobsDetails = "annotationJobsDetails"
}

import * as sagaEffects from "redux-saga/effects"

// Login Redux States
import {
    getUsersSuccess,
    getUsersFail,
    newUserSuccess,
    newUserFail,
    updateUserSuccess,
    updateUserFail,
    deleteUserSuccess,
    deleteUserFail, resetPasswordSuccess, resetPasswordFail
} from "./actions"
//Static
import StaticValues from "../../../src/_metronic/helpers/StaticValues";

//Include Both Helper File with needed methods
import {
    deleteUserData,
    getUsers, newUser, resetPasswordData, updateUserData,
} from "../../_metronic/helpers/backend_helper"
import {changePreloader} from "../layout/actions";
import {
    AllUsersResponse,
    CreateUserRequest,
    ResetPasswordResponse,
    UpdateUserRequest
} from "../../AUTO_GENERATED_TYPES";
import {UserActionTypes} from "../../types/StoreTypes";
import {toast} from "react-toastify";
import {updateDecisionSuccess} from "../community/actions";

const { call, put, takeEvery, delay } = sagaEffects as any
function* generalSagaError(error: any){
    yield put(changePreloader(false))
    // toast.error(error.message)
}


function* getUsersList() {
    yield put(changePreloader(true))
    try {
        const response: AllUsersResponse = yield call(getUsers)
        yield put(getUsersSuccess(response))
        yield put(changePreloader(false))
    } catch (error) {
        yield* generalSagaError(error)
    }

}

function* createNewUser({payload: data} : {payload: CreateUserRequest}) {
    yield put(changePreloader(true))
    try {
        yield call(newUser, data)
        yield put(changePreloader(false))
        toast.success('New User added',{autoClose:StaticValues.waitingTimeForAlert})
        yield put (newUserSuccess(true, ''))
        yield delay(StaticValues.waitingTimeForAlert)
        yield put (newUserSuccess(false, ''))
    } catch (error) {
        yield* generalSagaError(error)

    }

}

function* updateUser({ payload: {userId, data}} : { payload: {userId:number, data:UpdateUserRequest}}) {
    yield put(changePreloader(true))
    try {
        yield call(updateUserData, userId, data)
        yield put(changePreloader(false))
        toast.success('User infos updated successfully',{autoClose:StaticValues.waitingTimeForAlert})
        yield put (updateUserSuccess(true, ''))
        yield delay(StaticValues.waitingTimeForAlert)
        yield put (updateUserSuccess(false, ''))
    } catch (error) {
        yield* generalSagaError(error)
    }

}

function* deleteUser({ payload: userId} : { payload: number}) {
    yield put(changePreloader(true))
    try {
        yield call(deleteUserData, userId)
        yield put(changePreloader(false))
        toast.success('User has been deleted!',{autoClose:StaticValues.waitingTimeForAlert})
        yield put (deleteUserSuccess(true, '',userId))
        yield delay(StaticValues.waitingTimeForAlert)
        yield put (deleteUserSuccess(false, '',userId))
    } catch (error) {
        yield* generalSagaError(error)

    }

}

function* resetPassword({ payload: userId} : { payload: number}) {
    yield put(changePreloader(true))
    try {
        const response: ResetPasswordResponse = yield call(resetPasswordData, userId)
        yield put(resetPasswordSuccess(true, 'User password has been successfully reset', response))
        yield put(changePreloader(false))
        yield delay(10000)
        yield put(resetPasswordSuccess(false, '', null))
    } catch (error) {
        yield* generalSagaError(error)

    }

}


function* usersSaga() {
    yield takeEvery(UserActionTypes.GET_USERS, getUsersList)
    yield takeEvery(UserActionTypes.CREATE_NEW_USER, createNewUser)
    yield takeEvery(UserActionTypes.UPDATE_USER, updateUser)
    yield takeEvery(UserActionTypes.DELETE_USER, deleteUser)
    yield takeEvery(UserActionTypes.RESET_PASSWORD, resetPassword)
}

export default usersSaga

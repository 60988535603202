import {AppsActionTypes} from "../../types/StoreTypes";


import {AppActions, AppState} from "../../types/AppTypes";

const initialState : AppState= {
    successMsg:'',
    errorMsg:'',
    processResult:false,
    appsList:[],
    appConfig:[],
    reloadRes:false,
}

const appsReducer = (state = initialState, action:AppActions) => {
    switch (action.type) {
        case AppsActionTypes.GET_APPS:
            state = {
                ...state,
                reloadRes: true,
            }
            break
        case AppsActionTypes.GET_APPS_SUCCESS:
            state = {
                ...state,
                appsList: action.payload?.apps ?? []
            }
            break
        case AppsActionTypes.GET_APPS_FAIL:
            state = {
                ...state,
                processResult:action.payload.result,
                errorMsg: action.payload.msg
            }
            break
        case AppsActionTypes.CREATE_APP_SUCCESS:
            state = {
                ...state,
                // appsList: [...state.appsList, action.payload.addedApp],
                successMsg: action.payload.msg,
                processResult: action.payload.result,
            }
            break
        case AppsActionTypes.CREATE_APP_FAIL:
            state = {
                ...state,
                processResult:action.payload.result,
                errorMsg: action.payload.msg
            }
            break
        case AppsActionTypes.UPDATE_APP_SUCCESS:
            state = {
                ...state,
                successMsg: action.payload.msg,
                processResult: action.payload.result,
            }
            break
        case AppsActionTypes.UPDATE_APP_FAIL:
            state = {
                ...state,
                processResult:action.payload.result,
                errorMsg: action.payload.msg
            }
            break
        case AppsActionTypes.DELETE_APP_SUCCESS:
            state = {
                ...state,
                successMsg: action.payload.msg,
                processResult: action.payload.result,
                appsList: state.appsList !== null ? state.appsList.filter( itemPos => itemPos?.app_id !== action.payload.id) : [],
            }
            break
        case AppsActionTypes.DELETE_APP_FAIL:
            state = {
                ...state,
                processResult:action.payload.result,
                errorMsg: action.payload.msg
            }
            break
        case AppsActionTypes.ARCHIVE_APP_SUCCESS:
            state = {
                ...state,
                successMsg: action.payload.msg,
                processResult: action.payload.result,
            }
            break
        case AppsActionTypes.ARCHIVE_APP_FAIL:
            state = {
                ...state,
                processResult:action.payload.result,
                errorMsg: action.payload.msg
            }
            break
        case AppsActionTypes.UNARCHIVE_APP_SUCCESS:
            state = {
                ...state,
                successMsg: action.payload.msg,
                processResult: action.payload.result,
            }
            break
        case AppsActionTypes.UNARCHIVE_APP_FAIL:
            state = {
                ...state,
                processResult:action.payload.result,
                errorMsg: action.payload.msg
            }
            break
        case AppsActionTypes.CLONE_APP_SUCCESS:
            state = {
                ...state,
                successMsg: action.payload.msg,
                processResult: action.payload.result,
                // appsList: [...state.appsList, action.payload.app],
            }
            break
        case AppsActionTypes.CLONE_APP_FAIL:
            state = {
                ...state,
                processResult:action.payload.result,
                errorMsg: action.payload.msg
            }
            break
        case AppsActionTypes.UNARCHIVE_APP_LIST_SUCCESS:
            state = {
                ...state,
                successMsg: action.payload.msg,
                processResult: action.payload.result,
            }
            break
        case AppsActionTypes.UNARCHIVE_APP_LIST_FAIL:
            state = {
                ...state,
                processResult:action.payload.result,
                errorMsg: action.payload.msg
            }
            break
        case AppsActionTypes.DELETE_APP_LIST_SUCCESS:
            state = {
                ...state,
                successMsg: action.payload.msg,
                processResult: action.payload.result,
            }
            break
        case AppsActionTypes.DELETE_APP_LIST_FAIL:
            state = {
                ...state,
                processResult:action.payload.result,
                errorMsg: action.payload.msg
            }
            break
        case AppsActionTypes.ARCHIVE_APP_LIST_SUCCESS:
            state = {
                ...state,
                successMsg: action.payload.msg,
                processResult: action.payload.result,
            }
            break
        case AppsActionTypes.ARCHIVE_APP_LIST_FAIL:
            state = {
                ...state,
                processResult:action.payload.result,
                errorMsg: action.payload.msg
            }
            break
        case AppsActionTypes.GET_APP_CONFIG_SUCCESS:
            state = {
                ...state,
                appConfig: action.payload
            }
            break
        case AppsActionTypes.GET_APP_CONFIG_FAIL:
            state = {
                ...state,
                processResult:action.payload.result,
                errorMsg: action.payload.msg
            }
            break
        case AppsActionTypes.UPDATE_APP_CONFIG_SUCCESS:
            state = {
                ...state,
                successMsg: action.payload.msg,
                processResult: action.payload.result,
            }
            break
        case AppsActionTypes.UPDATE_APP_CONFIG_FAIL:
            state = {
                ...state,
                processResult:action.payload.result,
                errorMsg: action.payload.msg
            }
            break
        case AppsActionTypes.DELETE_APP_CONFIG_SUCCESS:
            state = {
                ...state,
                successMsg: action.payload.msg,
                processResult: action.payload.result,
            }
            break
        case AppsActionTypes.DELETE_APP_CONFIG_FAIL:
            state = {
                ...state,
                processResult:action.payload.result,
                errorMsg: action.payload.msg
            }
            break
        default:
            state = { ...state }
            break
    }
    return state
}

export default appsReducer

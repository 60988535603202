import {ConfigGroupResponse, UpdateConfigGroupRequest} from "../../AUTO_GENERATED_TYPES";

const compareUpdateToConfig = (update: UpdateConfigGroupRequest, config: ConfigGroupResponse): boolean => {
    const updateKeys = Object.keys(update) as Array<keyof UpdateConfigGroupRequest>;
    for (const key of updateKeys) {
        if (update[key] !== config[key]) {
            return false;
        }
    }
    return true;
}

export default compareUpdateToConfig

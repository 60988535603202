/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum AppMode {
    LIVE = 'LIVE',
    DEMO = 'DEMO',
    TESTING = 'TESTING',
    ALL = 'ALL',
}

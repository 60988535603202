/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {toAbsoluteUrl} from '../../../helpers'
import StaticValues from "../../../helpers/StaticValues";
import defaultUser from '../../../../assets/images/defaultuser.png'

const HeaderUserMenu: FC = () => {
  const {currentUser, logout} = useAuth()
  const [userName, setUserName] = useState<any>({})

  useEffect(() => {
    //@ts-ignore
    setUserName(JSON.parse(localStorage.getItem(StaticValues.AuthUserQuago)) || '')
  },[StaticValues.AuthUserQuago])

  const signout = () =>{
    localStorage.removeItem(StaticValues.AuthUserQuago)
    window.location.reload()
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={defaultUser} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {userName?.user_name}
              <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>{userName?.user_role}</span>
            </div>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
          My Profile
        </Link>
      </div>

      <Languages />

      <div className='menu-item px-5 my-1'>
        <Link to='/crafted/account/settings' className='menu-link px-5'>
          Account Settings
        </Link>
      </div>

      <div className='menu-item px-5'>
        <a onClick={signout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
      <i style={{fontSize:'12px', marginLeft:'11%'}}>Version 1.0.13</i>
    </div>
  )
}

export {HeaderUserMenu}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CdDeployHistoryListResponse } from '../models/CdDeployHistoryListResponse';
import type { CdDeployResponse } from '../models/CdDeployResponse';
import type { CdExportJobSegmentsResponse } from '../models/CdExportJobSegmentsResponse';
import type { CommunityDetectionExportJobsResponse } from '../models/CommunityDetectionExportJobsResponse';
import type { DeploymentExportResponse } from '../models/DeploymentExportResponse';
import type { IndexesExportResponse } from '../models/IndexesExportResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CommunityDeployService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Pre Deploy Cd
     * @param cdId 
     * @returns CdDeployResponse Successful Response
     * @throws ApiError
     */
    public preDeployCdV1CommunityDeployCdIdPreGet(
cdId: number,
): CancelablePromise<CdDeployResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/community/deploy/{cd_id}/pre',
            path: {
                'cd_id': cdId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Deploy Cd
     * @param cdId 
     * @returns CdDeployResponse Successful Response
     * @throws ApiError
     */
    public deployCdV1CommunityDeployCdIdPost(
cdId: number,
): CancelablePromise<CdDeployResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/community/deploy/{cd_id}',
            path: {
                'cd_id': cdId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Export Deployment
     * @param appToken 
     * @returns DeploymentExportResponse Successful Response
     * @throws ApiError
     */
    public exportDeploymentV1CommunityDeployAppTokenExportGet(
appToken: string,
): CancelablePromise<DeploymentExportResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/community/deploy/{app_token}/export',
            path: {
                'app_token': appToken,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Export Indexes
     * @param appToken 
     * @returns IndexesExportResponse Successful Response
     * @throws ApiError
     */
    public exportIndexesV1CommunityDeployAppTokenIndexesGet(
appToken: string,
): CancelablePromise<IndexesExportResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/community/deploy/{app_token}/indexes',
            path: {
                'app_token': appToken,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Export Job Ids By App Token
     * @param appToken 
     * @returns CommunityDetectionExportJobsResponse Successful Response
     * @throws ApiError
     */
    public exportJobIdsByAppTokenV1CommunityDeployAppTokenExportJobIdsGet(
appToken: string,
): CancelablePromise<CommunityDetectionExportJobsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/community/deploy/{app_token}/export/job_ids',
            path: {
                'app_token': appToken,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Export Cd Job Segments
     * @param cdId 
     * @returns CdExportJobSegmentsResponse Successful Response
     * @throws ApiError
     */
    public exportCdJobSegmentsV1CommunityDeployJobCdIdExportGet(
cdId: number,
): CancelablePromise<CdExportJobSegmentsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/community/deploy/job/{cd_id}/export',
            path: {
                'cd_id': cdId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Get Deployment History
     * @param appId 
     * @returns CdDeployHistoryListResponse Successful Response
     * @throws ApiError
     */
    public getDeploymentHistoryV1CommunityDeployAllGet(
appId?: number,
): CancelablePromise<CdDeployHistoryListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/community/deploy/all',
            query: {
                'app_id': appId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

}

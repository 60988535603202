import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import { KTSVG, toAbsoluteUrl } from '../../../helpers';
import { HeaderNotificationsMenu, HeaderUserMenu, ThemeModeSwitcher } from '../../../partials';
import { useLayout } from '../../core';
import defaultUser from '../../../../assets/images/defaultuser.png';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/reducers";
import './header.scss';

const buttonCommonClasses = 'd-flex align-items-center ms-1 ms-lg-3';

const Topbar: FC = () => {
    const [warnings, setWarnings] = useState<any>();

    const { warningsList } = useSelector((state: RootState) => ({
        warningsList: state.community.warningsList,
    }));

    useEffect(() => {
        setWarnings(warningsList);
    }, [warningsList]);

    const { config } = useLayout();

    return (
        <div className='d-flex align-items-stretch flex-shrink-0'>
            {/* Theme */}
            <div className={clsx(buttonCommonClasses)}>
                <div 
                    className={clsx(
                        'btn', 
                        'btn-icon', 
                        'btn-active-light-primary', 
                        'btn-custom', 
                        'notif_btn', 
                        'w-30px', 
                        'h-30px', 
                        'w-md-40px', 
                        'h-md-40px', 
                    )}
                    data-kt-menu-trigger='click'
                    data-kt-menu-attach='parent'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='bottom'>
                    <div className="d-flex justify-content-center align-items-center">
                        <KTSVG
                            path='/media/icons/duotune/general/gen019.svg'
                            className='svg-icon-2x'
                        />
                    </div>
                  <ThemeModeSwitcher />
                </div>
            </div>

            {/* Notifications */}
            <div className={clsx(buttonCommonClasses)}>
                <div
                    className='btn btn-icon btn-active-light-primary btn-custom notif_btn w-30px h-30px w-md-40px h-md-40px'
                    data-kt-menu-trigger='click'
                    data-kt-menu-attach='parent'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='bottom'
                >
                    <KTSVG
                        path='/media/icons/duotune/general/gen007.svg'
                        className='svg-icon-2x'
                    />
                    {warningsList.length > 0 &&
                        <span className="badge badge-circle badge-danger">{warningsList.length}</span>
                    }
                </div>
                <HeaderNotificationsMenu />
            </div>

            <div className={clsx(buttonCommonClasses)} id='kt_header_user_menu_toggle'>
                <div
                    className='cursor-pointer symbol symbol-30px symbol-md-40px'
                    data-kt-menu-trigger='click'
                    data-kt-menu-attach='parent'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='bottom'
                >
                    <img src={defaultUser} alt='metronic' />
                </div>
                <HeaderUserMenu />
            </div>

            {config.header.left === 'menu' && (
                <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
                    <div
                        className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
                        id='kt_header_menu_mobile_toggle'
                    >
                        <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
                    </div>
                </div>
            )}
        </div>
    );
}

export { Topbar };

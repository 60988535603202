/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum SegmentTag {
    NEW = 'NEW',
    POS = 'POS',
    NEG = 'NEG',
    UN_CERTAIN = 'UN_CERTAIN',
    UN_TAG = 'UN_TAG',
}

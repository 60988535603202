import {Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import {KTSVG} from "../../../../_metronic/helpers";
import React, {useEffect, useState} from "react";
import styles from '../../../general-styles/Modals/modals.module.scss'
import '../community.scss'
//@ts-ignore
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
//@ts-ignore
//@ts-ignore
import BootstrapTable from 'react-bootstrap-table-next';
//@ts-ignore
import paginationFactory from 'react-bootstrap-table2-paginator';


interface ZoomFileProps {
    show: boolean,
    handleCloseModal: any,
    data: any
}

const SummaryTableModal = ({show, handleCloseModal, data}: ZoomFileProps) => {
    const [tableData, setTableData] = useState<any>();

    useEffect(() => {
        if (data.existing) {
            setTableData([
                {
                    x: 'Rejected',
                    existing: data?.existing?.rejected.total,
                    New: data?.new.rejected.total,
                    Total: data?.total.rejected,
                    totalItemExisting: JSON.stringify(data?.existing.rejected.details),
                    totalItemNew: JSON.stringify(data?.new.rejected.details)

                },
                {
                    x: 'Approved',
                    existing: data.existing.approved.total,
                    New: data.new.approved.total,
                    Total: data.total.approved,
                    totalItemExisting: JSON.stringify(data.existing.approved.details),
                    totalItemNew: JSON.stringify(data.new.approved.details)
                },
                {
                    x: 'Uncertain',
                    existing: data.existing.uncertain.total,
                    New: data.new.uncertain.total,
                    Total: data.total.uncertian,
                    totalItemExisting: JSON.stringify(data.existing.uncertain.details),
                    totalItemNew: JSON.stringify(data.new.uncertain.details)
                },
                {
                    x: 'Review',
                    existing: data.existing.review.total,
                    New: data.new.review.total,
                    Total: data.total.review,
                    totalItemExisting: JSON.stringify(data.existing.review.details),
                    totalItemNew: JSON.stringify(data.new.review.details)
                },
                {
                    x: 'Waiting for Review',
                    existing: data.existing.waiting_for_review.total,
                    New: data.new.waiting_for_review.total,
                    Total: data.total.waiting_for_review,
                    totalItemExisting: JSON.stringify(data.existing.waiting_for_review.details),
                    totalItemNew: JSON.stringify(data.new.waiting_for_review.details)
                },
                {
                    x: 'Total',
                    existing: data.existing.total,
                    New: data.new.total,
                    Total: data.total.total
                }
            ])
        }
    }, [data]);

    return (
        <>
            {tableData &&
                <Modal show={show}
                       onHide={handleCloseModal}
                       centered
                       size="lg"
                >
                    <div>
                        <div className='modal-content'>
                            <div className={styles.flex_row_between}>
                                <div className={styles.title_close_space}>
                                    <h2>Summary Table</h2>
                                </div>
                                <div className='modal_close_btn'>
                                    <div className='btn btn-sm btn-icon btn-active-color-primary'
                                         onClick={handleCloseModal}>
                                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                                    </div>
                                </div>
                            </div>
                            <div className={'summary_table'}>
                                <table>
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th>Existing</th>
                                        <th>New</th>
                                        <th>Total</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {tableData.map((row: any, index: any) => (
                                        <tr key={index}>
                                            <td className={'row_header'}>{row.x}</td>
                                            <OverlayTrigger
                                                placement='bottom'
                                                overlay={<Tooltip><b>{row.totalItemExisting}</b></Tooltip>}
                                                defaultShow={undefined}
                                                delay={undefined}
                                                flip={undefined}
                                                onHide={undefined}
                                                onToggle={undefined}
                                                popperConfig={undefined}
                                                show={undefined}
                                                target={undefined}
                                                trigger={undefined}>
                                                <td className={'row_table'}>{row.existing}</td>
                                            </OverlayTrigger>

                                            <OverlayTrigger
                                                placement='bottom'
                                                overlay={<Tooltip><b>{row.totalItemNew}</b></Tooltip>}
                                                defaultShow={undefined}
                                                delay={undefined}
                                                flip={undefined}
                                                onHide={undefined}
                                                onToggle={undefined}
                                                popperConfig={undefined}
                                                show={undefined}
                                                target={undefined}
                                                trigger={undefined}>
                                                <td className={'row_table'}>{row.New}</td>
                                            </OverlayTrigger>
                                            <td style={{fontWeight: '600'}} className={'row_table'}>{row.Total}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Modal>
            }
        </>
    )
}
export default SummaryTableModal

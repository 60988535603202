import React, {forwardRef, Ref} from 'react'
import SVG from 'react-inlinesvg'
import {toAbsoluteUrl} from '../AssetHelpers'
import {Spinner} from "reactstrap";

type Props = {
    className?: string;
    path: string;
    svgClassName?: string;
    cache?: boolean;
    loader?: boolean;
};

type KTSVGProps = Props & {
    ref?: Ref<HTMLSpanElement>;
};

const noRefKTSVG: React.ForwardRefRenderFunction<HTMLSpanElement, KTSVGProps> = (
    { className = '', path, svgClassName = 'mh-50px', cache, loader = true },
    ref
) => {
    return (
        <span ref={ref} className={`svg-icon ${className}`}>
      <SVG
          src={toAbsoluteUrl(path)}
          cacheRequests={cache}
          fetchOptions={cache ? { cache: 'force-cache' } : undefined}
          className={svgClassName}
          loader={loader && <Spinner size={'sm'} />}
      />
    </span>
    );
};

const KTSVG = forwardRef(noRefKTSVG)

export {KTSVG};

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConfigHistoryListResponse } from '../models/ConfigHistoryListResponse';
import type { ConfigListResponse } from '../models/ConfigListResponse';
import type { CreateConfigRequest } from '../models/CreateConfigRequest';
import type { UpdateConfigRequest } from '../models/UpdateConfigRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ConfigDefaultService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Defaults Configs
     * @param groups 
     * @returns ConfigListResponse Successful Response
     * @throws ApiError
     */
    public getDefaultsConfigsV1ConfigDefaultAllGet(
groups?: string,
): CancelablePromise<ConfigListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/config/default/all',
            query: {
                'groups': groups,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Delete Default Config
     * @param configId 
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteDefaultConfigV1ConfigDefaultConfigIdDelete(
configId: number,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/config/default/{config_id}',
            path: {
                'config_id': configId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Update Default Config
     * @param configId 
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateDefaultConfigV1ConfigDefaultConfigIdPatch(
configId: number,
requestBody: UpdateConfigRequest,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/config/default/{config_id}',
            path: {
                'config_id': configId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Create Default Config
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public createDefaultConfigV1ConfigDefaultPost(
requestBody: CreateConfigRequest,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/config/default',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Get Default Config Audit
     * @param configId 
     * @returns ConfigHistoryListResponse Successful Response
     * @throws ApiError
     */
    public getDefaultConfigAuditV1ConfigDefaultConfigIdAuditGet(
configId: number,
): CancelablePromise<ConfigHistoryListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/config/default/{config_id}/audit',
            path: {
                'config_id': configId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

}

import {CommunitySegSort, CommunitySortActions, CommunitySortState} from "../../types/CommunitySortTypes";
import {v4 as uuidv4} from "uuid";
import {CommunitySortActionTypes} from "../../types/StoreTypes";
import {toast} from "react-toastify";
import {OrderByDirection} from "../../AUTO_GENERATED_TYPES";


const deepCopyState = (state: CommunitySortState) => {
    return JSON.parse((JSON.stringify(state))) as CommunitySortState
}

const initialState: CommunitySortState = {
    sorts: [{
        id: uuidv4(),
        sortType: {value: "", label: "Order By"},
        sortValue: {value: OrderByDirection.ASC, label: 'Ascending'},
    }],
    sortCount: 0,
    sortOptions: [{value: "", label: ""}]
}

const reducer = (state = initialState, action: CommunitySortActions) => {
    switch (action.type) {
        case CommunitySortActionTypes.SET_COM_SORT:
            state = {...state, sorts: action.payload, sortCount: action.payload.length}
            break
        case CommunitySortActionTypes.RESET_COM_SORT:
            const oldOptions = state.sortOptions
            state = {...initialState, sortOptions: oldOptions}
            break
        case CommunitySortActionTypes.ADD_COM_SORT:
            const existingAddIndex = state.sorts.findIndex(sort => sort.sortType === action?.payload?.sortType)
            if (existingAddIndex !== -1) {
                const newOrder = JSON.parse(JSON.stringify(state.sorts)) as CommunitySegSort[]
                newOrder[existingAddIndex] = action.payload!
                state = {...state, sorts: newOrder}
                break
            }

            if (state.sortCount === 3) {
                toast.warn('Max sorts already applied.')
                state = {...state}
            } else {
                if (action.payload) {
                    if (state.sortCount === 0) {
                        state = {...state, sorts: [action.payload], sortCount: state.sortCount + 1}
                    } else {
                        state = {...state, sorts: [...state.sorts, action.payload], sortCount: state.sortCount + 1}
                    }
                } else {
                    state = {
                        ...state,
                        sorts: [...state.sorts, {
                            id: uuidv4(),
                            sortType: {value: "", label: "Order By"},
                            sortValue: {value: OrderByDirection.ASC, label: 'Ascending'},
                        }],
                    }
                }
            }
            break
        case CommunitySortActionTypes.REMOVE_COM_SORT:
            if (state.sortCount === 1 || state.sortCount === 0) {
                const oldOptions = state.sortOptions
                state = {...initialState, sortOptions: oldOptions}
            } else {
                const newRemoveOrder = deepCopyState(state).sorts.filter(sort => sort.id !== action.payload)
                state = {...state, sorts: newRemoveOrder, sortCount: state.sortCount - 1}
            }
            break
        case CommunitySortActionTypes.SORT_TYPE_CHANGE:
            const newTypeChangeOrder = deepCopyState(state).sorts
            if (newTypeChangeOrder.some(sort => String(sort.sortType.value) === String(action.payload.newValue.value))) {
                toast.warn('This sorting is already applied')
                break
            }
            const changedTypeIndex = newTypeChangeOrder.findIndex(sort => sort.id === action.payload.id)
            if (changedTypeIndex !== -1) {
                newTypeChangeOrder[changedTypeIndex].sortType = action.payload.newValue;
                newTypeChangeOrder[changedTypeIndex].sortValue = {value: OrderByDirection.ASC, label: 'Ascending'}
                state = {...state, sorts: newTypeChangeOrder, sortCount: newTypeChangeOrder.length}
            }
            break
        case CommunitySortActionTypes.SORT_VALUE_CHANGE:
            const newValueChangeOrder = deepCopyState(state).sorts
            const changedValueIndex = newValueChangeOrder.findIndex(sort => sort.id === action.payload.id)
            if (changedValueIndex !== -1) {
                newValueChangeOrder[changedValueIndex].sortValue = action.payload.newValue
                state = {...state, sorts: newValueChangeOrder}
            }
            break
        case CommunitySortActionTypes.SET_SORT_OPTIONS:
            state = {...state, sortOptions: action.payload}
            break
        default:
            state = {...state}
    }
    return state
}

export default reducer

import React, {useEffect, useLayoutEffect, useState} from 'react'
import {PageLink, PageTitle} from "../../../../_metronic/layout/core";
import {Button} from "react-bootstrap";
import '../community.scss'
import {Label} from "reactstrap";
import {KTSVG} from "../../../../_metronic/helpers";
import {useDispatch, useSelector} from "react-redux";
import {
    getSegmentData,
    getSegmentHeader,
    getWarnings,
    patchSegDetails,
    segmentTagging
} from "../../../../store/community/actions";
import {SegmentTag, SegmentType, TagFilter} from "../../../../AUTO_GENERATED_TYPES";
import {RootState} from "../../../../store/reducers";
import {Link, useNavigate, useParams} from "react-router-dom";
import ZoomImageModel from "../Modals/ZoomImageModel";
//@ts-ignore
import {useFirstMountState, useTimeout} from 'react-use';
import DetailsModal from "../Modals/DetailsModal";
import StaticValues from "../../../../_metronic/helpers/StaticValues";
import SegmentTaggingFilter from "../Modals/SegmentTaggingFilter";
import TaggingTooltip from "../Components/TaggingTooltip";
import {CSSTransition} from 'react-transition-group';
import {useIsFetching, useQuery} from "react-query";
import QueryKeys from "../../../../react-query/QueryKeys";
import useCommunityFilters from "../../../../store/community-filters/useCommunityFilters";
import {resetFilters, saValueChange} from "../../../../store/community-filters/actions";
import SegmentDetailsPagination from "../Components/SegmentDetailsPagination";
import SweetAlert from "react-bootstrap-sweetalert";
import {getCDPlotNames, getSegmentFilters} from "../../../../_metronic/helpers/backend_helper";
import {toast} from "react-toastify";
import useGetFilterList from "../Hooks/useGetFilterList";
import useCommunitySort from "../../../../store/community-sort/useCommunitySort";
import SegmentTaggingSort from "../Modals/SegmentTaggingSort";
import useGetFiltersAndOrders from "../../../../store/community/useGetFiltersAndOrders";
import {resetSort} from "../../../../store/community-sort/actions";
import CreatableSelect from 'react-select/creatable'
import {SessionStorage} from "../../../../constants/sessionStorage";

const SegmentDetails = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const isFetching = useIsFetching()
    const {jobId: segId, type: segType, segFilter} = useParams()

    const {filterCount, onlyFullFilters} = useCommunityFilters()

    const {sortCount} = useCommunitySort(false)

    const filterRequestBody = useGetFiltersAndOrders()

    const {segmentHeader, segmentData, result, typeOfResult} = useSelector((state: RootState) => ({
        segmentHeader: state.community.segmentHeader,
        segmentData: state.community.segmentData,
        result: state.community.result,
        typeOfResult: state.community.typeOfResult,
    }))

    const {
        isPreloader
    } = useSelector((state: RootState) => ({
        isPreloader: state.Layout.isPreloader,
    }))

    const [handleZoomFile, setHandleZoomFile] = useState<boolean>(false)
    const [handleDetailFile, setHandleDetailFile] = useState<boolean>(false)
    const [showDetails, setShowDetails] = useState<boolean>(sessionStorage.getItem(SessionStorage.cSegDetails) !== 'false')
    const [notSupportedVersion, setNotSupportedVersion] = useState<boolean>(false)
    const [itemPerPageLimit, setItemPerPageLimit] = useState<boolean>(false)
    const [confirmationModal, setConfirmationModal] = useState<boolean>(false)
    const [forbiddenAction, setForbiddenAction] = useState<boolean>(false)
    const [userWarning, setUserWarning] = useState<boolean>(false)
    const [filterCleared, setFilterCleared] = useState<boolean>(false)
    const [hasClick, setHasClick] = useState<boolean>(false)
    const [filterIsOpen, setFilterIsOpen] = useState<boolean>(false)
    const [zoomFile, setZoomFile] = useState<string>('')
    const [selectedComment, setSelectedComment] = useState<any>('')
    const [taggingValue, setTaggingValue] = useState<TagFilter>(TagFilter.ALL)
    const [segmentTag, setSegmentTag] = useState<any>('0')
    const [commIdxValue, setCommIdxValue] = useState<any>('0')
    const [segmentTagIcons, setSegmentTagIcons] = useState<any>([])
    const [segmentPosList, setSegmentPosList] = useState<any>([])
    const [segmentNegList, setSegmentNegList] = useState<any>([])
    const [segmentUncertainList, setSegmentUncertainList] = useState<any>([])
    const [segmentUnTagList, setSegmentUnTagList] = useState<any>([])
    const [segmentTagListToTag, setSegmentTagListToTag] = useState<any>([])

    const [detailFile, setDetailFile] = useState<any>()
    const [pageCount, setPageCount] = useState<number>(10)
    const [totalItems, setTotalItems] = useState<number>()
    const [imgId, setImgId] = useState<number>()
    const [destination, setDestination] = useState<string>('')
    const [userName, setUserName] = useState<any>('')
    const [imgIndex, setImgIndex] = useState<number>()
    const [filterByLink, setFilterByLink] = useState<any>(null)
    const [showSort, setShowSort] = useState(false)

    const [currentPage, setCurrentPage] = useState<any>(1);
    const [itemPerPage, setItemPerPage] = useState<number>(100);
    const [isTimedOut, setIsTimedOut] = useState(false);
    const [hasTagged, setHasTagged] = useState(false);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

    const [imageType, setImageType] = useState({value: '', label: '- Plot Type -'})

    const firstRender = useFirstMountState()

    //@ts-ignore
    let currentUser: any = JSON.parse(localStorage.getItem(StaticValues.AuthUserQuago))?.user_id || ''

    const {filterList} = useGetFilterList(+(segId!), segType!)
    const communityId = filterList?.filters?.find(desc => desc.column_name === 'community_id')?.id
    const {data: segmentOptionsData, isLoading: loadingOptions} = useQuery([QueryKeys.SegmentFilters, {
        segId,
        value: communityId,
        segType,
        onlyFullFilters,
        tagFilter: taggingValue
    }], () => {
        if (communityId) {

            return getSegmentFilters(Number(segId), communityId, segType === 'positive' ? SegmentType.POS : SegmentType.NEG, onlyFullFilters, taggingValue)
        }
    }, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
        onError: () => {
            toast.error('Something went wrong! Please try again.')
        },
    })

    const {data: cdPlotNames} = useQuery([QueryKeys.CDPlotNames,Number(segId)], () => getCDPlotNames(Number(segId)),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            select: (data => data.plot_names),
            onSuccess: ((data: Record<string, string> | undefined) => {
                if (!data) {
                    return;
                }

                const firstOption = Object.entries(data).at(0)!
                setImageType({value: firstOption[0], label: firstOption[0]})

            }),
        })


    useEffect(() => {
        if (firstRender) {
            dispatch(getSegmentHeader(Number(segId), segType === 'positive' ? SegmentType.POS : SegmentType.NEG))
        }

        return () => {
            dispatch(resetSort())
            dispatch(resetFilters())
        }
    }, []);


    useEffect(() => {
        if (segFilter === '1') {
            if (taggingValue !== TagFilter.ALL) {
                if (!firstRender) {
                    dispatch(getSegmentData(Number(segId), getSegmentType(), filterRequestBody, itemPerPage, currentPage, taggingValue));
                }
            } else {
                if (!firstRender) {
                    dispatch(getSegmentHeader(Number(segId), getSegmentType()));
                    if (segmentData.paging && itemPerPage !== segmentData?.paging?.items_per_page ||
                        currentPage !== segmentData.paging.page
                        && segmentData) {
                        dispatch(getSegmentData(Number(segId), getSegmentType(), filterRequestBody, itemPerPage, currentPage, taggingValue));
                    }
                } else {
                    dispatch(getWarnings(Number(segId)));
                    dispatch(getSegmentData(Number(segId), getSegmentType(), filterRequestBody, itemPerPage, currentPage));
                }
            }
        } else {
            if (!firstRender) {
                dispatch(getSegmentData(Number(segId), getSegmentType(), filterRequestBody, itemPerPage, currentPage, taggingValue));
            }
        }

    }, [segId, currentPage, itemPerPage, dispatch, segType, taggingValue]);

    // function shouldFetchSegmentDataFilter() {
    //     if (segmentData.paging) {
    //         return (
    //             itemPerPage !== segmentData?.paging?.items_per_page ||
    //             currentPage !== segmentData.paging.page
    //         ) && segmentData;
    //     }
    // }

    useEffect(() => {
        return () => {
            if (timeoutId) {
                clearInterval(timeoutId);
            }
        };
    }, [timeoutId]);

    useEffect(() => {
        if (segFilter != '1' && communityId) {
            dispatch(getSegmentData(Number(segId), getSegmentType(), {
                filters: [{
                    filter_definition_id: communityId,
                    filter_operator: '==',
                    filter_value: segFilter !== undefined ? [segFilter] : []
                }], orders: []
            }, itemPerPage, currentPage))
            setCommIdxValue(segFilter)
        }
    }, [segFilter, communityId]);

    // useEffect(() => {
    //     if (shouldFetchSegmentDataFilter()) {
    //         dispatch(getSegmentData(Number(segId), getSegmentType(), filterRequestBody, itemPerPage, currentPage, taggingValue));
    //     }
    // }, [itemPerPage, currentPage, segmentData]);

    useEffect(() => {
        if (result === true && typeOfResult !== 'tag') {
            if (segFilter != '1' && !filterCleared) {
                dispatch(getWarnings(Number(segId)));
                dispatch(getSegmentHeader(Number(segId), getSegmentType()));
                dispatch(getSegmentData(Number(segId), getSegmentType(), {
                    filters: [{
                        filter_definition_id: communityId!,
                        filter_operator: '==',
                        filter_value: segFilter !== undefined ? [segFilter] : []
                    }], orders: []
                }, itemPerPage, currentPage))
            } else {
                dispatch(getWarnings(Number(segId)));
                dispatch(getSegmentHeader(Number(segId), getSegmentType()));
                dispatch(getSegmentData(Number(segId), getSegmentType(), filterRequestBody, itemPerPage, currentPage, taggingValue));
            }
        } else if (result === false && typeOfResult === 'tagError') {
            dispatch(getSegmentData(Number(segId), getSegmentType(), filterRequestBody, itemPerPage, currentPage, taggingValue));
        } else {
            if (result === true) {
                dispatch(getSegmentHeader(Number(segId), getSegmentType()));
            }
        }
    }, [result, typeOfResult]);

    useEffect(() => {
        setUserName(segmentHeader?.user_name)
        setDestination(segmentHeader.destination_folder)
        setSelectedComment(segmentHeader.comment)
        setTotalItems(segmentData?.paging?.total_items)
        setPageCount(Math.ceil(segmentData?.paging?.total_items / segmentData?.paging?.items_per_page))
    }, [segmentData, segmentHeader])

    useLayoutEffect(() => {
        setSegmentTagIcons(segmentData?.segments?.map((st: any) => (st.tag)))
    }, [segmentData]);

    const handleImageTypeChange = (event) => {
        const selectedValue = event.target.value;
        if (selectedValue) {
            setImageType({ value: selectedValue, label: selectedValue });
        }
    };

    const toggleTaggingIcon = (index: number, tagName: string, commIdx: any) => {
        setHasTagged(true)
        setIsTimedOut(false)
        if (timeoutId) {
            clearInterval(timeoutId);
        }
        const newSegmentListToTag = [...segmentTagListToTag];
        let indexArray = newSegmentListToTag.findIndex((i: any) => i.value === commIdx);
        // update the segmentTagListToTag with the new tag
        if (indexArray !== -1) {
            newSegmentListToTag[indexArray] = {value: commIdx, label: tagName};
        } else {
            newSegmentListToTag.push({value: commIdx, label: tagName});
        }
        setSegmentTagListToTag(newSegmentListToTag);
        // update the segmentTagIcons
        if (tagName !== segmentTagIcons[index]) {
            const newSegmentTagIcons = [...segmentTagIcons];
            newSegmentTagIcons[index] = tagName;
            setSegmentTagIcons(newSegmentTagIcons);
        }
        // update the segmentPosList, segmentNegList and segmentUncertainList
        setSegmentPosList(newSegmentListToTag.filter((item) => item.label === 'POS').map((item) => item.value));
        setSegmentNegList(newSegmentListToTag.filter((item) => item.label === 'NEG').map((item) => item.value));
        setSegmentUnTagList(newSegmentListToTag.filter((item) => item.label === 'UN_TAG').map((item) => item.value));
        setSegmentUncertainList(newSegmentListToTag.filter((item) => item.label === 'UN_CERTAIN').map((item) => item.value));

        setTimeoutId(setInterval(() => {
            setIsTimedOut(true);
        }, 2000));
    };


    useEffect(() => {
        if (!isTimedOut || !(segmentPosList.length <= 0 || segmentNegList.length <= 0 || segmentUncertainList.length <= 0 || segmentUnTagList.length <= 0)) {
            return;
        }
        const updateList: any[] = []
        if (segmentPosList.length > 0) updateList.push({segment_tag: SegmentTag.POS, seg_id_list: segmentPosList})
        if (segmentNegList.length > 0) updateList.push({segment_tag: SegmentTag.NEG, seg_id_list: segmentNegList})
        if (segmentUncertainList.length > 0) updateList.push({
            segment_tag: SegmentTag.UN_CERTAIN,
            seg_id_list: segmentUncertainList
        })
        if (segmentUnTagList.length > 0) updateList.push({
            segment_tag: SegmentTag.UN_TAG,
            seg_id_list: segmentUnTagList
        })
        if (updateList.length > 0) dispatch(segmentTagging(Number(segId), {"update_list": updateList}));

        setSegmentTagListToTag([]);
    }, [isTimedOut, segmentPosList, segmentNegList, segmentUncertainList]);


    function getSegmentType() {
        return segType === 'positive' ? SegmentType.POS : SegmentType.NEG;
    }

    const handleClick = () => {
        if (!hasClick) {
            setHasClick(true)
            if (segmentHeader?.user_id !== null) {
                if ((segmentHeader?.user_id != currentUser) && (segType === 'positive') && firstRender) {
                    setUserWarning(true)
                }
            }
        }
    }

    const segmentsBreadcrumbs: Array<PageLink> = [
        {
            title: 'Home /',
            path: '/dashboard/apps',
            isSeparator: false,
            isActive: false,
        },
        {
            title: 'Community Detection /',
            path: '/community/all',
            isSeparator: false,
            isActive: false,
        },
        {
            title: 'Community Job /',
            path: `/community/job/${segId}`,
            isSeparator: false,
            isActive: false,
        },
    ]

    const handleItemsPerPage = (items: number) => {
        if (items > 0) {
            setItemPerPage(items)
            setCurrentPage(1)
        } else {
            setItemPerPageLimit(true)
        }
    }

    const dateFormatter = (date: any) => {
        let newDate = date
            .replace(/[-]/g, '/')
            .replace(/[T]/g, ' ');
        let jsDate = newDate.slice(0, 16);
        return jsDate
    }

    const handlePaginate = (selectedItem: any) => {
        setCurrentPage(selectedItem.selected + 1)
        window.scrollTo(0, 0);
    }

    const EvidenceCommentHandleChange = (event: any) => {
        let commentValue = event.target.value;
        setSelectedComment(commentValue)
        setTimeout(() => {
            if (commentValue === event.target.value) {
                dispatch(patchSegDetails(Number(segId), {"comment": commentValue}))
            }
        }, 1000);
    };

    const updateDestinationHandleChange = (event: any) => {
        let destinationValue = event.target.value;
        setDestination(destinationValue)
        setTimeout(() => {
            if (destinationValue === event.target.value) {
                dispatch(patchSegDetails(Number(segId), {"destination_folder": destinationValue}))
            }
        }, 1000);
    }

    const outsideClick = () => {
        setFilterIsOpen(false)
    }

    const getFilterName = (filterName: string) => {
        const filterType: any = [
            {label: 'SEG ID', value: 'seg_id'},
            {label: 'SEG NAME', value: 'seg_name'},
            {label: 'USER ID', value: 'user_id'},
            {label: 'ADDITIONAL ID', value: 'additional_id'},
            {label: 'SESSION ID', value: 'session_id'},
            {label: 'IP', value: 'ip_address'},
            {label: 'COUNTRY', value: 'country_code'},
            {label: 'COMMUNITY ID', value: 'community_id'},
            {label: 'MODEL', value: 'model'},
            {label: 'DEVICE ID', value: 'device_id'},
            {label: 'SYSTEM VERSION', value: 'system_version'},
            {label: 'SCREEN WIDTH', value: 'screen_width'},
            {label: 'SCREEN HEIGHT', value: 'screen_height'},
            {label: 'IP NETWORK', value: 'ip_network'},
        ]
        if (filterName) {
            return filterType.find((f: any) => f.value === filterName).label
        }
    }

    return (
        <div className={'segment_page'}
             onClick={outsideClick}
        >
            <PageTitle
                breadcrumbs={segmentsBreadcrumbs}>{segType === 'positive' ? 'Positive Segment' : 'Negative Segment'}</PageTitle>

            {segmentData?.segments?.length > 0 &&
                <>
                    <ZoomImageModel file={zoomFile} handleCloseModal={() => setHandleZoomFile(false)}
                                    totalItems={totalItems!} show={handleZoomFile} title={'Image'} index={imgIndex!}
                                    imgId={imgId!}
                                    setImgId={setImgId}
                                    setImgIndex={setImgIndex}
                                    segData={segmentData.segments} currentPage={currentPage}
                                    changePage={() => setCurrentPage(currentPage + 1)} data={detailFile}
                                    itemsPerPage={itemPerPage}
                                    tagList={segmentData?.segments?.map((st: any) => (st.tag))}
                                    setForbiddenAction={setForbiddenAction}
                                    taggingValue={taggingValue}
                                    imageType={imageType}
                                    changeImageType={setImageType}
                                    setImage={setZoomFile}
                                    setSegment={setDetailFile}
                                    setDetailFile={setDetailFile}
                    />
                </>
            }

            {/*@ts-ignore*/}
            <DetailsModal data={detailFile}
                          handleCloseModal={() => {
                              setHandleDetailFile(false)
                          }}
                          show={handleDetailFile}
                          title={'File'}
                          segId={segId}
                          currentPage={currentPage}
                          itemsPerPage={itemPerPage}
                          segType={segType}
                          setTagDefault={() => {
                          }}
                          taggingValue={taggingValue}
                          segments={segmentData?.segments}
                          setSegment={setDetailFile}
            />

            {segmentHeader.tagging_summary ? (
                <>
                    <CSSTransition
                        in={showDetails}
                        timeout={300}
                        classNames="detail"
                        unmountOnExit
                    >
                        <div className={'seg_details'}>
                            <div className={'seg_det_row_1'}>
                                <div>
                                    <p>{segmentHeader.title} for <b>{segmentHeader.app_name}{'\u00A0'}{'\u00A0'}</b>
                                    </p>
                                </div>
                                <div className={'seg_det_row_2'}>
                                    <TaggingTooltip placement={'top'} tooltip={'New'}>
                                        <div>
                                            <b>
                                                <KTSVG
                                                    path='/media/icons/duotune/general/gen003.svg'
                                                    className='svg-icon-edit-star svg-icon-2x'
                                                />{'\u00A0'}{'\u00A0'}
                                                {segmentHeader.tagging_summary.new}
                                            </b>
                                        </div>
                                    </TaggingTooltip>
                                    <TaggingTooltip placement={'top'} tooltip={"Positive"}>
                                        <div>
                                            <b>
                                                <KTSVG
                                                    path='/media/icons/duotune/general/gen041.svg'
                                                    className='svg-icon-edit svg-icon-2x'
                                                />{'\u00A0'}{'\u00A0'}
                                                {segmentHeader.tagging_summary.positive}
                                            </b>
                                        </div>
                                    </TaggingTooltip>
                                    <TaggingTooltip placement={'top'} tooltip={'Negative'}>
                                        <div>
                                            <b>
                                                <KTSVG
                                                    path='/media/icons/duotune/general/gen042.svg'
                                                    className='svg-icon-edit svg-icon-2x'
                                                />{'\u00A0'}{'\u00A0'}
                                                {segmentHeader.tagging_summary.negative}
                                            </b>
                                        </div>
                                    </TaggingTooltip>
                                    <TaggingTooltip placement={'top'} tooltip={'Uncertain'}>
                                        <div>
                                            <b>
                                                <KTSVG
                                                    path='/media/icons/duotune/general/gen046.svg'
                                                    className='svg-icon-edit svg-icon-2x'
                                                />{'\u00A0'}{'\u00A0'}
                                                {segmentHeader.tagging_summary.uncertain}
                                            </b>
                                        </div>
                                    </TaggingTooltip>
                                    <TaggingTooltip placement={'top'} tooltip={'Tagged'}>
                                        <div>
                                            <b>
                                                <KTSVG
                                                    path='/media/icons/duotune/maps/map001.svg'
                                                    className='svg-icon-edit svg-icon-2x'
                                                />{'\u00A0'}{'\u00A0'}
                                                {segmentHeader.tagging_summary.tagged}
                                            </b>
                                        </div>
                                    </TaggingTooltip>
                                    <TaggingTooltip placement={'top'} tooltip={'UnTagged'}>
                                        <div>
                                            <b>
                                                <KTSVG
                                                    path='/media/icons/duotune/maps/map001.svg'
                                                    className='svg-icon svg-icon-2x'
                                                />{'\u00A0'}{'\u00A0'}
                                                {segmentHeader.tagging_summary.untagged}
                                            </b>
                                        </div>
                                    </TaggingTooltip>
                                    <TaggingTooltip placement={'top'} tooltip={'Items'}>
                                        <div>
                                            <b>
                                                <KTSVG
                                                    path='/media/icons/duotune/abstract/abs027.svg'
                                                    className='svg-icon-edit svg-icon-2x'
                                                />{'\u00A0'}{'\u00A0'}
                                                {segmentHeader.items_count}
                                            </b>
                                        </div>
                                    </TaggingTooltip>

                                    {segmentHeader.platform === 'ANDROID' ? (
                                        <div className={'seg_icon_container'}>
                                            <p>
                                                <KTSVG
                                                    path='/media/icons/duotune/social/soc002.svg'
                                                    className='svg-icon-android svg-icon-2x'
                                                />{'\u00A0'}{'\u00A0'}
                                            </p>
                                        </div>

                                    ) : segmentHeader.platform === 'IOS' ? (
                                        <div className={'seg_icon_container'}>
                                            <p>
                                                <KTSVG
                                                    path='/media/icons/duotune/social/soc001.svg'
                                                    className='svg-icon-ios svg-icon-2x'
                                                />{'\u00A0'}{'\u00A0'}
                                            </p>
                                        </div>
                                    ) : (
                                        <>
                                            <div className={'seg_icon_container'}>
                                                <p>
                                                    <KTSVG
                                                        path='/media/icons/duotune/social/soc003.svg'
                                                        className='svg-icon-windows svg-icon-2x'
                                                    />{'\u00A0'}{'\u00A0'}
                                                </p>
                                            </div>
                                        </>
                                    )
                                    }
                                </div>
                            </div>
                            <div className={'seg_det_row_5'}>
                                <div>
                                    <Label>App Token: </Label>
                                    <p>{segmentHeader.app_token}</p>
                                </div>
                                <div>
                                    <Label>Job Name: </Label>
                                    <p>{segmentHeader.source_job_name}</p>
                                </div>
                                <div>
                                    <Label>Session Creation Time:</Label>
                                    <p>{dateFormatter(segmentHeader.creation_time)}</p>
                                </div>
                                <div>
                                    <Label>Representation Segment </Label>
                                    <p>{segmentHeader.represented_segments_cnt}</p>
                                </div>
                                <div>
                                    <Label>Run Name</Label>
                                    <p>{segmentHeader.run_name}</p>
                                </div>
                            </div>
                            <br/>
                            <div className={'seg_det_row_3'}>
                                <div className={'comment_section'}>
                                        <textarea onClick={handleClick} value={selectedComment}
                                                  onChange={EvidenceCommentHandleChange}
                                                  placeholder={'Comments'} name="" id="" cols={80} rows={2}>
                                        </textarea>
                                </div>
                                <div>
                                    <Label>User Name</Label>
                                    <input disabled={true} type="text" value={userName}/>
                                </div>
                                <div>
                                    <Label>Destination</Label>
                                    <input onClick={handleClick} onChange={updateDestinationHandleChange}
                                           type="text"
                                           value={destination}/>
                                </div>
                                <div style={{marginLeft: 'auto'}}>
                                    {/*@ts-ignore*/}
                                    <Link style={{pointerEvents: segmentData?.segments?.length > 0 ? '' : 'none'}}
                                          to={`/community/job/segment/${segType}/${segId}/items-table`} target="_blank">
                                        <Button disabled={segmentData?.segments?.length <= 0}
                                                style={{marginRight: '10px'}}>
                                            <KTSVG
                                                path='/media/icons/duotune/files/fil002.svg'
                                                className='svg-icon-edit svg-icon-2'
                                            />
                                            Items Table</Button>
                                    </Link>
                                </div>
                                <div>
                                    {/*<Button onClick={() => setNotSupportedVersion(true)}*/}
                                    {/*        disabled={segType === 'negative'}>*/}
                                    {/*    <KTSVG*/}
                                    {/*        path='/media/icons/duotune/arrows/arr078.svg'*/}
                                    {/*        className='svg-icon-edit svg-icon-1'*/}
                                    {/*    />*/}
                                    {/*    Publish</Button>*/}
                                </div>
                            </div>
                        </div>
                    </CSSTransition>
                    {/*    <br/>*/}
                    {/*</>*/}
                    {/*}*/}

                    <div className={'seg_filter'}>
                        <div className={'seg_filter_part_1'}>
                            <select disabled={segType === 'negative'} onClick={handleClick} onChange={(e) => {
                                setConfirmationModal(true);
                                setSegmentTag(e.target.value)
                            }} value={segmentTag} name="" id="">
                                <option key={0} value="0">- Mark All -</option>
                                {StaticValues.segmentsTags.map((t: any) => (
                                    <option key={t.value} value={t.value}>{t.label}</option>
                                ))}
                            </select>
                            {/*<span style={{marginTop: '12px', width:'55px'}}>{'In Page'}</span>*/}
                            {/*<Label>*/}
                            {/*    <Switch className={'switch'} onChange={()=> setPageMode(!pageMode)} checked={false} uncheckedIcon={false} checkedIcon={false}  />*/}
                            {/*</Label>*/}
                            <Button className={'position-relative'}
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                        if (segmentHeader?.user_id != currentUser) {
                                            handleClick()
                                            if (hasClick)
                                                setFilterIsOpen(!filterIsOpen)
                                        } else {
                                            setFilterIsOpen(!filterIsOpen)
                                        }
                                        setShowSort(false)
                                    }}>
                                <KTSVG
                                    path='/media/icons/duotune/general/gen031.svg'
                                    className='svg-icon-edit svg-icon-2'
                                />
                                <span
                                    className={filterCount ? '' : 'seg_filter_none'}>Filter {filterCount ? `(${filterCount})` : ''}</span>
                                <div><SegmentTaggingFilter isOpen={filterIsOpen}
                                                           currentPage={currentPage}
                                                           itemPerPage={itemPerPage}
                                                           onClose={() => setFilterIsOpen(false)}
                                                           resetPage={() => setCurrentPage(1)}
                                                           tagFilter={taggingValue}
                                />
                                </div>
                            </Button>
                            {loadingOptions ? <select name="" id="" value={1}>
                                <option value="1">Loading...</option>
                            </select> : <select onChange={(e) => {
                                if (segmentOptionsData) {
                                    dispatch(saValueChange(e.target.value, segmentOptionsData))
                                    navigate(`/community/job/segment/${segType}/${segId}/${e.target.value}`)

                                    if (e.target.value === '1') {
                                        const filterWithoutCommunity = {...filterRequestBody}
                                        if (filterWithoutCommunity?.filters !== undefined) {
                                            filterWithoutCommunity.filters = filterWithoutCommunity.filters.filter(filter => String(filter.filter_definition_id) !== String(communityId))

                                            dispatch(getSegmentData(Number(segId), getSegmentType(), filterWithoutCommunity, itemPerPage, currentPage, taggingValue));
                                        }

                                    }
                                }
                            }} name="" id="" value={segFilter}>
                                <option value="1">Community Index</option>
                                {segmentOptionsData && segmentOptionsData?.filter_list?.map(filter => <option
                                    key={filter.filter_name}
                                    value={filter.filter_name}>{filter.filter_name} ({filter.filter_counter})</option>)}
                            </select>}

                            <select value={taggingValue} onClick={() => {
                                handleClick()
                            }} onChange={(e) => {
                                setHasTagged(false)
                                setFilterByLink(null)
                                setTaggingValue(e.target.value as TagFilter)
                            }} name="" id="">
                                {StaticValues.segmentsFilter.map((t: any) => (
                                    <option key={t.value} value={t.value}>{t.label}</option>
                                ))}
                            </select>
                            <TaggingTooltip placement={'bottom'} tooltip={'Refresh Filter'}>
                                <Button
                                    disabled={!hasTagged || taggingValue === 'ALL'}
                                    style={{width: '40px', paddingLeft: '9px', paddingTop: '9px'}} onClick={() => {
                                    dispatch(getSegmentData(Number(segId), getSegmentType(), filterRequestBody, itemPerPage, currentPage, taggingValue))
                                    setHasTagged(false)
                                }}>
                                    <KTSVG
                                        path='/media/icons/duotune/arrows/arr029.svg'
                                        className='svg-icon-edit svg-icon-1'
                                    />
                                </Button>
                            </TaggingTooltip>

                            <Button className={'position-relative'} onClick={(e) => {
                                e.stopPropagation()
                                setShowSort(prevState => !prevState)
                                setFilterIsOpen(false)
                            }}>
                                <KTSVG
                                    path='/media/icons/duotune/general/gen032.svg'
                                    className='svg-icon-edit svg-icon-2'
                                />
                                <span className={sortCount ? '' : 'seg_filter_none'}>Sort {Boolean(sortCount) &&
                                    <span>({sortCount})</span>}</span>
                                <SegmentTaggingSort taggingValue={taggingValue} isOpen={showSort} setIsOpen={setShowSort} itemPerPage={itemPerPage}
                                                    currentPage={currentPage}/>
                            </Button>
                            <Button
                                onClick={() => {
                                    setShowDetails(prevState => {
                                        const newVal = !prevState
                                        sessionStorage.setItem(SessionStorage.cSegDetails, String(newVal))
                                        return newVal
                                    })
                                }}>
                                <KTSVG
                                    path='/media/icons/duotune/arrows/arr032.svg'
                                    className='svg-icon-edit svg-icon-2'
                                />
                                Details</Button>
                                <select value={imageType.value} style={{ maxWidth: '200px' }} onChange={handleImageTypeChange}>
                                    <option value={''}>- Plot Type -</option>
                                    {cdPlotNames && Object.keys(cdPlotNames).map((key, index) => (
                                        <option key={index} value={key}>{cdPlotNames[key]}</option>
                                    ))}
                                </select>

                            <div style={{
                                marginLeft: '10px',
                                marginRight: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '5px',
                            }}>
                                <Label style={{marginBottom: 0}}>Per Page</Label>
                                <CreatableSelect value={{
                                    value: itemPerPage,
                                    label: `${itemPerPage} ${segmentData?.paging?.total_items ? ` (of ${segmentData?.paging?.total_items})` : ''}`
                                }}
                                                 onChange={(e) => handleItemsPerPage(Number(e?.value))}
                                                 options={[10, 25, 50, 100, 500, 1000].map(num => ({
                                                     value: num,
                                                     label: `${num} ${segmentData?.paging?.total_items ? ` (of ${segmentData?.paging?.total_items})` : ''}`
                                                 }))}
                                                 formatCreateLabel={(inputValue: string) => inputValue}
                                                 onFocus={handleClick}
                                                 createOptionPosition={'first'}
                                                 isValidNewOption={inputValue => !isNaN(parseInt(inputValue))}


                                />
                            </div>
                            {filterByLink &&
                                <div className={'filter_link_sign'}>
                                    <div>Active Filter :
                                        <TaggingTooltip placement={'bottom'} tooltip={filterByLink.filter_value}> <span
                                            style={{fontWeight: 'bold'}}>{getFilterName(filterByLink.filter_type)}</span></TaggingTooltip>
                                    </div>
                                </div>
                            }

                        </div>

                        <div className={'seg_filter_part_2'}>
                            <SegmentDetailsPagination currentPage={currentPage} pageCount={pageCount}
                                                      handlePaginate={handlePaginate}/>

                        </div>
                    </div>
                    <br/>
                    {segmentData?.segments?.length > 0 && segmentTagIcons && !isPreloader ? (
                        <>
                            <div className={'seg_images'}>
                                {segmentData?.segments?.map((seg: any, index: number) => (
                                    <div key={index} id={seg.community_id} className={'seg_img_exemple'}>
                                        <TaggingTooltip placement={'auto'}
                                                        tooltip={<> <p>Comm Idx : <i>{seg.community_id}</i></p>
                                                            <p>Seg Count : <i>{seg.cnt_segments}</i></p>
                                                            <p>Auth Inference : <i>{seg.auth_inference}</i></p>
                                                            <p>Emu Inference : <i>{seg.emu_inference}</i></p>
                                                            <p>Group : <i>{seg.group}</i></p>
                                                            <p>Seg Id : <i>{seg.seg_id}</i></p>
                                                            <p>Seg User Id : <i>{seg.user_id}</i></p>
                                                            <p>Seg Session Id : <i>{seg.session_id}</i></p></>}> <img
                                            onClick={() => {
                                                if (segmentHeader?.user_id != currentUser && segmentHeader?.user_id != null) {
                                                    handleClick()
                                                    if (hasClick) {
                                                        setZoomFile(seg?.plots[imageType.value] ?? Object.values(seg?.plots).at(0))
                                                        setImgId(seg.id);
                                                        setHandleZoomFile(true);
                                                        setImgIndex(seg.index)
                                                        setDetailFile(seg)
                                                    }
                                                } else if (segmentHeader?.user_id == currentUser) {
                                                    setZoomFile(seg?.plots[imageType.value] ?? Object.values(seg?.plots).at(0))
                                                    setImgId(seg.id);
                                                    setHandleZoomFile(true);
                                                    setImgIndex(seg.index)
                                                    setDetailFile(seg)
                                                } else {
                                                    setZoomFile(seg?.plots[imageType.value] ?? Object.values(seg?.plots).at(0))
                                                    setImgId(seg.id);
                                                    setHandleZoomFile(true);
                                                    setImgIndex(seg.index)
                                                    setDetailFile(seg)
                                                }
                                            }
                                            }
                                            src={seg.plots && imageType.value ? seg.plots[imageType.value] : seg.plots?.path_2d_motion_plot}
                                            width={500}
                                            height={300}
                                            alt="segment_graph"/></TaggingTooltip>

                                        <div className={'seg_img_btns'}>
                                            <div className={'seg_btns'}>
                                                <TaggingTooltip placement={'bottom'} tooltip={'Positive'}>
                                                    <Link onClick={() => {
                                                        handleClick()
                                                        if (segmentHeader?.user_id != currentUser && segmentHeader?.user_id != null) {
                                                            if (hasClick) {
                                                                if (segType !== 'negative') {
                                                                    toggleTaggingIcon(index, 'POS', seg.id)
                                                                } else {
                                                                    setForbiddenAction(true)
                                                                }
                                                            }
                                                        } else if (segmentHeader?.user_id == currentUser) {
                                                            if (segType !== 'negative') {
                                                                toggleTaggingIcon(index, 'POS', seg.id)

                                                            } else {
                                                                setForbiddenAction(true)
                                                            }
                                                        } else {
                                                            dispatch(patchSegDetails(Number(segId), {"user_id": currentUser}))
                                                            dispatch(getSegmentHeader(Number(segId), segType === 'positive' ? SegmentType.POS : SegmentType.NEG))
                                                            if (segType !== 'negative') {
                                                                toggleTaggingIcon(index, 'POS', seg.id)

                                                            } else {
                                                                setForbiddenAction(true)
                                                            }
                                                        }
                                                    }} to={'#'}>
                                                        <KTSVG
                                                            path='/media/icons/duotune/general/gen041.svg'
                                                            className={segmentTagIcons[index] === 'POS' ? 'svg-icon-edit-selected svg-icon-2qx' : 'svg-icon-edit-new svg-icon-2qx'}
                                                        />
                                                    </Link>
                                                </TaggingTooltip>
                                                <TaggingTooltip placement={'bottom'} tooltip={'Negative'}>
                                                    <Link onClick={() => {
                                                        handleClick()
                                                        if (segmentHeader?.user_id != currentUser && segmentHeader?.user_id != null) {
                                                            if (hasClick) {
                                                                if (segType !== 'negative') {
                                                                    toggleTaggingIcon(index, 'NEG', seg.id)

                                                                } else {
                                                                    setForbiddenAction(true)
                                                                }
                                                            }
                                                        } else if (segmentHeader?.user_id == currentUser) {
                                                            if (segType !== 'negative') {
                                                                toggleTaggingIcon(index, 'NEG', seg.id)

                                                            } else {
                                                                setForbiddenAction(true)
                                                            }
                                                        } else {
                                                            dispatch(patchSegDetails(Number(segId), {"user_id": currentUser}))
                                                            dispatch(getSegmentHeader(Number(segId), segType === 'positive' ? SegmentType.POS : SegmentType.NEG))
                                                            if (segType !== 'negative') {
                                                                toggleTaggingIcon(index, 'NEG', seg.id)

                                                            } else {
                                                                setForbiddenAction(true)
                                                            }
                                                        }
                                                    }} to={'#'}>
                                                        <KTSVG
                                                            path='/media/icons/duotune/general/gen042.svg'
                                                            className={segmentTagIcons[index] === 'NEG' ? 'svg-icon-edit-selected svg-icon-2qx' : 'svg-icon-edit-new svg-icon-2qx'}/>
                                                    </Link>
                                                </TaggingTooltip>
                                                <TaggingTooltip placement={'bottom'} tooltip={'Unknown'}>
                                                    <Link onClick={() => {
                                                        handleClick()
                                                        if (segmentHeader?.user_id != currentUser && segmentHeader?.user_id != null) {
                                                            if (hasClick) {
                                                                if (segType !== 'negative') {
                                                                    toggleTaggingIcon(index, 'UN_CERTAIN', seg.id)

                                                                } else {
                                                                    setForbiddenAction(true)
                                                                }
                                                            }
                                                        } else if (segmentHeader?.user_id == currentUser) {
                                                            if (segType !== 'negative') {
                                                                toggleTaggingIcon(index, 'UN_CERTAIN', seg.id)

                                                            } else {
                                                                setForbiddenAction(true)
                                                            }
                                                        } else {
                                                            dispatch(patchSegDetails(Number(segId), {"user_id": currentUser}))
                                                            dispatch(getSegmentHeader(Number(segId), segType === 'positive' ? SegmentType.POS : SegmentType.NEG))
                                                            if (segType !== 'negative') {
                                                                toggleTaggingIcon(index, 'UN_CERTAIN', seg.id)

                                                            } else {
                                                                setForbiddenAction(true)
                                                            }
                                                        }
                                                    }} to={'#'}>
                                                        <KTSVG
                                                            path='/media/icons/duotune/general/gen046.svg'
                                                            className={segmentTagIcons[index] === 'UN_CERTAIN' ? 'svg-icon-edit-selected svg-icon-2qx' : 'svg-icon-edit-new svg-icon-2qx'}/>
                                                    </Link>
                                                </TaggingTooltip>
                                                <TaggingTooltip placement={'bottom'} tooltip={'UnTag'}> <Link
                                                    onClick={() => {
                                                        handleClick()
                                                        if (segmentHeader?.user_id != currentUser && segmentHeader?.user_id != null) {
                                                            if (hasClick) {
                                                                if (segType !== 'negative') {
                                                                    toggleTaggingIcon(index, 'UN_TAG', seg.id)
                                                                } else {
                                                                    setForbiddenAction(true)
                                                                }
                                                            }
                                                        } else if (segmentHeader?.user_id == currentUser) {
                                                            if (segType !== 'negative') {
                                                                toggleTaggingIcon(index, 'UN_TAG', seg.id)
                                                            } else {
                                                                setForbiddenAction(true)
                                                            }
                                                        } else {
                                                            dispatch(patchSegDetails(Number(segId), {"user_id": currentUser}))
                                                            dispatch(getSegmentHeader(Number(segId), segType === 'positive' ? SegmentType.POS : SegmentType.NEG))
                                                            if (segType !== 'negative') {
                                                                toggleTaggingIcon(index, 'UN_TAG', seg.id)
                                                            } else {
                                                                setForbiddenAction(true)
                                                            }
                                                        }
                                                    }} to={'#'}>
                                                    <KTSVG
                                                        path='/media/icons/duotune/arrows/arr029.svg'
                                                        className={segmentTagIcons[index] === 'UN_TAG' ? 'svg-icon-edit-selected svg-icon-2qx' : 'svg-icon-edit-new svg-icon-2qx'}
                                                    />
                                                </Link></TaggingTooltip>

                                                <div className={'details_btn'}>
                                                    <TaggingTooltip placement={'bottom'} tooltip={'Details'}> <Link
                                                        to={'#'} onClick={() => {
                                                        setDetailFile(seg)
                                                        setHandleDetailFile(true)
                                                    }}>
                                                        <KTSVG
                                                            path='/media/icons/duotune/general/gen004.svg'
                                                            className='svg-icon-edit svg-icon-2qx'
                                                        />
                                                    </Link></TaggingTooltip>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <SegmentDetailsPagination currentPage={currentPage} pageCount={pageCount}
                                                      handlePaginate={handlePaginate}/>
                        </>
                    ) : (
                        isFetching || isPreloader ? null : <div className={'no_results'}>
                            <div>
                                <h3>No results found</h3>
                            </div>
                        </div>
                    )}
                    {/*@ts-ignore*/}
                    <SweetAlert
                        show={forbiddenAction}
                        warning
                        confirmBtnText="Okay"
                        confirmBtnBsStyle={'danger'}
                        title={`Action Forbidden`}
                        onConfirm={() => {
                            setForbiddenAction(false)
                        }
                        }
                    >
                        <p>Negative Segments cannot be modified !</p>
                    </SweetAlert>
                    {/*@ts-ignore*/}
                    <SweetAlert
                        show={itemPerPageLimit}
                        warning
                        confirmBtnText="Okay"
                        confirmBtnBsStyle={'danger'}
                        title={`Warning`}
                        onConfirm={() => {
                            setItemPerPageLimit(false)
                        }
                        }
                    >
                        <p>Items per page must be greater than 0</p>
                    </SweetAlert>
                    {/*@ts-ignore*/}
                    <SweetAlert
                        show={notSupportedVersion}
                        warning
                        confirmBtnText="Okay"
                        confirmBtnBsStyle={'danger'}
                        title={`Functionality not supported`}
                        onConfirm={() => {
                            setNotSupportedVersion(false)
                        }
                        }
                    >
                        <p>This will be integrated in future versions !</p>
                    </SweetAlert>
                    {/*@ts-ignore*/}
                    <SweetAlert
                        show={confirmationModal}
                        warning
                        cancelBtnText="Cancel"
                        showCancel
                        confirmBtnText="Yes"
                        confirmBtnBsStyle={'danger'}
                        title={`Are you sure you want to proceed ?`}
                        onConfirm={() => {
                            const segData = {
                                cdId: Number(segId),
                                segType: getSegmentType(),
                                body: filterRequestBody,
                                itemPp: itemPerPage,
                                page: currentPage,
                                tag: taggingValue
                            }

                            dispatch(segmentTagging(Number(segId), {
                                "update_list": [{
                                    segment_tag: segmentTag as SegmentTag,
                                    seg_id_list: [segmentData?.segments?.map((rowUnity: any) => Number(rowUnity.id))][0]
                                }]
                            }, segData))
                            setConfirmationModal(false)
                            setSegmentTag('0')
                        }
                        }
                        onCancel={() => {
                            setConfirmationModal(false)
                            setSegmentTag('0')
                        }}
                    >
                        <p>You are about to tag all current page segments
                            to {segmentTag === 'NEG' ? 'Negative' : segmentTag === 'POS' ? 'Positive' : segmentTag === 'UN_CERTAIN' ? 'Uncertain' : 'Untag'} </p>
                    </SweetAlert>
                    {/*@ts-ignore*/}
                    <SweetAlert
                        show={userWarning}
                        warning
                        showCancel
                        confirmBtnText="Yes"
                        cancelBtnText="Cancel"
                        confirmBtnBsStyle={'danger'}
                        title={`Warning`}
                        onConfirm={() => {
                            setUserWarning(false)
                        }
                        }
                        onCancel={() => {
                            setUserWarning(false)
                            navigate(`/community/job/${segId}`)
                        }}
                    >
                        <p>You are about to start a tagging session where an other user already has made changes. Do you
                            still want to proceed ?</p>
                    </SweetAlert>
                </>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    )
}

export default SegmentDetails

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SystemService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Health Check
     * @returns any Successful Response
     * @throws ApiError
     */
    public healthCheckV1SystemHealthGet(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/system/health',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

}

import {Button, Modal} from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import {KTSVG} from "../../../../_metronic/helpers";
//@ts-ignore
import styles from "../../../general-styles/Modals/modals.module.scss";
import {Label} from "reactstrap";
import React, {FormEventHandler, useState} from "react";
//@ts-ignore
import {createConfig, createConfigGroup, deleteApp} from "../../../../store/actions";
import {
    ConfigGroupListResponse,
    ConfigGroupResponse,
    CreateConfigGroupRequest,
    GroupMode,
    UpdateConfigGroupRequest
} from "../../../../AUTO_GENERATED_TYPES";
import Select from "react-select";
import {useMutation, useQueryClient} from "react-query";
import {createNewConfigGroup, editConfigGroup} from "../../../../_metronic/helpers/backend_helper";
import ReactSwitch from "react-switch";
import {toast} from "react-toastify";
import QueryKeys from "../../../../react-query/QueryKeys";
import compareUpdateToConfig from "../../../../assets/utils/compareUpdateToConfig";

type GroupOptionMode = Exclude<GroupMode, GroupMode.ALL>

interface ConfigGroupFormModalProps {
    handleClose: () => void,
    context: ConfigGroupResponse | null
    tableGroupMode: GroupMode
    tableArchived: boolean
}

const appModeList = [{value: GroupMode.LIVE, label: "Live"}, {
    value: GroupMode.DEMO,
    label: "Demo"
}, {value: GroupMode.TEST, label: "Test"}]

const ConfigGroupFormModal: React.FC<ConfigGroupFormModalProps> = ({
                                                                       handleClose,
                                                                       context,
                                                                       tableGroupMode,
                                                                       tableArchived
                                                                   }) => {
    const initialAppMode = appModeList.find(el => el.value === context?.mode)

    const queryClient = useQueryClient()

    const [name, setName] = useState<string>(context?.group_name ?? '')
    const [appRelated, setIsAppRelated] = useState<boolean>(context?.app_related ?? true)
    const [isProtected, setIsProtected] = useState<boolean>(context?.protected ?? true)
    const [groupMode, setGroupMode] = useState<typeof appModeList[number]>(initialAppMode ?? {
        value: GroupMode.LIVE,
        label: "Live"
    })
    const [description, setDescription] = useState(context?.description ?? '')
    const [nameIsValid, setNameIsValid] = useState<boolean>(true)
    const [isTouched, setIsTouched] = useState<boolean>(false)
    const [checkFieldsModal, setCheckFieldsModal] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')

    const {mutate: createConfigGroup} = useMutation((configGroupData: CreateConfigGroupRequest) => toast.promise(createNewConfigGroup(configGroupData), {
        pending: 'Creating new config group...',
        success: 'Created Successfully!',
    }), {
        onSuccess: () => {
            queryClient.invalidateQueries([QueryKeys.ConfigGroup])
        },
    })

    const {mutate: updateConfigGroup} = useMutation<any, unknown, { configGroupData: UpdateConfigGroupRequest, groupId: number }, any>(({
                                                                                                                                            configGroupData,
                                                                                                                                            groupId
                                                                                                                                        }) => toast.promise(editConfigGroup(configGroupData, groupId), {
        pending: 'Updating config group...',
        success: 'Updated Successfully!',
    }), {
        onSuccess: (_data, variables) => {
            if(tableGroupMode === GroupMode.ALL){
            const newData = queryClient.getQueryData<ConfigGroupListResponse>([QueryKeys.ConfigGroup, tableGroupMode, tableArchived]);
            const groupsCopy = newData?.groups?.slice() || [];
            const groupIndex = groupsCopy.findIndex(group => group.id === variables.groupId);
            if (groupIndex !== -1) {
                const oldGroup = groupsCopy[groupIndex];
                groupsCopy[groupIndex] = {...oldGroup, ...variables.configGroupData};
                queryClient.setQueryData([QueryKeys.ConfigGroup, tableGroupMode, tableArchived], {groups: groupsCopy});
            }
            }else{
            queryClient.invalidateQueries([QueryKeys.ConfigGroup])
            }
        },
    })

    const formIsValid = isTouched && nameIsValid || Boolean(context);


    const checkValidity = (str: string) => {
        setIsTouched(true)

        if (str.length > 0) {
            setNameIsValid(true)

        } else {
            setNameIsValid(false)
            setCheckFieldsModal(true)
            setErrorMessage("Please provide a valid name")
        }
    }

    const handleCloseModal = () => {
        setName('')

        handleClose()
    }


    const submitHandler: FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault()


        if (context) {
            const updateConfigData: UpdateConfigGroupRequest = {
                app_related: appRelated,
                protected: isProtected,
                description: description.trim(),
                mode: groupMode.value
            }

            if (compareUpdateToConfig(updateConfigData, context)) {
                toast.warn('No changes were made.')
                handleCloseModal()

                return
            }

            updateConfigGroup({configGroupData: updateConfigData, groupId: context.id})
        } else {
            const newConfigData: CreateConfigGroupRequest = {
                group_name: name.trim(),
                app_related: appRelated,
                protected: isProtected,
                description: description.trim(),
                mode: groupMode.value
            }

            createConfigGroup(newConfigData)
        }
        handleCloseModal()
    }

    return (
        <>
            <Modal
                show={true}
                onHide={handleCloseModal}
                backdrop={'static'}
                centered
            >
                {/*@ts-ignore*/}
                <SweetAlert
                    show={checkFieldsModal}
                    warning
                    confirmBtnText="Okay"
                    confirmBtnBsStyle={'danger'}
                    title={"Wrong Format Type"}
                    onConfirm={() => {
                        setCheckFieldsModal(false)
                    }}
                    onCancel={() => setCheckFieldsModal(false)}
                >
                    {errorMessage}
                </SweetAlert>

                <div>
                    <div className='modal-content'>
                        <div className={styles.flex_row_between}>
                            <div className={styles.title_close_space}>
                                <h2 style={{fontWeight: 400}}>{context ? 'Edit Config Group' : 'Create New Config Group'}</h2>
                            </div>
                            <div className='modal_close_btn'>
                                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                                </div>
                            </div>
                        </div>
                        <form className={'form_container'} onSubmit={submitHandler}>
                            <div className={styles.modal_block_general}>
                                <Label className={'label_font_size'}> Name <span
                                    className={styles.mandatory_star}>*</span></Label>
                                <input value={name || ''}
                                       disabled={Boolean(context)}
                                       onBlur={(e) => checkValidity(e.target.value)}
                                       className={nameIsValid ? styles.classic_input : styles.borderRed}
                                       onChange={(e) => setName(e.target.value)}
                                       type="text"/>
                                <br/>
                                <Label className={'label_font_size'}> Description</Label>
                                <input value={description || ''}
                                       onChange={(e) => setDescription(e.target.value)}
                                       type="text"/>
                                <br/>
                                <Label className={styles.label_font_size}>Protected <span
                                    className={styles.mandatory_star}>*</span></Label>

                                <div className="form-check form-switch form-check-custom form-check-solid">
                                    <ReactSwitch checked={isProtected} onChange={setIsProtected} offColor={'#2d6f94'}
                                                 onColor={'#0095e8'}/>
                                </div>
                                <br/>
                                <Label className={styles.label_font_size}>App Related <span
                                    className={styles.mandatory_star}>*</span></Label>

                                <div className="form-check form-switch form-check-custom form-check-solid">
                                    <ReactSwitch checked={appRelated} onChange={setIsAppRelated} offColor={'#2d6f94'}
                                                 onColor={'#0095e8'}/>
                                </div>
                                <br/>
                                <Label className={'label_font_size'}> Group Mode <span
                                    className={styles.mandatory_star}>*</span></Label>
                                <Select value={groupMode}
                                        options={appModeList}
                                        onChange={(e) => {
                                            setGroupMode(e!)
                                        }}
                                />
                                <br/>
                                <div className={styles.confirm_btn}>
                                    <Button
                                        disabled={!formIsValid}
                                        type='submit'
                                    >{context ? 'Edit' : 'Create'}</Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ConfigGroupFormModal

import React, {PropsWithChildren, useEffect, useRef, useState} from "react"
import classes from "./IconWithBubble.module.scss"
import {CSSTransition} from "react-transition-group"
import {debounce} from "lodash"
import {KTSVG} from "../../../../_metronic/helpers";
import TaggingTooltip from "../../../modules/Community/Components/TaggingTooltip";
import useSVG from "../../../helpers/hooks/useSVG";
import {createPortal} from "react-dom";

const bubblesMapper = new Map()

const IconWithBubble: React.FC<PropsWithChildren> = ({children}) => {
    const [styles, setStyles] = useState<React.CSSProperties>();
    const [showBubble, setShowBubble] = useState(false)
    const nodeRef = useRef(null)
    const dotsRef = useRef<HTMLSpanElement>(null)
    const dotsSVG = useSVG('/media/icons/duotune/general/gen053.svg')
    const portalDomNode = document.getElementById('root-modals')

    useEffect(() => {
        const handleScroll = (event) => {
            event.preventDefault();
        }

        bubblesMapper.set(nodeRef, () => setShowBubble(false))
        window.addEventListener('scroll', handleScroll, { passive: false });

        return () => {
            bubblesMapper.clear()
            window.removeEventListener('scroll',handleScroll );

        }
    }, [bubblesMapper])



    const toggleBubble = () => {
        bubblesMapper.forEach((value, key) => {
            if (key !== nodeRef) {
                value()
            }
        })
        setShowBubble(prevState => !prevState)

        if (dotsRef && dotsRef.current) {
            const { x, y } = dotsRef.current.getBoundingClientRect();
            setStyles({position: 'absolute', left: x, top: y });
        }
    }

    const debouncedBubbleState = debounce(
        (open: boolean) => setShowBubble(open),
        150,
        {
            trailing: true,
        }
    )

    const portalChildren = <CSSTransition
        in={showBubble}
        nodeRef={nodeRef}
        timeout={{enter: 150, exit: 300}}
        classNames={{...classes}}
        unmountOnExit
    >
        <div
            className={classes.bubble}
            ref={nodeRef}
            style={styles}
            onMouseEnter={() => debouncedBubbleState(true)}
        >
            {children}
        </div>
    </CSSTransition>

    return (
        <div
            className={
                "position-relative d-flex justify-content-center align-items-center"
            }
            onClick={toggleBubble}
            onMouseLeave={() => debouncedBubbleState(false)}
        >
            <TaggingTooltip placement={'bottom'} tooltip={'More Actions'}>
                {dotsSVG && <KTSVG
                    ref={dotsRef}
                    path={dotsSVG}
                    className={`svg-icon-edit svg-icon-1 ${classes.icon}`}
                />}
            </TaggingTooltip>

            {createPortal(portalChildren, portalDomNode!)}

        </div>
    )
}

export default IconWithBubble

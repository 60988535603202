/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { AnnotationService } from './services/AnnotationService';
import { AppService } from './services/AppService';
import { AuthService } from './services/AuthService';
import { CommunityDeployService } from './services/CommunityDeployService';
import { CommunityDetectionService } from './services/CommunityDetectionService';
import { ConfigAppService } from './services/ConfigAppService';
import { ConfigDefaultService } from './services/ConfigDefaultService';
import { ConfigGeneralService } from './services/ConfigGeneralService';
import { ConfigGroupService } from './services/ConfigGroupService';
import { DefaultService } from './services/DefaultService';
import { SystemService } from './services/SystemService';
import { UserService } from './services/UserService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class AppClient {

    public readonly annotation: AnnotationService;
    public readonly app: AppService;
    public readonly auth: AuthService;
    public readonly communityDeploy: CommunityDeployService;
    public readonly communityDetection: CommunityDetectionService;
    public readonly configApp: ConfigAppService;
    public readonly configDefault: ConfigDefaultService;
    public readonly configGeneral: ConfigGeneralService;
    public readonly configGroup: ConfigGroupService;
    public readonly default: DefaultService;
    public readonly system: SystemService;
    public readonly user: UserService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '0.1.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.annotation = new AnnotationService(this.request);
        this.app = new AppService(this.request);
        this.auth = new AuthService(this.request);
        this.communityDeploy = new CommunityDeployService(this.request);
        this.communityDetection = new CommunityDetectionService(this.request);
        this.configApp = new ConfigAppService(this.request);
        this.configDefault = new ConfigDefaultService(this.request);
        this.configGeneral = new ConfigGeneralService(this.request);
        this.configGroup = new ConfigGroupService(this.request);
        this.default = new DefaultService(this.request);
        this.system = new SystemService(this.request);
        this.user = new UserService(this.request);
    }
}

import {CommunityFilterActions, CommunityFilterState, CommunitySegFilter} from "../../types/CommunityFilterTypes";
import {v4 as uuidv4} from 'uuid';
import {CommunityFilterActionTypes} from "../../types/StoreTypes";
import {toast} from "react-toastify";
import {FilterDataType} from "../../AUTO_GENERATED_TYPES";
import isFilterFull from "../../assets/utils/isFilterFull";


const deepCopyState = (state: CommunityFilterState) => {
    return JSON.parse((JSON.stringify(state))) as CommunityFilterState
}

const initialState: CommunityFilterState = {
    filters: [{
        id: uuidv4(),
        filterType: {value: "", label: "Filter By"},
        filterValue: [{value: "", label: "Value"}],
        filterValues: [{value: "", label: ""}],
        filter_operator: {value: "", label: "Operator"},
        filter_operators: [{value: "", label: ""}],
        dataType: null
    }],
    filterCount: 0
}

const reducer = (state = initialState, action: CommunityFilterActions) => {
    switch (action.type) {
        case CommunityFilterActionTypes.SET_COM_FILTERS:
            state = {filters: action.payload, filterCount: action.payload.length}
            break
        case CommunityFilterActionTypes.ADD_COM_FILTERS:
            const existingAddIndex = state.filters.findIndex(filter => String(filter.filterType.value) === String(action?.payload?.filterType.value))
            if (existingAddIndex !== -1) {
                const newFilters = JSON.parse(JSON.stringify(state.filters)) as CommunitySegFilter[]
                newFilters[existingAddIndex] = action.payload!
                state = {...state, filters: newFilters}
                break
            }

            if (state.filters.length === 5) {
                toast.warn('Max filters already applied.')
                state = {...state}
            } else {
                if (action.payload) {
                    if (state.filterCount === 0) {
                        state = {...state, filters: [action.payload], filterCount: state.filterCount + 1}
                    } else {
                        const filters = [...state.filters].filter(isFilterFull)
                        filters.push(action.payload)
                        state = {
                            ...state,
                            filters,
                            filterCount: filters.length
                        }
                    }
                } else {
                    state = {
                        ...state,
                        filters: [...state.filters, {
                            id: uuidv4(),
                            filterType: {value: "", label: "Filter By"},
                            filterValue: [],
                            filterValues: [{value: "", label: ""}],
                            filter_operator: {value: "", label: "Operator"},
                            filter_operators: [{value: "", label: "Value"}],
                            dataType: null
                        }]
                    }
                }
            }
            break
        case CommunityFilterActionTypes.REMOVE_COM_FILTERS_SUCCESS:
            if (state.filters.length === 1) {
                state = initialState
            } else {
                const newRemoveFilters = deepCopyState(state).filters.filter(filter => filter.id !== action.payload)
                const newCount = newRemoveFilters.reduce((prev, currEl) => Boolean(currEl.filterValue[0]?.value) ? prev + 1 : prev, 0)
                state = {filters: newRemoveFilters, filterCount: newCount}
            }
            break
        case CommunityFilterActionTypes.RESET_COM_FILTERS:
            state = initialState
            break
        case CommunityFilterActionTypes.TYPE_CHANGE:
            const newTypeChangeFilters = deepCopyState(state).filters
            if (!action.payload.inDeletion &&newTypeChangeFilters.some(filter => String(filter.filterType.value) === String(action.payload.newValue.value))) {
                toast.warn('This filter is already selected')
                break
            }
            const changedTypeIndex = newTypeChangeFilters.findIndex(filter => filter.id === action.payload.id)
            if (changedTypeIndex !== -1) {
                const valueOptions = action.payload.meta.filter_list?.map(option => ({
                    value: option.filter_name || '',
                    label: `${option.filter_name} (${option.filter_counter})`
                }))
                const operatorOptions = action.payload.meta.filter_operator_values?.map(option => ({
                    value: option,
                    label: option
                }))

                newTypeChangeFilters[changedTypeIndex].filterType = action.payload.newValue;
                newTypeChangeFilters[changedTypeIndex].filter_operators = operatorOptions ?? []
                newTypeChangeFilters[changedTypeIndex].filter_operator = newTypeChangeFilters[changedTypeIndex].filter_operators[0] || {
                    value: '',
                    label: 'Choose Operator'
                }
                newTypeChangeFilters[changedTypeIndex].filterValue = []
                newTypeChangeFilters[changedTypeIndex].filterValues = valueOptions ?? []
                newTypeChangeFilters[changedTypeIndex].dataType = action.payload.meta.filter_data_type

                let newFilterCount = deepCopyState(state).filterCount
                if (newTypeChangeFilters[changedTypeIndex].filterType.value !== '') {
                    newFilterCount = newTypeChangeFilters.filter(isFilterFull).length
                }

                state = {...state, filters: newTypeChangeFilters, filterCount: newFilterCount}
            }
            break
        case CommunityFilterActionTypes.OPERATOR_CHANGE:
            const newOperatorChangeFilters = deepCopyState(state).filters
            const changedOperatorIndex = newOperatorChangeFilters.findIndex(filter => filter.id === action.payload.id)
            if (changedOperatorIndex !== -1) {
                newOperatorChangeFilters[changedOperatorIndex].filter_operator = action.payload.newValue
                newOperatorChangeFilters[changedOperatorIndex].filterValue = []
                state = {...state, filters: newOperatorChangeFilters}
            }
            break
        case CommunityFilterActionTypes.VALUE_CHANGE:
            let newValueChangeFilters = deepCopyState(state).filters
            const changedValueIndex = newValueChangeFilters.findIndex(filter => filter.id === action.payload.id)
            if (changedValueIndex !== -1) {
                newValueChangeFilters[changedValueIndex].filterValue = [action.payload.newValue].flat()
                if(!action.payload.inDeletion){
                newValueChangeFilters = newValueChangeFilters.slice(0, changedValueIndex + 1)
                }
                state = {...state, filters: newValueChangeFilters, filterCount: newValueChangeFilters.length}
            }
            break
        case CommunityFilterActionTypes.SA_CHANGE:
            const newSAChangeState = deepCopyState(state)
            let saChangeFilters = newSAChangeState.filters
            const changedValueSAIndex = saChangeFilters.findIndex(filter => filter.filterType.label === 'Community Id')
            let newIndex = newSAChangeState.filterCount

            const filterTypeValue = action.payload.options?.filter_definition_id
            const operatorsOptions = action.payload.options?.filter_operator_values?.map(option => ({
                value: option,
                label: option
            }))
            const valuesOptions = action.payload.options?.filter_list?.map(option => ({
                value: option.filter_name || '',
                label: `${option.filter_name} (${option.filter_counter})`
            }))
            const newValue = valuesOptions?.find(el => el.value === action.payload.newValue)

            const changedFilter = {
                id: uuidv4(),
                filterType: {value: String(filterTypeValue || ''), label: "Community Id"},
                filterValue: [newValue ?? {value: "", label: ""}],
                filterValues: valuesOptions ?? [{value: "", label: ""}],
                filter_operator: {value: "==", label: "=="},
                filter_operators: operatorsOptions ?? [{value: "", label: ""}],
                dataType: FilterDataType.NUMERIC
            }

            if (changedValueSAIndex === -1) {
                if (action.payload.newValue === '1') {
                    return state
                } else {
                    if (newIndex === 0) {
                        saChangeFilters[0] = changedFilter

                        newIndex = newIndex + 1
                    } else {
                        if (state.filters.length === 5) {
                            toast.warn('Max filters already applied.')
                        } else {
                            saChangeFilters.push(changedFilter)
                            newIndex = newIndex + 1
                        }
                    }
                }
            } else {
                if (action.payload.newValue === '1') {
                    saChangeFilters = saChangeFilters.filter((_data, index) => index !== changedValueSAIndex)
                    if (saChangeFilters.length === 0) {
                        state = initialState
                        break
                    }
                    newIndex = newIndex - 1
                } else {
                    saChangeFilters[changedValueSAIndex] = changedFilter
                }
            }

            state = {...state, filters: saChangeFilters, filterCount: newIndex}
            break
        default:
            state = {...state}
    }
    return state
}

export default reducer

import {Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import {KTSVG} from "../../../../_metronic/helpers";
import React, {useCallback, useEffect, useState} from "react";
import styles from '../../../general-styles/Modals/modals.module.scss'
import {Link, useParams} from "react-router-dom";
import '../community.scss'
import {segmentTagging} from "../../../../store/community/actions";
import {PlotNameListResponse, SegmentTag} from "../../../../AUTO_GENERATED_TYPES";
import {useDispatch} from "react-redux";
import DetailsModal from "./DetailsModal";
import QueryKeys from "../../../../react-query/QueryKeys";
import {useQueryClient} from "react-query";

interface ZoomFileProps {
    show: boolean,
    handleCloseModal: any,
    file: string,
    title: string,
    index: number,
    imgId: number,
    segData: any,
    currentPage: number,
    totalItems: number,
    changePage: Function,
    data: any,
    itemsPerPage: any,
    taggingValue: any,
    tagList: any,
    setForbiddenAction: React.Dispatch<React.SetStateAction<boolean>>,
    imageType: { value: string, label: string },
    changeImageType: React.Dispatch<React.SetStateAction<{ value: string, label: string }>>,
    setImage: React.Dispatch<React.SetStateAction<string>>,
    setSegment: (seg: any) => void,
    setImgId: React.Dispatch<React.SetStateAction<number | undefined>>,
    setImgIndex: React.Dispatch<React.SetStateAction<number | undefined>>,
    setDetailFile
}

const ZoomImageModel = ({
                            show,
                            handleCloseModal,
                            file,
                            title,
                            index,
                            imgId,
                            segData,
                            currentPage,
                            changePage,
                            totalItems,
                            data,
                            itemsPerPage,
                            tagList,
                            taggingValue,
                            setForbiddenAction,
                            imageType,
                            changeImageType,
                            setImage,
                            setImgId,
                            setImgIndex,
                            setSegment,
                            setDetailFile,
                        }: ZoomFileProps) => {

    const dispatch = useDispatch()
    const [currentImg, setCurrentImg] = useState<any>()
    const [itemPerPage, setItemPerPage] = useState<number>()
    const [handleDetailFile, setHandleDetailFile] = useState<boolean>(false)
    const [segmentTagListToTag, setSegmentTagListToTag] = useState<any>([])
    const [currentTag, setCurrentTag] = useState<any>()

    const queryClient = useQueryClient()

    const {jobId: segId, type: segType} = useParams()

    const changeImageTypeWithArrows = useCallback((type: 'ArrowUp' | 'ArrowDown') => {
        const types = queryClient.getQueryData<PlotNameListResponse>([QueryKeys.CDPlotNames, Number(segId)])?.plot_names
        if (!types) {
            return
        }
        const currSeg = (segData as Array<any>).find(seg => seg.index === index)

        const typesArray = Object.entries(types)
        const currTypeIndex = typesArray.findIndex((value => value[0] === imageType.value))
        let nextIndex = currTypeIndex + (type === 'ArrowUp' ? -1 : 1)

        if (nextIndex < 0) {
            nextIndex = typesArray.length - 1;
        } else if (nextIndex >= typesArray.length) {
            nextIndex = 0;
        }
        const newType = typesArray.at( (currTypeIndex === -1 || nextIndex === typesArray.length) ? 0 : nextIndex)
        if (!newType) {
            return
        }

        const newTypeAsObj = {value: newType[0], label: newType[1]}
        if(newTypeAsObj.value === imageType.value || imageType.value === ''){
            const nextType =typesArray.at(((currTypeIndex === -1 || nextIndex === typesArray.length) ? 0 : nextIndex) + 1)!
            newTypeAsObj.value = nextType[0];
            newTypeAsObj.label = nextType[1];
        }


        if (currSeg) {
            changeImageType(newTypeAsObj)
            setImage(currSeg?.plots[newTypeAsObj.value] ?? Object.values(currSeg?.plots).at(0))
        }

    }, [queryClient, changeImageType, segData, file,index])

    const changeImageWithArrows = useCallback((event: KeyboardEvent) => {
        if (index !== -1 && segData && show) {
            const previous = segData.find(data => data.index === (index ?? 0) - 1)
            const next = segData.find(data => data.index === (index ?? 0) + 1)

            if (event.key === 'ArrowRight' && next) {
                setImgId(next?.id)
                setImgIndex(next?.index)
                setImage(imageType.value ? next?.plots[imageType.value] : Object.values(next?.plots ?? {})[0])
            } else if (event.key === 'ArrowLeft' && previous) {
                setImgId(previous?.id)
                setImgIndex(previous?.index)
                setImage(imageType.value ? previous?.plots[imageType.value] : Object.values(previous?.plots ?? {})[0])
            }

        }


    }, [show, index, segData, setImage, imageType])

    useEffect(() => {
        if (!show) {
            return
        }
        const handleKeyPress = (e: KeyboardEvent) => {

            e.preventDefault()
            if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
                changeImageTypeWithArrows(e.key as 'ArrowUp' | 'ArrowDown')
            } else if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
                changeImageWithArrows(e)
            }
        }
        document.addEventListener('keydown', handleKeyPress)

        return () => {
            document.removeEventListener('keydown', handleKeyPress)
        }

    }, [changeImageTypeWithArrows, changeImageWithArrows, show])


    useEffect(() => {
        setSegmentTagListToTag(tagList)
        setCurrentTag(tagList?.find((t: any) => (t === currentImg?.tag)))
    }, [currentImg])

    useEffect(() => {
        setItemPerPage(segData?.slice(-1)[0].index)
    }, [segData]);

    useEffect(() => {
        const selectedSegment = segData?.find((seg) => seg.index === index);
        setDetailFile(selectedSegment);
        setCurrentImg(selectedSegment);
    }, [index, file]);


    const updateTagIcon = (tagName: string, commIdx: number) => {
        if (segType === 'negative') {
            setForbiddenAction(true)
            return
        }
        const newSegmentListToTag = [...segmentTagListToTag];
        let indexArray = newSegmentListToTag.findIndex((i: any) => i.value === commIdx);
        // update the segmentTagListToTag with the new tag
        if (indexArray !== -1) {
            newSegmentListToTag[indexArray] = {value: commIdx, label: tagName};
        } else {
            newSegmentListToTag.push({value: commIdx, label: tagName});
        }
        setSegmentTagListToTag(newSegmentListToTag);

        // update the segmentTagIcons
        if (tagName !== currentTag) {
            const newSegmentTagIcons = [...segmentTagListToTag];
            newSegmentTagIcons[index] = tagName;
            setCurrentTag(newSegmentTagIcons[index]);
            if (tagName === 'POS') {
                dispatch(segmentTagging(Number(segId), {
                    "update_list": [{
                        segment_tag: SegmentTag.POS,
                        seg_id_list: [imgId]
                    }]
                }))
            } else if (tagName === 'NEG') {
                dispatch(segmentTagging(Number(segId), {
                    "update_list": [{
                        segment_tag: SegmentTag.NEG,
                        seg_id_list: [imgId]
                    }]
                }))
            } else if (tagName === 'UN_CERTAIN') {
                dispatch(segmentTagging(Number(segId), {
                    "update_list": [{
                        segment_tag: SegmentTag.UN_CERTAIN,
                        seg_id_list: [imgId]
                    }]
                }))
            } else {
                dispatch(segmentTagging(Number(segId), {
                    "update_list": [{
                        segment_tag: SegmentTag.UN_TAG,
                        seg_id_list: [imgId]
                    }]
                }))
            }
        }
    }

    return (
        <div>
            <DetailsModal data={data}
                          handleCloseModal={() => {
                              setHandleDetailFile(false)
                          }}
                          show={handleDetailFile}
                          title={'File'}
                          segId={segId}
                          currentPage={currentPage}
                          itemsPerPage={itemsPerPage}
                          segType={segType}
                          closeModal={handleCloseModal}
                          taggingValue={taggingValue}
                          segments={segData}
                          setSegment={setSegment}

            />
            <Modal show={show}
                   onHide={handleCloseModal}
                   centered
                   size="lg"
            >
                <div>
                    <div className='modal-content'>
                        <div className={styles.flex_row_between}>
                            <div className={styles.title_close_space}>
                                <h2>{title} Zoom In</h2>
                            </div>
                            <div className='modal_close_btn'>
                                <div className='btn btn-sm btn-icon btn-active-color-primary'
                                     onClick={handleCloseModal}>
                                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                                </div>
                            </div>
                        </div>
                        <div>
                            {file && <img src={file} alt=""
                                          width="100%" height='100%'/>}
                        </div>
                        <div className={'bottom_bar'}>
                            <div className={'seg_img_btns_modal'}>
                                <OverlayTrigger
                                    placement='bottom'
                                    overlay={<Tooltip>Positive</Tooltip>}
                                    defaultShow={undefined}
                                    delay={undefined}
                                    flip={undefined}
                                    onHide={undefined}
                                    onToggle={undefined}
                                    popperConfig={undefined}
                                    show={undefined}
                                    target={undefined}
                                    trigger={undefined}>
                                    <Link onClick={() => {
                                        updateTagIcon('POS', imgId)

                                    }} to={'#'}>
                                        <KTSVG
                                            path='/media/icons/duotune/general/gen041.svg'
                                            className={currentTag === 'POS' ? 'icon_selected svg-icon-2qx' : 'svg-icon-edit-unselected svg-icon-2qx'}
                                        />
                                    </Link>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement='bottom'
                                    overlay={<Tooltip>Negative</Tooltip>}
                                    defaultShow={undefined}
                                    delay={undefined}
                                    flip={undefined}
                                    onHide={undefined}
                                    onToggle={undefined}
                                    popperConfig={undefined}
                                    show={undefined}
                                    target={undefined}
                                    trigger={undefined}>
                                    <Link onClick={() => {
                                        updateTagIcon('NEG', imgId)
                                    }} to={'#'}>
                                        <KTSVG
                                            path='/media/icons/duotune/general/gen042.svg'
                                            className={currentTag === 'NEG' ? 'icon_selected svg-icon-2qx' : 'svg-icon-edit-unselected svg-icon-2qx'}
                                        />
                                    </Link>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement='bottom'
                                    overlay={<Tooltip>Unknown</Tooltip>}
                                    defaultShow={undefined}
                                    delay={undefined}
                                    flip={undefined}
                                    onHide={undefined}
                                    onToggle={undefined}
                                    popperConfig={undefined}
                                    show={undefined}
                                    target={undefined}
                                    trigger={undefined}>
                                    <Link onClick={() => {
                                        updateTagIcon('UN_CERTAIN', imgId)
                                    }} to={'#'}>
                                        <KTSVG
                                            path='/media/icons/duotune/general/gen046.svg'
                                            className={currentTag === 'UN_CERTAIN' ? 'icon_selected svg-icon-2qx' : 'svg-icon-edit-unselected svg-icon-2qx'}
                                        />
                                    </Link>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement='bottom'
                                    overlay={<Tooltip>UnTag</Tooltip>}
                                    defaultShow={undefined}
                                    delay={undefined}
                                    flip={undefined}
                                    onHide={undefined}
                                    onToggle={undefined}
                                    popperConfig={undefined}
                                    show={undefined}
                                    target={undefined}
                                    trigger={undefined}>
                                    <Link onClick={() => {
                                        updateTagIcon('UN_TAG', imgId)
                                    }} to={'#'}>
                                        <KTSVG
                                            path='/media/icons/duotune/arrows/arr029.svg'
                                            className={currentTag === 'UN_TAG' ? 'icon_selected svg-icon-2qx' : 'svg-icon-edit-unselected svg-icon-2qx'}

                                        />
                                    </Link>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement='bottom'
                                    overlay={<Tooltip>Details</Tooltip>}
                                    defaultShow={undefined}
                                    delay={undefined}
                                    flip={undefined}
                                    onHide={undefined}
                                    onToggle={undefined}
                                    popperConfig={undefined}
                                    show={undefined}
                                    target={undefined}
                                    trigger={undefined}>
                                    <Link to={'#'} onClick={() => {
                                        setHandleDetailFile(true)
                                    }}>
                                        <KTSVG
                                            path='/media/icons/duotune/general/gen004.svg'
                                            className='svg-icon-edit svg-icon-2qx'
                                        />
                                    </Link>
                                </OverlayTrigger>
                            </div>
                            <div style={{marginTop: '18px', marginRight: '20px'}}>
                                <b style={{marginRight: '10px'}}>{index}/{itemPerPage}</b>
                                <b>Page {currentPage}</b>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default ZoomImageModel

import {FilterResponse} from "../../AUTO_GENERATED_TYPES";
import {Option} from "../../types/CommunityFilterTypes";

const filterResponseToOption = (obj: FilterResponse): Option => {
    return{
        value: obj?.filter_name ?? "",
        label: `${obj.filter_name} (${obj.filter_counter})`
    }
}

export default filterResponseToOption

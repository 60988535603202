import {Button, Modal} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import { newUser, updateUser} from "../../../../store/users/actions";
import {KTSVG} from "../../../../_metronic/helpers";
import './users-modals.scss'
import styles from '../../../general-styles/Modals/modals.module.scss'
import {Label} from "reactstrap";
import StaticValues from "../../../../_metronic/helpers/StaticValues";
import SweetAlert from "react-bootstrap-sweetalert";
import {UserRole} from "../../../../AUTO_GENERATED_TYPES";
import {RootState} from "../../../../store/reducers";



interface NewUserProps {
    show: boolean,
    handleClose: () => void,
    isEdit?: boolean
    currentUser: any
}


const NewUser = ({show, handleClose, isEdit, currentUser}: NewUserProps) => {

    const dispatch = useDispatch();

    const {errorMsg} = useSelector((state: RootState) => ({
        errorMsg: state.users.errorMsg
    }))


    const [username, setUsername] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [fullName, setFullName] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [role, setRole] = useState<UserRole>(UserRole.ADMIN)

    const [errorMessage, setErrorMessage] = useState<string>('')
    const [wrongPasswordModal, setWrongPasswordModal] = useState<boolean>(false)
    const [wrongFieldModal, setWrongFieldModal] = useState<boolean>(false)

    const [passwordValid, setPasswordValid] = useState<boolean>(false)
    const [formValid, setFormValid] = useState<boolean>(false)
    const [editValid, setEditValid] = useState<boolean>(false)
    const [wrongMail, setWrongMail] = useState<boolean>(false)
    const [errorBorder, setErrorBorder] = useState<boolean>(false)
    const [editConfirmation, setEditConfirmation] = useState<boolean>(false)

    useEffect(() => {
        if (currentUser && isEdit) {
            setUsername(currentUser.username)
            setEmail(currentUser.email)
            setFullName(currentUser.full_name)
            setRole(currentUser.role)
        }
    }, [currentUser,isEdit]);

    useEffect(() => {
        if (!isEdit) {
            setUsername('')
            setEmail('')
            setFullName('')
            setRole(UserRole.ADMIN)
            setPassword('')
        }
    }, [isEdit]);

    useEffect(() => {
        if (!isEdit) {
            if (username.length > 0 && fullName.length > 0 && passwordValid && !wrongMail) {
                setFormValid(true)
            } else {
                setFormValid(false)
            }
        }
    }, [username, wrongMail, fullName, passwordValid, formValid])

    useEffect(() => {
        if (isEdit) {
            if (username.length > 0 && fullName.length > 0 && !wrongMail) {
                setEditValid(true)
            } else {
                setEditValid(false)
            }
        }
    }, [username, wrongMail, fullName, editValid,isEdit])


    const handleCloseModal = () => {
        setErrorBorder(false)
        setFormValid(false)
        handleClose()
    }

    const createUser = () => {
        let userData = {
            "username": username,
            "email": email,
            "full_name": fullName,
            "password": password,
            "role": role
        }
        dispatch(newUser(userData))
        handleClose()
    }

    const updateUserData = () => {
        const userData = {
            "username": username,
            "full_name": fullName,
            "email": email,
            "role": role
        }
        dispatch(updateUser(currentUser.id, userData))
        handleClose()
    }

    function checkPassword(value: string) {
        setErrorBorder(true)
        if (value.length < 8) {
            setErrorMessage("Your password must be at least 8 characters");
            setWrongPasswordModal(true)

        } else if (value.search(/[a-z]/i) < 0) {
            setErrorMessage("Your password must contain at least one letter.");
            setWrongPasswordModal(true)

        } else if (!value.match(/[A-Z]/)) {
            setErrorMessage("Your password must contain at least one capital letter.");
            setWrongPasswordModal(true)

        } else if (value.search(/[0-9]/) < 0) {
            setErrorMessage("Your password must contain at least one digit.");
            setWrongPasswordModal(true)

        } else {
            setErrorBorder(false)
            setPasswordValid(true)
        }
    }

    const checkFields = (value: string) => {
        let filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (!filter.test(value)) {
            setWrongFieldModal(true)
            setErrorMessage('Wrong Email format ! Please try again')
            setWrongMail(true)
        } else {
            setWrongMail(false)
        }
    }


    return (
        <>
            <Modal
                show={show}
                onHide={handleCloseModal}
                centered
            >
                {/*@ts-ignore*/}
                <SweetAlert
                    title={"Incorrect Password Format"}
                    danger
                    onConfirm={() => {
                        setWrongPasswordModal(false)
                    }}
                    show={wrongPasswordModal}
                    warning
                    confirmBtnText="Okay"
                >
                    {errorMessage}
                </SweetAlert>
                {/*@ts-ignore*/}
                <SweetAlert
                    title={'Invalid Format'}
                    danger
                    onConfirm={() => {
                        setWrongFieldModal(false)
                    }}
                    show={wrongFieldModal}
                    warning
                    confirmBtnText="Okay"
                >
                    {errorMessage}
                </SweetAlert>

                <div>
                    <div className='modal-content'>
                        <div className={styles.flex_row_between}>
                            <div className={styles.title_close_space}>
                                <h2>{isEdit ? <span style={{fontWeight: 400}}>Update {fullName} </span> :
                                    <span style={{fontWeight: 400}}>Create a new User</span>}</h2>
                            </div>
                            <div className='modal_close_btn'>
                                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                                </div>
                            </div>
                        </div>
                        <form className={'form_container'} action="">
                            <div className={styles.modal_block_general}>
                                <Label className={'label_font_size'}>User Name <span
                                   className={styles.mandatory_star}>*</span></Label>
                                <input value={username || ''} className={'classic_input'}
                                       onChange={(e) => setUsername(e.target.value)} type="text"/>
                                <br/>
                                <Label className={styles.label_font_size}>Email <span className={styles.mandatory_star}>*</span></Label>
                                <input onBlur={(e) => checkFields(e.target.value)} value={email || ''}
                                       className={wrongMail ? 'red_border' : 'classic_input'}
                                       onChange={(e) => setEmail(e.target.value)} type="text"/>
                                <br/>

                                <Label className={'label_font_size'}>Full Name <span
                                    className={styles.mandatory_star}>*</span></Label>
                                <input value={fullName || ''} className={'classic_input'}
                                       onChange={(e) => setFullName(e.target.value)} type="text"/>
                                <br/>
                                {!isEdit &&
                                    <>
                                        <Label className={styles.label_font_size}>Password <span className={styles.mandatory_star}>*</span></Label>
                                        <input onBlur={(e) => checkPassword(e.target.value)}
                                               className={errorBorder ? 'red_border' : 'classic_input'}
                                               onChange={(e) => setPassword(e.target.value)} type="text"/>
                                        <br/>
                                    </>
                                }


                                <Label className={'label_font_size'}>Role <span className={'mandatory_star'}>*</span></Label>
                                <select style={{marginBottom: '5px'}} className='classic_input'

                                        value={role} onChange={(e) => setRole(e.target.value as UserRole)}
                                        name="" id="">
                                    {StaticValues.rolesType.map((role) => (
                                        <option key={role.value} value={role.value}>{role.label}</option>
                                    ))}
                                </select>
                                <br/>
                                <div className={styles.confirm_btn}>
                                    <Button disabled={isEdit ? !editValid : !formValid}
                                            onClick={isEdit ? ()=> setEditConfirmation(true) : createUser}>{isEdit ? 'Update' : 'Create'}</Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
            {/*@ts-ignore*/}
            <SweetAlert
                show={editConfirmation}
                warning
                showCancel
                confirmBtnText="Yes"
                cancelBtnText="Cancel"
                confirmBtnBsStyle={'danger'}
                title={"Update User"}
                onConfirm={() => {
                    updateUserData()
                    setEditConfirmation(false)
                }}
                onCancel={() => setEditConfirmation(false)}
            >
                Are you sure you want to edit <span style={{fontWeight: 'bold'}}>{username}</span>
            </SweetAlert>
        </>
    )
}

export default NewUser

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ConfigType {
    BOOLEAN = 'BOOLEAN',
    STR = 'STR',
    INT = 'INT',
    FLOAT = 'FLOAT',
    JSON = 'JSON',
    ENUM = 'ENUM',
    URL = 'URL',
}

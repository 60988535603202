import {createRoot} from 'react-dom/client'
import '../src/_metronic/helpers/api_helper'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from "react-query/devtools";
// Apps
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import {AppRoutes} from './app/routing/AppRoutes'
import {AuthProvider, setupAxios} from './app/modules/auth'
import { Provider } from "react-redux"

import store from "./store"
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import queryClient from "./react-query/queryClient";

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios)

Chart.register(...registerables)

const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
      <QueryClientProvider client={queryClient}>
          <ToastContainer
              position="bottom-left"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={true}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
          />
          <Provider store={store}>

              <AuthProvider>
                  <AppRoutes />
              </AuthProvider>
          </Provider>
          {/*<ReactQueryDevtools/>*/}
      </QueryClientProvider>

  )
}

import {Modal} from "reactstrap";
import ReactJson from "react-json-view";
import React from "react";

interface JsonViewerProps{
    isOpen:boolean,
    toggle:()=>void,
    json: object
}

const JsonViewer: React.FC<JsonViewerProps> = ({isOpen,toggle,json})=>{

   return <Modal isOpen={isOpen} toggle={toggle} fade={false} style={{ maxWidth:'max-content'}} >
       <div className={"p-4"}>
        <ReactJson src={json}/>
       </div>
    </Modal>
}

export default JsonViewer

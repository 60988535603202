import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import gamelogo from '../../../../assets/images/gamelogodefault.jpg'
import NewAppModal from "../Modals/NewAppModal";
import {KTSVG} from "../../../../_metronic/helpers";
import {
    archiveApp,
    archiveAppList,
    cloneApp,
    deleteApp,
    deleteAppList,
    getApps,
    unArchiveApp,
    unArchiveAppList
} from "../../../../store/actions";
import UpdateAppModal from "../Modals/UpdateAppModal";
import '../appstable.scss'
import {Button, Col, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
// @ts-ignore
import BootstrapTable from 'react-bootstrap-table-next';
// @ts-ignore
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
// @ts-ignore
import paginationFactory from 'react-bootstrap-table2-paginator';
import {Link} from "react-router-dom";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import StaticValues from "../../../../_metronic/helpers/StaticValues";
import SweetAlert from "react-bootstrap-sweetalert";
import {PageLink, PageTitle} from "../../../../_metronic/layout/core";
// import JsonViewModal from "../Modals/JsonViewModal";
import {AppFilter, AppMode, AppResponse} from "../../../../AUTO_GENERATED_TYPES";
import {RootState} from "../../../../store/reducers";

type confirmTypeType = 'Archive' | 'Unarchive' | 'Archive List' | 'Unarchive List' | 'Clone' | 'Delete List';

type AppsTableProps = {}

const AppsTable: React.FC<AppsTableProps> = ({}) => {

    const [appId, setAppId] = useState<string>('')
    const [filter, setFilter] = useState<AppFilter>(AppFilter.ACTIVE)
    const [mode, setMode] = useState<AppMode>(AppMode.LIVE)
    const [appName, setAppName] = useState<string>('')
    const [menu, setMenu] = useState<boolean>(false)
    const [multipleSelection, setMultipleSelection] = useState<boolean>(false)
    const [appModal, setAppModal] = useState<boolean>(false)
    const [updateAppModal, setUpdateAppModal] = useState<boolean>(false)
    const [currentApp, setCurrentApp] = useState<AppResponse | undefined>()
    const [targetArray, setTargetArray] = useState<(null | number)[]>([])
    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const [confirmationModal, setConfirmationModal] = useState<boolean>(false)
    const [confirmMessage, setConfirmMessage] = useState<string>('')
    const [confirmType, setConfirmType] = useState<confirmTypeType | ''>('')
    const [archiveMode, setArchiveMode] = useState<boolean>(false)


    const {appsList} = useSelector((state: RootState) => ({
        appsList: state.appsReducer.appsList,
    }))
    const {processResult} = useSelector((state: RootState) => ({
        processResult: state.appsReducer.processResult,
    }))

    const {SearchBar} = Search;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getApps(mode, filter))
    }, [mode, filter]);

    useEffect(() => {
        if (processResult === true) {
            dispatch(getApps(mode, filter))
        }
    }, [processResult])

    useEffect(() => {
        setTargetArray([])
    }, [filter])

    useEffect(() => {
        if (appsList.length > 0) {
            const activeApps = appsList.filter((app: any) => app.app_status === filter)
            let inArray = activeApps.some((app: any) => targetArray.some((id) => id === app.app_id))
            if (filter === StaticValues.modeFilter.filterAll) {
                inArray = true;
            }
            if (targetArray.length > 1 && inArray) {
                setMultipleSelection(true)
            } else {
                setMultipleSelection(false)
            }
        }
    }, [targetArray, appsList, filter])

    let searchApp: AppResponse | undefined | null;
    const updateApp = (id: any) => {
        if (appsList) {
            searchApp = appsList.find((app: any) => app.app_id === id) as AppResponse
            setCurrentApp(searchApp)
            setUpdateAppModal(true)
        }
    }

    const handleContextMenu = (id: string, appName: string) => {
        setAppId(id)
        setAppName(appName)
        setMenu(prevState => !prevState)
    }

    const handleOnSelect = (row: any, isSelect: boolean) => {
        if (row.app_status === 'ARCHIVED') {
            setArchiveMode(true)
        } else {
            setArchiveMode(false)
        }
        if (isSelect) {
            setTargetArray([...targetArray, row.app_id])
        } else {
            const selectedAcc = targetArray.filter((a: any) => {
                return a !== Number(row.app_id);
            });
            setTargetArray([...selectedAcc]);
        }
    }

    const handleOnSelectAll = (isSelect: boolean, row: any) => {
        if (row[0].app_status === 'ARCHIVED') {
            setArchiveMode(true)
        } else {
            setArchiveMode(false)
        }
        if (isSelect) {
            setTargetArray(row.map((rowUnity: any) => rowUnity.app_id))
        } else {
            setTargetArray([])
        }
    }

    function imageFormatter(cell: any, row: any) {
        if (row.app_name) {
            return (
                <span className={'imageFormatter'}>
                    <p className={'imageFormatter__p'}>
                        <img className={'imageFormatter__image'}
                             src={row.app_image ? row.app_image : gamelogo} alt="logo"
                        />
                        <span className={'imageFormatter__name'} title={cell}>{cell}</span>
                    </p>
                </span>
            );
        }
    }

    const handleDeleteModal = (id: any, name: any) => {
        setAppId(id)
        setAppName(name)
        setDeleteModal(true)
    }

    //function to close menu when click outside
    const parentHandler = () => {
        setMenu(false)

    };
    //function to keep menu open if clicking inside
    const childHandler = (e: any) => {
        e.stopPropagation();
    };

    const columns = [
        {
            dataField: 'app_id',
            hidden: true,
            text: '#',
            sort: true,
            headerStyle: (colum: any, colIndex: any) => {
                return {width: '35px', textAlign: 'center'};
            },
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            }
        },
        {
            dataField: 'app_name',
            text: 'App Name',
            editable: false,
            sort: true,
            style: {
                marginLeft: '10px',
                borderBottom: '.5px solid #d0d0d0',
                verticalAlign: 'middle',

            },
            formatter: imageFormatter
        },
        {
            dataField: 'company_name',
            text: 'Company Name',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0',
                verticalAlign: 'middle',

            }
        },
        {
            dataField: 'app_status',
            text: 'App Status',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0',
                verticalAlign: 'middle',
            }
        },
        {
            dataField: 'app_token',
            text: 'App Token',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0',
                verticalAlign: 'middle',
            }
        },
        {
            dataField: 'app_mode',
            text: 'App Mode',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0',
                verticalAlign: 'middle',
            },
        },
        {
            dataField: "actions",
            isDummyField: true,
            editable: false,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0',
                verticalAlign: 'middle',
            },
            text: "Actions",
            formatter: (cellContent: any, row: any, rowIndex: any, extraData: any) => (
                <>
                    <div style={{position: "relative"}}>
                        {extraData.appId === row.app_id && extraData.menu &&
                            <div className="menu_context">
                                <h6 className='svg-icon-context'>{row.app_name}</h6>
                                <br/>
                                {row.app_status === "ARCHIVED" ? (
                                    <p onClick={(e) => {
                                        childHandler(e)
                                        setAppId(row.app_id)
                                        setConfirmType('Unarchive')
                                        setConfirmationModal(true)
                                        setConfirmMessage(`Are you sure you want to unarchive ${row.app_name} ?`)
                                    }}>
                                        Unarchive
                                    </p>
                                ) : (
                                    <>
                                        <p onClick={(e) => {
                                            childHandler(e)
                                            setAppId(row.app_id)
                                            setConfirmType('Archive')
                                            setConfirmationModal(true)
                                            setConfirmMessage(`Are you sure you want to archive ${row.app_name} ?`)
                                        }}>
                                            <KTSVG
                                                path='/media/icons/duotune/files/fil017.svg'
                                                className='svg-icon-context'
                                            /> Archive
                                        </p>
                                        <p onClick={(e) => {
                                            childHandler(e)
                                            setAppId(row.app_id)
                                            setConfirmType('Clone')
                                            setConfirmationModal(true)
                                            setConfirmMessage(`Are you sure you want to clone ${row.app_name} ?`)
                                        }}>
                                            <KTSVG
                                                path='/media/icons/duotune/general/gen028.svg'
                                                className='svg-icon-context'
                                            /> Clone
                                        </p>
                                        <p>
                                            <KTSVG
                                                path='/media/icons/duotune/general/gen024.svg'
                                                className='svg-icon-context'
                                            />
                                            <Link className='config_link'
                                                  to={`/community/all/${row.app_id}`}>
                                                Community Jobs
                                            </Link>
                                        </p>
                                        <p>
                                            <KTSVG
                                                path='/media/icons/duotune/text/txt009.svg'
                                                className='svg-icon-context'
                                            />
                                            <Link className='config_link'
                                                  to={`/config/config-app/?appId=${row.app_id}`}>
                                                Config Items
                                            </Link>
                                        </p>

                                        {/*<p */}
                                        {/*    onClick={(e) => {*/}
                                        {/*    childHandler(e)*/}
                                        {/*    dispatch(getAppConfig(row.app_token))*/}
                                        {/*}}>App Config*/}
                                        {/*</p>*/}
                                        {/*<p onClick={(e) => {*/}
                                        {/*    childHandler(e)*/}
                                        {/*    setJsonViewModal(true)*/}
                                        {/*}}>Json View Compare*/}
                                        {/*</p>*/}
                                    </>
                                )
                                }
                            </div>
                        }

                        <div className={'table_action'}>
                            <OverlayTrigger
                                placement='bottom'
                                overlay={<Tooltip>Menu</Tooltip>}
                                defaultShow={undefined}
                                delay={undefined}
                                flip={undefined}
                                onHide={undefined}
                                onToggle={undefined}
                                popperConfig={undefined}
                                show={undefined}
                                target={undefined}
                                trigger={undefined}>
                                <Link
                                    to="#"
                                    className="text-success"
                                    id='menuapp'
                                    onClick={(e) => {
                                        childHandler(e)
                                        handleContextMenu(row.app_id, row.app_name)
                                    }}
                                >
                                    <KTSVG
                                        path='/media/icons/duotune/general/gen019.svg'
                                        className='svg-icon-actions svg-icon-1'
                                    />
                                </Link>
                            </OverlayTrigger>
                            {row.app_status !== 'ARCHIVED' &&
                                <OverlayTrigger
                                    placement='bottom'
                                    overlay={<Tooltip>Edit App</Tooltip>}
                                    defaultShow={undefined}
                                    delay={undefined}
                                    flip={undefined}
                                    onHide={undefined}
                                    onToggle={undefined}
                                    popperConfig={undefined}
                                    show={undefined}
                                    target={undefined}
                                    trigger={undefined}>
                                    <Link
                                        to="#"
                                        className="text-danger"
                                        //@ts-ignore
                                        onClick={() => updateApp(row.app_id)}
                                    >
                                        <KTSVG path='/media/icons/duotune/art/art005.svg'
                                               className='svg-icon-edit svg-icon-1'/>

                                    </Link>
                                </OverlayTrigger>
                            }
                            <OverlayTrigger
                                placement='bottom'
                                overlay={<Tooltip>Delete App</Tooltip>}
                                defaultShow={undefined}
                                delay={undefined}
                                flip={undefined}
                                onHide={undefined}
                                onToggle={undefined}
                                popperConfig={undefined}
                                show={undefined}
                                target={undefined}
                                trigger={undefined}>
                                <Link
                                    to="#"
                                    className="link-primary"
                                    onClick={() => handleDeleteModal(row.app_id, row.app_name)}
                                >
                                    <KTSVG
                                        path='/media/icons/duotune/general/gen027.svg'
                                        className='svg-icon-delete svg-icon-1'
                                    />
                                </Link>
                            </OverlayTrigger>
                        </div>
                    </div>
                </>
            ),
            formatExtraData: {appId, menu}
        },
    ];

    const selectRow = {
        mode: 'checkbox',
        dbClickToSelect: true,
        selected: targetArray,
        onSelect: handleOnSelect,
        onSelectAll: handleOnSelectAll,
        nonSelectable: [0],
        headerColumnStyle: {padding: '8px'},
        selectColumnStyle: {
            padding: '8px',
            borderBottom: '.5px solid #d0d0d0',
            verticalAlign: 'middle',
            textAlign: "center"
        },
    };

    const appsBreadcrumbs: Array<PageLink> = [
        {
            title: '/',
            path: '/dashboard/apps',
            isSeparator: false,
            isActive: false,
        },
    ]

    return (
        <div onClick={parentHandler}>
            <PageTitle breadcrumbs={appsBreadcrumbs}>Apps</PageTitle>
            {/*@ts-ignore*/}
            <NewAppModal isEdit={false} show={appModal} handleClose={() => {
                setAppModal(false)
            }}/>
            <UpdateAppModal currentApp={currentApp} show={updateAppModal} handleClose={() => {
                setUpdateAppModal(false)
            }}/>
            {/*<JsonViewModal show={jsonViewModal} handleClose={()=> setJsonViewModal(false)}/>*/}

            {appsList ? (
                <>
                    <ToolkitProvider
                        keyField="app_id"
                        data={appsList ? appsList : []}
                        columns={columns}
                        search
                        bootstrap4
                    >
                        {
                            (props: any) => (
                                <div style={{padding: '15px'}}>
                                    <div>
                                        <br/>
                                        <div className={'toolbar_table'}>
                                            <div>
                                                <SearchBar {...props.searchProps} />
                                            </div>
                                            {multipleSelection &&
                                                <>
                                                    {archiveMode ? (
                                                        <div className={'flex_row_space'}>
                                                            <Button onClick={() => {
                                                                setConfirmType('Unarchive List')
                                                                setConfirmationModal(true)
                                                                setConfirmMessage(`Are you sure you want to unarchive this list ?`)
                                                            }}> <KTSVG
                                                                path='/media/icons/duotune/files/fil018.svg'
                                                                className='svg-icon-context'
                                                            /> Unarchive Selected</Button>
                                                            <Button onClick={() => {
                                                                setConfirmType('Delete List')
                                                                setConfirmationModal(true)
                                                                setConfirmMessage(`Are you sure you want to delete this list ?`)
                                                            }} variant={"danger"} className="btn--second">
                                                                <KTSVG
                                                                    path='/media/icons/duotune/general/gen027.svg'
                                                                    className='svg-icon-del'
                                                                /> Delete Selected</Button>
                                                        </div>
                                                    ) : (
                                                        <div className={'flex_row_space'}>
                                                            <Button onClick={() => {
                                                                setConfirmType('Archive List')
                                                                setConfirmationModal(true)
                                                                setConfirmMessage(`Are you sure you want to archive this list ?`)
                                                            }}><KTSVG
                                                                path='/media/icons/duotune/files/fil017.svg'
                                                                className='svg-icon-context'
                                                            /> Archive Selected</Button>
                                                            <Button onClick={() => {
                                                                setConfirmType('Delete List')
                                                                setConfirmationModal(true)
                                                                setConfirmMessage(`Are you sure you want to delete this list ?`)
                                                            }} variant={"danger"} className="btn--second">
                                                                <KTSVG
                                                                    path='/media/icons/duotune/general/gen027.svg'
                                                                    className='svg-icon-del'
                                                                /> Delete Selected</Button>
                                                        </div>
                                                    )
                                                    }
                                                </>
                                            }
                                            <div>
                                                <div className={'filter_section'}>
                                                    <div className={'filter_box'}>
                                                        <select className={'filter_select'}
                                                                onChange={(e) => setFilter(e.target.value as AppFilter)}>
                                                            {StaticValues.filterType.map((filter: any) => (
                                                                <option key={filter.value}
                                                                        value={filter.value}>{filter.label}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className={'filter_box'}>
                                                        <select className={'filter_select'}
                                                                defaultValue={'LIVE'}
                                                                onChange={(e) => setMode(e.target.value as AppMode)}>
                                                            {StaticValues.modeType.map((mode: any) => (
                                                                <option key={mode.value}
                                                                        value={mode.value}>{mode.label}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <Button className={'add_btn'} onClick={() => setAppModal(true)}>
                                                        {/*@ts-ignore*/}
                                                        <div style={{marginRight: "10px"}}>
                                                            <KTSVG
                                                                path='/media/icons/duotune/arrows/arr052.svg'
                                                                className='svg-icon svg-icon-2x'
                                                            />
                                                        </div>
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        <br/>
                                    </div>
                                    <Row>
                                        <Col xl='12'>
                                            <BootstrapTable
                                                {...props.baseProps}
                                                keyField='app_id'
                                                pagination={paginationFactory()}
                                                bordered={true}
                                                responsive
                                                stripped
                                                condensed
                                                headerWrapperClasses={"header-class"}
                                                selectRow={selectRow}
                                            />
                                        </Col>

                                    </Row>
                                </div>
                            )
                        }
                    </ToolkitProvider>
                </>) : (
                <>
                    <p>Loading....</p>
                </>
            )
            }
            {/*@ts-ignore*/}
            <SweetAlert
                show={deleteModal}
                warning
                showCancel
                confirmBtnText="Yes"
                cancelBtnText="Cancel"
                confirmBtnBsStyle={'danger'}
                title={"Delete App"}
                onConfirm={() => {
                    dispatch(deleteApp(+appId))
                    setDeleteModal(false)
                }}
                onCancel={() => setDeleteModal(false)}
            >
                Are you sure you want to delete <span style={{fontWeight: 'bold'}}>{appName}</span>
            </SweetAlert>
            {/*@ts-ignore*/}
            <SweetAlert
                show={confirmationModal}
                warning
                showCancel
                confirmBtnText="Yes"
                cancelBtnText="Cancel"
                confirmBtnBsStyle={'danger'}
                title={`${confirmType} App`}
                onConfirm={() => {
                    if (confirmType === 'Unarchive') {
                        dispatch(unArchiveApp(+appId))
                        setMenu(false)
                    } else if (confirmType === 'Archive') {
                        dispatch(archiveApp(+appId))
                        setMenu(false)
                    } else if (confirmType === 'Clone') {
                        dispatch(cloneApp(+appId))
                        setMenu(false)
                    } else if (confirmType === 'Unarchive List') {
                        dispatch(unArchiveAppList(targetArray.join()))
                        setArchiveMode(false)
                        setMultipleSelection(false)
                    } else if (confirmType === 'Delete List') {
                        dispatch(deleteAppList(targetArray.join()))
                        setArchiveMode(false)
                        setMultipleSelection(false)
                    } else if (confirmType === 'Archive List') {
                        dispatch(archiveAppList(targetArray.join()))
                        setArchiveMode(false)
                        setMultipleSelection(false)
                    }
                    setConfirmationModal(false)
                    setTargetArray([])
                }}
                onCancel={() => {
                    setConfirmationModal(false)
                    setMenu(false)
                }}
            >
                {confirmMessage}
            </SweetAlert>
        </div>
    )
}

export default AppsTable

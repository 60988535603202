
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
// import {UsersListWrapper} from "../../apps/user-management/users-list/UsersList";
import {TablesWidget11, TablesWidget13} from "../../../../_metronic/partials/widgets";


const PermissionsPage = () => {

    const permissionsBreadcrumbs: Array<PageLink> = [
        {
            title: 'Home /',
            path: '/dashboard/apps',
            isSeparator: false,
            isActive: false,
        },
    ]

    return(
            <>
                <PageTitle breadcrumbs={permissionsBreadcrumbs}>Permissions</PageTitle>
                <TablesWidget13 className={'table'}/>
            </>
        )

}

export default PermissionsPage

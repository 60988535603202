import React from 'react';
import './apps-modals.scss'
import NewAppModal from "./NewAppModal";
import {AppResponse} from "../../../../AUTO_GENERATED_TYPES";



interface UpdateAppProps {
    show: boolean,
    handleClose: () => void,
    currentApp?: AppResponse
}


const UpdateAppModal = ({show, handleClose, currentApp }: UpdateAppProps) =>{

    return(
        <>
            <NewAppModal isEdit={true} currentApp={currentApp} show={show} handleClose={handleClose}/>
        </>
    )
}

export default UpdateAppModal

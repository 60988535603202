import {useParams} from "react-router-dom";
import {PageLink, PageTitle} from "../../../../_metronic/layout/core";
import React, {useState} from "react";
import {ConfigHistoryResponse, GroupMode} from "../../../../AUTO_GENERATED_TYPES";
import {Col, Row} from "react-bootstrap";
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import tableClasses from "../../Community/pages/table.module.scss";
import Moment from "moment";
import BootstrapTable from 'react-bootstrap-table-next';
import {useIsFetching, useQuery} from "react-query";
import QueryKeys from "../../../../react-query/QueryKeys";
import {getAppAudits, getDefaultAudits,} from "../../../../_metronic/helpers/backend_helper";
import caseInsensitiveSort from "../../../helpers/utils/sorting/caseInsensitiveSort";
import IconWithBubble from "../../../Components/UI/IconWithBubble/IconWithBubble";
import {KTSVG} from "../../../../_metronic/helpers";
import useSVG from "../../../helpers/hooks/useSVG";
import TaggingTooltip from "../../Community/Components/TaggingTooltip";

const ConfigAudits = () => {
    const rollbackSVG = useSVG('/media/icons/duotune/arrows/arr029.svg')
    const createSVG = useSVG('/media/icons/duotune/general/gen041.svg')
    const cogSVG = useSVG('/media/icons/duotune/coding/cod001.svg')
    const resetSVG = useSVG('/media/icons/duotune/arrows/arr043.svg')
    const trashSVG = useSVG('/media/icons/duotune/general/gen027.svg')

    const loadedSVG = rollbackSVG && createSVG && cogSVG && resetSVG && trashSVG

    const params = useParams()
    const defaultId = params.defaultId
    const appId = params.appId

    const {SearchBar} = Search;

    const isFetching = useIsFetching()


    const {
        data: defaultAudits,
    } = useQuery([QueryKeys.DefaultAudit, defaultId], () => getDefaultAudits(Number(defaultId)), {
        retry: false,
        refetchOnWindowFocus: false,
        enabled: Boolean(defaultId),
        placeholderData: null,
    })

    const {
        data: appAudits,
    } = useQuery([QueryKeys.AppAudit, appId], () => getAppAudits(Number(appId)), {
        retry: false,
        refetchOnWindowFocus: false,
        enabled: Boolean(appId)
    })

    const dataToRender = defaultAudits ?? appAudits

    const historyData = dataToRender?.history_list

    const columns = [
        {
            dataField: 'config_hist_id',
            hidden: true,
            text: '#',
            sort: true,
        },
        {
            dataField: 'creation_time',
            text: 'Timestamp',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0',
                minWidth:'150px'

            },
            formatter: ((cellContent: string) => {
                return <div style={{minWidth: 'max-content'}}>{Moment(cellContent).format('YYYY/MM/DD HH:mm')}</div>
            })
        },
        {
            dataField: 'config_src_before',
            text: 'Source Before',
            editable: false,
            sort: true,
            style: {
                borderBottom: '.5px solid #d0d0d0',
                textAlign: 'center',
                minWidth:'150px'

            },
        },
        {
            dataField: 'config_src_after',
            text: 'Source After',
            editable: false,
            sort: true,
            style: {
                borderBottom: '.5px solid #d0d0d0',
                textAlign: 'center',
                minWidth:'150px'

            },
        },
        {
            dataField: 'config_value_before',
            text: 'Value Before',
            editable: false,
            sort: true,
            style: {
                borderBottom: '.5px solid #d0d0d0',
                textAlign: 'center',
                minWidth:'150px'
            },
            formatter: ((cellContent: string) => <TaggingTooltip placement={'bottom'}
                                                                 tooltip={cellContent?.split(',').map(value => <div
                                                                     key={value}>{value}</div>)}>
                <div className={'seg_long_copy'}>{cellContent}</div>
            </TaggingTooltip>)
        },
        {
            dataField: 'config_value_after',
            text: 'Value After',
            editable: false,
            sort: true,
            style: {
                borderBottom: '.5px solid #d0d0d0',
                textAlign: 'center',
                minWidth:'150px'
            },
            formatter: ((cellContent: string) => <TaggingTooltip placement={'bottom'}
                                                                 tooltip={cellContent?.split(',').map(value => <div
                                                                     key={value}>{value}</div>)}>
                <div className={'seg_long_copy'}>{cellContent}</div>
            </TaggingTooltip>)
        },
        {
            dataField: 'user_name',
            text: 'User',
            editable: false,
            sort: true,
            sortFunc: caseInsensitiveSort,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0',
                minWidth:'150px'
            },
        },
        // {
        //     dataField: "actions",
        //     isDummyField: true,
        //     editable: false,
        //     style: {
        //         textAlign: 'center',
        //         borderBottom: '.5px solid #d0d0d0'
        //     },
        //     text: "Actions",
        //     formatter: (cellContent: any, row: ConfigHistoryResponse, rowIndex: any, extraData: any) => (
        //         <>
        //             <div className={'position-relative'}>
        //                 <div className={'table_action'} style={{gap: '10px'}}>
        //                     <IconWithBubble>
        //                         <h6>{row.user_name} - {Moment(row.creation_time).format('YYYY/MM/DD HH:mm')}</h6>
        //                        <div
        //                             onClick={() => {
        //
        //                             }}
        //                            style={{cursor: 'not-allowed',filter:'grayScale(100%)'}}
        //                         >{rollbackSVG && <KTSVG path={rollbackSVG!}
        //                                                 className='svg-icon-context svg-icon-1'
        //                                                 loader={!rollbackSVG}/>} Rollback</div>
        //
        //                        <div
        //                             onClick={() => {
        //
        //                             }}
        //                            style={{cursor: 'not-allowed',filter:'grayScale(100%)'}}
        //                         >{createSVG && <KTSVG
        //                            path={createSVG || ''}
        //                            className='svg-icon-context svg-icon-1'
        //                            loader={!createSVG}
        //                        />} Create</div>
        //
        //                        <div
        //                             onClick={() => {
        //
        //                             }}
        //                            style={{cursor: 'not-allowed',filter:'grayScale(100%)'}}
        //                         >{cogSVG && <KTSVG
        //                            path={cogSVG || ''}
        //                            className='svg-icon-context svg-icon-1'
        //                            loader={!cogSVG}
        //                        />} Update</div>
        //
        //                        <div
        //                             onClick={() => {
        //
        //                             }}
        //                            style={{cursor: 'not-allowed',filter:'grayScale(100%)'}}
        //                         >{resetSVG && <KTSVG
        //                            path={resetSVG || ''}
        //                            className='svg-icon-context svg-icon-1'
        //                            loader={!resetSVG}
        //                        />} Reset</div>
        //
        //                        <div
        //                             onClick={() => {
        //
        //                             }}
        //                            style={{cursor: 'not-allowed',filter:'grayScale(100%)'}}
        //                         >{trashSVG && <KTSVG
        //                            path={trashSVG || ''}
        //                            className='svg-icon-context svg-icon-1'
        //                            loader={!trashSVG}
        //                        />} Delete</div>
        //                     </IconWithBubble>
        //                 </div>
        //             </div>
        //         </>
        //     ),
        // },
    ];


    const configBreadcrumbs: Array<PageLink> = [
        {
            title: 'Home /',
            path: '/dashboard/apps',
            isSeparator: false,
            isActive: false,
        },
    ]

    if (defaultId) {
        configBreadcrumbs.push({
            title: 'Config Default /',
            path: '/config/config-default',
            isSeparator: false,
            isActive: false,
        },)
    }

    if (appId) {
        configBreadcrumbs.push({
            title: 'App Config /',
            path: '/config/config-app',
            isSeparator: false,
            isActive: false,
        },)
    }


    if(!loadedSVG){
        return null
    }

    return <>
        <PageTitle breadcrumbs={configBreadcrumbs}>Config's Audits</PageTitle>
        <ToolkitProvider
            keyField="config_hist_id"
            data={historyData ?? []}
            columns={columns}
            search
            bootstrap4
        >
            {
                (props: any) => (
                    <div style={{ padding: '15px'}}>
                        <div>
                            <br/>
                            <div className={'d-flex gap-5'}>
                                <span>
                                    Config Name: <b>{defaultAudits?.central_config_name ?? appAudits?.app_config_name}</b>
                                </span>
                                <span>
                                    Config Group: <b>{defaultAudits?.config_group ?? appAudits?.config_group}</b>
                                </span>
                                <span>
                                    Config Mode: <b>{defaultAudits?.config_group_mode ?? appAudits?.config_group_mode}</b>
                                </span>
                                <span>
                                    App Related:  <b>{(defaultAudits?.config_group_app_related ?? appAudits?.config_group_app_related) ? 'True' : 'False'}</b>
                                </span>
                                {appAudits &&<span>
                                    App: <b>{appAudits.app_name} ({appAudits.app_token})</b>
                                </span>}
                            </div>
                            <br/>

                            <div className={'toolbar_table'}>
                                <div>
                                    <SearchBar {...props.searchProps} />
                                </div>
                                {/*<div className={'filter_toolbar'} style={{zIndex: 20}}>*/}
                                    {/*<select value={groupMode} onChange={(e) => {*/}
                                    {/*    setGroupMode(e.target.value as GroupMode)*/}
                                    {/*    // setPage(1)*/}
                                    {/*}}>*/}
                                    {/*    <option value={GroupMode.ALL}>- Group Mode -</option>*/}
                                    {/*    <option value={GroupMode.LIVE}>Live</option>*/}
                                    {/*    <option value={GroupMode.DEMO}>Demo</option>*/}
                                    {/*    <option value={GroupMode.TEST}>Test</option>*/}
                                    {/*</select>*/}

                                    {/*{groupsOption && <Select isMulti placeholder={'Groups'}*/}
                                    {/*                         value={filterGroups.length > 0 ? filterGroups : null}*/}
                                    {/*                         options={groupsOption}*/}
                                    {/*                         onChange={(e) => {*/}
                                    {/*                             navigate('/config/config-default')*/}
                                    {/*                             setFilterGroups(e as Option[])*/}
                                    {/*                         }}*/}
                                    {/*                         styles={{*/}
                                    {/*                             control: (baseStyles) => ({*/}
                                    {/*                                 ...baseStyles,*/}
                                    {/*                                 borderRadius: '8px',*/}
                                    {/*                                 minWidth: '200px'*/}
                                    {/*                             }),*/}
                                    {/*                         }}/>}*/}
                                {/*</div>*/}
                            </div>

                            <br/>
                        </div>
                        <Row>
                            <Col xl='12'
                                 className={`${tableClasses.table_wrapper} ${tableClasses['table_wrapper--community']}`}>
                                {historyData && historyData?.length > 0 ? (
                                    <BootstrapTable
                                        {...props.baseProps}
                                        keyField='config_hist_id'
                                        pagination={paginationFactory()}
                                        bordered={true}
                                        responsive
                                        stripped
                                        headerWrapperClasses={"header-class"}
                                    />) : isFetching ? (
                                    <>
                                        <p>Loading....</p>
                                    </>
                                ) : <p>No Audits Found</p>
                                }
                            </Col>
                        </Row>
                    </div>
                )
            }
        </ToolkitProvider>
    </>
}

export default ConfigAudits;

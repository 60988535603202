/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum TagFilter {
    TAGGED = 'TAGGED',
    ALL = 'ALL',
    NEW = 'NEW',
    POS = 'POS',
    NEG = 'NEG',
    UN_TAG = 'UN_TAG',
    UN_CERTAIN = 'UN_CERTAIN',
}

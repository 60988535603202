import React, {useEffect, useState} from 'react';
import {PageLink, PageTitle} from "../../../../_metronic/layout/core";
//@ts-ignore
import BootstrapTable from 'react-bootstrap-table-next';
//@ts-ignore
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import NewConfig from "../../Config/Modals/NewConfig";
import EditConfig from "../../Config/Modals/EditConfig";
import alertStyle from "../../../general-styles/Alerts/alert-cards.module.scss";
import {Alert, Button, Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {KTSVG} from "../../../../_metronic/helpers";
import {useDispatch, useSelector} from "react-redux";
import {getConfig, getDefaultGroups} from "../../../../store/config/actions";
import {Link} from "react-router-dom";
//@ts-ignore
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import {getAppConfig} from "../../../../store/actions";

const AppConfig = () =>{

    const dispatch = useDispatch();

    const path = window.location.pathname;
    const directories = path.split("/");
    const appToken = directories[(directories.length - 1)];
    const appName = directories[(directories.length - 2)].replace(/%20/g, " ")

    const {SearchBar} = Search;

    const { appConfig, processResult } = useSelector((state:any) => ({
        appConfig: state.appsReducer.appConfig,
        processResult: state.appsReducer.processResult,
    }))

    const [tokenApp, setTokenApp] = useState<any>(appToken)
    const [currentConfig, setCurrentConfig] = useState()
    const [editConfigModal, setEditConfigModal] = useState<boolean>(false)
    //
    useEffect(() => {
        if(appConfig.length <= 0){
            dispatch(getAppConfig(appToken))
        }
    }, []);

    useEffect(() => {
        if (processResult) {
            dispatch(getAppConfig(appToken))
        }
    }, [processResult])

    useEffect(() => {
        console.log(appConfig)
    },[appConfig])

    // useEffect(() => {
    //     if(configList.length <= 0){
    //         dispatch(getConfig())
    //     }
    //     if(groupsList.length <= 0){
    //         dispatch(getDefaultGroups())
    //     }
    // },[])

    const updateConfig = (id: any) => {
        if (appConfig.configs) {
            let currConfig = appConfig.configs.find((conf: any) => conf.config_id === id)
            setCurrentConfig(currConfig)
            console.log(currentConfig)
        }
    }

    const columns = [
        {
            dataField: 'config_id',
            hidden: true,
            text: '#',
            sort: true,
            headerStyle: (colum: any, colIndex: any) => {
                return {width: '35px', textAlign: 'center'};
            },
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            }
        },
        {
            dataField: 'config_groups[0].group_name',
            text: 'Group',
            editable: false,
            sort: true,
            headerStyle: (colum: any, colIndex: any) => {
                return {width: '355px'};
            },
            style: {
                borderBottom: '.5px solid #d0d0d0',
                // textAlign: 'center',
                // marginLeft:'30px'
                paddingLeft:'8px'
            },

        },
        {
            dataField: 'config_name',
            text: 'Name',
            editable: false,
            headerStyle: (colum: any, colIndex: any) => {
                return {width: '355px'};
            },
            sort: true,
            style: {
                borderBottom: '.5px solid #d0d0d0'
            }
        },
        {
            dataField: 'config_value',
            text: 'Value',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            }
        },
        {
            dataField: 'config_src',
            text: 'Source',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            }
        },
        {
            dataField: 'config_type',
            text: 'Type',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            }
        },
        {
            dataField: 'config_description',
            text: 'Description',
            editable: false,
            sort: true,
            style: {
                borderBottom: '.5px solid #d0d0d0',
            },
        },
        {
            dataField: "actions",
            isDummyField: true,
            editable: false,
            headerStyle: (colum: any, colIndex: any) => {
                return {width: '15%'};
            },
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            text: "Actions",
            formatter: (cellContent: any, row: any, rowIndex: any, extraData: any) => (
                <>
                    <div style={{position: "relative"}}>

                        <div className={'table_action'}>
                            <OverlayTrigger
                                placement='bottom'
                                overlay={<Tooltip>Edit Config</Tooltip>}
                                defaultShow={undefined}
                                delay={undefined}
                                flip={undefined}
                                onHide={undefined}
                                onToggle={undefined}
                                popperConfig={undefined}
                                show={undefined}
                                target={undefined}
                                trigger={undefined}>
                            <Link
                                to="#"
                                className="text-danger"
                                //@ts-ignore
                                onClick={() => {
                                    updateConfig(row.config_id)
                                    setEditConfigModal(true)
                                }}
                            >
                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-edit svg-icon-1'/>
                            </Link>
                            </OverlayTrigger>
                            <OverlayTrigger
                                placement='bottom'
                                overlay={<Tooltip>View History</Tooltip>}
                                defaultShow={undefined}
                                delay={undefined}
                                flip={undefined}
                                onHide={undefined}
                                onToggle={undefined}
                                popperConfig={undefined}
                                show={undefined}
                                target={undefined}
                                trigger={undefined}>
                            <Link
                                to="#"
                                className="text-success"
                                id='menuapp'
                            >
                                <KTSVG
                                    path='/media/icons/duotune/arrows/arr025.svg'
                                    className='svg-icon-edit svg-icon-1'
                                />
                            </Link>
                            </OverlayTrigger>
                            <OverlayTrigger
                                placement='bottom'
                                overlay={<Tooltip>Rollback Changes</Tooltip>}
                                defaultShow={undefined}
                                delay={undefined}
                                flip={undefined}
                                onHide={undefined}
                                onToggle={undefined}
                                popperConfig={undefined}
                                show={undefined}
                                target={undefined}
                                trigger={undefined}>
                            <Link
                                to="#"
                                className="link-primary"
                            >
                                <KTSVG
                                    path='/media/icons/duotune/arrows/arr021.svg'
                                    className='svg-icon-edit svg-icon-1'
                                />

                            </Link>
                            </OverlayTrigger>

                        </div>
                    </div>
                </>
            ),
        },
    ];

    const configBreadcrumbs: Array<PageLink> = [
        {
            title: 'Apps /',
            path: '/dashboard/apps',
            isSeparator: false,
            isActive: false,
        },
    ]

    return(
        <>
            <PageTitle breadcrumbs={configBreadcrumbs}>App Config</PageTitle><h4>Central Config - {appName}</h4>
            {/*<EditConfig show={editConfigModal} handleClose={()=> setEditConfigModal(false)} currentConfig={currentConfig} isAppConfig={true} appToken={tokenApp} />*/}
            {appConfig.configs ? (
                <>
                    <ToolkitProvider
                        keyField="config_id"
                        data={appConfig.configs ? appConfig.configs : []}
                        columns={columns}
                        search
                        bootstrap4
                    >
                        {
                            (props: any) => (
                                <div style={{padding: '15px'}}>
                                    <div>
                                        <br/>
                                        <div className={'toolbar_table'}>
                                            <div>
                                                <SearchBar {...props.searchProps} />
                                            </div>
                                        </div>
                                        <br/>
                                    </div>
                                    <Row>
                                        <Col xl='12'>
                                            <BootstrapTable
                                                {...props.baseProps}
                                                keyField='config_id'
                                                pagination={paginationFactory()}
                                                bordered={true}
                                                responsive
                                                stripped
                                                headerWrapperClasses={"header-class"}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            )
                        }
                    </ToolkitProvider>
                </>
            ) : (
                <>
                    <p>Loading....</p>
                </>
            )
            }
        </>
    )
}

export default AppConfig

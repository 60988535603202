import {KTSVG} from "../../../../../../_metronic/helpers";
import React from "react";
import LongDetailCell from "../../../../Community/Components/LongDetailCell";
import {AnnotationSegFilter} from "../../../../../../types/AnnotationTypes";
import {Label} from "reactstrap";
import {useNavigate, useParams} from "react-router-dom";
import useCreateFilterLink from "../../../Hooks/useCreateFilterLink";

interface DetailsLinkProps {
    label: string,
    data: string | any[],
    type: string
    onClick: (filter?: (AnnotationSegFilter | undefined)) => void,
}

const DetailsLink: React.FC<DetailsLinkProps> = ({label, type, data, onClick}) => {
    const {id} = useParams()
    const annotationId = Number(id)
    const navigate = useNavigate();

    const filter = useCreateFilterLink(type, data)

    const clickHandler = () => {
        if (filter) {

            onClick(filter)

        }
    }

    return <div>
        <Label>
            <KTSVG
                path='/media/icons/duotune/general/gen031.svg'
                className='svg-icon-edit svg-icon-2'
            />
            {label}
        </Label>
        <LongDetailCell data={data === '' ? "N/A" : data ?? 'N/A'} routeOnClick={clickHandler}/>

    </div>
}

export default DetailsLink

import {UsersActions, UserState} from "../../types/UserTypes";
import { UserActionTypes} from "../../types/StoreTypes";
import {Reducer} from "redux";

const initialState: UserState = {
    loading:false,
    errorMsg: "",
    successMsg:'',
    usersList:[],
    result:false,
    updateRes:false,
    newPassword:''
}
const users = (state = initialState, action:UsersActions) :UserState => {
    switch (action.type) {
        case UserActionTypes.GET_USERS:
            return {
                ...state,
                loading: true,
            }

        case UserActionTypes.GET_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                usersList: action.payload?.users ?? []
            }

        case UserActionTypes.GET_USERS_FAIL:
            return {
                ...state,
                loading: false,
                errorMsg: action.payload
            }

        case UserActionTypes.CREATE_NEW_USER_SUCCESS:
            return {
                ...state,
                updateRes: action.payload.result,
                successMsg: action.payload.msg
            }

        case UserActionTypes.CREATE_NEW_USER_FAIL:
            return {
                ...state,
               errorMsg: action.payload.msg
            }

        case UserActionTypes.UPDATE_USER_SUCCESS:
            return {
                ...state,
                updateRes: action.payload.result,
                successMsg: action.payload.msg

            }

        case UserActionTypes.UPDATE_USER_FAIL:
            return {
                ...state,
                updateRes: action.payload.result,
                errorMsg: action.payload.msg
            }

        case UserActionTypes.DELETE_USER_SUCCESS:
            return {
                ...state,
                updateRes: action.payload.result,
                successMsg: action.payload.msg
            }

        case UserActionTypes.DELETE_USER_FAIL:
            return {
                ...state,
                updateRes: action.payload.result,
                errorMsg: action.payload.msg
            }
        case UserActionTypes.RESET_PASSWORD:
            return {
                ...state,
            }

        case UserActionTypes.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                updateRes: action.payload.result,
                successMsg: action.payload.msg,
                newPassword:action.payload.password?.new_password ?? ''
            }

        case UserActionTypes.RESET_PASSWORD_FAIL:
            return {
                ...state,
                updateRes: action.payload.result,
                errorMsg: action.payload.msg,
                newPassword:''
            }

        default:
            return { ...state }
    }

}

export default users

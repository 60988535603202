import {AppsActionTypes} from "../../types/StoreTypes";
import {
    AppFilter,
    AppListResponse,
    AppMode,
    AppResponse,
    CreateAppRequest,
    UpdateAppRequest
} from "../../AUTO_GENERATED_TYPES";
import {
    ArchiveApp,
    ArchiveAppFail, ArchiveAppList, ArchiveAppListFail, ArchiveAppListSuccess,
    ArchiveAppSuccess,
    CloneApp,
    CloneAppFail,
    CloneAppSuccess,
    CreateApp,
    CreateAppFail,
    CreateAppSuccess,
    DeleteApp, DeleteAppConfigFail, DeleteAppConfigSuccess,
    DeleteAppFail, DeleteAppList, DeleteAppListFail, DeleteAppListSuccess,
    DeleteAppSuccess,
    GetApp, GetAppConfigFail, GetAppConfigSuccess,
    GetAppFail,
    GetAppSuccess,
    UnArchiveApp,
    UnArchiveAppFail,
    UnArchiveAppList,
    UnArchiveAppListFail,
    UnArchiveAppListSuccess,
    UnArchiveAppSuccess,
    UpdateApp, UpdateAppConfigFail, UpdateAppConfigSuccess,
    UpdateAppFail,
    UpdateAppSuccess
} from "../../types/AppTypes";



export const getApps = (mode:AppMode, filter?:AppFilter | undefined):GetApp => ({
    type: AppsActionTypes.GET_APPS,
    payload: {mode, filter}
})
export const getAppSuccess = (apps:AppListResponse):GetAppSuccess => ({
    type: AppsActionTypes.GET_APPS_SUCCESS,
    payload:apps
})
export const getAppFail = (result:boolean, msg:string) : GetAppFail=> ({
    type: AppsActionTypes.GET_APPS_FAIL,
    payload: {result, msg}
})
export const createApp = (newApp:CreateAppRequest):CreateApp => ({
    type: AppsActionTypes.CREATE_APP,
    payload: newApp
})
export const createAppSuccess = (addedApp:AppResponse | null,result:boolean, msg:string):CreateAppSuccess => ({
    type: AppsActionTypes.CREATE_APP_SUCCESS,
    payload: {addedApp,result, msg}
})
export const createAppFail = (result:boolean, msg:string):CreateAppFail => ({
    type: AppsActionTypes.CREATE_APP_FAIL,
    payload: {result, msg}
})
export const updateApp = (updatedApp:UpdateAppRequest, id:number):UpdateApp => ({
    type: AppsActionTypes.UPDATE_APP,
    payload: {updatedApp, id}
})
export const updateAppSuccess = (result:boolean, msg:string) :UpdateAppSuccess=> ({
    type: AppsActionTypes.UPDATE_APP_SUCCESS,
    payload: {result, msg}
})
export const updateAppFail = (result:boolean, msg:string):UpdateAppFail => ({
    type: AppsActionTypes.UPDATE_APP_FAIL,
    payload: {result, msg}
})
export const deleteApp = (id:number): DeleteApp => ({
    type: AppsActionTypes.DELETE_APP,
    payload: id
})
export const deleteAppSuccess = (id:number, result:boolean, msg:string):DeleteAppSuccess => ({
    type: AppsActionTypes.DELETE_APP_SUCCESS,
    payload: {id, result, msg}
})
export const deleteAppFail = (result:boolean, msg:string):DeleteAppFail => ({
    type: AppsActionTypes.DELETE_APP_FAIL,
    payload: {result, msg}
})
export const archiveApp = (id:number) : ArchiveApp=> ({
    type: AppsActionTypes.ARCHIVE_APP,
    payload: id
})
export const archiveAppSuccess = (id:number, result:boolean, msg:string):ArchiveAppSuccess => ({
    type: AppsActionTypes.ARCHIVE_APP_SUCCESS,
    payload: {id, result, msg}
})
export const archiveAppFail = (result:boolean, msg:string):ArchiveAppFail => ({
    type: AppsActionTypes.ARCHIVE_APP_FAIL,
    payload: {result, msg}
})
export const unArchiveApp = (id:number) :UnArchiveApp => ({
    type: AppsActionTypes.UNARCHIVE_APP,
    payload: id
})
export const unArchiveAppSuccess = (id:number, result:boolean, msg:string):UnArchiveAppSuccess => ({
    type: AppsActionTypes.UNARCHIVE_APP_SUCCESS,
    payload: {id, result, msg}
})
export const unArchiveAppFail = (result:boolean, msg:string):UnArchiveAppFail => ({
    type: AppsActionTypes.UNARCHIVE_APP_FAIL,
    payload: {result, msg}
})
export const cloneApp = (id:number) : CloneApp => ({
    type: AppsActionTypes.CLONE_APP,
    payload: id
})
export const cloneAppSuccess = (app:AppResponse, result:boolean, msg:string) :CloneAppSuccess=> ({
    type: AppsActionTypes.CLONE_APP_SUCCESS,
    payload: {app, result, msg}
})
export const cloneAppFail = (result:boolean, msg:string):CloneAppFail => ({
    type: AppsActionTypes.CLONE_APP_FAIL,
    payload: {result, msg}
})
export const unArchiveAppList = (ids:string) :UnArchiveAppList=> ({
    type: AppsActionTypes.UNARCHIVE_APP_LIST,
    payload: ids
})
export const unArchiveAppListSuccess = (ids:string, result:boolean, msg:string) : UnArchiveAppListSuccess => ({
    type: AppsActionTypes.UNARCHIVE_APP_LIST_SUCCESS,
    payload: {ids, result, msg}
})
export const unArchiveAppListFail = (result:boolean, msg:string) :UnArchiveAppListFail=> ({
    type: AppsActionTypes.UNARCHIVE_APP_LIST_FAIL,
    payload: {result, msg}
})
export const deleteAppList = (ids:string):DeleteAppList => ({
    type: AppsActionTypes.DELETE_APP_LIST,
    payload: ids
})
export const deleteAppListSuccess = (ids:string, result:boolean, msg:string):DeleteAppListSuccess => ({
    type: AppsActionTypes.DELETE_APP_LIST_SUCCESS,
    payload: {ids, result, msg}
})
export const deleteAppListFail = (result:boolean, msg:string):DeleteAppListFail => ({
    type: AppsActionTypes.DELETE_APP_LIST_FAIL,
    payload: {result, msg}
})
export const archiveAppList = (ids:string):ArchiveAppList => ({
    type: AppsActionTypes.ARCHIVE_APP_LIST,
    payload: ids
})
export const archiveAppListSuccess = (ids:string, result:boolean, msg:string):ArchiveAppListSuccess => ({
    type: AppsActionTypes.ARCHIVE_APP_LIST_SUCCESS,
    payload: {ids, result, msg}
})
export const archiveAppListFail = (result:boolean, msg:string): ArchiveAppListFail=> ({
    type: AppsActionTypes.ARCHIVE_APP_LIST_FAIL,
    payload: {result, msg}
})
export const getAppConfig = (appToken:string)  => ({
    type: AppsActionTypes.GET_APP_CONFIG,
    payload: appToken
})
export const getAppConfigSuccess = (config:any) : GetAppConfigSuccess=> ({
    type: AppsActionTypes.GET_APP_CONFIG_SUCCESS,
    payload: config
})
export const getAppConfigFail = (result:boolean, msg:string) : GetAppConfigFail => ({
    type: AppsActionTypes.GET_APP_CONFIG_FAIL,
    payload: {result, msg}
})

export const updateAppConfig = (configData:any, appToken:string) => ({
    type: AppsActionTypes.UPDATE_APP_CONFIG,
    payload: {configData, appToken}
})
export const updateAppConfigSuccess = (result:boolean, msg:string) : UpdateAppConfigSuccess => ({
    type: AppsActionTypes.UPDATE_APP_CONFIG_SUCCESS,
    payload: {result, msg}
})
export const updateAppConfigFail = (result:boolean, msg:string) : UpdateAppConfigFail => ({
    type: AppsActionTypes.UPDATE_APP_CONFIG_FAIL,
    payload: {result, msg}
})

export const deleteAppConfig = (appToken:string, configId:number) => ({
    type: AppsActionTypes.DELETE_APP_CONFIG,
    payload: {appToken, configId}
})
export const deleteAppConfigSuccess = (result:boolean, msg:string) : DeleteAppConfigSuccess => ({
    type: AppsActionTypes.DELETE_APP_CONFIG_SUCCESS,
    payload: {result, msg}
})
export const deleteAppConfigFail = (result:boolean, msg:string) : DeleteAppConfigFail => ({
    type: AppsActionTypes.DELETE_APP_CONFIG_FAIL,
    payload: {result, msg}
})

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum AnnotationJobStatus {
    NEW = 'NEW',
    IN_REVIEW = 'IN_REVIEW',
}

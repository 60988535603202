import * as SagaEffects from "redux-saga/effects"
import {call, delay, put} from "redux-saga/effects"

// Login Redux States
import {
    archiveAppListSuccess,
    archiveAppSuccess,
    cloneAppSuccess,
    createAppSuccess,
    deleteAppConfigSuccess,
    deleteAppListSuccess,
    deleteAppSuccess,
    getAppSuccess,
    unArchiveAppListSuccess,
    unArchiveAppSuccess,
    updateAppConfigSuccess,
    updateAppSuccess
} from "./actions"
//Static
import StaticValues from "../../../src/_metronic/helpers/StaticValues";

//Include Both Helper File with needed methods
import {
    archiveApp,
    archiveAppList,
    cloneApp,
    createApp,
    deleteApp,
    deleteAppList,
    getApps,
    unArchiveApp,
    unArchiveAppList,
    updateApp,
} from "../../_metronic/helpers/backend_helper"
import {changePreloader} from "../layout/actions";
import {AppsActionTypes} from "../../types/StoreTypes";
import {
    AppFilter,
    AppListResponse,
    AppMode,
    AppResponse,
    CreateAppRequest,
    UpdateAppRequest
} from "../../AUTO_GENERATED_TYPES";
import {toast} from "react-toastify";

const {takeEvery} = SagaEffects as any

function* generalSagaError(error: any) {
    yield put(changePreloader(false))
    console.log(error)
    // toast.error(error.message)
}

function* getAllApps({payload: {mode, filter}}: { payload: { mode: AppMode, filter?: AppFilter | undefined } }) {

    yield put(changePreloader(true))
    try {
        const response: AppListResponse = yield call(getApps, mode, filter)
        yield put(getAppSuccess(response))
        yield put(changePreloader(false))
    } catch (error) {
        yield* generalSagaError(error)
    }
}

function* createNewApp({payload: app}: { payload: CreateAppRequest }) {

    yield put(changePreloader(true))
    try {
        const response: AppResponse = yield call(createApp, app)
        yield put(createAppSuccess(response, true, 'Successfully created app'))
        yield put(changePreloader(false))
        toast.success('App successfully created', {autoClose: StaticValues.waitingTimeForAlert})
        yield delay(StaticValues.waitingTimeForAlert)
        yield put(createAppSuccess(response, false, ''))
    } catch (error) {
        yield* generalSagaError(error)
    }
}

function* updateAppSaga({payload: {updatedApp, id}}: { payload: { updatedApp: UpdateAppRequest, id: number } }) {
    yield put(changePreloader(true))
    try {
        yield call(updateApp, updatedApp, id)
        yield put(changePreloader(false))
        toast.success('Apps successfully updated', {autoClose: StaticValues.waitingTimeForAlert})
        yield put(updateAppSuccess(true, ''))
        yield delay(StaticValues.waitingTimeForAlert)
        yield put(updateAppSuccess(false, ''))
    } catch (error) {
        yield* generalSagaError(error)

    }
}

function* deleteAppSaga({payload: id}: { payload: number }) {
    yield put(changePreloader(true))
    try {
        yield call(deleteApp, id)
        yield put(deleteAppSuccess(id, true, 'App successfully deleted'))
        yield put(changePreloader(false))
        toast.success('App successfully deleted!', {autoClose: StaticValues.waitingTimeForAlert})
        yield delay(StaticValues.waitingTimeForAlert)
        yield put(deleteAppSuccess(id, false, ''))
    } catch (error) {
        yield* generalSagaError(error)

    }
}

function* archiveAppSaga({payload: id}: { payload: number }) {

    yield put(changePreloader(true))
    try {
        yield call(archiveApp, id)
        yield put(archiveAppSuccess(id, true, 'App successfully archived'))
        yield put(changePreloader(false))
        toast.success('App successfully archived!', {autoClose: StaticValues.waitingTimeForAlert})
        yield delay(StaticValues.waitingTimeForAlert)
        yield put(archiveAppSuccess(id, false, ''))
    } catch (error) {
        yield* generalSagaError(error)

    }
}

function* unArchiveAppSaga({payload: id}: { payload: number }) {

    yield put(changePreloader(true))
    try {
        yield call(unArchiveApp, id)
        yield put(unArchiveAppSuccess(id, true, 'App successfully unarchived'))
        yield put(changePreloader(false))
        toast.success('App successfully unarchived!', {autoClose: StaticValues.waitingTimeForAlert})
        yield delay(StaticValues.waitingTimeForAlert)
        yield put(unArchiveAppSuccess(id, false, ''))
    } catch (error) {
        yield* generalSagaError(error)

    }
}

function* cloneAppSaga({payload: id}: { payload: number }) {
    yield put(changePreloader(true))
    try {
        const response: AppResponse = yield call(cloneApp, id)
        yield put(changePreloader(false))
        toast.success('App successfully cloned', {autoClose: StaticValues.waitingTimeForAlert})
        yield put(cloneAppSuccess(response, true, ''))
        yield delay(StaticValues.waitingTimeForAlert)
        yield put(cloneAppSuccess(response, false, ''))
    } catch (error) {
        yield* generalSagaError(error)

    }
}

function* unArchiveAppsListSaga({payload: ids}: { payload: string }) {

    yield put(changePreloader(true))
    try {
        yield call(unArchiveAppList, ids)
        yield put(unArchiveAppListSuccess(ids, true, 'Apps successfully unarchived'))
        yield put(changePreloader(false))
        toast.success('Apps successfully unarchived', {autoClose: StaticValues.waitingTimeForAlert})
        yield delay(StaticValues.waitingTimeForAlert)
        yield put(unArchiveAppListSuccess(ids, false, ''))
    } catch (error) {
        yield* generalSagaError(error)

    }
}

function* deleteAppsListSaga({payload: ids}: { payload: string }) {
    yield put(changePreloader(true))
    try {
        yield call(deleteAppList, ids)
        yield put(deleteAppListSuccess(ids, true, 'Apps successfully deleted'))
        yield put(changePreloader(false))
        toast.success('Apps successfully deleted!', {autoClose: StaticValues.waitingTimeForAlert})
        yield delay(StaticValues.waitingTimeForAlert)
        yield put(deleteAppListSuccess(ids, false, ''))
    } catch (error) {
        yield* generalSagaError(error)

    }
}

function* archiveAppListSaga({payload: ids}: { payload: string }) {
    yield put(changePreloader(true))
    try {
        yield call(archiveAppList, ids)
        yield put(changePreloader(false))
        toast.success('App successfully archived!', {autoClose: StaticValues.waitingTimeForAlert})
        yield put(archiveAppListSuccess(ids, true, ''))
        yield delay(StaticValues.waitingTimeForAlert)
        yield put(archiveAppListSuccess(ids, false, ''))
    } catch (error) {
        yield* generalSagaError(error)

    }
}

function* getAppConfigSaga({payload: appToken}: { payload: string }) {
    yield put(changePreloader(true))
    try {
        // const response:AppResponse = yield call(getAppConfig, appToken)
        // yield put(getAppConfigSuccess(response))
        yield put(changePreloader(false))
    } catch (error) {
        yield* generalSagaError(error)

    }
}

function* updateAppConfigSaga({payload: {configData, appToken}}: { payload: any }) {

    yield put(changePreloader(true))
    try {
        // yield call(updateAppConfig, configData, appToken)
        yield put(changePreloader(false))
        toast.success('App Config successfully updated', {autoClose: StaticValues.waitingTimeForAlert})
        yield put(updateAppConfigSuccess(true, ''))
        yield delay(StaticValues.waitingTimeForAlert)
        yield put(updateAppConfigSuccess(false, ''))
    } catch (error) {
        yield* generalSagaError(error)


    }

}

function* deleteAppConfigSaga({payload: {configId, appToken}}: { payload: any }) {

    yield put(changePreloader(true))
    try {
        // yield call(deleteAppConfig, configId, appToken)
        yield put(changePreloader(false))
        toast.success('App Config successfully deleted', {autoClose: StaticValues.waitingTimeForAlert})
        yield put(deleteAppConfigSuccess(true, ''))
        yield delay(StaticValues.waitingTimeForAlert)
        yield put(deleteAppConfigSuccess(false, ''))
    } catch (error) {
        yield* generalSagaError(error)


    }

}

function* appsSaga() {
    yield takeEvery(AppsActionTypes.GET_APPS, getAllApps)
    yield takeEvery(AppsActionTypes.CREATE_APP, createNewApp)
    yield takeEvery(AppsActionTypes.UPDATE_APP, updateAppSaga)
    yield takeEvery(AppsActionTypes.DELETE_APP, deleteAppSaga)
    yield takeEvery(AppsActionTypes.ARCHIVE_APP, archiveAppSaga)
    yield takeEvery(AppsActionTypes.UNARCHIVE_APP, unArchiveAppSaga)
    yield takeEvery(AppsActionTypes.CLONE_APP, cloneAppSaga)
    yield takeEvery(AppsActionTypes.UNARCHIVE_APP_LIST, unArchiveAppsListSaga)
    yield takeEvery(AppsActionTypes.DELETE_APP_LIST, deleteAppsListSaga)
    yield takeEvery(AppsActionTypes.ARCHIVE_APP_LIST, archiveAppListSaga)
    yield takeEvery(AppsActionTypes.GET_APP_CONFIG, getAppConfigSaga)
    yield takeEvery(AppsActionTypes.UPDATE_APP_CONFIG, updateAppConfigSaga)
    yield takeEvery(AppsActionTypes.DELETE_APP_CONFIG, deleteAppConfigSaga)

}

export default appsSaga

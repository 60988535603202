import React, {useEffect, useMemo} from 'react';
import {Card, CardBody, Col, Row} from "reactstrap";
import Select from "react-select";
import {KTSVG} from "../../../../_metronic/helpers";
import {FilterDataType, FilterListResponse, SegmentFilter, SegmentType} from "../../../../AUTO_GENERATED_TYPES";
import {getSegmentData} from "../../../../store/community/actions";
import {useDispatch} from "react-redux";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import TaggingTooltip from "../Components/TaggingTooltip";
import useGetFilterList from "../Hooks/useGetFilterList";
import QueryKeys from "../../../../react-query/QueryKeys";
import {useQuery, useQueryClient} from "react-query";
import {useFirstMountState} from "react-use";
import {getFilterList, getSegmentFilters} from "../../../../_metronic/helpers/backend_helper";
import useCommunityFilters from "../../../../store/community-filters/useCommunityFilters";
import {
    addFilter,
    filterOperatorChange,
    filterTypeChange,
    filterValueChange,
    removeFilter,
    resetFilters,
    setFilters
} from "../../../../store/community-filters/actions";
import {CommunitySegFilter} from "../../../../types/CommunityFilterTypes";
import {toast} from "react-toastify";
import useGetFiltersAndOrders from "../../../../store/community/useGetFiltersAndOrders";
import {CSSTransition} from "react-transition-group";
import CreatableSelect from "react-select/creatable";
import {v4 as uuidv4} from "uuid";

interface SegmentTaggingFilterProps {
    isOpen: boolean,
    itemPerPage: number,
    currentPage: number,
    isItemTable?: boolean,
    tagFilter: any,
    resetPage: () => void,
    onClose: () => void
}

const SegmentTaggingFilter = ({
                                  isOpen,
                                  itemPerPage,
                                  currentPage,
                                  onClose,
                                  resetPage,
                                  isItemTable = false,
                                  tagFilter
                              }: SegmentTaggingFilterProps) => {

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const {jobId: segId, type: segType, segFilter} = useParams()
    const {definitions: filterListDefinition, getOperators} = useGetFilterList(+(segId!), segType!)
    const [searchParams] = useSearchParams()


    const queryClient = useQueryClient()

    const filterRequestBody = useGetFiltersAndOrders()
    const {filters: filterList, filterCount, onlyFullFilters} = useCommunityFilters()

    const {
        data: filterListData,
        refetch
    } = useQuery([QueryKeys.FilterDefinition, segType], () => getFilterList(Number(segId!), segType === 'positive' ? SegmentType.POS : SegmentType.NEG), {
        cacheTime: Infinity,
        staleTime: Infinity,
        enabled: false
    })
    const communityId = filterListData?.filters?.find(desc => desc.column_name === 'community_id')?.id

    const allowAdd = useMemo(() => filterList[0].filterValue[0]?.value, [filterList])

    const firstMountState = useFirstMountState()

    useEffect(() => {
        if (isItemTable) {
            dispatch(resetFilters())
            return
        }
        if (firstMountState) {
            const urlFromJSON = (JSON.parse(searchParams.get('json') ?? 'null') ?? []) as SegmentFilter[]

            refetch().then(({data}) => {
                if (String(segFilter) === '1' && urlFromJSON.length === 0) {
                    return
                }

                const communityId = data?.filters?.find(desc => desc.column_name === 'community_id')?.id!

                getSegmentFilters(Number(segId), communityId, segType === 'positive' ? SegmentType.POS : SegmentType.NEG, onlyFullFilters, tagFilter).then(response => {
                    queryClient.setQueryData([QueryKeys.SegmentFilters, {
                        segId,
                        value: communityId,
                        segType,
                        onlyFullFilters,
                        tagFilter
                    }], response)
                    const operators = response.filter_operator_values?.map(value => ({value, label: value}))
                    const values = response.filter_list?.map(filter => ({
                        value: filter.filter_name!,
                        label: `${filter.filter_name} (${filter.filter_counter})`
                    }))

                    const filterFromURL = [{}] as CommunitySegFilter[]
                    if (String(segFilter) !== '1') {
                        filterFromURL[0].filterType = {
                            value: String(communityId),
                            label: 'Community Id'
                        }
                        filterFromURL[0].filter_operator = {value: '==', label: '=='}
                        filterFromURL[0].filterValue = [{
                            value: segFilter!,
                            label: values!.find(value => value.value === segFilter)!.label
                        }]
                        filterFromURL[0].filter_operators = operators ?? [{value: '', label: ''}]
                        filterFromURL[0].filterValues = values ?? [{value: '', label: ''}]
                        filterFromURL[0].dataType = response.filter_data_type
                    } else {
                        filterFromURL.pop()
                    }
                    let promises;
                    if (urlFromJSON.length > 0) {
                        promises = urlFromJSON.map(element => {
                            return getSegmentFilters(Number(segId), element.filter_definition_id, segType === 'positive' ? SegmentType.POS : SegmentType.NEG, onlyFullFilters, tagFilter).then(response => {
                                queryClient.setQueryData([QueryKeys.SegmentFilters, {
                                    segId,
                                    value: element.filter_definition_id,
                                    segType,
                                    onlyFullFilters,
                                    tagFilter
                                }], response)
                                const operators = response.filter_operator_values?.map(value => ({value, label: value}))
                                const values = response.filter_list?.map(filter => ({
                                    value: filter.filter_name!,
                                    label: `${filter.filter_name} (${filter.filter_counter})`
                                }))

                                const label = data?.filters?.find(desc => desc.id === element.filter_definition_id)?.description


                                const newFilterFromURL = {} as CommunitySegFilter
                                newFilterFromURL.filterType = {
                                    value: String(element.filter_definition_id),
                                    label: label ?? ''
                                }
                                newFilterFromURL.filter_operator = operators?.find(operator => operator.value === element.filter_operator) ?? {
                                    value: '==',
                                    label: '=='
                                }
                                newFilterFromURL.filterValue = element.filter_value.map(filterValue => ({
                                    value: filterValue,
                                    label: values!.find(valueFromList => valueFromList.value === filterValue)!.label
                                }))
                                newFilterFromURL.filter_operators = operators ?? [{value: '', label: ''}]
                                newFilterFromURL.filterValues = values ?? [{value: '', label: ''}]
                                newFilterFromURL.dataType = response.filter_data_type
                                newFilterFromURL.id = uuidv4()

                                filterFromURL.push(newFilterFromURL)
                            })
                        })
                    }
                    Promise.all(promises).finally(() => dispatch(setFilters(filterFromURL)))

                })

            }).catch(reason => (console.log(reason)))

        }
    }, [segFilter]);

    function getSegmentType() {
        return segType === 'positive' ? SegmentType.POS : SegmentType.NEG;
    }

    const addNewFilter = () => {
        if (allowAdd) {
            dispatch(addFilter())
        }
    }

    const resetFilter = () => {
        dispatch(getSegmentData(Number(segId), getSegmentType(), {
            filters: [],
            orders: []
        }, itemPerPage, currentPage,tagFilter))
        dispatch(resetFilters())
        if (!isItemTable) {
            navigate(`/community/job/segment/${segType}/${segId}/1`)
        }
        resetPage()
    }

    const handleFilterData = () => {
        onClose()
        const filterMap: Array<SegmentFilter> = filterList.map((filter) => (
            {
                filter_definition_id: Number(filter.filterType.value),
                filter_operator: filter.filter_operator.value,
                filter_value: filter.filterValue.map((f: any) => f.value)
            }
        ))
        dispatch(getSegmentData(Number(segId), getSegmentType(), filterRequestBody, itemPerPage, currentPage,tagFilter))
        if (!isItemTable) {
            const communityIdForURL = filterMap.find(filter => filter.filter_definition_id === communityId)?.filter_value ?? 1
            navigate(`/community/job/segment/${segType}/${segId}/${communityIdForURL}`)
        }
        resetPage()
    }


    const deleteFilter = (id: string) => {
        if (filterList.length === 1) {
            resetFilter()
        } else {
            dispatch(removeFilter(id, segType ?? '', Number(segId), tagFilter))
        }
        resetPage()
    }

    const filterTypeHandleChange = (id: string, selectedOption: any) => {

        const meta = queryClient.getQueryData([QueryKeys.SegmentFilters, {
            segId,
            value: selectedOption.value,
            segType,
            onlyFullFilters
        }]) as FilterListResponse
        if (meta) {
            dispatch(filterTypeChange(id, selectedOption, meta))
        } else {

            getSegmentFilters(Number(segId), selectedOption.value, segType === 'positive' ? SegmentType.POS : SegmentType.NEG, onlyFullFilters, tagFilter).then(response => {
                queryClient.setQueryData([QueryKeys.SegmentFilters, {
                    segId,
                    value: selectedOption.value,
                    segType,
                    onlyFullFilters,
                    tagFilter
                }], response)
                dispatch(filterTypeChange(id, selectedOption, response))
            }).catch(() => toast.error('Something went wrong! Please try again.'))
        }
    }

    const filterOperatorHandleChange = (id: string, selectedOperator: any,) => {
        dispatch(filterOperatorChange(id, selectedOperator))
    }

    const filterValueHandleChange = (id: string, selectedOption: any) => {
        dispatch(filterValueChange(id, selectedOption))

    };


    return (
        <CSSTransition
            in={isOpen}
            timeout={300}
            classNames="modal_animation"
            unmountOnExit
        >
            <div onClick={(e) => e.stopPropagation()} className={'segment_filter_window'}>
                <Card className="filter_card">
                    <CardBody id="advanced_filter">
                        {filterList.some(filter => filter.dataType === FilterDataType.NUMERIC) &&
                            <div style={{position: "absolute", right: '10px', top: '10px', cursor: 'pointer'}}
                                 onClick={() => {
                                     const numberFilters = filterList.filter(filter => filter.dataType === FilterDataType.NUMERIC).map(filter => {
                                         const filterForRequest: SegmentFilter = {
                                             filter_value: filter.filterValue.map(option => option.value),
                                             filter_operator: filter.filter_operator.value,
                                             filter_definition_id: Number.parseInt(filter.filterType.value)
                                         }

                                         return filterForRequest
                                     })
                                     const encodedFilterList = encodeURI(window.location.href.split('?').at(0) + '?json=' + JSON.stringify(numberFilters))
                                     navigator.clipboard.writeText(encodedFilterList).then(() => {
                                         toast.info('Link Copied to clipboard!')
                                     })
                                 }}><TaggingTooltip
                                placement={'right'} tooltip={'Copy URL Link (Only Numeric Filters)'}><KTSVG
                                path='/media/icons/duotune/communication/com008.svg'
                                className={'svg-icon-1 active_paste'}
                            /></TaggingTooltip></div>}
                        <h4 className="mb-4 text-start">Advanced Filter</h4>
                        {filterList.map(({id}, idx) => (
                            <Row key={idx} style={{marginBottom: "10px"}} className="align-items-center">
                                <Col sm="3">
                                    {filterListDefinition && <Select
                                        value={filterList[idx].filterType}
                                        //@ts-ignore
                                        options={filterListDefinition}
                                        onChange={(e) => filterTypeHandleChange(id, e)}
                                        styles={{
                                            menu: (baseStyles) => ({
                                                ...baseStyles,
                                                color: 'black',
                                            }),
                                        }}
                                    />}
                                </Col>
                                <Col sm="3">
                                    {getOperators && <Select
                                        isDisabled={filterList[idx].filterType.value === ""}
                                        value={filterList[idx].filter_operator}
                                        options={filterList[idx].filter_operators}
                                        onChange={(e) => filterOperatorHandleChange(id, e)}
                                        styles={{
                                            menu: (baseStyles) => ({
                                                ...baseStyles,
                                                color: 'black',
                                            }),
                                        }}
                                    />}
                                </Col>
                                <Col sm={filterList[idx].dataType === 'JSON' ? '4' : '5'}>
                                    {filterList[idx].dataType === 'STRING' ? <Select
                                            isDisabled={filterList[idx].filter_operator.value === ""}
                                            value={filterList[idx].filterValue}
                                            //@ts-ignore
                                            options={filterList[idx].filterValues}
                                            placeholder="Choose Filter Values ..."
                                            isMulti={true}
                                            onChange={(e) => filterValueHandleChange(id, [e].flat())}
                                            isClearable={false}
                                            closeMenuOnSelect={false}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            styles={{
                                                menu: (baseStyles) => ({
                                                    ...baseStyles,
                                                    color: 'black',
                                                }),
                                                valueContainer: (baseStyles) => ({
                                                    ...baseStyles,
                                                    maxHeight: '35px',
                                                    overflowY: 'auto'
                                                }),
                                            }}
                                        /> :
                                        <CreatableSelect
                                            isDisabled={filterList[idx].filter_operator.value === ""}
                                            value={filterList[idx].filterValue}
                                            //@ts-ignore
                                            options={filterList[idx].filterValues}
                                            placeholder="Choose Filter Values ..."
                                            onChange={(e) => filterValueHandleChange(id, [e].flat())}
                                            isClearable={false}
                                            closeMenuOnSelect={false}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            styles={{
                                                menu: (baseStyles) => ({
                                                    ...baseStyles,
                                                    color: 'black',
                                                }),
                                                valueContainer: (baseStyles) => ({
                                                    ...baseStyles,
                                                    maxHeight: '35px',
                                                    overflowY: 'auto'
                                                }),
                                            }}
                                            formatCreateLabel={(inputValue: string) => `Filter by: ${inputValue}`}
                                            isValidNewOption={inputValue => !isNaN(parseInt(inputValue))}
                                            createOptionPosition={'first'}

                                        />

                                    }

                                </Col>
                                {filterList[idx].dataType === 'JSON' && <Col sm='1'>
                                    <TaggingTooltip placement={'right'} tooltip={'Paste Value'}>
                                        <div onClick={() => {
                                            if (filterList[idx].filter_operator.value === "") {
                                                return
                                            }
                                            navigator.clipboard.readText().then(text => {
                                                const convertedText = text.replaceAll('\\', '\"').replaceAll(',[', ', [')
                                                const newFilter = filterList[idx].filterValues.find(option => option.value === convertedText)
                                                if (newFilter) {
                                                    filterValueHandleChange(id, [newFilter].flat())
                                                }
                                            })
                                        }} className={filterList[idx].filter_operator.value === "" ? '' : 'del_button'}>
                                            <KTSVG path='/media/icons/duotune/arrows/arr092.svg'
                                                   className={`svg-icon-1 ${filterList[idx].filter_operator.value === "" ? 'inactive_plus' : 'active_paste'}`}
                                            />
                                        </div>
                                    </TaggingTooltip>
                                </Col>}
                                <Col sm="auto">
                                    <TaggingTooltip placement={'right'} tooltip={'Delete Filter'}>
                                        <div onClick={() => deleteFilter(id)} className={'del_button'}>
                                            <KTSVG path='/media/icons/duotune/arrows/arr061.svg'
                                                   className={'svg-icon-1 active_delete'}
                                            />
                                        </div>
                                    </TaggingTooltip>
                                </Col>
                            </Row>
                        ))}
                        <Row className="align-items-center mt-2">
                            <Col sm="10">
                                {filterList.length <= 4 &&
                                    <TaggingTooltip placement={'left'}
                                                    tooltip={!allowAdd ? 'Complete the filter first' : 'Add New'}>
                                        <div onClick={addNewFilter} className={'plus_button'}>
                                            <KTSVG path='/media/icons/duotune/general/gen041.svg'
                                                   className={`svg-icon-1 ${!allowAdd ? 'inactive_plus' : 'active_plus'}`}
                                            />
                                        </div>
                                    </TaggingTooltip>}
                            </Col>
                            <Col sm="1">
                                <TaggingTooltip placement={'bottom'} tooltip={'Reset Filter'}>
                                    <div onClick={resetFilter} className={'refresh_button'}>
                                        <KTSVG path='/media/icons/duotune/arrows/arr029.svg'
                                               className='svg-icon-1 active_plus'/>
                                    </div>
                                </TaggingTooltip>
                            </Col>
                            <Col sm="auto">
                                <TaggingTooltip placement={'bottom'} tooltip={'Apply Filters'}>
                                    <div onClick={handleFilterData} className={'refresh_button'}>
                                        <KTSVG path='/media/icons/duotune/arrows/arr027.svg'
                                               className='svg-icon-1 active_plus'/>
                                    </div>
                                </TaggingTooltip>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        </CSSTransition>
    )
}

export default SegmentTaggingFilter

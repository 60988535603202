import {GroupMode} from "../../../../AUTO_GENERATED_TYPES";

function getGroupModeLabel(groupMode: GroupMode): string {
    switch (groupMode) {
        case GroupMode.ALL:
            return '- Group Mode -'
        case GroupMode.LIVE:
            return 'Live'
        case GroupMode.DEMO:
            return 'Demo'
        case GroupMode.TEST:
            return 'Test'
        default:
            return ''
    }
}

export default  getGroupModeLabel

import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {WithChildren} from "../../../../_metronic/helpers";
import React, {ReactNode} from "react";
import {Placement} from "react-bootstrap/types";

interface TaggingTooltip {
    placement: Placement
    tooltip: ReactNode
}

const TaggingTooltip: React.FC<TaggingTooltip & WithChildren> = ({tooltip, children, placement}) => {
    return <OverlayTrigger
        placement={placement}
        overlay={<Tooltip>{tooltip}</Tooltip>}
        defaultShow={undefined}
        delay={undefined}
        flip={undefined}
        onHide={undefined}
        onToggle={undefined}
        popperConfig={undefined}
        show={undefined}
        target={undefined}
        trigger={undefined}>
        <span>{children}</span>
    </OverlayTrigger>
}

export default TaggingTooltip

import {AnnotationActionTypes} from "../../types/StoreTypes";
import {
    AddAnnotationFilter,
    AddAnnotationOrder,
    AnnotationSegFilter,
    AnnotationSegSort,
    ChangeAnnotationFilterOperator,
    ChangeAnnotationFilterSA,
    ChangeAnnotationFilterType,
    ChangeAnnotationFilterValue,
    ChangeAnnotationImageType,
    ChangeAnnotationOrderType,
    ChangeAnnotationOrderValue,
    RemoveAnnotationFilter,
    RemoveAnnotationOrder,
    ResetAnnotationFilter,
    ResetAnnotationOrderBy,
    SetAnnotationDataRequest,
    SetAnnotationFilters,
    SetAnnotationIPP,
    SetAnnotationPage,
    SetAnnotationTagFilter,
    SortValueOptions
} from "../../types/AnnotationTypes";
import {Option} from "../../types/CommunitySortTypes";
import {FilterListResponse, FilterRequest} from "../../AUTO_GENERATED_TYPES";

export const setAnnotationDataRequest = (request: FilterRequest): SetAnnotationDataRequest => ({
    type: AnnotationActionTypes.SET_ANNOTATION_DATA_REQUEST,
    payload: request
})

export const setAnnotationTagFilter = (tag: string): SetAnnotationTagFilter => ({
    type: AnnotationActionTypes.SET_ANNOTATION_TAG_FILTER,
    payload: tag
})

export const setAnnotationPage = (page: number): SetAnnotationPage => ({
    type: AnnotationActionTypes.SET_ANNOTATION_PAGE,
    payload: page
})

export const setAnnotationIPP = (perPage: number): SetAnnotationIPP => ({
    type: AnnotationActionTypes.SET_ANNOTATION_IPP,
    payload: perPage
})

export const addAnnotationOrder = (order?: AnnotationSegSort): AddAnnotationOrder => ({
    type: AnnotationActionTypes.ADD_ANNOTATION_ORDER_BY,
    payload: order
})

export const removeAnnotationOrder = (id: string): RemoveAnnotationOrder => ({
    type: AnnotationActionTypes.REMOVE_ANNOTATION_ORDER_BY,
    payload: id
})

export const changeAnnotationOrderType = (id: string, value: Option): ChangeAnnotationOrderType => ({
    type: AnnotationActionTypes.ANNOTATION_ORDER_TYPE,
    payload: {id, value}
})

export const changeAnnotationOrderValue = (id: string, value: SortValueOptions): ChangeAnnotationOrderValue => ({
    type: AnnotationActionTypes.ANNOTATION_ORDER_VALUE,
    payload: {id, value}
})

export const resetAnnotationOrderBy = (): ResetAnnotationOrderBy => ({
    type: AnnotationActionTypes.RESET_ANNOTATION_ORDER_BY
})

export const addAnnotationFilter = (filter?: AnnotationSegFilter): AddAnnotationFilter => ({
    type: AnnotationActionTypes.ADD_ANNOTATION_FILTER,
    payload: filter
})

export const removeAnnotationFilter = (id: string): RemoveAnnotationFilter => ({
    type: AnnotationActionTypes.REMOVE_ANNOTATION_FILTER,
    payload: id
})

export const resetAnnotationFilter = (): ResetAnnotationFilter => ({
    type: AnnotationActionTypes.RESET_ANNOTATION_FILTER
})

export const setAnnotationFilters = (annotations: AnnotationSegFilter[]): SetAnnotationFilters => ({
    type: AnnotationActionTypes.SET_ANNOTATION_FILTERS,
    payload: annotations
})

export const changeAnnotationFilterType = (id: string, newValue: Option, meta: FilterListResponse, inDeletion?: boolean): ChangeAnnotationFilterType => ({
    type: AnnotationActionTypes.CHANGE_ANNOTATION_FILTER_TYPE,
    payload: {id, newValue, meta, inDeletion}
})

export const changeAnnotationFilterOperator = (id: string, newValue: Option): ChangeAnnotationFilterOperator => ({
    type: AnnotationActionTypes.CHANGE_ANNOTATION_FILTER_OPERATOR,
    payload: {id, newValue}
})

export const changeAnnotationFilterValue = (id: string, newValue: Option, inDeletion?: boolean): ChangeAnnotationFilterValue => ({
    type: AnnotationActionTypes.CHANGE_ANNOTATION_FILTER_VALUE,
    payload: {id, newValue, inDeletion}
})

export const changeAnnotationFilterSA = (newValue: string, options: FilterListResponse | undefined): ChangeAnnotationFilterSA => ({
    type: AnnotationActionTypes.CHANGE_ANNOTATION_FILTER_SA,
    payload: {newValue, options}
})

export const changeAnnotationImageType = (type: Option): ChangeAnnotationImageType => ({
    type: AnnotationActionTypes.CHANGE_ANNOTATION_IMAGE_TYPE,
    payload: type
})

import {FilterDefinitionResponse, SegmentType} from "../../../../AUTO_GENERATED_TYPES";
import {useQuery} from "react-query";
import QueryKeys from "../../../../react-query/QueryKeys";
import {getAnnotationFiltersDefinition, getFilterList} from "../../../../_metronic/helpers/backend_helper";
import {useParams} from "react-router-dom";

const createFilterListOptions = (filters?: Array<FilterDefinitionResponse>): { label: string, value: number | '' }[] => {
    if (!filters) {
        return [{label: '', value: ''}]
    }
    return filters.map(filter => ({label: filter.short_name, value: filter.id}))
}


const useGetAnnotationFilterList = ( enabled = true) => {
    const {id} = useParams()
    const annotationId = Number(id)
    const {data: filterList} = useQuery([QueryKeys.AnnotationFiltersDefinition, annotationId], () => getAnnotationFiltersDefinition(annotationId), {
        cacheTime: Infinity,
        staleTime: Infinity,
        enabled
    })


    if (!filterList) {
        return {definitions: null, getOperators: null}
    }

    const createOperatorsOptions = (columnName: string) => {
        return filterList.filters?.find(filter => filter.column_name === columnName)?.operators.map(operator => ({
            label: operator,
            value: operator
        }))
    }

    return {definitions: createFilterListOptions(filterList.filters), getOperators: createOperatorsOptions, filterList}
}

export default useGetAnnotationFilterList

import {all, fork} from "redux-saga/effects"


import AuthSaga from "./auth/saga"
import UsersSaga from "./users/saga"
import AppsSaga from "./apps/saga"
import ConfigSaga from "./config/saga"
import CommunitySaga from "./community/saga"
import CommunityFilterSaga from './community-filters/saga'
import CommunitySortSaga from './community-sort/saga'
import AnnotationSaga from './annotation/saga'


export default function* rootSaga() {

    yield all([
        fork(AuthSaga),
        fork(UsersSaga),
        fork(AppsSaga),
        fork(ConfigSaga),
        fork(CommunitySaga),
        fork(CommunityFilterSaga),
        fork(CommunitySortSaga),
        fork(AnnotationSaga)

    ])
}


import {Modal, ModalBody, ModalHeader} from "reactstrap";
import classes from "../AnnotationJobData/AnnotationJobData.module.scss";
import {KTSVG} from "../../../../../../_metronic/helpers";
import AnnotationTag from "../AnnotationTag/AnnotationTag";
import {
    AnnotationClassesListResponse,
    AnnotationSegmentListResponse,
    AnnotationSegmentResponse,
    PlotNameListResponse,
    UpdateAnnotationRequest
} from "../../../../../../AUTO_GENERATED_TYPES";
import React, {useCallback, useEffect} from "react";
import useAnnotation from "../../../../../../store/annotation/useAnnotation";
import QueryKeys from "../../../../../../react-query/QueryKeys";
import {useParams} from "react-router-dom";
import {useQueryClient} from "react-query";
import {Option} from "../../../../../../types/CommunitySortTypes";

interface AnnotationZoomModalProps {
    zoomContext: { show: boolean, segment?: AnnotationSegmentResponse, index?: number }
    toggleZoom: (segment?: AnnotationSegmentResponse | React.KeyboardEventHandler<any> | React.MouseEventHandler<any> | undefined) => void
    openModalHandler: (segment?: AnnotationSegmentResponse) => void
    annotationClasses: AnnotationClassesListResponse | undefined
    tagHandler: (requestBody: UpdateAnnotationRequest[]) => void
    setZoomContext: React.Dispatch<React.SetStateAction<{ show: boolean, segment?: AnnotationSegmentResponse | undefined, index?: number | undefined }>>
}

const AnnotationZoomModal: React.FC<AnnotationZoomModalProps> = ({
                                                                     zoomContext,
                                                                     toggleZoom,
                                                                     openModalHandler,
                                                                     annotationClasses,
                                                                     setZoomContext,
                                                                     tagHandler
                                                                 }) => {
    const {id} = useParams()
    const annotationId = Number(id)
    const queryClient = useQueryClient()

    const segment = zoomContext.segment

    const {
        data: {page, itemsPerPage, dataRequest, tagFilter, imageType},
        functions: {changeImageType}
    } = useAnnotation()


    const segments = (JSON.parse(JSON.stringify(queryClient.getQueryData([QueryKeys.Annotation, QueryKeys.AnnotationJobData, annotationId, dataRequest, tagFilter, itemsPerPage, page]))) as AnnotationSegmentListResponse)?.segments
    const annotationIndex = segments?.findIndex((seg => seg.id === segment?.id))
    const segClasses = queryClient.getQueryData<AnnotationClassesListResponse>([QueryKeys.AnnotationJobClasses, annotationId])

    const changeImageTypeWithArrows = useCallback((type: 'ArrowUp' | 'ArrowDown') => {
        const types = queryClient.getQueryData<PlotNameListResponse>([QueryKeys.AnnotationPlotNames,annotationId])?.plot_names
        if (!types) {
            return
        }

        const typesArray = Object.entries(types)
        const currTypeIndex = typesArray.findIndex((value => value[0] === imageType.value))
        const nextIndex = currTypeIndex + (type === 'ArrowUp' ? -1 : 1)
        const newType = typesArray.at(nextIndex === typesArray.length ? 0 : nextIndex)
        if (!newType) {
            return
        }
        const newTypeAsObj = {value: newType[0], label: newType[1]} as Option
        changeImageType(newTypeAsObj)
    }, [queryClient, changeImageType])

    useEffect(() => {
        if (!zoomContext.show) {
            return
        }
        const handleKeyPress = (e: KeyboardEvent) => {

            e.preventDefault()
            if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
                changeImageTypeWithArrows(e.key as 'ArrowUp' | 'ArrowDown')
            }
        }

        document.addEventListener('keydown', handleKeyPress)

        return () => {
            document.removeEventListener('keydown', handleKeyPress)
        }

    }, [changeImageTypeWithArrows, zoomContext.show])

    useEffect(() => {
        let handleNavigation: any;

        if (annotationIndex !== -1 && segments && zoomContext.show) {
            const previous = segments[(annotationIndex ?? 0) - 1]
            const next = segments[(annotationIndex ?? 0) + 1]

            handleNavigation = (event: KeyboardEvent) => {
                if (event.key === 'ArrowRight' && next) {
                    toggleZoom(next)
                } else if (event.key === 'ArrowLeft' && previous) {
                    toggleZoom(previous)

                }
            }

            document.addEventListener('keydown', handleNavigation)
        }

        return () => {
            document.removeEventListener('keydown', handleNavigation)
        }
    }, [annotationIndex, segments])

    const openDetailsHandler = ()=>{
        toggleZoom()
        setTimeout(()=>{
            openModalHandler(segment)
        },500)
    }

    return <Modal isOpen={zoomContext.show}
                  toggle={toggleZoom as any}
                  centered
                  style={{width: "max-content", maxWidth: "none"}}
                  className={'position-relative'}>
        <ModalHeader toggle={toggleZoom as any}>
            Id: {segment?.id}
            <div
                className={classes.page}>{zoomContext?.index !== undefined ? zoomContext?.index + 1 : 0} / {itemsPerPage} -
                Page {page}</div>

        </ModalHeader>
        <ModalBody style={{width: "auto", maxWidth: "none"}}>
            <div className={`${classes.images__image_container} ${classes['images__image_container--zoom']}`}>
                <img
                    src={(segment?.plots ? segment?.plots[imageType.value as keyof typeof segment] : Object.values(segment?.plots ?? {})[0]) as string}
                    alt="segment_graph"/>
                <div className={classes.images__tagging}>
                    {annotationClasses?.classes.map((annotationClass, index) =>
                        <AnnotationTag key={index} annotationClass={annotationClass}
                                       segId={segment?.id}
                                       isSelected={segment?.tag === annotationClass.class_code}
                                       onClick={(requestBody: UpdateAnnotationRequest[]) => {
                                           tagHandler(requestBody)
                                           if (requestBody[0].seg_id_list[0] === segment?.id) {
                                               setZoomContext(prevState => {
                                                   const newSeg = JSON.parse(JSON.stringify(prevState.segment)) as AnnotationSegmentResponse
                                                   const newTag = requestBody[0].segment_tag
                                                   newSeg.tag = newTag

                                                   const oldName = newSeg.tag_name
                                                   newSeg.tag_name = segClasses?.classes.find(segClass => segClass.class_code === newTag)?.class_name ?? oldName
                                                   return {...prevState, segment: newSeg}
                                               })
                                           }
                                       }}/>)}
                    <span onClick={openDetailsHandler} className={classes.mag_glass}
                    >
                                <KTSVG
                                    path='/media/icons/duotune/general/gen004.svg'
                                    className={'svg-icon-edit svg-icon-2qx cursor-pointer'}
                                />
                            </span>
                </div>
            </div>
        </ModalBody>
    </Modal>
}

export default AnnotationZoomModal

import {FilterDefinitionListResponse} from "../../AUTO_GENERATED_TYPES";
import QueryKeys from "../../react-query/QueryKeys";
import queryClient from "../../react-query/queryClient";

const getFilterIdByColumnName =(column_name: string,segType?:string)=> {
    const filters = queryClient.getQueryData<FilterDefinitionListResponse>([QueryKeys.FilterDefinition, segType])?.filters ?? []
    const filter = filters.find(f => f.column_name === column_name);
    return filter?.id;
}

export default getFilterIdByColumnName

import {CommunitySortActionTypes} from "../../types/StoreTypes";
import {
    AddSort,
    CommunitySegSort,
    Option,
    RemoveSort,
    ResetSorts,
    SetSort,
    SetSortOptions,
    SortTypeChange,
    SortValueChange,
    SortValueOptions
} from "../../types/CommunitySortTypes";

export const setSort = (order: CommunitySegSort[]): SetSort => ({
    type: CommunitySortActionTypes.SET_COM_SORT,
    payload: order
})

export const addSort = (order?: CommunitySegSort): AddSort => ({
    type: CommunitySortActionTypes.ADD_COM_SORT,
    payload: order
})

export const removeSort = (id: string): RemoveSort => ({
    type: CommunitySortActionTypes.REMOVE_COM_SORT,
    payload: id
})

export const resetSort = (): ResetSorts => ({
    type: CommunitySortActionTypes.RESET_COM_SORT
})

export const sortTypeChange = (id: string, newValue: Option): SortTypeChange => ({
    type: CommunitySortActionTypes.SORT_TYPE_CHANGE,
    payload: {id, newValue}
})

export const sortValueChange = (id: string, newValue: SortValueOptions): SortValueChange => ({
    type: CommunitySortActionTypes.SORT_VALUE_CHANGE,
    payload: {id, newValue}
})

export const setSortOptions = (options: Option[]): SetSortOptions => ({
    type: CommunitySortActionTypes.SET_SORT_OPTIONS,
    payload: options
})


import {UserActionTypes} from "../../types/StoreTypes";
import {
    DeleteUser, DeleteUserFail, DeleteUserSuccess,
    GetUsers,
    GetUsersFail,
    GetUsersSuccess,
    NewUser,
    NewUserFail,
    NewUserSuccess, ResetPassword, ResetPasswordFail, ResetPasswordSuccess,
    UpdateUser, UpdateUserFail, UpdateUserSuccess
} from "../../types/UserTypes";
import {
    AllUsersResponse,
    CreateUserRequest,
    ResetPasswordResponse,
    UpdateUserRequest
} from "../../AUTO_GENERATED_TYPES";

export const getUsers = () : GetUsers=> ({
    type: UserActionTypes.GET_USERS,
})
export const getUsersSuccess = (list:AllUsersResponse):GetUsersSuccess => ({
    type: UserActionTypes.GET_USERS_SUCCESS,
    payload: list
})
export const getUsersFail = (error:string):GetUsersFail => ({
    type: UserActionTypes.GET_USERS_FAIL,
    payload: error
})
export const newUser = (data:CreateUserRequest) : NewUser =>  ({
    type: UserActionTypes.CREATE_NEW_USER,
    payload: data
})
export const newUserSuccess = (result:boolean, msg:string):NewUserSuccess => ({
    type: UserActionTypes.CREATE_NEW_USER_SUCCESS,
    payload: {result, msg}
})
export const newUserFail = (result:boolean, msg:string):NewUserFail => ({
    type: UserActionTypes.CREATE_NEW_USER_FAIL,
    payload:  {result, msg}
})
export const updateUser = (userId:number, data:UpdateUserRequest):UpdateUser => ({
    type: UserActionTypes.UPDATE_USER,
    payload: {userId, data}
})
export const updateUserSuccess = (result:boolean, msg:string):UpdateUserSuccess => ({
    type: UserActionTypes.UPDATE_USER_SUCCESS,
    payload: {result, msg}
})
export const updateUserFail =(result:boolean, msg:string) :UpdateUserFail => ({
    type: UserActionTypes.UPDATE_USER_FAIL,
    payload: {result, msg}
})
export const deleteUser = (userId:number):DeleteUser => ({
    type: UserActionTypes.DELETE_USER,
    payload: userId
})
export const deleteUserSuccess = (result:boolean, msg:string, userId:number):DeleteUserSuccess => ({
    type: UserActionTypes.DELETE_USER_SUCCESS,
    payload: {result, msg, userId}
})
export const deleteUserFail = (result:boolean, msg:string):DeleteUserFail => ({
    type: UserActionTypes.DELETE_USER_FAIL,
    payload: {result, msg}
})
export const resetPassword = (userId:number):ResetPassword => ({
    type: UserActionTypes.RESET_PASSWORD,
    payload: userId
})
export const resetPasswordSuccess = (result:boolean, msg:string, password:ResetPasswordResponse | null):ResetPasswordSuccess => ({
    type: UserActionTypes.RESET_PASSWORD_SUCCESS,
    payload: {result, msg, password}
})
export const resetPasswordFail = (result:boolean, msg:string):ResetPasswordFail => ({
    type: UserActionTypes.RESET_PASSWORD_FAIL,
    payload: {result, msg}
})

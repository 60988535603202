import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store/reducers";
import './header_navbar.scss'
import {Button} from "reactstrap";
import {updateWarning} from "../../../../store/community/actions";
import {WarningStatus, WarningType} from "../../../../AUTO_GENERATED_TYPES";
import {KTSVG} from "../../../helpers";
import {Transition, CSSTransition, TransitionGroup} from 'react-transition-group';
import { useRef } from 'react';

const HeaderNotificationsMenu = () => {
    const path = window.location.pathname;
    const directories = path.split("/");
    // const jobId = directories[(directories.length - 2)];
    // const jobIdComm = directories[(directories.length - 1)];
    const commId = JSON.parse(localStorage.getItem('currentCommId') as string)
    const [warnings, setWarnings] = useState<any>()
    const [isFadingOut, setIsFadingOut] = useState(false);

    const dispatch = useDispatch()

    const {warningsList} = useSelector((state: RootState) => ({
        warningsList: state.community.warningsList,
    }))

    useEffect(() => {
        setWarnings(warningsList)
    }, [warningsList])

    const dateFormatter = (date: any) => {
        let newDate = date
            .replace(/[-]/g, '/')
            .replace(/[T]/g, ' ');
        let jsDate = newDate.slice(0, 16);
        return jsDate
    }

    const ignoreWarning = (id:any) =>{
        dispatch(updateWarning(Number(commId), {
            warning_status: WarningStatus.IGNORED,
            update_list: [id]
        }))
        setIsFadingOut(true)
    }

    return (
        <div
            className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
            data-kt-menu='true'
        >
            <div className={'topborder_notification'}>
                <div className={'warning_title_block'}>
                    <h3>
                        Notifications
                    </h3>
                    <p style={{marginLeft:'10px', marginTop:'2px'}}>
                        {warningsList.length > 0 ? (
                                <span >
                            {warningsList.length} warnings
                          </span>) :
                            (
                                <>
                                    <span className='fs-8 opacity-75 ps-3'>No warnings received</span>
                                </>
                            )
                        }
                    </p>
                </div>
                <div>
                    {warningsList.length > 0 &&
                        <Button onClick={() => {
                            dispatch(updateWarning(Number(commId), {
                                warning_status: WarningStatus.IGNORED,
                                update_list: warningsList.map((w: any) => w.id)
                            }))
                            setIsFadingOut(true)
                        }} className={'ca_btn'}>Clear All</Button>
                    }
                </div>
            </div>
            <div className='tab-content'>
                {warningsList &&
                    <div>
                        <div className='scroll-y mh-325px my-5 px-8'>
                           <TransitionGroup component={null}> {warnings?.map((war: any) => (

                               <CSSTransition
                                   in={isFadingOut}
                                   timeout={800}
                                   classNames="alert"
                                   key={war.id}
                                   onExited={() => setIsFadingOut(false)}
                               >
                                   <div key={war.id} className={'alert_warning'}>
                                       <div>
                                           <KTSVG
                                               path='/media/icons/duotune/general/gen044.svg'
                                               className='svg-icon-warning svg-icon-1'
                                           />
                                           <b style={{marginLeft: '10px', color: 'orange', marginTop: '2px'}}>{
                                               war.warning_type === WarningType.NOT_APPROVED_AND_EXISTING ? 'Not Approved and Existing' : 'Approved and Tagged Negative'
                                           }</b>
                                       </div>
                                       <div>
                                           {war.warning_msg}
                                       </div>
                                       <div style={{marginTop: '5px'}}>
                                           <div style={{width:'50%'}}>
                                               <i>{dateFormatter(war.creation_time)}</i>
                                           </div>
                                           <Button className={'ignore_btn'}
                                                   onClick={() => ignoreWarning(war.id)}>Ignore</Button>
                                       </div>
                                   </div>
                               </CSSTransition>
                           ))}</TransitionGroup>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export {HeaderNotificationsMenu}

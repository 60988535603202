// src/routes/PrivateRoutes.tsx

import { FC, lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import ROUTE_PATHS from './RoutePaths'

import AppsTable from '../modules/Dashboard/pages/AppsTable'
import CommunityJob from '../modules/Community/pages/CommunityJob'
import Community from '../modules/Community/pages/CommunityTable'
import Segments from '../modules/Community/pages/SegmentDetails'
import AppConfig from '../modules/Dashboard/pages/AppConfig'
import ConfigGroupList from '../modules/Config/pages/ConfigGroupTable'
import ConfigList from '../modules/Config/pages/ConfigDefaultsTable'
import ItemsTable from '../modules/Community/pages/ItemsTable'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import PermissionsPage from "../modules/Admin/pages/PermissionsPage";
import UsersTable from "../modules/Admin/pages/UsersTable";
import AnnotationJobs from "../modules/Annotation/Pages/AnnotationJobs";
import AnnotationSegments from "../modules/Annotation/Pages/AnnotationSegments";
import AnnotationItemsTable from "../modules/Annotation/Pages/AnnotationItemsTable";
import ConfigAppTable from "../modules/Config/pages/ConfigAppTable";
import ConfigAudits from "../modules/Config/pages/ConfigAudits";
import AllConfigAudits from "../modules/Config/pages/AllConfigAudits";
import DeploymentHistory from "../modules/Community/pages/DeploymentHistory";

const PrivateRoutes = () => {
    const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
    const RolesPage = lazy(() => import('../modules/Admin/pages/RolesPage'))

    return (
        <Routes>
            <Route element={<MasterLayout/>}>

                {/* Redirect to Dashboard after success login/registration */}
                <Route path='auth/*' element={<Navigate to={ROUTE_PATHS.DASHBOARD.APPS}/>}/>

                {/* Pages */}
                <Route path={ROUTE_PATHS.DASHBOARD.APPS} element={<SuspensedView><AppsTable/></SuspensedView>}/>
                <Route path={ROUTE_PATHS.BUILDER} element={<BuilderPageWrapper/>}/>
                <Route path={ROUTE_PATHS.MENU_TEST} element={<MenuTestPage/>}/>

                {/* Lazy Modules */}
                <Route path={ROUTE_PATHS.DASHBOARD.APP_CONFIG} element={<SuspensedView><AppConfig/></SuspensedView>}/>
                <Route path={ROUTE_PATHS.PROFILE} element={<SuspensedView><ProfilePage/></SuspensedView>}/>
                <Route path={ROUTE_PATHS.ADMIN.ROLES} element={<SuspensedView><RolesPage/></SuspensedView>}/>
                <Route path={ROUTE_PATHS.ADMIN.PERMISSIONS} element={<SuspensedView><PermissionsPage/></SuspensedView>}/>
                <Route path={ROUTE_PATHS.ADMIN.USERS} element={<SuspensedView><UsersTable/></SuspensedView>}/>
                <Route path={ROUTE_PATHS.CONFIG.DEFAULT_GROUP} element={<SuspensedView><ConfigList/></SuspensedView>}/>
                <Route path={ROUTE_PATHS.CONFIG.DEFAULT_AUDITS} element={<SuspensedView><ConfigAudits/></SuspensedView>}/>
                <Route path={ROUTE_PATHS.CONFIG.DEFAULT} element={<SuspensedView><ConfigList/></SuspensedView>}/>
                <Route path={ROUTE_PATHS.CONFIG.APP} element={<SuspensedView><ConfigAppTable/></SuspensedView>}/>
                <Route path={ROUTE_PATHS.CONFIG.APP_AUDITS} element={<SuspensedView><ConfigAudits/></SuspensedView>}/>
                <Route path={ROUTE_PATHS.CONFIG.GROUP} element={<SuspensedView><ConfigGroupList/></SuspensedView>}/>
                <Route path={ROUTE_PATHS.CONFIG.AUDITS} element={<SuspensedView><AllConfigAudits/></SuspensedView>}/>
                <Route path={ROUTE_PATHS.COMMUNITY.ALL} element={<SuspensedView><Community/></SuspensedView>}/>
                <Route path={ROUTE_PATHS.COMMUNITY.ALL_ID} element={<SuspensedView><Community/></SuspensedView>}/>
                <Route path={ROUTE_PATHS.COMMUNITY.JOB} element={<SuspensedView><CommunityJob/></SuspensedView>}/>
                <Route path={ROUTE_PATHS.COMMUNITY.SEGMENT_GENERAL} element={<SuspensedView><Segments/></SuspensedView>}/>
                <Route path={ROUTE_PATHS.COMMUNITY.SEGMENT_FILTER} element={<SuspensedView><Segments/></SuspensedView>}/>
                <Route path={ROUTE_PATHS.COMMUNITY.SEGMENT_ITEMS_TABLE} element={<SuspensedView><ItemsTable/></SuspensedView>}/>
                <Route path={ROUTE_PATHS.COMMUNITY.DEPLOYMENT_HISTORY} element={<SuspensedView><DeploymentHistory/></SuspensedView>}/>
                <Route path={ROUTE_PATHS.ANNOTATION.JOBS} element={<SuspensedView><AnnotationJobs/></SuspensedView>}/>
                <Route path={ROUTE_PATHS.ANNOTATION.SEGMENTS} element={<SuspensedView><AnnotationSegments/></SuspensedView>}/>
                <Route path={ROUTE_PATHS.ANNOTATION.SEGMENTS_TABLE} element={<SuspensedView><AnnotationItemsTable/></SuspensedView>}/>

                {/* Page Not Found */}
                <Route path='*' element={<Navigate to={ROUTE_PATHS.ERROR_404}/>}/>
            </Route>
        </Routes>
    )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
    const baseColor = getCSSVariableValue('--kt-primary')
    TopBarProgress.config({
        barColors: {
            '0': baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    })
    return <Suspense fallback={<TopBarProgress/>}>{children}</Suspense>
}

export { PrivateRoutes }

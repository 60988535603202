type SortOrder = 'asc' | 'desc';


const caseInsensitiveSort = (a: string, b: string, order: SortOrder) => {
    const aValue = a.toLowerCase();
    const bValue = b.toLowerCase();
    if (order === 'asc') {
        return aValue.localeCompare(bValue);
    } else {
        return bValue.localeCompare(aValue);
    }
}

export default caseInsensitiveSort

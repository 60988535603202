import {CommunityFilterActionTypes} from "../../types/StoreTypes";
import {call, put, select, takeEvery} from "redux-saga/effects";
import {addFilter, filterOperatorChange, filterTypeChange, filterValueChange, removeFilterSuccess} from "./actions";
import {CommunitySegFilter} from "../../types/CommunityFilterTypes";
import {FilterDataType, FilterListResponse, FilterRequest, SegmentType, TagFilter} from "../../AUTO_GENERATED_TYPES";
import {getSegmentData} from "../community/actions";
import {RootState} from "../reducers";
import queryClient from "../../react-query/queryClient";
import QueryKeys from "../../react-query/QueryKeys";
import {getSegmentFilters} from "../../_metronic/helpers/backend_helper";
import isFilterFull from "../../assets/utils/isFilterFull";
import filterResponseToOption from "../../assets/utils/filterRespoonseToOption";
import {changePreloader} from "../layout/actions";
import {toast} from "react-toastify";

function* generalSagaError(error: any) {
    yield put(changePreloader(false))
    toast.error(error.message)
}

function* addFromDetailsSaga({
                                 payload: {
                                     filter,
                                     fetchData
                                 }
                             }: { payload: { filter: CommunitySegFilter, fetchData: { cdId: number, segType: SegmentType, body: FilterRequest, itemPp: number, page: number, tag?: TagFilter | undefined } } }) {
    yield put(addFilter(filter))
    yield put(getSegmentData(Number(fetchData.cdId), fetchData.segType, fetchData.body, fetchData.itemPp, fetchData.page, fetchData.tag))
}

function* deleteFilterSaga({payload: {id, segId, segType, tagFilter}}: any) {
    yield put(removeFilterSuccess(id))

    const {filters}: { filters: CommunitySegFilter[] } = yield select((state: RootState) => ({
        filters: state.communityFilter.filters,
    }))

    for (let [i, filter] of filters.entries()) {
        const oldOperator = filter.filter_operator
        const type = filter.dataType
        let oldValue: string | undefined;
        if (type === FilterDataType.NUMERIC || type === FilterDataType.JSON) {
            oldValue = filter.filterValue[0]?.value
        } else {
            oldValue = filter.filterValue.map(el => el.value).join('::')
        }

        const onlyFullFilters = {
            filters: filters.slice(0, i === 0 ? 1 : i).filter(isFilterFull).map(filter => ({
                filter_definition_id: Number.parseInt(filter.filterType.value),
                filter_value: filter.filterValue.map(el => el.value),
                filter_operator: filter.filter_operator.value
            }))
        }

        try {
            let meta = queryClient.getQueryData([QueryKeys.SegmentFilters, {
                segId,
                value: filter.filterType.value,
                segType,
                onlyFullFilters,
                tagFilter
            }]) as FilterListResponse
            if (!meta) {
                meta = yield call(getSegmentFilters, Number(segId), Number(filter.filterType.value), segType === 'positive' ? SegmentType.POS : SegmentType.NEG, onlyFullFilters, tagFilter)
                queryClient.setQueryData([QueryKeys.SegmentFilters, {
                    segId,
                    value: filter.filterType.value,
                    segType,
                    onlyFullFilters,
                    tagFilter
                }], meta)
            }
            yield put(filterTypeChange(filter.id, filter.filterType, meta, true))
            yield put(filterOperatorChange(filter.id, oldOperator))
            if (oldValue) {
                if (type === FilterDataType.NUMERIC || type === FilterDataType.JSON) {
                    yield put(filterValueChange(filter.id, filterResponseToOption(meta.filter_list!.find(valueOption => valueOption?.filter_name === oldValue)!), true))
                } else {
                   const oldValues = oldValue.split('::')
                    const options = oldValues.map(value => filterResponseToOption(meta.filter_list!.find(valueOption => valueOption?.filter_name === value)!))
                    yield put(filterValueChange(filter.id, options as any, true))

                }
            }
        } catch (error) {
            yield* generalSagaError(error)
            return
        }


    }
}

function* communityFilterSaga() {

    yield takeEvery(CommunityFilterActionTypes.ADD_COM_FILTERS_DETAILS as any, addFromDetailsSaga)
    yield takeEvery(CommunityFilterActionTypes.REMOVE_COM_FILTERS as any, deleteFilterSaga)

}

export default communityFilterSaga

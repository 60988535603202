import React, {useEffect, useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import {KTSVG} from "../../../../_metronic/helpers";
import {useDispatch, useSelector} from "react-redux";
import {getUsers, resetPassword} from "../../../../store/actions";
import './users-modals.scss'
import styles from '../../../general-styles/Modals/modals.module.scss'
import {RootState} from "../../../../store/reducers";



interface ChangePasswordProps {
    show: boolean,
    handleClose: () => void,
    userId:any,
    userName:string
}

const ChangePasswordModal = ({show, handleClose, userId, userName }: ChangePasswordProps) =>{
    const [error, setError] = useState<boolean>(false)
    const [validity, setValidity] = useState<boolean>(true)
    const [errorMsg, setErrorMsg] = useState<string>('')
    const dispatch = useDispatch();

    const { updateRes, newPassword } = useSelector((state: RootState) => ({
        updateRes: state.users.updateRes ,
        newPassword: state.users.newPassword
    }))


    const resetPasswordData = () =>{
       dispatch(resetPassword(userId))
    }


    return(
        <div>
                <Modal
                    show={show}
                    onHide={handleClose}
                    centered
                >
                    <div>
                        <div className='modal-content'>
                            <div className={styles.flex_row_between}>
                                <div className={styles.title_close_space}>
                                    <h2>Password Reset for <span style={{fontWeight: 400}}>{userName}</span> </h2>
                                </div>
                                <div className='modal_close_btn'>
                                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                                    </div>
                                </div>
                            </div>
                            <form className={'form_container'}>
                            <div className={styles.modal_block_password_modal}>
                                {newPassword &&
                                    <>
                                        <h5 style={{color:'green'}}>New Password has been reset successfully</h5>
                                        <h5>
                                            <div className="new_password_content">
                                            <h3 style={{marginTop:'5px'}}>{newPassword}</h3>
                                        </div>
                                        </h5>
                                        <br/>
                                    </>
                                }
                                <br/>
                                <div>
                                    <Button  onClick={newPassword ? ()=>{ handleClose()} : resetPasswordData} >{newPassword ? 'Close' : 'Reset Password'}</Button>
                                </div>
                                <br/>
                            </div>
                            </form>
                        </div>
                    </div>
                </Modal>

        </div>
    )
}

export default ChangePasswordModal

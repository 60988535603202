/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useEffect, useState} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import {useSelector} from "react-redux";
import StaticValues from "../../_metronic/helpers/StaticValues";
import {RootState} from "../../store/reducers";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env
const link = '/'

const AppRoutes: FC = () => {
    {/*@ts-ignore*/}
    // const token = JSON.parse(localStorage.getItem(StaticValues.AuthUser))
    const [user, setUser] = useState(false)
    const { result } = useSelector((state:RootState) => ({
        result: state.login.result,
    }))
    // @ts-ignore
    // const token:any = JSON.parse(localStorage.getItem(StaticValues.AuthUser).access_token || "")
    useEffect(() => {
        setUser(result)
    },[result])
  // const {currentUser} = useAuth()
  return (
    <BrowserRouter basename={link}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {localStorage.getItem(StaticValues.AuthUserQuago) && localStorage.getItem(StaticValues.userName) ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard/apps' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}

import {PageLink, PageTitle} from "../../../../_metronic/layout/core";
import React, {useState} from "react";
import {useParams} from "react-router-dom";
import AnnotationSegmentTable from "./Components/AnnotationSegmentTable/AnnotationSegmentTable";

const AnnotationItemsTable = () => {

  return <div>
      <PageTitle breadcrumbs={[]}> Segments Table</PageTitle>
      <AnnotationSegmentTable/>
  </div>
}

export default AnnotationItemsTable

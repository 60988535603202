enum QueryKeys {
    FilterDefinition = "FilterDefinition",
    SegmentFilters = "SegmentFilters",
    SegmentOrders = "SegmentOrders",
    Annotation = "Annotation",
    AnnotationJobs = "AnnotationJobs",
    AnnotationJobHeader = "AnnotationJobHeader",
    AnnotationJobData = "AnnotationJobData",
    AnnotationJobClasses = "AnnotationJobClasses",
    AnnotationFiltersDefinition = "AnnotationFiltersDefinition",
    AnnotationFilters = "AnnotationFilters",
    AnnotationOrderByDefinition = "AnnotationOrderByDefinition",
    ConfigGroup = "ConfigGroup",
    AuditsGroups = "AuditsGroups",
    ConfigDefault = 'ConfigDefault',
    AppConfig = "AppConfig",
    AppNames = "AppNames",
    AppAudit = "AppAudit",
    DefaultAudit = "DefaultAudit",
    AllAudits = "AllAudits",
    DeploymentHistory = "DeploymentHistory",
    AnnotationPlotNames = "AnnotationPlotNames",
    CDPlotNames = "CDPlotNames",
}

export default QueryKeys

import {PageLink, PageTitle} from "../../../../_metronic/layout/core";
import React from "react";
import AnnotationJobsTable from "./Components/AnnotationJobsTable/AnnotationJobsTable";

const AnnotationJobs = () => {
    const configBreadcrumbs: Array<PageLink> = [
        {
            title: 'Home /',
            path: '/dashboard/apps',
            isSeparator: false,
            isActive: false,
        },
    ]

    return <div>
        <PageTitle breadcrumbs={configBreadcrumbs}>Annotation Jobs</PageTitle>
        <AnnotationJobsTable/>
    </div>
}

export default AnnotationJobs

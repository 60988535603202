import {Modal} from "react-bootstrap";
import {KTSVG} from "../../../../_metronic/helpers";
import React from "react";
import JsonViewCompare from "../../../../_metronic/layout/components/JSONViewCompare/JsonViewCompare";
import styles from '../../../general-styles/Modals/modals.module.scss'

interface LongFileModalProps {
    show: boolean,
    handleCloseModal: any,
    file: string,
    title: string
}

const LongFileModal = ({show, handleCloseModal, file, title}: LongFileModalProps) => {
    return (
        <>
            <Modal
                show={show}
                onHide={handleCloseModal}
                centered
                size='lg'
            >
                <div>
                    <div className='modal-content'>
                        <div className={styles.flex_row_between}>
                            <div className={styles.title_close_space}>
                                <h2>{title}</h2>
                            </div>
                            <div className='modal_close_btn'>
                                <div className='btn btn-sm btn-icon btn-active-color-primary'
                                     onClick={handleCloseModal}>
                                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                                </div>
                            </div>
                        </div>
                        <div>
                            <JsonViewCompare oldData={file} newData={file}/>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default LongFileModal

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../reducers";
import {
    addAnnotationFilter,
    addAnnotationOrder,
    changeAnnotationFilterOperator,
    changeAnnotationFilterSA,
    changeAnnotationFilterType,
    changeAnnotationFilterValue,
    changeAnnotationImageType,
    changeAnnotationOrderType,
    changeAnnotationOrderValue,
    removeAnnotationFilter,
    removeAnnotationOrder,
    resetAnnotationFilter,
    resetAnnotationOrderBy,
    setAnnotationDataRequest,
    setAnnotationFilters,
    setAnnotationIPP,
    setAnnotationPage,
    setAnnotationTagFilter
} from "./actions";
import { AnnotationSegFilter, AnnotationSegSort, SortValueOptions } from "../../types/AnnotationTypes";
import { Option } from "../../types/CommunitySortTypes";
import { FilterListResponse } from "../../AUTO_GENERATED_TYPES";
import isFilterFull from "../../assets/utils/isFilterFull";
import { toast } from "react-toastify";

const useAnnotation = () => {
    const dispatch = useDispatch();

    const {
        page,
        itemsPerPage,
        tagFilter,
        dataRequest,
        filters,
        filterCount,
        sorts,
        sortCount,
        imageType
    } = useSelector((state: RootState) => ({
        page: state.annotation.page,
        itemsPerPage: state.annotation.itemsPerPage,
        tagFilter: state.annotation.tagFilter,
        dataRequest: state.annotation.dataRequest,
        filters: state.annotation.filters,
        filterCount: state.annotation.filterCount,
        sorts: state.annotation.sorts,
        sortCount: state.annotation.sortCount,
        imageType: state.annotation.imageType
    }));

    // Data Request
    const updateDataRequest = () => {
        if (filterCount > 0 && !filters.every(isFilterFull)) {
            toast.warn('Check that the Filters are complete!');
            return;
        }

        dispatch(setAnnotationPage(1));
        setTimeout(() => {
            console.log("Updating Data Request", filters, sorts);
            dispatch(setAnnotationDataRequest({
                filters: filterCount ? filters.map(filter => ({
                    filter_definition_id: Number(filter.filterType.value),
                    filter_operator: filter.filter_operator.value,
                    filter_value: filter.filterValue.map(el => el.value),
                })).filter(filter => filter.filter_value) : [],
                orders: sorts.map(sort => ({
                    order_definition_id: Number(sort.sortType.value),
                    direction: sort.sortValue.value,
                })).filter(order => order.order_definition_id)
            }));
        }, 300);
    };

    // Tag Filter
    const changeTagFilter = (tag: string) => {
        console.log("Changing Tag Filter", tag);
        dispatch(setAnnotationTagFilter(tag));
    };

    // Pagination Functions
    const changePage = (page: number) => {
        dispatch(setAnnotationPage(page + 1));
    };

    const changeItemsPerPage = (perPage: number) => {
        console.log("Changing Items Per Page", perPage);
        dispatch(setAnnotationIPP(perPage));
    };

    // Sort Functions
    const addSort = (order?: AnnotationSegSort) => {
        console.log("Adding Sort", order);
        dispatch(addAnnotationOrder(order));
    };

    const removeSort = (id: string) => {
        console.log("Removing Sort", id);
        dispatch(removeAnnotationOrder(id));
    };

    const resetSort = () => {
        console.log("Resetting Sort");
        dispatch(resetAnnotationOrderBy());
    };

    const sortTypeChange = (id: string, value: Option) => {
        console.log("Changing Sort Type", id, value);
        dispatch(changeAnnotationOrderType(id, value));
    };

    const sortValueChange = (id: string, value: SortValueOptions) => {
        console.log("Changing Sort Value", id, value);
        dispatch(changeAnnotationOrderValue(id, value));
    };

    // Filter
    const filterReset = () => {
        console.log("Resetting Filter");
        dispatch(resetAnnotationFilter());
    };

    const setFilters = (annotations: AnnotationSegFilter[]) => {
        console.log("Setting Filters", annotations);
        dispatch(setAnnotationFilters(annotations));
    };

    const addFilter = (filter?: AnnotationSegFilter) => {
        console.log("Adding Filter", filter);
        dispatch(addAnnotationFilter(filter));
    };

    const removeFilter = (id: string) => {
        console.log("Removing Filter", id);
        dispatch(removeAnnotationFilter(id));
    };

    const filterTypeChange = (id: string, newValue: Option, meta: FilterListResponse, inDeletion?: boolean | undefined) => {
        console.log("Changing Filter Type", id, newValue, meta, inDeletion);
        dispatch(changeAnnotationFilterType(id, newValue, meta, inDeletion));
    };

    const filterOperatorChange = (id: string, newValue: Option) => {
        console.log("Changing Filter Operator", id, newValue);
        dispatch(changeAnnotationFilterOperator(id, newValue));
    };

    const filterValueChange = (id: string, newValue: Option, inDeletion?: boolean | undefined) => {
        console.log("Changing Filter Value", id, newValue, inDeletion);
        dispatch(changeAnnotationFilterValue(id, newValue, inDeletion));
    };

    const filterSAChange = (newValue: string, options: FilterListResponse | undefined) => {
        console.log("Changing Filter SA", newValue, options);
        dispatch(changeAnnotationFilterSA(newValue, options));
    };

    const changeImageType = (type: Option) => {
        console.log("Changing Image Type", type);
        dispatch(changeAnnotationImageType(type));
    };

    return {
        data: { page, itemsPerPage, tagFilter, dataRequest, filters, filterCount, sorts, sortCount, imageType },
        functions: {
            changePage,
            changeItemsPerPage,
            addSort,
            removeSort,
            resetSort,
            sortTypeChange,
            sortValueChange,
            updateDataRequest,
            changeTagFilter,
            filterReset,
            setFilters,
            addFilter,
            removeFilter,
            filterTypeChange,
            filterOperatorChange,
            filterValueChange,
            filterSAChange,
            changeImageType
        }
    };
};
export default useAnnotation;

import React from "react";

const ROUTE_PATHS = {
    DASHBOARD: {
      APPS: '/dashboard/apps',
      APP_CONFIG: '/dashboard/apps/app-config/:appName/:appToken/*'
    },
    BUILDER: '/builder',
    MENU_TEST: '/menu-test',
    PROFILE: '/crafted/pages/profile/*',
    ADMIN: {
      ROLES: '/admin/roles',
      PERMISSIONS: '/admin/permissions',
      USERS: '/admin/users',
    },
    CONFIG: {
      DEFAULT: '/config/config-default',
      DEFAULT_GROUP: '/config/config-default/:groupId',
      DEFAULT_AUDITS: '/config/config-default/:defaultId/audits',
      APP: '/config/config-app',
      APP_AUDITS: '/config/config-app/:appId/audits',
      GROUP: '/config/config-group',
      AUDITS: '/config/audits',
    },
    COMMUNITY: {
      ALL: '/community/all/*',
      ALL_ID: '/community/all/:appId',
      JOB: '/community/job/:id',
      SEGMENT_GENERAL: '/community/job/segment/:type/:jobId/general',
      SEGMENT_FILTER: '/community/job/segment/:type/:jobId/:segFilter/*',
      SEGMENT_ITEMS_TABLE: '/community/job/segment/:type/:jobId/items-table/*',
      DEPLOYMENT_HISTORY: '/community/deployment-history',
    },
    ANNOTATION: {
      JOBS: '/annotation',
      SEGMENTS: '/annotation/:id/',
      SEGMENTS_TABLE: '/annotation/:id/table',
    },
    ERROR_404: '/error/404'
  };
  
  export default ROUTE_PATHS;
  
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ConfigSrc {
    DEFAULT = 'DEFAULT',
    APP = 'APP',
}

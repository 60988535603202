/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum CommunityDecision {
    REVIEW = 'REVIEW',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    UNCERTAIN = 'UNCERTAIN',
    WAITING_FOR_REVIEW = 'WAITING_FOR_REVIEW',
}

import React, {useState} from 'react';
import {PageLink, PageTitle} from "../../../../_metronic/layout/core";
import {useDispatch} from "react-redux";
import {Button, Col, Row} from "react-bootstrap";
import {KTSVG} from "../../../../_metronic/helpers";
// @ts-ignore
import BootstrapTable from 'react-bootstrap-table-next';
// @ts-ignore
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
// @ts-ignore
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import SweetAlertImport from "react-bootstrap-sweetalert";
import {Link, useNavigate} from "react-router-dom";
import '../configtable.scss'
//@ts-ignore
import ConfigGroupFormModal from "../Modals/ConfigGroupFormModal";
import {ConfigGroupListResponse, ConfigGroupResponse, GroupMode} from "../../../../AUTO_GENERATED_TYPES";
import tableClasses from "../../Community/pages/table.module.scss";
import TaggingTooltip from "../../Community/Components/TaggingTooltip";
import {useMutation, useQuery, useQueryClient} from "react-query";
import QueryKeys from "../../../../react-query/QueryKeys";
import usePreloader from "../../../../store/layout/usePreloader";
import {
    changeConfigGroupArchive,
    deleteConfigGroup,
    getGroupsDefault
} from "../../../../_metronic/helpers/backend_helper";
import ArchiveSwitch from "../../Community/Components/ArchiveSwitch/ArchiveSwitch";
import {toast} from "react-toastify";
import IconWithBubble from "../../../Components/UI/IconWithBubble/IconWithBubble";

const SweetAlert = SweetAlertImport as any

interface ConfigModalState {
    show: boolean,
    context: ConfigGroupResponse | null
}

const dateFormatter = (cell: any, row: any) => {
    let date = row.creation_time
        .replace(/[-]/g, '/')
        .replace(/[T]/g, '\u00A0');
    let jsDate = date.slice(0, 16);
    if (row.creation_time) {
        return (
            <span>
                    <p style={{marginBottom: 0, marginLeft: 5}}>
                      {jsDate}
                    </p>
                </span>
        );
    }
}

const ConfigGroupTable = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const {SearchBar} = Search;

    const [currentConfig, setCurrentConfig] = useState<Partial<ConfigGroupResponse>>({})
    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false)
    const [configModal, setConfigModal] = useState<ConfigModalState>({
        show: false,
        context: null
    })
    const [isDelete, setIsDelete] = useState<boolean>(false)
    const [groupMode, setGroupMode] = useState<GroupMode>(GroupMode.LIVE)
    const [archived, setArchived] = useState(false)
    const [archiveModal, setArchivedModal] = useState<ConfigGroupResponse | null>(null)

    const openCreateModal = () => {
        setConfigModal({show: true, context: null})
    }

    const openEditModal = (context: ConfigGroupResponse) => {
        setConfigModal({show: true, context})
    }

    const closeFormModal = () => {
        setConfigModal({show: false, context: null})
    }

    const queryClient = useQueryClient()

    const {
        data: groupsList,
        refetch,
        isFetching
    } = useQuery([QueryKeys.ConfigGroup, groupMode, archived], () => getGroupsDefault(groupMode, archived), {
        retry: false,
        refetchOnWindowFocus: false
    })

    const {mutate: deleteConfigGroupMutation} = useMutation<any, unknown, number, unknown>((groupId: number) => toast.promise(deleteConfigGroup(groupId), {
        pending: 'Deleting config group...',
        success: 'Deleted Successfully!',
    }), {
        onSuccess: (_data, id) => {
            const oldData = queryClient.getQueryData<ConfigGroupListResponse>([QueryKeys.ConfigGroup, groupMode, archived])

            const newData = {groups: oldData?.groups?.filter(group => group.id !== id) ?? oldData}

            queryClient.setQueryData([QueryKeys.ConfigGroup, groupMode, archived], newData)
        },
    })

    const {mutate: archiveConfigGroup} = useMutation<any, unknown, { groupId: number, archive: boolean }, unknown>(({
                                                                                                                        groupId,
                                                                                                                        archive
                                                                                                                    }) => toast.promise(changeConfigGroupArchive(groupId, archive), {
        pending: `${archive ? 'Archiving' : 'UnArchiving'} config group...`,
        success: `${archive ? 'Archived' : 'UnArchived'} Successfully!`,
    }), {
        onSuccess: () => {
            refetch()
        },
    })


    usePreloader(isFetching)

    const columns = [
        {
            dataField: 'group_id',
            hidden: true,
            text: '#',
            sort: true,
            headerStyle: (colum: any, colIndex: any) => {
                return {width: '35px', textAlign: 'center'};
            },
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            }
        },
        {
            dataField: 'group_name',
            text: 'Name',
            editable: false,
            headerStyle: (colum: any, colIndex: any) => {
                return {width: '710px'};
            },
            sort: true,
            style: {
                borderBottom: '.5px solid #d0d0d0',
                paddingLeft: '10px',
                verticalAlign: 'middle'
            },
            formatter: (cellContent: string, row: ConfigGroupResponse) => {
                return <div>
                    <span>{cellContent}</span>
                    <TaggingTooltip placement={'bottom'}
                                    tooltip={row?.description?.trim() !== '' && row?.description ? row.description : 'No description yet'}>
                        <KTSVG
                            path='/media/icons/duotune/general/gen045.svg'
                            className={'svg-icon-edit svg-icon-1'}
                        />
                    </TaggingTooltip></div>
            }
        },
        {
            dataField: 'creation_time',
            text: 'Creation Time',
            editable: false,
            headerStyle: (colum: any, colIndex: any) => {
                return {width: '355px'};
            },
            sort: true,
            style: {
                borderBottom: '.5px solid #d0d0d0',
                paddingLeft: '10px',
                verticalAlign: 'middle'
            },
            formatter: dateFormatter
        },
        {
            dataField: 'mode',
            text: 'Mode',
            editable: false,
            headerStyle: (colum: any, colIndex: any) => {
                return {width: '355px'};
            },
            sort: true,
            style: {
                borderBottom: '.5px solid #d0d0d0',
                paddingLeft: '10px',
                verticalAlign: 'middle',
                textAlign: 'center'
            }
        },
        {
            dataField: 'app_related',
            text: 'App Related',
            editable: false,
            headerStyle: (colum: any, colIndex: any) => {
                return {width: '300'};
            },
            sort: true,
            style: {
                borderBottom: '.5px solid #d0d0d0',
                paddingLeft: '10px',
                verticalAlign: 'middle',
                textAlign: 'center'
            }
        },
        {
            dataField: 'protected',
            text: 'Protected',
            editable: false,
            headerStyle: (colum: any, colIndex: any) => {
                return {width: '300'};
            },
            sort: true,
            style: {
                borderBottom: '.5px solid #d0d0d0',
                paddingLeft: '10px',
                verticalAlign: 'middle',
                textAlign: 'center'
            }
        },
        {
            dataField: "actions",
            isDummyField: true,
            editable: false,
            headerStyle: (colum: any, colIndex: any) => {
                return {width: '15%'};
            },
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0',
            },
            text: "Actions",
            formatter: (cellContent: any, row: ConfigGroupResponse, rowIndex: any, extraData: any) => (
                <>
                    <div className={'position-relative'}>

                        <div className={'table_action'}>
                            {!row.archived && <> <TaggingTooltip placement={'bottom'} tooltip={'Edit'}>
                                <Link
                                    to="#"
                                    id='menuapp'
                                    onClick={() => {
                                        openEditModal(row)
                                    }}
                                >
                                    <KTSVG
                                        path='/media/icons/duotune/art/art005.svg'
                                        className={`svg-icon-edit svg-icon-1`}
                                    />
                                </Link>
                            </TaggingTooltip>
                                <TaggingTooltip placement={'bottom'}
                                                tooltip={row.can_be_deleted ? "No Defaults Use this Config" : "Config Group"}>
                                    <Link
                                        to="#"
                                        id='menuapp'
                                        onClick={(e) => {
                                            e.preventDefault()
                                            if (!row.can_be_deleted) {
                                                navigate(`/config/config-default/${row.id}?groupMode=${row.mode}`)
                                            }
                                        }}
                                        style={{cursor: row.can_be_deleted ? 'not-allowed' : 'pointer'}}
                                    >
                                        <KTSVG path='/media/icons/duotune/coding/cod001.svg'
                                               className={`svg-icon${row.can_be_deleted ? '' : '-edit'} svg-icon-1`}/>
                                    </Link>
                                </TaggingTooltip></>}

                            <IconWithBubble>
                                <h6>{row.group_name}</h6>
                                <p
                                    onClick={() => {
                                        setArchivedModal(row)
                                    }}
                                    style={{cursor: 'pointer'}}
                                ><KTSVG
                                    path={`/media/icons/duotune/files/fil01${row.archived ? '8' : '7'}.svg`}
                                    className={`svg-icon-context svg-icon-1`}
                                />{row.archived ? 'UnArchive' : 'Archive'}</p>

                                <p
                                    onClick={() => {
                                        //TODO: Version 2
                                    }}
                                    style={{cursor: 'not-allowed'}}
                                ><KTSVG path='/media/icons/duotune/general/gen054.svg'
                                        className='svg-icon svg-icon-1'/> Clone</p>

                                {row.can_be_deleted ? <p
                                    onClick={() => {
                                        setIsDelete(true)
                                        setCurrentConfig(row)
                                        setOpenConfirmModal(true)

                                    }}
                                    style={{cursor: 'pointer'}}
                                ><KTSVG
                                    path='/media/icons/duotune/general/gen027.svg'
                                    className='svg-icon-context svg-icon-1'
                                /> Delete</p> : <TaggingTooltip placement={'left'}
                                                                tooltip={'Delete is disabled because it has config relations'}>
                                    <p
                                        style={{cursor:'not-allowed'}}
                                    ><KTSVG
                                        path='/media/icons/duotune/general/gen027.svg'
                                        className='svg-icon svg-icon-1'
                                    /> Delete</p>
                                </TaggingTooltip>}

                            </IconWithBubble>
                        </div>
                    </div>
                </>
            ),
        },
    ];

    const configBreadcrumbs: Array<PageLink> = [
        {
            title: 'Home /',
            path: '/dashboard/apps',
            isSeparator: false,
            isActive: false,
        },
    ]

    return (
        <>
            <PageTitle breadcrumbs={configBreadcrumbs}>Config Group</PageTitle>
            {configModal.show && <ConfigGroupFormModal handleClose={closeFormModal} context={configModal.context}
                                                       tableGroupMode={groupMode} tableArchived={archived}/>}
            <SweetAlert
                show={openConfirmModal}
                warning
                showCancel
                confirmBtnText="Yes"
                cancelBtnText="Cancel"
                title={`${isDelete ? 'Delete' : 'Change'} Config Group`}
                confirmBtnBsStyle={'danger'}
                onConfirm={() => {
                    setOpenConfirmModal(false)
                    deleteConfigGroupMutation(currentConfig.id!)
                }}
                onCancel={() => setOpenConfirmModal(false)}
            >
                Are you sure you want to delete <span
                style={{fontWeight: 'bold'}}>{currentConfig.group_name}</span>?
            </SweetAlert>

            <SweetAlert
                show={Boolean(archiveModal)}
                warning
                showCancel
                confirmBtnText="Yes"
                confirmBtnBsStyle={'danger'}
                cancelBtnText="Cancel"
                title={`${archiveModal?.archived ? 'UnArchive' : 'Archive'} Config Group`}
                onConfirm={() => {
                    archiveConfigGroup({groupId: archiveModal!.id, archive: !archiveModal!.archived})
                    setArchivedModal(null)
                }}
                onCancel={() => setArchivedModal(null)}
            >
                Are you sure you want to {archiveModal?.archived ? 'UnArchive' : 'Archive'} <span
                style={{fontWeight: 'bold'}}>{archiveModal?.group_name}</span>?
            </SweetAlert>
            <>
                <ToolkitProvider
                    keyField="config_id"
                    data={groupsList?.groups ?? []}
                    columns={columns}
                    search
                    bootstrap4
                >
                    {
                        (props: any) => (
                            <div style={{ padding: '15px'}}>
                                <div>
                                    <br/>
                                    <div className={'toolbar_table'}>
                                        <div>
                                            <SearchBar {...props.searchProps} />
                                        </div>
                                        <div className={'filter_toolbar'}>
                                            <select value={groupMode} onChange={(e) => {
                                                setGroupMode(e.target.value as GroupMode)
                                                // setPage(1)
                                            }}>
                                                <option value={GroupMode.ALL}>- Group Mode -</option>
                                                <option value={GroupMode.LIVE}>Live</option>
                                                <option value={GroupMode.DEMO}>Demo</option>
                                                <option value={GroupMode.TEST}>Test</option>
                                            </select>

                                            <ArchiveSwitch checked={archived} onChange={() => {
                                                setArchived(prevState => !prevState)
                                                // setPage(1)
                                            }}/>

                                            <Button className={'add_btn'}
                                                    onClick={openCreateModal}
                                            >
                                                {/*@ts-ignore*/}
                                                <div style={{marginRight: "10px"}}>
                                                    <KTSVG
                                                        path='/media/icons/duotune/arrows/arr052.svg'
                                                        className='svg-icon svg-icon-2x'
                                                    />
                                                </div>
                                            </Button>
                                        </div>
                                    </div>
                                    <br/>
                                </div>
                                <Row>
                                    <Col xl='12'
                                         className={`${tableClasses.table_wrapper} ${tableClasses['table_wrapper--community']}`}>
                                        {(!isFetching && groupsList && groupsList.groups && groupsList.groups?.length > 0) ?
                                            <BootstrapTable
                                                {...props.baseProps}
                                                keyField='id'
                                                pagination={paginationFactory()}
                                                bordered={true}
                                                responsive
                                                stripped
                                                headerWrapperClasses={"header-class"}
                                            /> : <div>No Results Found</div>}
                                    </Col>
                                </Row>
                            </div>
                        )
                    }
                </ToolkitProvider>
            </>
        </>
    )
}

export default ConfigGroupTable

import {useEffect} from "react";
import {changePreloader} from "./actions";
import {useDispatch} from "react-redux";

const usePreloader = (isLoading:boolean)=>{
    const dispatch = useDispatch()

    useEffect(() => {
        if (isLoading) {
            dispatch(changePreloader(true))
        } else {
            dispatch(changePreloader(false))
        }
    }, [isLoading])
}

export default usePreloader

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum AppPlan {
    BASIC = 'BASIC',
    PREMIUM = 'PREMIUM',
}

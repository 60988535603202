import {AppClient, OpenAPIConfig} from "../../AUTO_GENERATED_TYPES";
import StaticValues from "./StaticValues";

const getToken = async ()=>{
    return JSON.parse(localStorage.getItem(StaticValues.AuthUserQuago) as string)?.access_token;
}


const appClientConfig: Partial<OpenAPIConfig> = {
    BASE: process.env.REACT_APP_API_URL || "http://localhost:8080",
    TOKEN: getToken,
}

const appClient = new AppClient(appClientConfig)

export default appClient;

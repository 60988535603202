/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ArchiveConfigGroupRequest } from '../models/ArchiveConfigGroupRequest';
import type { ConfigGroupListResponse } from '../models/ConfigGroupListResponse';
import type { CreateConfigGroupRequest } from '../models/CreateConfigGroupRequest';
import type { GroupMode } from '../models/GroupMode';
import type { ShortConfigGroupResponseList } from '../models/ShortConfigGroupResponseList';
import type { UpdateConfigGroupRequest } from '../models/UpdateConfigGroupRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ConfigGroupService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Groups With Filters
     * @param groupMode 
     * @param archived 
     * @param appRelated 
     * @returns ConfigGroupListResponse Successful Response
     * @throws ApiError
     */
    public getGroupsWithFiltersV1ConfigGroupAllFilterGet(
groupMode?: GroupMode,
archived: boolean = false,
appRelated: boolean = false,
): CancelablePromise<ConfigGroupListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/config/group/all/filter',
            query: {
                'group_mode': groupMode,
                'archived': archived,
                'app_related': appRelated,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Get All Groups For Audit
     * @param groupMode 
     * @returns ShortConfigGroupResponseList Successful Response
     * @throws ApiError
     */
    public getAllGroupsForAuditV1ConfigGroupAllAuditGet(
groupMode?: GroupMode,
): CancelablePromise<ShortConfigGroupResponseList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/config/group/all/audit',
            query: {
                'group_mode': groupMode,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Create Group
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public createGroupV1ConfigGroupCreatePost(
requestBody: CreateConfigGroupRequest,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/config/group/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Update Group
     * @param groupId 
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateGroupV1ConfigGroupGroupIdUpdatePatch(
groupId: number,
requestBody: UpdateConfigGroupRequest,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/config/group/{group_id}/update',
            path: {
                'group_id': groupId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Archive Group
     * @param groupId 
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public archiveGroupV1ConfigGroupGroupIdArchivePatch(
groupId: number,
requestBody: ArchiveConfigGroupRequest,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/config/group/{group_id}/archive',
            path: {
                'group_id': groupId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Delete Group
     * @param groupId 
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteGroupV1ConfigGroupGroupIdDeleteDelete(
groupId: number,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/config/group/{group_id}/delete',
            path: {
                'group_id': groupId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

}

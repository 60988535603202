import React from 'react';
//@ts-ignore
import ReactJsonViewCompare from "react-json-view-compare";

interface JsonViewProps {
    // show: boolean,
    // handleClose: () => void,
    oldData: Object,
    newData: Object,

}

const JsonViewCompare = ({ oldData, newData}: JsonViewProps) =>{
    return(
        <>
            <ReactJsonViewCompare oldData={oldData} newData={newData} />
        </>
    )
}

export default JsonViewCompare

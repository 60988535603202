import ReactSwitch from "react-switch";
import classes from './ArchiveSwitch.module.scss'
import React from "react";

interface ArchiveSwitchProps {
    checked: boolean,
    onChange: (event?: any) => void,
}

const ArchiveSwitch: React.FC<ArchiveSwitchProps> = ({checked, onChange}) => {
    return <div className={classes.container}>
        <label className={classes.label}>Archive mode:</label>
        <ReactSwitch
            offColor={'#0095e8'}
            onColor={'#181C32'}
            width={100}
            checkedIcon={<div className={classes.icon}>Archive</div>}
            uncheckedIcon={<div className={`${classes.icon} ${classes.icon__unchecked}`}>UnArchive</div>}
            checked={checked}
            onChange={onChange}/>
    </div>
}

export default ArchiveSwitch

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum SegmentType {
    POS = 'POS',
    NEG = 'NEG',
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {Button} from "react-bootstrap";
import './newuser.scss'
import {newUser} from "../../../../store/actions";
import {useDispatch} from "react-redux";
import {CreateUserRequest, UserRole} from "../../../../AUTO_GENERATED_TYPES";

const InviteUsers: FC = () => {
  const dispatch = useDispatch();

  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [fullname, setFullname] = useState('')
  const [password, setPassword] = useState('')
  const [role, setRole] = useState<UserRole | ''>('')

  const createUser = () => {
    let userData = {
      "username": username,
      "email": email,
      "full_name":fullname,
      "password": password,
      "role": role
    }

    console.log(userData)
    dispatch(newUser(userData as CreateUserRequest))
  }

  return (
    <div className='modal fade' id='kt_modal_new_user' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Create a new User</h1>
            </div>
          </div>
            <div style={{ marginLeft:'auto', marginRight:'auto', width: '60%', textAlign: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignContent:'center'}}>
              <input onChange={(e)=> setUsername(e.target.value)} type="text" placeholder='Username'/>
              <br/>
              <input onChange={(e)=> setEmail(e.target.value)} type="text" placeholder='Email'/>
              <br/>
              <input onChange={(e)=> setFullname(e.target.value)} type="text" placeholder='Full Name'/>
              <br/>
              <input onChange={(e)=> setPassword(e.target.value)} type="text" placeholder='Password'/>
              <br/>
              <select onChange={(e)=> setRole(e.target.value as UserRole)} name="" id="">
                <option value="ADMIN">Admin</option>
                <option value="SUPERUSER">SuperUser</option>
                <option value="ANALYST">Analyst</option>
              </select>
              <br/>
              <br/>
              <Button onClick={createUser}>Confirm</Button>
              <br/>
              <br/>
            </div>


        </div>
      </div>
    </div>
  )
}

export {InviteUsers}

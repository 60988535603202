import React, {useEffect, useState} from 'react';
import {PageLink, PageTitle} from "../../../../_metronic/layout/core";
import {Button, Col, Row} from "react-bootstrap";
import {KTSVG} from "../../../../_metronic/helpers";
// @ts-ignore
import BootstrapTable from 'react-bootstrap-table-next';
// @ts-ignore
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
// @ts-ignore
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import '../configtable.scss'
import {useMutation, useQuery, useQueryClient} from "react-query";
import QueryKeys from "../../../../react-query/QueryKeys";
import {deleteConfig, getConfigDefault, getGroupsDefault} from "../../../../_metronic/helpers/backend_helper";
import usePreloader from "../../../../store/layout/usePreloader";
import {ConfigListResponse, ConfigResponse, GroupMode} from "../../../../AUTO_GENERATED_TYPES";
import Select from "react-select";
import Moment from 'moment'
import TaggingTooltip from "../../Community/Components/TaggingTooltip";
import IconWithBubble from "../../../Components/UI/IconWithBubble/IconWithBubble";
import tableClasses from "../../Community/pages/table.module.scss";
import {Option} from "../../../../types/CommunitySortTypes";
import SweetAlertImport from "react-bootstrap-sweetalert";
import {toast} from "react-toastify";
import NewConfig from "../Modals/NewConfig";
import EditConfig from "../Modals/EditConfig";
import caseInsensitiveSort from "../../../helpers/utils/sorting/caseInsensitiveSort";
import useSVG from "../../../helpers/hooks/useSVG";
import getGroupModeLabel from "../utils/getGroupModeLabel";

const SweetAlert = SweetAlertImport as any


const ConfigDefaultsTable = () => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const params = useParams()
    const groupId = params.groupId
    const [searchParams, setSearchParams] = useSearchParams()

    const {SearchBar} = Search;

    const [currentConfig, setCurrentConfig] = useState<ConfigResponse | null>(null)
    const [newConfigModal, setNewConfigModal] = useState<boolean>(false)
    const [editConfigModal, setEditConfigModal] = useState<boolean>(false)
    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false)
    const [filterGroups, setFilterGroups] = useState<Option[]>([])
    const [groupMode, setGroupMode] = useState<GroupMode>(searchParams.get('groupMode') as GroupMode ?? GroupMode.ALL)

    const infoSVG = useSVG('/media/icons/duotune/general/gen045.svg')
    const penSVG = useSVG('/media/icons/duotune/art/art005.svg')
    const copySVG = useSVG('/media/icons/duotune/general/gen054.svg')
    const paperSVG = useSVG('/media/icons/duotune/general/gen005.svg')
    const trashSVG = useSVG('/media/icons/duotune/general/gen027.svg')

    const allSVGLoaded = infoSVG && penSVG && copySVG && paperSVG && trashSVG


    useEffect(() => {
        setSearchParams({})
    }, [])

    const {
        data: groupsList,
        refetch: refetchGroups,
        isLoading
    } = useQuery([QueryKeys.ConfigGroup, groupMode], () => getGroupsDefault(groupMode), {
        retry: false,
        refetchOnWindowFocus: false,
        onSuccess: (data => {
            if (groupId !== undefined) {
                const group = data.groups?.find(group => group.id === Number.parseInt(groupId))
                if (!group) {
                    return
                }
                const option = {value: String(group.id), label: group.group_name}
                setFilterGroups([option])
            }
        })
    })


    const {
        data: defaultGroups,
        isFetching
    } = useQuery([QueryKeys.ConfigDefault, filterGroups], () => getConfigDefault(filterGroups.map(group => group.value).join(',')), {
        enabled: Boolean(groupsList),
        refetchOnWindowFocus: false, select: (data: ConfigListResponse): ConfigListResponse => {
            if (groupMode === GroupMode.ALL) {
                return data
            }

            const unfilteredConfigs = data.configs?.slice() ?? []
            const filteredConfigs = unfilteredConfigs.filter(config => config.config_group_mode === groupMode)

            return {configs: filteredConfigs}
        }
    })

    const {mutate: deleteConfigDefault} = useMutation<any, unknown, number, unknown>((configId) => toast.promise(deleteConfig(configId), {
        pending: 'Deleting config...',
        success: 'Deleted Successfully!',
    }), {
        onSuccess: (_data, id) => {
            // const oldData = queryClient.getQueryData<ConfigListResponse>([QueryKeys.ConfigDefault, filterGroups])

            refetchGroups().then(response => {
                queryClient.invalidateQueries([QueryKeys.ConfigDefault])


                // setFilterGroups(prevState => {
                //     const newGroupIdList = response.data?.groups?.map(group => group.id) ?? []
                //     return prevState.filter(group => !newGroupIdList.includes(Number.parseInt(group.value)))
                // })
                // setCurrentConfig(null)
                //
                // const newData = {configs: oldData?.configs?.filter(config => config.config_id !== id) ?? []}
                //
                // queryClient.setQueryData([QueryKeys.ConfigDefault, filterGroups], newData)
            })


        },
    })

    const groupsOption = groupsList?.groups?.map(group => ({
        value: String(group.id),
        label: group.group_name
    })) ?? null

    usePreloader(isLoading || isFetching)

    const columns = [
        {
            dataField: 'config_id',
            hidden: true,
            text: '#',
            sort: true,
        },
        {
            dataField: 'config_name',
            text: 'Name',
            editable: false,
            headerStyle: (colum: any, colIndex: any) => {
                return {width: '255px'};
            },
            sort: true,
            sortFunc: caseInsensitiveSort,
            style: {
                borderBottom: '.5px solid #d0d0d0',
                paddingLeft: '10px'
            },
            formatter: (cellContent: string, row: ConfigResponse) => {
                return <div style={{minWidth: 'max-content'}}>
                    <span>{cellContent}</span>
                    <TaggingTooltip placement={'bottom'}
                                    tooltip={row?.config_description?.trim() !== '' && row?.config_description ? row.config_description : 'No description yet'}>
                        {infoSVG && <KTSVG
                            path={infoSVG}
                            className={'svg-icon-edit svg-icon-1'}
                        />}
                    </TaggingTooltip></div>
            }
        },
        {
            dataField: 'config_group',
            text: 'Group',
            editable: false,
            sort: true,
            style: {
                borderBottom: '.5px solid #d0d0d0',
            },
        },
        {
            dataField: 'config_group_mode',
            text: 'Mode',
            editable: false,
            sort: true,
            style: {
                borderBottom: '.5px solid #d0d0d0'
            },
        },
        {
            dataField: 'config_group_app_related',
            text: 'App Related',
            editable: false,
            sort: true,
            style: {
                borderBottom: '.5px solid #d0d0d0'
            },
        },
        {
            dataField: 'config_value',
            text: 'Value',
            editable: false,
            sort: true,
            sortFunc: caseInsensitiveSort,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: ((cellContent: string) => <TaggingTooltip placement={'bottom'}
                                                                 tooltip={cellContent?.split(',').map(value => <div
                                                                     key={value}>{value}</div>)}>
                <div className={'seg_long_copy'}>{cellContent}</div>
            </TaggingTooltip>)
        },
        {
            dataField: 'config_type',
            text: 'Type',
            editable: false,
            sort: true,
            sortFunc: caseInsensitiveSort,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            }
        },
        {
            dataField: 'config_valid_values',
            text: 'Allowed Values',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: ((cellContent: string[]) => <TaggingTooltip placement={'bottom'}
                                                                   tooltip={cellContent.map(value => <div
                                                                       key={value}>{value}</div>)}>
                <div className={'seg_long_copy'}>{cellContent.join(', ')}</div>
            </TaggingTooltip>)

        },
        {
            dataField: 'config_last_update_time',
            text: 'Last Update Time',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: ((cellContent: string) => {
                return <div style={{minWidth: 'max-content'}}>{Moment(cellContent).format('YYYY/MM/DD HH:mm')}</div>
            })
        },
        {
            dataField: 'config_last_update_by',
            text: 'Last Update By',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            }
        },
        {
            dataField: "actions",
            isDummyField: true,
            editable: false,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            text: "Actions",
            formatter: (cellContent: any, row: ConfigResponse, rowIndex: any, extraData: any) => (
                <>
                    <div className={'position-relative'}>

                        <div className={'table_action'} style={{gap: '10px'}}>
                            <TaggingTooltip placement={'bottom'} tooltip={'Edit'}>
                                <Link
                                    to="#"
                                    id='menuapp'
                                    onClick={() => {
                                        setEditConfigModal(true)
                                        setCurrentConfig(row)
                                    }}
                                >
                                    {penSVG && <KTSVG
                                        path={penSVG}
                                        className={`svg-icon-edit svg-icon-1`}
                                    />}
                                </Link>
                            </TaggingTooltip>
                            <IconWithBubble>
                                <h6>{row.config_name}</h6>
                                <p
                                    onClick={() => {
                                        //TODO: Version 2
                                    }}
                                    style={{cursor: 'not-allowed'}}
                                >{copySVG && <KTSVG path={copySVG!}
                                                    className='svg-icon svg-icon-1' loader={!copySVG}/>} Clone</p>

                                <p
                                    onClick={() => {
                                        navigate(`/config/config-default/${row.config_id}/audits`)
                                    }}
                                    style={{cursor: 'pointer'}}
                                ><KTSVG
                                    path={paperSVG || ''}
                                    className='svg-icon-context svg-icon-1'
                                    loader={!paperSVG}
                                /> Audit Log</p>

                                <p
                                    onClick={() => {
                                        setCurrentConfig(row)
                                        setOpenConfirmModal(true)
                                    }}
                                    style={{cursor: 'pointer'}}
                                ><KTSVG
                                    path={trashSVG || ''}
                                    className='svg-icon-context svg-icon-1'
                                    loader={!trashSVG}
                                /> Delete</p>
                            </IconWithBubble>
                        </div>
                    </div>
                </>
            ),
        },
    ];

    const configBreadcrumbs: Array<PageLink> = [
        {
            title: 'Home /',
            path: '/dashboard/apps',
            isSeparator: false,
            isActive: false,
        },
    ]

    if (groupId) {
        configBreadcrumbs.push({
            title: 'Config Group /',
            path: '/config/config-group',
            isSeparator: false,
            isActive: false,
        },)
    }

    if (!allSVGLoaded) {
        return null
    }

    return (
        <>
            <PageTitle breadcrumbs={configBreadcrumbs}>Default Config</PageTitle>
            <NewConfig show={newConfigModal} handleClose={() => setNewConfigModal(false)}/>
            {currentConfig && <EditConfig show={editConfigModal} handleClose={() => {
                setEditConfigModal(false)
                setCurrentConfig(null)
            }}
                                          currentConfig={currentConfig}/>}
            <>
                <ToolkitProvider
                    keyField="config_id"
                    data={defaultGroups?.configs ?? []}
                    columns={columns}
                    search
                    bootstrap4
                >
                    {
                        (props: any) => (
                            <div style={{ padding: '15px'}}>
                                <div>
                                    <br/>
                                    <div className={'toolbar_table'}>
                                        <div>
                                            <SearchBar {...props.searchProps} />
                                        </div>
                                        <div className={'filter_toolbar'} style={{zIndex: 20}}>
                                            <Select value={{value: groupMode, label: getGroupModeLabel(groupMode)}}
                                                    onChange={(option) => {
                                                        setGroupMode(option!.value as GroupMode)
                                                        // setPage(1)
                                                    }} options={[
                                                {value: GroupMode.ALL, label: '- Group Mode -'},
                                                {value: GroupMode.LIVE, label: 'Live'},
                                                {value: GroupMode.DEMO, label: 'Demo'},
                                                {value: GroupMode.TEST, label: 'Test'}
                                            ]}
                                                    styles={{
                                                        control: (baseStyles) => ({
                                                            ...baseStyles,
                                                            borderRadius: '8px',
                                                            minWidth: '200px'
                                                        }),
                                                    }}
                                            />

                                            {groupsOption && <Select isMulti placeholder={'Groups'}
                                                                     value={filterGroups.length > 0 ? filterGroups : null}
                                                                     options={groupsOption}
                                                                     onChange={(e) => {
                                                                         navigate('/config/config-default')
                                                                         setFilterGroups(e as Option[])
                                                                     }}
                                                                     styles={{
                                                                         control: (baseStyles) => ({
                                                                             ...baseStyles,
                                                                             borderRadius: '8px',
                                                                             minWidth: '200px'
                                                                         }),
                                                                     }}/>}

                                            <Button className={'add_btn'}
                                                    onClick={() => {
                                                        setNewConfigModal(true)
                                                    }}
                                            >
                                                <div style={{marginRight: "10px"}}>
                                                    <KTSVG
                                                        path='/media/icons/duotune/arrows/arr052.svg'
                                                        className='svg-icon svg-icon-2x'
                                                    />
                                                </div>
                                            </Button>
                                        </div>
                                    </div>

                                    <br/>
                                </div>
                                <Row>
                                    <Col xl='12'
                                         className={`${tableClasses.table_wrapper} ${tableClasses['table_wrapper--community']}`}>
                                        {defaultGroups?.configs && defaultGroups.configs.length > 0 ? (
                                            <BootstrapTable
                                                {...props.baseProps}
                                                keyField='config_id'
                                                pagination={paginationFactory()}
                                                bordered={true}
                                                responsive
                                                stripped
                                                headerWrapperClasses={"header-class"}
                                            />) : isFetching ? (
                                            <>
                                                <p>Loading....</p>
                                            </>
                                        ) : <p>No Configs Found</p>
                                        }
                                    </Col>
                                </Row>
                            </div>
                        )
                    }
                </ToolkitProvider>
            </>
            <SweetAlert
                show={openConfirmModal}
                warning
                showCancel
                confirmBtnText="Yes"
                cancelBtnText="Cancel"
                title={'Delete Default Config'}
                confirmBtnBsStyle={'danger'}
                onConfirm={() => {
                    setOpenConfirmModal(false)
                    deleteConfigDefault(currentConfig!.config_id)
                }}
                onCancel={() => setOpenConfirmModal(false)}
            >
                Are you sure you want to delete <span
                style={{fontWeight: 'bold'}}>{currentConfig?.config_name}</span>?
            </SweetAlert>
        </>
    )
}

export default ConfigDefaultsTable

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppConfigHistoryListResponse } from '../models/AppConfigHistoryListResponse';
import type { ConfigListResponse } from '../models/ConfigListResponse';
import type { CreateAppConfigRequest } from '../models/CreateAppConfigRequest';
import type { CreateConfigFromDefaultRequest } from '../models/CreateConfigFromDefaultRequest';
import type { UpdateAppConfigRequest } from '../models/UpdateAppConfigRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ConfigAppService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create App Config
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public createAppConfigV1ConfigAppCreatePost(
requestBody: CreateAppConfigRequest,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/config/app/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Create App Config From Default
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public createAppConfigFromDefaultV1ConfigAppCreateFromDefaultPost(
requestBody: CreateConfigFromDefaultRequest,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/config/app/create/fromDefault',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Get All Apps Configs
     * @param groups 
     * @param appId 
     * @param showOnlyApp 
     * @returns ConfigListResponse Successful Response
     * @throws ApiError
     */
    public getAllAppsConfigsV1ConfigAppAllGet(
groups?: string,
appId?: number,
showOnlyApp: boolean = false,
): CancelablePromise<ConfigListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/config/app/all',
            query: {
                'groups': groups,
                'app_id': appId,
                'show_only_app': showOnlyApp,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Delete App Config
     * @param appConfigId 
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteAppConfigV1ConfigAppAppConfigIdDeleteDelete(
appConfigId: number,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/config/app/{app_config_id}/delete',
            path: {
                'app_config_id': appConfigId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Update App Config
     * @param appConfigId 
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateAppConfigV1ConfigAppAppConfigIdUpdatePatch(
appConfigId: number,
requestBody: UpdateAppConfigRequest,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/config/app/{app_config_id}/update',
            path: {
                'app_config_id': appConfigId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Get App Config Audit
     * @param appConfigId 
     * @returns AppConfigHistoryListResponse Successful Response
     * @throws ApiError
     */
    public getAppConfigAuditV1ConfigAppAppConfigIdAuditGet(
appConfigId: number,
): CancelablePromise<AppConfigHistoryListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/config/app/{app_config_id}/audit',
            path: {
                'app_config_id': appConfigId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

}

import {Card, CardBody, Col, Row} from "reactstrap";
import Select from "react-select";
import React, {useEffect, useMemo} from "react";
import {CSSTransition} from "react-transition-group";
import useAnnotation from "../../../../../../store/annotation/useAnnotation";
import {OrderByDirection} from "../../../../../../AUTO_GENERATED_TYPES";
import {Option} from "../../../../../../types/CommunitySortTypes";
import {SortValueOptions} from "../../../../../../types/AnnotationTypes";
import TaggingTooltip from "../../../../Community/Components/TaggingTooltip";
import {KTSVG} from "../../../../../../_metronic/helpers";
import {useQuery} from "react-query";
import QueryKeys from "../../../../../../react-query/QueryKeys";
import {getAnnotationOrderDefinition} from "../../../../../../_metronic/helpers/backend_helper";
import {useParams} from "react-router-dom";

const valueOptions = [{value: OrderByDirection.ASC, label: "Ascending"}, {
    value: OrderByDirection.DESC,
    label: "Descending"
}]

interface AnnotationSortProps {
    isOpen: boolean,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const AnnotationSort: React.FC<AnnotationSortProps> = ({
                                                           isOpen,
                                                           setIsOpen,
                                                       }) => {
    const {id} = useParams()
    const annotationId = Number(id)

    const {
        data: {sorts, sortCount},
        functions: {removeSort, resetSort, addSort, sortTypeChange, sortValueChange, updateDataRequest}
    } = useAnnotation()

    const {data: sortData} = useQuery([QueryKeys.Annotation, QueryKeys.AnnotationOrderByDefinition, annotationId], () => getAnnotationOrderDefinition(annotationId), {
        refetchOnWindowFocus: false,
        staleTime: Infinity,
        cacheTime: Infinity
    })
    const sortOptions = sortData?.orders?.map(sort => ({value: sort.id, label: sort.short_name}))


    const allowAdd = useMemo(() => sorts[0]?.sortType.value !== '', [sorts[0]])

    useEffect(() => {
        const closeModal = () => {
            setIsOpen(false)
        }
        document.body.addEventListener('click', closeModal)
        return () => {
            document.body.removeEventListener('click', closeModal)

        }
    }, [])

    const addNewSort = () => {
        addSort()
    }

    const handleResetSort = () => {
        resetSort()
    }

    const handleRemoveSort = (id: string) => {
        removeSort(id)

        if (sortCount === 1) {
            resetSort()
        }
    }

    const typeHandleChange = (id: string, newValue?: Option | null) => {
        if (!newValue) {
            return
        }
        sortTypeChange(id, newValue)
    }

    const valueHandleChange = (id: string, newValue: SortValueOptions) => {
        sortValueChange(id, newValue)
    }

    const handleFilterData = () => {
        setIsOpen(false)
        updateDataRequest()
    }


    return (
        <CSSTransition
            in={isOpen}
            timeout={300}
            classNames="modal_animation"
            unmountOnExit
        >
            <div onClick={(e) => e.stopPropagation()} className={'segment_filter_window segment_filter_window--sort'}>
                <Card className="filter_card">
                    <CardBody id="advanced_filter">
                        <h4 className="mb-4 text-start">Order by</h4>
                        {sorts.map(({id}, idx) => (
                            <Row key={idx} style={{marginBottom: "10px"}} className="align-items-center">
                                <Col sm="5">
                                    {sortOptions && <Select
                                        value={sorts[idx].sortType}
                                        //@ts-ignore
                                        options={sortOptions}
                                        onChange={(e) => typeHandleChange(id, e)}
                                        styles={{
                                            menu: (baseStyles) => ({
                                                ...baseStyles,
                                                color: 'black',
                                            }),
                                        }}
                                    />}
                                </Col>
                                <Col sm="5">
                                    <Select
                                        isDisabled={sorts[idx].sortType.value === ""}
                                        value={sorts[idx]?.sortValue}
                                        options={valueOptions as any}
                                        placeholder={'ASC/DESC'}
                                        onChange={(e) => valueHandleChange(id, e!)}
                                        styles={{
                                            menu: (baseStyles) => ({
                                                ...baseStyles,
                                                color: 'black',
                                            }),
                                        }}
                                    />
                                </Col>
                                <Col sm="auto">
                                    <TaggingTooltip placement={'right'} tooltip={'Delete Order'}>
                                        <div onClick={() => handleRemoveSort(id)} className={'del_button'}>
                                            <KTSVG path='/media/icons/duotune/arrows/arr061.svg'
                                                   className={'svg-icon-1 active_delete'}
                                            />
                                        </div>
                                    </TaggingTooltip>
                                </Col>
                            </Row>
                        ))}
                        <Row className="align-items-center mt-2">
                            <Col sm="9">
                                {sorts.length <= 2 &&
                                    <TaggingTooltip placement={'left'}
                                                    tooltip={!allowAdd ? 'Select ASC/DESC First' : 'Add New'}>
                                        <div onClick={addNewSort} className={'plus_button'}>
                                            <KTSVG path='/media/icons/duotune/general/gen041.svg'
                                                   className={`svg-icon-1 ${!allowAdd ? 'inactive_plus' : 'active_plus'}`}
                                            />
                                        </div>
                                    </TaggingTooltip>}
                            </Col>
                            <Col sm="1">
                                <TaggingTooltip placement={'bottom'} tooltip={'Reset Order By'}>
                                    <div onClick={handleResetSort} className={'refresh_button'}>
                                        <KTSVG path='/media/icons/duotune/arrows/arr029.svg'
                                               className='svg-icon-1 active_plus'/>
                                    </div>
                                </TaggingTooltip>
                            </Col>
                            <Col sm="auto">
                                <TaggingTooltip placement={'bottom'} tooltip={'Apply Order'}>
                                    <div onClick={handleFilterData} className={'refresh_button'}>
                                        <KTSVG path='/media/icons/duotune/arrows/arr027.svg'
                                               className='svg-icon-1 active_plus'/>
                                    </div>
                                </TaggingTooltip>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        </CSSTransition>)
}

export default AnnotationSort

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum WarningType {
    NOT_APPROVED_AND_EXISTING = 'NOT_APPROVED_AND_EXISTING',
    APPROVED_AND_TAGGED_NEG = 'APPROVED_AND_TAGGED_NEG',
}

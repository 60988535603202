import {Label} from "reactstrap";
import React from "react";
import LongDetailCell from "../../../../Community/Components/LongDetailCell";

interface DetailsAdditionalDataProps {
    label: string,
    data: any,
    copy?:boolean
}

const DetailsAdditionalData: React.FC<DetailsAdditionalDataProps> = React.memo(({data, label,copy}) => {
    let displayedData = <p>{data ?? 'N/A'}</p>
    if (Array.isArray(data) || copy) {
        displayedData = <LongDetailCell data={data}/>
    }

    return <div>
        <Label>{label}</Label>
        {displayedData}
    </div>
})

export default DetailsAdditionalData

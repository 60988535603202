import {useEffect, useState} from "react";
import {toAbsoluteUrl} from "../../../_metronic/helpers";

const svgMapper = new Map<string, string>()

const svgCache = new Map<string, Promise<string>>()

const useSVG = (svgPath: string, retries = 3, delay = 1000) => {
    const [svg, setSVG] = useState<string | null>(null)
    const [retryCount, setRetryCount] = useState<number>(0)

    useEffect(() => {
        let svgPromise = svgCache.get(svgPath)
        if (!svgPromise) {
            svgPromise = fetch(toAbsoluteUrl(svgPath)).then(res => res.text())

            svgCache.set(svgPath, svgPromise)
        }

        svgPromise.then(svgData => {
            svgMapper.set(svgPath, svgData)
            setSVG(svgData)
        }).catch(error => {
            if (retryCount < retries) {
                setTimeout(() => {
                    setRetryCount(retryCount + 1)
                }, delay)
            }
        })
    }, [svgPath, retryCount, retries, delay])

    return svg
}

export default useSVG

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum IntegrationType {
    BASIC = 'BASIC',
    TAILORED = 'TAILORED',
}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppExportConfigListResponse } from '../models/AppExportConfigListResponse';
import type { AuditAllConfigListResponse } from '../models/AuditAllConfigListResponse';
import type { ConfigGroupExportResponseList } from '../models/ConfigGroupExportResponseList';
import type { GroupMode } from '../models/GroupMode';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ConfigGeneralService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Audit For All Configs
     * @param groupMode 
     * @param groups 
     * @param appId 
     * @param configName 
     * @returns AuditAllConfigListResponse Successful Response
     * @throws ApiError
     */
    public getAuditForAllConfigsV1ConfigAuditAllGet(
groupMode: GroupMode,
groups?: string,
appId?: number,
configName?: string,
): CancelablePromise<AuditAllConfigListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/config/audit/all',
            query: {
                'group_mode': groupMode,
                'groups': groups,
                'app_id': appId,
                'config_name': configName,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Export Configs
     * @param groupMode 
     * @param groupName 
     * @returns ConfigGroupExportResponseList Successful Response
     * @throws ApiError
     */
    public exportConfigsV1ConfigExportByGroupGet(
groupMode: GroupMode,
groupName?: string,
): CancelablePromise<ConfigGroupExportResponseList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/config/exportByGroup',
            query: {
                'group_mode': groupMode,
                'group_name': groupName,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Export App Configs
     * @param appToken 
     * @returns AppExportConfigListResponse Successful Response
     * @throws ApiError
     */
    public exportAppConfigsV1ConfigExportByAppGet(
appToken: string,
): CancelablePromise<AppExportConfigListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/config/exportByApp',
            query: {
                'app_token': appToken,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

}

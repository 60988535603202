function isValidJson(jsonString: string): boolean {
    try {
        JSON.parse(jsonString);
        return true;
    } catch (_) {
        return false;
    }
}

export default isValidJson

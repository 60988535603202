import ReactPaginate from "react-paginate";
import React, { useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";

interface SegmentDetailsPaginationProps {
    pageCount: number;
    currentPage: number;
    handlePaginate: (selectedItem: { selected: number }) => void;
}

const SegmentDetailsPagination: React.FC<SegmentDetailsPaginationProps> = ({
    handlePaginate,
    pageCount,
    currentPage,
}) => {
    const location = useLocation();
    const navigate = useNavigate();

    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const segpage = queryParams.get("segpage");

    const initialPage = parseInt(segpage || '1', 10) - 1;

    const onPageChange = (selectedItem: { selected: number }) => {
        handlePaginate(selectedItem);
        navigate(`?segpage=${selectedItem.selected + 1}`, { replace: true });
    };

    useEffect(() => {
        handlePaginate({selected: initialPage});
    },[segpage])

    return <ReactPaginate
        previousLabel={"Prev"}
        nextLabel={"Next"}
        pageCount={isNaN(pageCount) ? 1 : pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        containerClassName={"pagination justify-content-end flex-nowrap"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
        onPageChange={onPageChange}
        forcePage={currentPage-1}
    />
}

export default SegmentDetailsPagination;

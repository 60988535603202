/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppFilter } from '../models/AppFilter';
import type { AppListResponse } from '../models/AppListResponse';
import type { AppMode } from '../models/AppMode';
import type { AppNameListResponse } from '../models/AppNameListResponse';
import type { AppResponse } from '../models/AppResponse';
import type { CreateAppRequest } from '../models/CreateAppRequest';
import type { UpdateAppRequest } from '../models/UpdateAppRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AppService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get All Apps
     * @param appMode 
     * @param appFilter 
     * @returns AppListResponse Successful Response
     * @throws ApiError
     */
    public getAllAppsV1AppAllGet(
appMode: AppMode,
appFilter?: AppFilter,
): CancelablePromise<AppListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/app/all',
            query: {
                'app_mode': appMode,
                'app_filter': appFilter,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Export All Apps
     * @param appMode 
     * @returns AppListResponse Successful Response
     * @throws ApiError
     */
    public exportAllAppsV1AppExportAllGet(
appMode: AppMode,
): CancelablePromise<AppListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/app/export/all',
            query: {
                'app_mode': appMode,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Get All Apps Names
     * @param appMode 
     * @returns AppNameListResponse Successful Response
     * @throws ApiError
     */
    public getAllAppsNamesV1AppAllNamesGet(
appMode?: AppMode,
): CancelablePromise<AppNameListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/app/all/names',
            query: {
                'app_mode': appMode,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Get App List
     * @param appIds 
     * @returns AppListResponse Successful Response
     * @throws ApiError
     */
    public getAppListV1AppGetListGet(
appIds: string,
): CancelablePromise<AppListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/app/get_list',
            query: {
                'app_ids': appIds,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Get App
     * @param appId 
     * @returns AppResponse Successful Response
     * @throws ApiError
     */
    public getAppV1AppAppIdGet(
appId: number,
): CancelablePromise<AppResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/app/{app_id}',
            path: {
                'app_id': appId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Update App
     * @param appId 
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateAppV1AppAppIdPatch(
appId: number,
requestBody: UpdateAppRequest,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/app/{app_id}',
            path: {
                'app_id': appId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Create App
     * @param requestBody 
     * @returns AppResponse Successful Response
     * @throws ApiError
     */
    public createAppV1AppPost(
requestBody: CreateAppRequest,
): CancelablePromise<AppResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/app/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Archive App List
     * @param appIds 
     * @returns any Successful Response
     * @throws ApiError
     */
    public archiveAppListV1AppArchiveListPatch(
appIds: string,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/app/archive_list',
            query: {
                'app_ids': appIds,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Unarchive App List
     * @param appIds 
     * @returns any Successful Response
     * @throws ApiError
     */
    public unarchiveAppListV1AppUnarchiveListPatch(
appIds: string,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/app/unarchive_list',
            query: {
                'app_ids': appIds,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Delete App List
     * @param appIds 
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteAppListV1AppDeleteListDelete(
appIds: string,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/app/delete_list',
            query: {
                'app_ids': appIds,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Archive App
     * @param appId 
     * @returns any Successful Response
     * @throws ApiError
     */
    public archiveAppV1AppAppIdArchivePatch(
appId: number,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/app/{app_id}/archive',
            path: {
                'app_id': appId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Delete App
     * @param appId 
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteAppV1AppAppIdDeleteDelete(
appId: number,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/app/{app_id}/delete',
            path: {
                'app_id': appId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Un Archive App
     * @param appId 
     * @returns any Successful Response
     * @throws ApiError
     */
    public unArchiveAppV1AppAppIdUnArchivePatch(
appId: number,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/app/{app_id}/unArchive',
            path: {
                'app_id': appId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Clone App
     * @param appId 
     * @returns AppResponse Successful Response
     * @throws ApiError
     */
    public cloneAppV1AppAppIdClonePost(
appId: number,
): CancelablePromise<AppResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/app/{app_id}/clone',
            path: {
                'app_id': appId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

}

import React, {useState} from 'react';
import {PageLink, PageTitle} from "../../../../_metronic/layout/core";
import {Button, Col, Row} from "react-bootstrap";
import {KTSVG} from "../../../../_metronic/helpers";
// @ts-ignore
import BootstrapTable from 'react-bootstrap-table-next';
// @ts-ignore
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
// @ts-ignore
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import '../configtable.scss'
import {useMutation, useQuery, useQueryClient} from "react-query";
import QueryKeys from "../../../../react-query/QueryKeys";
import {
    createAppConfigFromDefault,
    deleteAppConfig,
    getAppConfig,
    getAppsNames,
    getGroupsDefault
} from "../../../../_metronic/helpers/backend_helper";
import usePreloader from "../../../../store/layout/usePreloader";
import {
    AppMode,
    ConfigListResponse,
    ConfigResponse,
    ConfigSrc,
    ConfigType,
    GroupMode
} from "../../../../AUTO_GENERATED_TYPES";
import Select from "react-select";
import Moment from 'moment'
import TaggingTooltip from "../../Community/Components/TaggingTooltip";
import IconWithBubble from "../../../Components/UI/IconWithBubble/IconWithBubble";
import tableClasses from "../../Community/pages/table.module.scss";
import {Option} from "../../../../types/CommunitySortTypes";
import SweetAlertImport from "react-bootstrap-sweetalert";
import {toast} from "react-toastify";
import EditConfig from "../Modals/EditConfig";
import caseInsensitiveSort from "../../../helpers/utils/sorting/caseInsensitiveSort";
import useSVG from "../../../helpers/hooks/useSVG";
import NewAppConfig from "../Modals/NewAppConfig";
import ReactSwitch from "react-switch";
import {Input, Label} from "reactstrap";
import getGroupModeLabel from "../utils/getGroupModeLabel";

const SweetAlert = SweetAlertImport as any


const ConfigAppTable = () => {
    const queryClient = useQueryClient()
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()


    const {SearchBar} = Search;

    const [currentConfig, setCurrentConfig] = useState<ConfigResponse | null>(null)
    const [newConfigModal, setNewConfigModal] = useState<boolean>(false)
    const [newFDConfigModal, setNewFDConfigModal] = useState({isOpen: false, value: '', appId: ''})
    const [editConfigModal, setEditConfigModal] = useState<boolean>(false)
    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false)
    const [filterGroups, setFilterGroups] = useState<Option[]>([])
    const [filterApps, setFilterApps] = useState<Option | null>(null)
    const [groupMode, setGroupMode] = useState<GroupMode>(GroupMode.ALL)
    const [showOnlyApp, setShowOnlyApp] = useState<boolean>(true)

    const infoSVG = useSVG('/media/icons/duotune/general/gen045.svg')
    const penSVG = useSVG('/media/icons/duotune/art/art005.svg')
    const copySVG = useSVG('/media/icons/duotune/general/gen054.svg')
    const paperSVG = useSVG('/media/icons/duotune/general/gen005.svg')
    const trashSVG = useSVG('/media/icons/duotune/general/gen027.svg')
    const addSVG = useSVG('/media/icons/duotune/general/gen041.svg')


    const {
        data: groupsList,
        refetch: refetchGroups,
        isLoading
    } = useQuery([QueryKeys.ConfigGroup, groupMode], () => getGroupsDefault(groupMode, false, true), {
        retry: false,
        refetchOnWindowFocus: false,
    })

    const {
        data: appNamesList,
        isLoading: isLoadingAppNames
    } = useQuery([QueryKeys.AppNames], () => getAppsNames(AppMode.ALL), {
        retry: false,
        refetchOnWindowFocus: false,
        onSuccess: (data => {
            const appId = searchParams.get('appId')
            const selectedApp = data?.apps?.find(app => app.app_id === Number(appId))
            if (selectedApp) {
                setFilterApps({value: String(selectedApp.app_id), label: selectedApp.app_name})
                setSearchParams({})
            }
        })
    })

    const {
        data: appConfigs,
        isLoading: isLoadingConfigs,
        isFetching: isFetchingConfigs
    } = useQuery([QueryKeys.AppConfig, filterGroups, filterApps, showOnlyApp], () => {
        const requestGroups = filterGroups.map(group => group.value).join(',')

        if (filterApps?.value) {
            return getAppConfig(requestGroups, Number(filterApps.value), showOnlyApp)
        }

        return getAppConfig(requestGroups)
    }, {
        enabled: !isLoading || !isLoadingAppNames,
        refetchOnWindowFocus: false, select: (data: ConfigListResponse): ConfigListResponse => {
            if (groupMode === GroupMode.ALL) {
                return data
            }

            const unfilteredConfigs = data.configs?.slice() ?? []
            const filteredConfigs = unfilteredConfigs.filter(config => config.config_group_mode === groupMode)

            return {configs: filteredConfigs}
        }
    })


    const {mutate: createFDConfig} = useMutation<any, unknown, { app_id: number, config_id: number, config_value: string }, unknown>((request) => toast.promise(createAppConfigFromDefault(request), {
        pending: 'Creating config...',
        success: 'Created Successfully!',
    }), {
        onSuccess: (_data, ids) => {
            setNewFDConfigModal({isOpen: false, value: '', appId: ''})

            queryClient.invalidateQueries([QueryKeys.AppConfig, filterGroups, filterApps, showOnlyApp])
        },
    })

    const {mutate: deleteConfig} = useMutation<any, unknown, number, unknown>((appConfigId) => toast.promise(deleteAppConfig(appConfigId), {
        pending: 'Deleting config...',
        success: 'Deleted Successfully!',
    }), {
        onSuccess: (_data, id) => {
            // const oldData = queryClient.getQueryData<ConfigListResponse>([QueryKeys.AppConfig, filterGroups, filterApps, showOnlyApp])

            refetchGroups().then(response => {
                setCurrentConfig(null)
                queryClient.invalidateQueries([QueryKeys.AppConfig])

                // const newData = {configs: oldData?.configs?.filter(config => config.config_id !== id) ?? []}
                // queryClient.setQueryData([QueryKeys.AppConfig, filterGroups, filterApps, showOnlyApp], newData)
            })
        },
    })

    const groupsOption = groupsList?.groups?.map(group => ({
        value: String(group.id),
        label: group.group_name
    })) ?? null

    const appsOptions = appNamesList?.apps?.map(app => ({
        value: String(app.app_id),
        label: app.app_name
    })) ?? null

    usePreloader(isLoading || isLoadingConfigs || isLoadingAppNames)

    const columns = [
        {
            dataField: 'config_id',
            hidden: true,
            text: '#',
            sort: true,
        },
        {
            dataField: 'config_name',
            text: 'Name',
            editable: false,
            headerStyle: (colum: any, colIndex: any) => {
                return {width: '255px'};
            },
            sort: true,
            sortFunc: caseInsensitiveSort,
            style: {
                borderBottom: '.5px solid #d0d0d0',
                paddingLeft: '10px'
            },
            formatter: (cellContent: string, row: ConfigResponse) => {
                return <div style={{minWidth: 'max-content'}}>
                    <span>{cellContent}</span>
                    <TaggingTooltip placement={'bottom'}
                                    tooltip={row?.config_description?.trim() !== '' && row?.config_description ? row.config_description : 'No description yet'}>
                        {infoSVG && <KTSVG
                            path={infoSVG}
                            className={'svg-icon-edit svg-icon-1'}
                        />}
                    </TaggingTooltip></div>
            }
        },
        {
            dataField: 'config_group',
            text: 'Group',
            editable: false,
            sort: true,
            style: {
                borderBottom: '.5px solid #d0d0d0'
            },
        },
        {
            dataField: 'config_group_mode',
            text: 'Mode',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
        },
        {
            dataField: 'app_name',
            text: 'App',
            editable: false,
            sort: true,
            style: {
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cellContent: string, row: ConfigResponse) => {
                return cellContent ? <div>
                    <div>{row.app_token}</div>
                    <div>({cellContent})</div>
                </div> : null
            }
        },
        {
            dataField: 'config_value',
            text: 'Value',
            editable: false,
            sort: true,
            sortFunc: caseInsensitiveSort,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: ((cellContent: string) => <TaggingTooltip placement={'bottom'}
                                                                 tooltip={cellContent?.split(',').map(value => <div
                                                                     key={value}>{value}</div>)}>
                <div className={'seg_long_copy'}>{cellContent}</div>
            </TaggingTooltip>)
        },
        {
            dataField: 'config_type',
            text: 'Type',
            editable: false,
            sort: true,
            sortFunc: caseInsensitiveSort,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            }
        },
        {
            dataField: 'config_src',
            text: 'Source',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            }
        },
        {
            dataField: 'config_valid_values',
            text: 'Allowed Values',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: ((cellContent: string[]) => <TaggingTooltip placement={'bottom'}
                                                                   tooltip={cellContent.map(value => <div
                                                                       key={value}>{value}</div>)}>
                <div className={'seg_long_copy'}>{cellContent.join(', ')}</div>
            </TaggingTooltip>)

        },
        {
            dataField: 'config_last_update_time',
            text: 'Last Update Time',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: ((cellContent: string) => {
                return <div style={{minWidth: 'max-content'}}>{Moment(cellContent).format('YYYY/MM/DD HH/mm')}</div>
            })
        },
        {
            dataField: 'config_last_update_by',
            text: 'Last Update By',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            }
        },
        {
            dataField: "actions",
            isDummyField: true,
            editable: false,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            text: "Actions",
            formatter: (cellContent: any, row: ConfigResponse, rowIndex: any, extraData: any) => (
                <>
                    <div className={'position-relative'}>
                        <div className={'table_action'}>
                            {row.config_src === ConfigSrc.APP && <TaggingTooltip placement={'bottom'} tooltip={'Edit'}>
                                <Link
                                    to="#"
                                    onClick={() => {
                                        setEditConfigModal(true)
                                        setCurrentConfig(row)
                                    }}
                                >
                                    {penSVG && <KTSVG
                                        path={penSVG}
                                        className={`svg-icon-edit svg-icon-1`}
                                    />}
                                </Link>
                            </TaggingTooltip>}
                            <IconWithBubble>
                                <h6>{row.config_name}</h6>

                                {row.config_src === ConfigSrc.DEFAULT && <p
                                    onClick={() => {
                                        setCurrentConfig(row)
                                        setNewFDConfigModal(prevState => ({...prevState, isOpen: true}))
                                    }}
                                    style={{cursor: 'pointer'}}
                                ><KTSVG
                                    path={addSVG || ''}
                                    className='svg-icon-context svg-icon-1'
                                    loader={!addSVG}
                                /> Create App Config</p>}

                                <p
                                    onClick={() => {
                                        //TODO: Version 2
                                    }}
                                    style={{cursor: 'not-allowed'}}
                                >{copySVG && <KTSVG path={copySVG!}
                                                    className='svg-icon svg-icon-1' loader={!copySVG}/>} Clone</p>

                                <p
                                    onClick={() => {
                                        navigate(`/config/config-app/${row.config_id}/audits`)
                                    }}
                                    style={{cursor: 'pointer'}}
                                ><KTSVG
                                    path={paperSVG || ''}
                                    className='svg-icon-context svg-icon-1'
                                    loader={!paperSVG}
                                /> Audit Log</p>

                                <p
                                    onClick={() => {
                                        setCurrentConfig(row)
                                        setOpenConfirmModal(true)
                                    }}
                                    style={{cursor: 'pointer'}}
                                ><KTSVG
                                    path={trashSVG || ''}
                                    className='svg-icon-context svg-icon-1'
                                    loader={!trashSVG}
                                /> Delete</p>
                            </IconWithBubble>
                        </div>
                    </div>
                </>
            ),
        },
    ];

    const configBreadcrumbs: Array<PageLink> = [
        {
            title: 'Home /',
            path: '/dashboard/apps',
            isSeparator: false,
            isActive: false,
        },
    ]


    return (
        <>
            <PageTitle breadcrumbs={configBreadcrumbs}>Config Apps</PageTitle>
            <NewAppConfig show={newConfigModal} handleClose={() => setNewConfigModal(false)}/>
            {currentConfig && <EditConfig show={editConfigModal} handleClose={() => {
                setEditConfigModal(false)
                setCurrentConfig(null)
            }}
                                          currentConfig={currentConfig}
                                          isAppConfig={true}
            />}
            <SweetAlert
                show={newFDConfigModal.isOpen}
                confirmBtnCssClass={'d-none'}
                title={`Create App Config from ${currentConfig?.config_name}`}
            >

                <Label>Current Value: </Label>
                <p>{currentConfig?.config_value}</p>
                <Label>Config Type: </Label>
                <p>{currentConfig?.config_type}</p>
                <br/>

                <Select placeholder={'Select an app for inheritance'}
                        isClearable={true}
                        options={appsOptions ?? []}
                        menuPortalTarget={document.getElementById('root-modals')}
                        onChange={(e) => {
                            setNewFDConfigModal(prevState => ({...prevState, appId: e!.value}))
                        }}
                        styles={{
                            control: (baseStyles) => ({
                                ...baseStyles,
                                borderRadius: '8px',
                                minWidth: '200px',
                                marginBottom: '1rem',
                                zIndex: '10',

                            }),
                        }}/>
                {currentConfig?.config_type === ConfigType.ENUM ?
                    <Select defaultValue={{value: currentConfig.config_value, label: currentConfig.config_value}}
                            onChange={option => setNewFDConfigModal(prevState => ({
                                ...prevState,
                                value: option!.value
                            }))} options={currentConfig.config_valid_values?.map(option => ({
                        value: option,
                        label: option
                    }))}
                            menuPortalTarget={document.getElementById('root-modals')}

                    /> :
                    <Input placeholder={'Enter new value'}
                           onBlur={event => setNewFDConfigModal(prevState => ({
                               ...prevState,
                               value: event.target.value
                           }))}/>}
                <div className={'d-flex justify-content-between mt-5'}>
                    <Button variant={'link'}
                            onClick={() => setNewFDConfigModal({isOpen: false, value: '', appId: ''})}>Cancel</Button>
                    <Button variant={'primary'} disabled={!newFDConfigModal.appId || newFDConfigModal.value === ''}
                            onClick={() => {
                                if (currentConfig) {
                                    createFDConfig({
                                        app_id: Number(newFDConfigModal.appId),
                                        config_id: currentConfig.config_id,
                                        config_value: newFDConfigModal.value
                                    })
                                }
                            }}>Create</Button>
                </div>
            </SweetAlert>

            <>
                <ToolkitProvider
                    keyField={(row: ConfigResponse) => row.config_id + row.config_src}
                    data={appConfigs?.configs ?? []}
                    columns={columns}
                    search
                    bootstrap4
                >
                    {
                        (props: any) => (
                            <div style={{ padding: '15px'}}>
                                <div>
                                    <br/>
                                    <div className={'toolbar_table'}>
                                        <div>
                                            <SearchBar {...props.searchProps} />
                                        </div>
                                        <div className={'filter_toolbar'} style={{zIndex: 20}}>
                                            <Select value={{value: groupMode, label: getGroupModeLabel(groupMode)}}
                                                    onChange={(option) => {
                                                        setGroupMode(option!.value as GroupMode)
                                                        setFilterGroups([])
                                                        setFilterApps(null)
                                                        // setPage(1)
                                                    }} options={[
                                                {value: GroupMode.ALL, label: '- Group Mode -'},
                                                {value: GroupMode.LIVE, label: 'Live'},
                                                {value: GroupMode.DEMO, label: 'Demo'},
                                                {value: GroupMode.TEST, label: 'Test'}
                                            ]}
                                                    styles={{
                                                        control: (baseStyles) => ({
                                                            ...baseStyles,
                                                            borderRadius: '8px',
                                                            minWidth: '200px'
                                                        }),
                                                    }}
                                            />

                                            {groupsOption && <Select isMulti placeholder={'Groups'}
                                                                     value={filterGroups.length > 0 ? filterGroups : null}
                                                                     options={groupsOption}
                                                                     onChange={(e) => {
                                                                         setFilterGroups(e as Option[])
                                                                     }}
                                                                     styles={{
                                                                         control: (baseStyles) => ({
                                                                             ...baseStyles,
                                                                             borderRadius: '8px',
                                                                             minWidth: '200px'
                                                                         }),
                                                                     }}/>}

                                            {appsOptions && <Select placeholder={'Filter By App'}
                                                                    value={filterApps}
                                                                    isClearable={true}
                                                                    options={appsOptions}
                                                                    onChange={(e) => {
                                                                        setFilterApps(e as Option)
                                                                    }}
                                                                    styles={{
                                                                        control: (baseStyles) => ({
                                                                            ...baseStyles,
                                                                            borderRadius: '8px',
                                                                            minWidth: '200px'
                                                                        }),
                                                                    }}/>}

                                            {filterApps &&
                                                <div className={'position-relative d-flex align-items-center'}>
                                                    <div style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: 0,
                                                        transform: 'translateY(-100%)',
                                                        width: 'max-content',
                                                        fontSize: '10px'
                                                    }}>Apps Only
                                                    </div>
                                                    <ReactSwitch
                                                        onColor="#0095e8"
                                                        onHandleColor="#fff"
                                                        handleDiameter={30}
                                                        uncheckedIcon={false}
                                                        checkedIcon={false}
                                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                        height={20}
                                                        width={48}
                                                        checked={showOnlyApp}
                                                        onChange={checked => setShowOnlyApp(checked)}/></div>}

                                            <Button className={'add_btn'}
                                                    onClick={() => {
                                                        setNewConfigModal(true)
                                                    }}
                                            >
                                                <div style={{marginRight: "10px"}}>
                                                    <KTSVG
                                                        path='/media/icons/duotune/arrows/arr052.svg'
                                                        className='svg-icon svg-icon-2x'
                                                    />
                                                </div>
                                            </Button>
                                        </div>
                                    </div>

                                    <br/>
                                </div>
                                <Row>
                                    <Col xl='12'
                                         className={`${tableClasses.table_wrapper} ${tableClasses['table_wrapper--community']}`}>
                                        {appConfigs?.configs && appConfigs.configs.length > 0 && !isFetchingConfigs ? (
                                            <BootstrapTable
                                                {...props.baseProps}
                                                keyField={(row: ConfigResponse) => row.config_id + row.config_src}
                                                pagination={paginationFactory()}
                                                bordered={true}
                                                responsive
                                                stripped
                                                headerWrapperClasses={"header-class"}
                                            />) : isLoadingConfigs ? (
                                            <>
                                                <p>Loading....</p>
                                            </>
                                        ) : <p>No Configs Found</p>
                                        }
                                    </Col>
                                </Row>
                            </div>
                        )
                    }
                </ToolkitProvider>
            </>
            <SweetAlert
                show={openConfirmModal}
                warning
                showCancel
                confirmBtnText="Yes"
                cancelBtnText="Cancel"
                title={'Delete App Config'}
                confirmBtnBsStyle={'danger'}
                onConfirm={() => {
                    setOpenConfirmModal(false)
                    deleteConfig(currentConfig!.config_id)
                }}
                onCancel={() => setOpenConfirmModal(false)}
            >
                Are you sure you want to delete <span
                style={{fontWeight: 'bold'}}>{currentConfig?.config_name}</span>?
            </SweetAlert>
        </>
    )
}

export default ConfigAppTable

import {Card, CardBody, Col, Row} from "reactstrap";
import Select from "react-select";
import TaggingTooltip from "../Components/TaggingTooltip";
import {KTSVG} from "../../../../_metronic/helpers";
import React, {useEffect, useMemo} from "react";
import useCommunitySort from "../../../../store/community-sort/useCommunitySort";
import {Option, SortValueOptions} from "../../../../types/CommunitySortTypes";
import {useDispatch} from "react-redux";
import {
    addSort,
    removeSort,
    resetSort,
    sortTypeChange,
    sortValueChange
} from "../../../../store/community-sort/actions";
import {OrderByDirection, SegmentType, TagFilter} from "../../../../AUTO_GENERATED_TYPES";
import useGetFiltersAndOrders from "../../../../store/community/useGetFiltersAndOrders";
import {getSegmentData} from "../../../../store/community/actions";
import {useParams} from "react-router-dom";
import {CSSTransition} from "react-transition-group";

const valueOptions = [{value: OrderByDirection.ASC, label: "Ascending"}, {
    value: OrderByDirection.DESC,
    label: "Descending"
}]

interface SegmentTaggingSortProps {
    isOpen: boolean,
    itemPerPage: number,
    currentPage: number,
    taggingValue?: TagFilter,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const SegmentTaggingSort: React.FC<SegmentTaggingSortProps> = ({
                                                                   isOpen,
                                                                   setIsOpen,
                                                                   itemPerPage,
                                                                   currentPage,
    taggingValue
                                                               }) => {
    const dispatch = useDispatch()
    const {sorts, sortCount, sortOptions} = useCommunitySort()
    const filterRequestBody = useGetFiltersAndOrders()
    const {jobId: segId, type: segType} = useParams()

    const allowAdd = useMemo(() => sorts[0]?.sortType.value !== '', [sorts[0]])

    useEffect(() => {
        const closeModal = () => {
            setIsOpen(false)
        }
        document.body.addEventListener('click', closeModal)
        return () => {
            document.body.removeEventListener('click', closeModal)

        }
    }, [])

    const addNewSort = () => {
        dispatch(addSort())
    }

    const handleResetSort = () => {
        dispatch(resetSort())
        const bodyWithoutSort = {...filterRequestBody, orders: []}
        dispatch(getSegmentData(Number(segId), segType === 'positive' ? SegmentType.POS : SegmentType.NEG, bodyWithoutSort, itemPerPage, currentPage))

    }

    const handleRemoveSort = (id: string) => {
        dispatch(removeSort(id))

        if (sortCount === 1) {
            const bodyWithoutSort = {...filterRequestBody, orders: []}
            dispatch(getSegmentData(Number(segId), segType === 'positive' ? SegmentType.POS : SegmentType.NEG, bodyWithoutSort, itemPerPage, currentPage))
        }
    }

    const typeHandleChange = (id: string, newValue?: Option | null) => {
        if (!newValue) {
            return
        }
        dispatch(sortTypeChange(id, newValue))
    }

    const valueHandleChange = (id: string, newValue: SortValueOptions) => {
        dispatch(sortValueChange(id, newValue))
    }

    const handleFilterData = () => {
        setIsOpen(false)
        dispatch(getSegmentData(Number(segId), segType === 'positive' ? SegmentType.POS : SegmentType.NEG, filterRequestBody, itemPerPage, currentPage,taggingValue))
    }


    return (
        <CSSTransition
            in={isOpen}
            timeout={300}
            classNames="modal_animation"
            unmountOnExit
        >
            <div onClick={(e) => e.stopPropagation()} className={'segment_filter_window segment_filter_window--sort'}>
                <Card className="filter_card">
                    <CardBody id="advanced_filter">
                        <h4 className="mb-4 text-start">Order by</h4>
                        {sorts.map(({id}, idx) => (
                            <Row key={idx} style={{marginBottom: "10px"}} className="align-items-center">
                                <Col sm="5">
                                    {sortOptions && <Select
                                        value={sorts[idx].sortType}
                                        //@ts-ignore
                                        options={sortOptions}
                                        onChange={(e) => typeHandleChange(id, e)}
                                        styles={{
                                            menu: (baseStyles) => ({
                                                ...baseStyles,
                                                color: 'black',
                                            }),
                                        }}
                                    />}
                                </Col>
                                <Col sm="5">
                                    <Select
                                        isDisabled={sorts[idx].sortType.value === ""}
                                        value={sorts[idx]?.sortValue}
                                        options={valueOptions as any}
                                        placeholder={'ASC/DESC'}
                                        onChange={(e) => valueHandleChange(id, e!)}
                                        styles={{
                                            menu: (baseStyles) => ({
                                                ...baseStyles,
                                                color: 'black',
                                            }),
                                        }}
                                    />
                                </Col>
                                <Col sm="auto">
                                    <TaggingTooltip placement={'right'} tooltip={'Delete Order'}>
                                        <div onClick={() => handleRemoveSort(id)} className={'del_button'}>
                                            <KTSVG path='/media/icons/duotune/arrows/arr061.svg'
                                                   className={'svg-icon-1 active_delete'}
                                            />
                                        </div>
                                    </TaggingTooltip>
                                </Col>
                            </Row>
                        ))}
                        <Row className="align-items-center mt-2">
                            <Col sm="9">
                                {sorts.length <= 2 &&
                                    <TaggingTooltip placement={'left'}
                                                    tooltip={!allowAdd ? 'Select ASC/DESC First' : 'Add New'}>
                                        <div onClick={addNewSort} className={'plus_button'}>
                                            <KTSVG path='/media/icons/duotune/general/gen041.svg'
                                                   className={`svg-icon-1 ${!allowAdd ? 'inactive_plus' : 'active_plus'}`}
                                            />
                                        </div>
                                    </TaggingTooltip>}
                            </Col>
                            <Col sm="1">
                                <TaggingTooltip placement={'bottom'} tooltip={'Reset Order By'}>
                                    <div onClick={handleResetSort} className={'refresh_button'}>
                                        <KTSVG path='/media/icons/duotune/arrows/arr029.svg'
                                               className='svg-icon-1 active_plus'/>
                                    </div>
                                </TaggingTooltip>
                            </Col>
                            <Col sm="auto">
                                <TaggingTooltip placement={'bottom'} tooltip={'Apply Order'}>
                                    <div onClick={handleFilterData} className={'refresh_button'}>
                                        <KTSVG path='/media/icons/duotune/arrows/arr027.svg'
                                               className='svg-icon-1 active_plus'/>
                                    </div>
                                </TaggingTooltip>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        </CSSTransition>)
}

export default SegmentTaggingSort

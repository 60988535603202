import {FilterRequest, SegmentFilter, SegmentOrder} from "../../AUTO_GENERATED_TYPES";
import useCommunityFilters from "../community-filters/useCommunityFilters";
import useCommunitySort from "../community-sort/useCommunitySort";

const useGetFiltersAndOrders = () => {
    const {filters: currentFilters, filterCount} = useCommunityFilters()

    const {
        sorts: currentSort, sortCount
    } = useCommunitySort(false)


    let filters: SegmentFilter[] = currentFilters.map(filter => ({
        filter_definition_id: Number.parseInt(filter.filterType.value),
        filter_value: filter.filterValue.map(el => el.value),
        filter_operator: filter.filter_operator.value
    }))
    if (filterCount && filters.some(filter => !Object.values(filter).every(Boolean))) {
        return null
    } else if (filterCount === 0) {
        filters = []
    }

    let orders: SegmentOrder[] = currentSort.map(sort => ({
        order_definition_id: Number.parseInt(sort.sortType.value),
        direction: sort.sortValue?.value as any
    }))
    if (sortCount && orders.some(order => !Object.values(order).every(Boolean))) {
        return null
    } else if (sortCount === 0) {
        orders = []
    }
    return {filters, orders} as FilterRequest
}

export default useGetFiltersAndOrders

import {ConfigActionTypes} from "../../types/StoreTypes";
import {
    ConfigGroupListResponse,
    ConfigListResponse,
    CreateConfigGroupRequest,
    CreateConfigRequest,
    GroupMode,
    UpdateConfigGroupRequest,
    UpdateConfigRequest
} from "../../AUTO_GENERATED_TYPES";
import {
    CreateConfig,
    CreateConfigFail,
    CreateConfigGroup,
    CreateConfigGroupFail,
    CreateConfigGroupSuccess,
    CreateConfigSuccess,
    DeleteConfig,
    DeleteConfigFail,
    DeleteConfigGroup,
    DeleteConfigGroupFail,
    DeleteConfigGroupSuccess,
    DeleteConfigSuccess,
    EditConfig,
    EditConfigFail,
    EditConfigGroup,
    EditConfigGroupFail,
    EditConfigGroupSuccess,
    EditConfigSuccess,
    GetConfig,
    GetConfigFail,
    GetConfigSuccess,
    GetDefaultGroups,
    GetDefaultGroupsFail,
    GetDefaultGroupsSuccess,
    RollbackAppConfig,
    RollbackAppConfigFail,
    RollbackAppConfigSuccess
} from "../../types/ConfigTypes";


export const getConfig = (): GetConfig => ({
    type: ConfigActionTypes.GET_CONFIG_DEFAULT,
})
export const getConfigSuccess = (config: ConfigListResponse): GetConfigSuccess => ({
    type: ConfigActionTypes.GET_CONFIG_DEFAULT_SUCCESS,
    payload: config
})
export const getConfigFail = (result: boolean, msg: string): GetConfigFail => ({
    type: ConfigActionTypes.GET_CONFIG_DEFAULT_FAIL,
    payload: {result, msg}
})
export const getDefaultGroups = (groupMode?: GroupMode | undefined, archived?: boolean): GetDefaultGroups => ({
    type: ConfigActionTypes.GET_GROUPS_DEFAULT,
    payload: {groupMode, archived}
})
export const getDefaultGroupsSuccess = (groups: ConfigGroupListResponse): GetDefaultGroupsSuccess => ({
    type: ConfigActionTypes.GET_GROUPS_DEFAULT_SUCCESS,
    payload: groups
})
export const getDefaultGroupsFail = (result: boolean, msg: string): GetDefaultGroupsFail => ({
    type: ConfigActionTypes.GET_GROUPS_DEFAULT_FAIL,
    payload: {result, msg}
})
export const createConfig = (configData: CreateConfigRequest): CreateConfig => ({
    type: ConfigActionTypes.CREATE_CONFIG,
    payload: configData
})
export const createConfigSuccess = (result: boolean, msg: string): CreateConfigSuccess => ({
    type: ConfigActionTypes.CREATE_CONFIG_SUCCESS,
    payload: {result, msg}
})
export const createConfigFail = (result: boolean, msg: string): CreateConfigFail => ({
    type: ConfigActionTypes.CREATE_CONFIG_FAIL,
    payload: {result, msg}
})

export const createConfigGroup = (configData: CreateConfigGroupRequest): CreateConfigGroup => ({
    type: ConfigActionTypes.CREATE_CONFIG_GROUP,
    payload: configData
})

export const createConfigGroupSuccess = (result: boolean, msg: string): CreateConfigGroupSuccess => ({
    type: ConfigActionTypes.CREATE_CONFIG_GROUP_SUCCESS,
    payload: {result, msg}
})
export const createConfigGroupFail = (result: boolean, msg: string): CreateConfigGroupFail => ({
    type: ConfigActionTypes.CREATE_CONFIG_GROUP_FAIL,
    payload: {result, msg}
})


export const editConfig = (configData: UpdateConfigRequest, configId: number): EditConfig => ({
    type: ConfigActionTypes.EDIT_CONFIG,
    payload: {configData, configId}
})
export const editConfigSuccess = (result: boolean, msg: string): EditConfigSuccess => ({
    type: ConfigActionTypes.EDIT_CONFIG_SUCCESS,
    payload: {result, msg}
})
export const editConfigFail = (result: boolean, msg: string): EditConfigFail => ({
    type: ConfigActionTypes.EDIT_CONFIG_FAIL,
    payload: {result, msg}
})

export const editConfigGroup = (configData: UpdateConfigGroupRequest, configId: number): EditConfigGroup => ({
    type: ConfigActionTypes.EDIT_CONFIG_GROUP,
    payload: {configData, configId}
})
export const editConfigGroupSuccess = (result: boolean, msg: string): EditConfigGroupSuccess => ({
    type: ConfigActionTypes.EDIT_CONFIG_GROUP_SUCCESS,
    payload: {result, msg}
})
export const editConfigGroupFail = (result: boolean, msg: string): EditConfigGroupFail => ({
    type: ConfigActionTypes.EDIT_CONFIG_GROUP_FAIL,
    payload: {result, msg}
})

export const deleteConfig = (configId: number): DeleteConfig => ({
    type: ConfigActionTypes.DELETE_CONFIG,
    payload: configId
})
export const deleteConfigSuccess = (result: boolean, msg: string): DeleteConfigSuccess => ({
    type: ConfigActionTypes.DELETE_CONFIG_SUCCESS,
    payload: {result, msg}
})
export const deleteConfigFail = (result: boolean, msg: string): DeleteConfigFail => ({
    type: ConfigActionTypes.DELETE_CONFIG_FAIL,
    payload: {result, msg}
})

export const deleteConfigGroup = (configId: number): DeleteConfigGroup => ({
    type: ConfigActionTypes.DELETE_CONFIG_GROUP,
    payload: configId
})
export const deleteConfigGroupSuccess = (result: boolean, msg: string): DeleteConfigGroupSuccess => ({
    type: ConfigActionTypes.DELETE_CONFIG_GROUP_SUCCESS,
    payload: {result, msg}
})
export const deleteConfigGroupFail = (result: boolean, msg: string): DeleteConfigGroupFail => ({
    type: ConfigActionTypes.DELETE_CONFIG_GROUP_FAIL,
    payload: {result, msg}
})

export const rollBackAppConfig = (configHistId: number): RollbackAppConfig => ({
    type: ConfigActionTypes.ROLLBACK_CONFIG,
    payload: configHistId
})

export const rollBackAppConfigSuccess = (result: boolean, msg: string): RollbackAppConfigSuccess => ({
    type: ConfigActionTypes.ROLLBACK_CONFIG_SUCCESS,
    payload: {result, msg}
})

export const rollBackAppConfigFail = (result: boolean, msg: string): RollbackAppConfigFail => ({
    type: ConfigActionTypes.ROLLBACK_CONFIG_FAIL,
    payload: {result, msg}
})



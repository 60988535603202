import * as sageEffect from "redux-saga/effects"

// Login Redux States

import {
    loginFail,
    loginSuccess,
} from "./actions"
//Static
import StaticValues from "../../../src/_metronic/helpers/StaticValues";

//Include Both Helper File with needed methods
import {
    postLogin

} from "../../_metronic/helpers/backend_helper"
import {changePreloader} from "../layout/actions";
import {AuthActionTypes} from "../../types/StoreTypes";
import {Body_login_for_access_token_token_login_post, Token} from "../../AUTO_GENERATED_TYPES";

const { call, put, takeEvery } = sageEffect as any;

function* loginUser({ payload: { user } } : { payload: { user:Body_login_for_access_token_token_login_post, history:any } }) {

    yield put(changePreloader(true))
    try {
        const response : Token = yield call(postLogin,  user)

        localStorage.setItem(StaticValues.AuthUserQuago, JSON.stringify(response))
        localStorage.setItem(StaticValues.userName, JSON.stringify(response.user_name))
        yield put(loginSuccess(response, true))
        yield put(changePreloader(false))
    } catch (error) {
        yield put(changePreloader(false))
        yield put(loginFail('An Error occurred while trying to Sign In. Please try again !'))
    }

}


function* authSaga() {
    yield takeEvery(AuthActionTypes.LOGIN, loginUser)
}

export default authSaga

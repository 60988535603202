import {ConfigActionTypes} from "../../types/StoreTypes";
import {ConfigActions, ConfigState} from "../../types/ConfigTypes";

const initialState:ConfigState = {
    result:false,
    successMsg:'',
    errorMsg:'',
    configList:[],
    groupsList:[]
}

const config = (state = initialState, action: ConfigActions)=> {
    switch (action.type) {
        case ConfigActionTypes.GET_CONFIG_DEFAULT:
            state = {
                ...state,
            }
            break
        case ConfigActionTypes.GET_CONFIG_DEFAULT_SUCCESS:
            state = {
                ...state,
               configList: action.payload?.configs ?? []
            }
            break
        case ConfigActionTypes.GET_CONFIG_DEFAULT_FAIL:
            state = {
                ...state,
                result: action.payload.result,
                errorMsg: action.payload.msg
            }
            break
        case ConfigActionTypes.GET_GROUPS_DEFAULT:
            state = {
                ...state,
            }
            break
        case ConfigActionTypes.GET_GROUPS_DEFAULT_SUCCESS:
            state = {
                ...state,
                groupsList: action.payload?.groups ?? []
            }
            break
        case ConfigActionTypes.GET_GROUPS_DEFAULT_FAIL:
            state = {
                ...state,
                result: action.payload.result,
                errorMsg: action.payload.msg
            }
            break
        case ConfigActionTypes.CREATE_CONFIG:
            state = {
                ...state,
            }
            break
        case ConfigActionTypes.CREATE_CONFIG_SUCCESS:
            state = {
                ...state,
                result: action.payload.result,
                successMsg: action.payload.msg
            }
            break
        case ConfigActionTypes.CREATE_CONFIG_FAIL:
            state = {
                ...state,
                result: action.payload.result,
                errorMsg: action.payload.msg
            }
            break
        case ConfigActionTypes.CREATE_CONFIG_GROUP:
            state = {
                ...state,
            }
            break
        case ConfigActionTypes.CREATE_CONFIG_GROUP_SUCCESS:
            state = {
                ...state,
                result: action.payload.result,
                successMsg: action.payload.msg
            }
            break
        case ConfigActionTypes.CREATE_CONFIG_GROUP_FAIL:
            state = {
                ...state,
                result: action.payload.result,
                errorMsg: action.payload.msg
            }
            break
        case ConfigActionTypes.EDIT_CONFIG_SUCCESS:
            state = {
                ...state,
                result: action.payload.result,
                successMsg: action.payload.msg
            }
            break
        case ConfigActionTypes.EDIT_CONFIG_FAIL:
            state = {
                ...state,
                result: action.payload.result,
                errorMsg: action.payload.msg
            }
            break
        case ConfigActionTypes.EDIT_CONFIG_GROUP:
            state = {
                ...state,
            }
            break
        case ConfigActionTypes.EDIT_CONFIG_GROUP_SUCCESS:
            state = {
                ...state,
                result: action.payload.result,
                successMsg: action.payload.msg
            }
            break
        case ConfigActionTypes.EDIT_CONFIG_GROUP_FAIL:
            state = {
                ...state,
                result: action.payload.result,
                errorMsg: action.payload.msg
            }
            break
        case ConfigActionTypes.DELETE_CONFIG_SUCCESS:
            state = {
                ...state,
                result: action.payload.result,
                successMsg: action.payload.msg
            }
            break
        case ConfigActionTypes.DELETE_CONFIG_FAIL:
            state = {
                ...state,
                result: action.payload.result,
                errorMsg: action.payload.msg
            }
            break
        case ConfigActionTypes.DELETE_CONFIG_GROUP:
            state = {
                ...state,
            }
            break
        case ConfigActionTypes.DELETE_CONFIG_GROUP_SUCCESS:
            state = {
                ...state,
                result: action.payload.result,
                successMsg: action.payload.msg
            }
            break
        case ConfigActionTypes.DELETE_CONFIG_GROUP_FAIL:
            state = {
                ...state,
                result: action.payload.result,
                errorMsg: action.payload.msg
            }
            break
        case ConfigActionTypes.ROLLBACK_CONFIG_SUCCESS:
            state = {
                ...state,
                result: action.payload.result,
                successMsg: action.payload.msg
            }
            break
        case ConfigActionTypes.ROLLBACK_CONFIG_FAIL:
            state = {
                ...state,
                result: action.payload.result,
                errorMsg: action.payload.msg
            }
            break

        default:
            state = { ...state }
            break
    }
    return state
}

export default config

import {CommunitySegFilter} from "../../types/CommunityFilterTypes";
import {AnnotationSegFilter} from "../../types/AnnotationTypes";

const isPrimitive = (val: unknown) => {
    return val !== Object(val);
}

const isFilterFull = (filter?: CommunitySegFilter | AnnotationSegFilter) => {
    if (!filter) {
        return false
    }

    let valid = true;

    for (let value of Object.values(filter)) {
        if (!value) {
            valid = false
            break
        }
        if (Array.isArray(value)) {
            if (value.length === 0) {
                valid = false
                break
            }
            valid = value.every(obj => Boolean(obj?.value) && Boolean(obj?.label))
            if (!valid) {
                break
            }
        } else {
            if (!isPrimitive(value)) {
                valid = Boolean(value.value) && Boolean(value.label)
                if (!valid) {
                    break
                }
            }
        }
    }

    return valid
}

export default isFilterFull

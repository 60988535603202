import { AuthActionTypes} from "../../types/StoreTypes";
import {AuthState, LoginActions} from "../../types/AuthTypes";



const initialState : AuthState = {
    error: "",
    loading: false,
    accessToken:"None",
    idToken:'None',
    result:false,
    successMsg:'',
    errorMsg:'',
    processResult:false
}

const login = (state = initialState, action:LoginActions) => {
    switch (action.type) {
        case AuthActionTypes.LOGIN:
            state = {
                ...state,
                loading: true,
            }
            break
        case AuthActionTypes.LOGIN_SUCCESS:
            state = {
                ...state,
                loading: false,
                result: action.payload.result
            }
            break
        case AuthActionTypes.LOGIN_FAIL:
            state = {
                ...state,
                loading: false,
                errorMsg: action.payload
            }
            break
        default:
            state = { ...state }
            break
    }
    return state
}

export default login

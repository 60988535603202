import {PageLink, PageTitle} from "../../../../_metronic/layout/core";
import React, {useState} from "react";
import {Col, Row} from "react-bootstrap";
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import tableClasses from "../../Community/pages/table.module.scss";
import Moment from "moment";
import BootstrapTable from 'react-bootstrap-table-next';
import {useIsFetching, useQuery} from "react-query";
import QueryKeys from "../../../../react-query/QueryKeys";
import {getAppsNames, getDeploymentHistory,} from "../../../../_metronic/helpers/backend_helper";
import caseInsensitiveSort from "../../../helpers/utils/sorting/caseInsensitiveSort";
import useSVG from "../../../helpers/hooks/useSVG";
import TaggingTooltip from "../../Community/Components/TaggingTooltip";
import {AppMode, GroupMode} from "../../../../AUTO_GENERATED_TYPES";
import usePreloader from "../../../../store/layout/usePreloader";
import Select from "react-select";
import {Option} from "../../../../types/CommunitySortTypes";
import {debounce} from "lodash";
import getFilterIdByColumnName from "../../../../assets/utils/getFilterIdByColumnName";
import {toast} from "react-toastify";
import {KTSVG} from "../../../../_metronic/helpers";

const DeploymentHistory = () => {
    const [filterApps, setFilterApps] = useState<Option | null>(null)

    // const rollbackSVG = useSVG('/media/icons/duotune/arrows/arr029.svg')
    // const createSVG = useSVG('/media/icons/duotune/general/gen041.svg')
    // const cogSVG = useSVG('/media/icons/duotune/coding/cod001.svg')
    // const resetSVG = useSVG('/media/icons/duotune/arrows/arr043.svg')
    // const trashSVG = useSVG('/media/icons/duotune/general/gen027.svg')

    // const loadedSVG = rollbackSVG && createSVG && cogSVG && resetSVG && trashSVG

    const {SearchBar} = Search;

    const isFetching = useIsFetching()

    usePreloader(Boolean(isFetching))

    const {
        data: deploymentHistory,
    } = useQuery([QueryKeys.DeploymentHistory, filterApps], () => getDeploymentHistory(filterApps?.value ? Number(filterApps.value) : undefined), {
        retry: false,
        refetchOnWindowFocus: false,
        placeholderData: null,
        select: (data => data?.deployments)
    })

    const {
        data: appNamesList,
    } = useQuery([QueryKeys.AppNames], () => getAppsNames(AppMode.ALL), {
        retry: false,
        refetchOnWindowFocus: false,
    })

    const appsOptions = appNamesList?.apps?.map(app => ({
        value: String(app.app_id),
        label: app.app_name
    })) ?? null

    const copyFormatter = (data: any, row: any, i: any, additionalData?: any) => {

        const handleCopy = (e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation()
            navigator.clipboard.writeText(String(data)).then(() => {
                toast.info('Copied to clipboard!')
            })
        }

        const dataIsArray = Array.isArray(data);
        let renderData = data
        if (dataIsArray) {
            renderData = data.join(',')
        }
        return <div className={'d-flex justify-content-between'} title={renderData}>
            <div className={'seg_long_copy'}>{renderData ?? 'N/A'}</div>
            { (dataIsArray && data.length > 0) && <div className={'copy_btn'} onClick={handleCopy}><KTSVG
                path='/media/icons/duotune/general/gen054.svg'
                className='svg-icon-edit svg-icon-2'
                cache
            />
            </div>}
        </div>

    }

    const columns = [
        {
            dataField: 'id',
            hidden: true,
            text: '#',
            sort: true,
        },
        {
            dataField: 'creation_time',
            text: 'Timestamp',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0',
                minWidth: '150px'

            },
            formatter: ((cellContent: string) => {
                return <div style={{minWidth: 'max-content'}}>{Moment(cellContent).format('YYYY/MM/DD HH:mm')}</div>
            })
        }, {
            dataField: 'app_name',
            text: 'App Name',
            editable: false,
            sort: true,
            style: {
                borderBottom: '.5px solid #d0d0d0',
                textAlign: 'center',
                minWidth: '150px'

            },
        }, {
            dataField: 'cd_id',
            text: 'CD ID',
            editable: false,
            sort: true,
            style: {
                borderBottom: '.5px solid #d0d0d0',
                textAlign: 'center',
                minWidth: '150px'

            },
            formatter: (cell: any, row: any) => {
                if (cell) {
                    return <a className={'link-primary'} href={"job/" + cell} onClick={e => e.stopPropagation()}>{cell}</a>
                }
                return 'N/A'
            }
        },
        {
            dataField: 'app_token',
            text: 'App token',
            editable: false,
            sort: true,
            style: {
                borderBottom: '.5px solid #d0d0d0',
                textAlign: 'center',
                minWidth: '150px'
            },
        },
        {
            dataField: 'approved_new_list',
            text: 'Approved New List',
            editable: false,
            sort: true,
            style: {
                borderBottom: '.5px solid #d0d0d0',
                textAlign: 'center',
                minWidth: '150px'
            },
            formatter: copyFormatter
        },
        {
            dataField: 'approved_existing_list',
            text: 'Approved Existing List',
            editable: false,
            sort: true,
            style: {
                borderBottom: '.5px solid #d0d0d0',
                textAlign: 'center',
                minWidth: '150px'
            },
            formatter: copyFormatter
        },
        {
            dataField: 'rejected_new_list',
            text: 'Rejected New List',
            editable: false,
            sort: true,
            style: {
                borderBottom: '.5px solid #d0d0d0',
                textAlign: 'center',
                minWidth: '150px'
            },
            formatter: copyFormatter
        },
        {
            dataField: 'rejected_not_existing_list',
            text: 'Rejected Not Existing List',
            editable: false,
            sort: true,
            style: {
                borderBottom: '.5px solid #d0d0d0',
                textAlign: 'center',
                minWidth: '150px'
            },
            formatter: copyFormatter
        },
        {
            dataField: 'max_dim',
            text: 'Max Dim',
            editable: false,
            sort: true,
            style: {
                borderBottom: '.5px solid #d0d0d0',
                textAlign: 'center',
                minWidth: '150px'
            },
        },
        {
            dataField: 'min_motion_cnt',
            text: 'Min Motion Count',
            editable: false,
            sort: true,
            style: {
                borderBottom: '.5px solid #d0d0d0',
                textAlign: 'center',
                minWidth: '150px'
            },
        },
        {
            dataField: 'max_motion_cnt',
            text: 'Max Motion Count',
            editable: false,
            sort: true,
            style: {
                borderBottom: '.5px solid #d0d0d0',
                textAlign: 'center',
                minWidth: '150px'
            },
        },
        {
            dataField: 'trim_motion',
            text: 'Trim Motion',
            editable: false,
            sort: true,
            style: {
                borderBottom: '.5px solid #d0d0d0',
                textAlign: 'center',
                minWidth: '150px'
            },
        },
        {
            dataField: 'user_full_name',
            text: 'User Full Name',
            editable: false,
            sort: true,
            sortFunc: caseInsensitiveSort,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0',
                minWidth: '150px'
            },
        },
        // {
        //     dataField: "actions",
        //     isDummyField: true,
        //     editable: false,
        //     style: {
        //         textAlign: 'center',
        //         borderBottom: '.5px solid #d0d0d0'
        //     },
        //     text: "Actions",
        //     formatter: (cellContent: any, row: ConfigHistoryResponse, rowIndex: any, extraData: any) => (
        //         <>
        //             <div className={'position-relative'}>
        //                 <div className={'table_action'} style={{gap: '10px'}}>
        //                     <IconWithBubble>
        //                         <h6>{row.user_name} - {Moment(row.creation_time).format('YYYY/MM/DD HH:mm')}</h6>
        //                        <div
        //                             onClick={() => {
        //
        //                             }}
        //                            style={{cursor: 'not-allowed',filter:'grayScale(100%)'}}
        //                         >{rollbackSVG && <KTSVG path={rollbackSVG!}
        //                                                 className='svg-icon-context svg-icon-1'
        //                                                 loader={!rollbackSVG}/>} Rollback</div>
        //
        //                        <div
        //                             onClick={() => {
        //
        //                             }}
        //                            style={{cursor: 'not-allowed',filter:'grayScale(100%)'}}
        //                         >{createSVG && <KTSVG
        //                            path={createSVG || ''}
        //                            className='svg-icon-context svg-icon-1'
        //                            loader={!createSVG}
        //                        />} Create</div>
        //
        //                        <div
        //                             onClick={() => {
        //
        //                             }}
        //                            style={{cursor: 'not-allowed',filter:'grayScale(100%)'}}
        //                         >{cogSVG && <KTSVG
        //                            path={cogSVG || ''}
        //                            className='svg-icon-context svg-icon-1'
        //                            loader={!cogSVG}
        //                        />} Update</div>
        //
        //                        <div
        //                             onClick={() => {
        //
        //                             }}
        //                            style={{cursor: 'not-allowed',filter:'grayScale(100%)'}}
        //                         >{resetSVG && <KTSVG
        //                            path={resetSVG || ''}
        //                            className='svg-icon-context svg-icon-1'
        //                            loader={!resetSVG}
        //                        />} Reset</div>
        //
        //                        <div
        //                             onClick={() => {
        //
        //                             }}
        //                            style={{cursor: 'not-allowed',filter:'grayScale(100%)'}}
        //                         >{trashSVG && <KTSVG
        //                            path={trashSVG || ''}
        //                            className='svg-icon-context svg-icon-1'
        //                            loader={!trashSVG}
        //                        />} Delete</div>
        //                     </IconWithBubble>
        //                 </div>
        //             </div>
        //         </>
        //     ),
        // },
    ];


    const configBreadcrumbs: Array<PageLink> = [
        {
            title: 'Home /',
            path: '/dashboard/apps',
            isSeparator: false,
            isActive: false,
        },
    ]


    // if (!loadedSVG) {
    //     return null
    // }

    return <>
        <PageTitle breadcrumbs={configBreadcrumbs}>Deployment History</PageTitle>
        <ToolkitProvider
            keyField="id"
            data={deploymentHistory ?? []}
            columns={columns}
            search
            bootstrap4
        >
            {
                (props: any) => (
                    <div style={{padding: '15px'}}>
                        <div>
                            <br/>
                            <div className={'toolbar_table'}>
                                <div>
                                    <SearchBar {...props.searchProps} />
                                </div>
                                <div className={'filter_toolbar'} style={{zIndex: 20}}>

                                    {appsOptions && <Select placeholder={'Filter By App'}
                                                            value={filterApps}
                                                            isClearable={true}
                                                            options={appsOptions}
                                                            onChange={(e) => {
                                                                setFilterApps(e as Option)
                                                            }}
                                                            styles={{
                                                                control: (baseStyles) => ({
                                                                    ...baseStyles,
                                                                    borderRadius: '8px',
                                                                    minWidth: '200px'
                                                                }),
                                                            }}/>}
                                </div>
                            </div>

                            <br/>
                        </div>
                        <Row>
                            <Col xl='12'
                                 className={`${tableClasses.table_wrapper} ${tableClasses['table_wrapper--community']}`}>
                                {deploymentHistory && deploymentHistory?.length > 0 ? (
                                    <BootstrapTable
                                        {...props.baseProps}
                                        keyField='id'
                                        pagination={paginationFactory()}
                                        bordered={true}
                                        responsive
                                        stripped
                                        headerWrapperClasses={"header-class"}
                                    />) : isFetching ? (
                                    <>
                                        <p>Loading....</p>
                                    </>
                                ) : <p>No Deploys Found</p>
                                }
                            </Col>
                        </Row>
                    </div>
                )
            }
        </ToolkitProvider>
    </>
}

export default DeploymentHistory;

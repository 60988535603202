import React, {useEffect, useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import {KTSVG} from "../../../../_metronic/helpers";
import {Input, Label} from "reactstrap";
//@ts-ignore
import styles from '../../../general-styles/Modals/modals.module.scss'
import './config-modals.scss'
//@ts-ignore
import {deleteApp, deleteAppConfig, deleteConfig} from "../../../../store/actions";
import SweetAlertImport from "react-bootstrap-sweetalert";
//@ts-ignore
import SvelteJSONEditor from "../../../../_metronic/helpers/JSONEditor";
import {
    ConfigResponse,
    ConfigType,
    UpdateAppConfigRequest,
    UpdateConfigRequest
} from "../../../../AUTO_GENERATED_TYPES";
import {useMutation, useQueryClient} from "react-query";
import {toast} from "react-toastify";
import QueryKeys from "../../../../react-query/QueryKeys";
import {editConfig, updateAppConfig} from "../../../../_metronic/helpers/backend_helper";
import isValidInteger from "../../../helpers/utils/validations/isValidInt";
import isValidUrl from "../../../helpers/utils/validations/isValidUrl";
import isValidFloat from "../../../helpers/utils/validations/isValidFloat";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import TaggingTooltip from "../../Community/Components/TaggingTooltip";
import useSVG from "../../../helpers/hooks/useSVG";
import isEqual from "../../../helpers/utils/validations/isEqual";

const SweetAlert = SweetAlertImport as any

interface EditConfigProps {
    show: boolean,
    handleClose: () => void,
    currentConfig: ConfigResponse | null,
    isAppConfig?: boolean,
    appToken?: any,
}

const EditConfig = ({show, handleClose, currentConfig, isAppConfig, appToken}: EditConfigProps) => {
    const queryClient = useQueryClient()

    const [description, setDescription] = useState<string>('')
    const [group, setGroup] = useState<string>('')
    const [type, setType] = useState<string>('')
    const [value, setValue] = useState<string>('')
    const [validValues, setValidValues] = useState<any>()
    const [content, setContent] = useState<any>();
    const [token, setToken] = useState<any>();

    const infoSVG = useSVG('/media/icons/duotune/general/gen045.svg')
    const trashSVG = useSVG('/media/icons/duotune/general/gen040.svg')


    const [confirmationModal, setConfirmationModal] = useState<boolean>(false)
    const [editConfirmModal, setEditConfirmModal] = useState<boolean>(false)
    const [confirmMessage, setConfirmMessage] = useState<string>('')

    // console.log(currentConfig)

    const {mutate: createConfigDefault} = useMutation<any, unknown, { configData: UpdateConfigRequest, configId: number }, any>(({
                                                                                                                                     configData,
                                                                                                                                     configId
                                                                                                                                 }) => toast.promise(editConfig(configData, configId), {
        pending: 'Updating config...',
        success: 'Updated Successfully!',
    }), {
        onSuccess: () => {
            queryClient.invalidateQueries([QueryKeys.ConfigDefault])
        },
        onSettled: handleClose
    })

    const {mutate: updateAppConfigMutation} = useMutation<any, unknown, { configData: UpdateAppConfigRequest, appConfigId: number }, any>(({
                                                                                                                                               configData,
                                                                                                                                               appConfigId
                                                                                                                                           }) => toast.promise(updateAppConfig(appConfigId, configData), {
        pending: 'Updating app config...',
        success: 'Updated Successfully!',
    }), {
        onSuccess: () => {
            queryClient.invalidateQueries([QueryKeys.AppConfig])
        },
        onSettled: handleClose
    })


    useEffect(() => {
        if (currentConfig) {
            // console.log(currentConfig)
            setDescription(currentConfig.config_description)
            setGroup(currentConfig.config_group)
            setType(currentConfig.config_type)
            setToken(appToken)
            setValue(currentConfig.config_value)
            setValidValues(currentConfig.config_valid_values)
            if (currentConfig.config_type === 'JSON') {
                setContent
                (
                    {json: JSON.parse(currentConfig.config_value)}
                )
            }
        }
    }, [currentConfig]);


    const saveChanges = () => {
        if (!currentConfig) {
            return
        }

        switch (currentConfig.config_type) {
            case ConfigType.INT:
                if (!isValidInteger(value)) {
                    toast.warn('Invalid Value - (Integer)')
                    return
                }
                break

            case ConfigType.FLOAT:
                if (!isValidFloat(value)) {
                    toast.warn('Invalid Value - (Float)')
                    return
                }
                break

            case ConfigType.URL:
                if (!isValidUrl(value)) {
                    toast.warn('Invalid Value - (URL)')
                    return
                }
                break

        }


        if (!currentConfig) {
            return
        }

        if (isAppConfig) {
            const configData: UpdateAppConfigRequest = {
                config_value: currentConfig?.config_type === 'JSON' ? (JSON.stringify(content.json) ?? content.text) : value,
                config_description: description,
                config_valid_values: validValues
            }

            if (isEqual(configData.config_value, currentConfig.config_value)) {
                configData.config_value = undefined;
            }

            if (isEqual(configData.config_description, currentConfig.config_description)) {
                configData.config_description = undefined;
            }

            if (isEqual(configData.config_valid_values, currentConfig.config_valid_values)) {
                configData.config_valid_values = undefined;
            }

            updateAppConfigMutation({
                appConfigId: currentConfig.config_id,
                configData: configData
            })
        } else {
            const configData: UpdateConfigRequest = {
                config_value: currentConfig?.config_type === 'JSON' ? (JSON.stringify(content.json) ?? content.text) : value,
                config_description: description,
                config_valid_values: validValues
            }

            if (isEqual(configData.config_value, currentConfig.config_value)) {
                configData.config_value = undefined;
            }

            if (isEqual(configData.config_description, currentConfig.config_description)) {
                configData.config_description = undefined;
            }

            if (isEqual(configData.config_valid_values, currentConfig.config_valid_values)) {
                configData.config_valid_values = undefined;
            }

            createConfigDefault({configId: currentConfig.config_id, configData: configData})
        }
        setContent({})
    }


    return (
        <>
            {currentConfig &&
                <Modal
                    show={show}
                    onHide={() => {
                        handleClose()
                    }}
                    centered
                >
                    <div>
                        <div className='modal-content'>

                            <div className={styles.flex_row_between}>
                                <div className={styles.title_close_space}>
                                    <h2>Edit Config: {currentConfig.config_name}</h2>
                                </div>
                                <div className='modal_close_btn'>
                                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <br/>
                                <form className={'form_container'}>
                                    <div>
                                        <div className={'d-flex flex-column'}>
                                            <Label>Value {!(currentConfig.from_default && isAppConfig) && currentConfig.config_type === ConfigType.ENUM &&
                                                <TaggingTooltip placement={'right'}
                                                                tooltip={'Start typing to add a new option'}>
                                                    {infoSVG && <KTSVG
                                                        path={infoSVG}
                                                        className={'svg-icon-edit svg-icon-1'}
                                                    />}
                                                </TaggingTooltip>}</Label>
                                            <br/>
                                            {type === 'BOOLEAN' ? (
                                                <select value={value || ''} onChange={(e) => setValue(e.target.value)}>
                                                    <option value="true">true</option>
                                                    <option value="false">false</option>
                                                </select>
                                            ) : type === 'JSON' ? (
                                                <SvelteJSONEditor
                                                    content={content}
                                                    onChange={setContent}
                                                />
                                            ) : type === 'ENUM' ? (
                                                (currentConfig.from_default && isAppConfig) ?
                                                    <Select value={value ? {value, label: value} : null}
                                                            onChange={(option) => setValue(option?.value ?? '')}
                                                            options={validValues.map((vv: any) => (
                                                                {value: vv, label: vv}
                                                            ))}
                                                    /> :
                                                    <CreatableSelect value={value ? {value, label: value} : null}
                                                                     onChange={(option) => setValue(option?.value ?? '')}
                                                                     options={validValues.map((vv: any) => (
                                                                         {value: vv, label: vv}
                                                                     ))}
                                                                     onCreateOption={inputValue => setValidValues(prev => [...prev, inputValue])}
                                                                     formatOptionLabel={(data) => {
                                                                         return <div
                                                                             className={'d-flex justify-content-between'}>
                                                                             <span>{data.value}</span> {
                                                                             value !== data.value &&
                                                                             <p onClick={event => {
                                                                                 event.stopPropagation()
                                                                                 setValidValues(prev => prev?.filter(option => option !== data.value) ?? [])
                                                                             }}
                                                                                style={{cursor: 'pointer'}}
                                                                             ><KTSVG
                                                                                 path={trashSVG || ''}
                                                                                 className='svg-icon-context svg-icon-1'
                                                                                 loader={!trashSVG}
                                                                             /></p>}</div>
                                                                     }}
                                                    />
                                            ) : (
                                                <input style={{width: '70%'}} onChange={(e) => setValue(e.target.value)}
                                                       type="text" value={value || ''}/>
                                            )}
                                            <br/>
                                            <br/>
                                            <Label>Description {(currentConfig.from_default && isAppConfig)  &&
                                                <TaggingTooltip placement={'right'}
                                                                tooltip={'Description is not editable when the origin is Default Config'}>
                                                    {infoSVG && <KTSVG
                                                        path={infoSVG}
                                                        className={'svg-icon-context svg-icon-1'}
                                                    />}
                                                </TaggingTooltip>}</Label>
                                            <Input
                                                type={'textarea'}
                                                readOnly={currentConfig.from_default && isAppConfig}
                                                value={description || ''}
                                                onChange={(e) => setDescription(e.target.value)}/>
                                        </div>
                                        <br/>
                                    </div>
                                    <br/>
                                    {type !== 'JSON' &&
                                        <>
                                            <div>
                                                <Label>Groups</Label>
                                                <p>{group}</p>
                                            </div>
                                            <br/>
                                            <div>
                                                <Label>Type</Label>
                                                <p>{type}</p>
                                            </div>
                                            <br/>
                                        </>
                                    }
                                    <div className={styles.confirm_btn}>
                                        <Button
                                            onClick={() => {
                                                setEditConfirmModal(true)
                                            }
                                            }
                                        >
                                            Confirm</Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Modal>
            }
            <SweetAlert
                show={editConfirmModal}
                warning
                showCancel
                confirmBtnText="Yes"
                cancelBtnText="Cancel"
                confirmBtnBsStyle={'danger'}
                title={"Edit Config"}
                onConfirm={() => {
                    setEditConfirmModal(false)
                    setConfirmMessage('')
                    saveChanges()
                }}
                onCancel={() => {
                    setEditConfirmModal(false)
                    setConfirmMessage('')
                }
                }
            >
                Are you sure you want to edit this config ?
            </SweetAlert>
        </>
    )
}

export default EditConfig

import {useSelector} from "react-redux";
import {RootState} from "../reducers";
import isFilterFull from "../../assets/utils/isFilterFull";

const useCommunityFilters = () => {
    const {filters, filterCount} = useSelector((state: RootState) => ({
        filters: state.communityFilter.filters,
        filterCount: state.communityFilter.filterCount,
    }))

    const onlyFullFilters = filters.filter(isFilterFull).map(filter => ({
        filter_definition_id: Number.parseInt(filter.filterType.value),
        filter_value: filter.filterValue.map(el => el.value),
        filter_operator: filter.filter_operator.value
    }))

    return {filters, filterCount, onlyFullFilters: {filters: onlyFullFilters}}
}

export default useCommunityFilters

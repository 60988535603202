import {AnnotationActions, AnnotationSegFilter, AnnotationState} from "../../types/AnnotationTypes";
import {AnnotationActionTypes} from "../../types/StoreTypes";
import {CommunitySegSort} from "../../types/CommunitySortTypes";
import {toast} from "react-toastify";
import {v4 as uuidv4} from "uuid";
import {FilterDataType, FilterRequest, OrderByDirection} from "../../AUTO_GENERATED_TYPES";
import isFilterFull from "../../assets/utils/isFilterFull";

const deepCopyState = (state: AnnotationState) => {
    return JSON.parse((JSON.stringify(state))) as AnnotationState
}

const initialState: AnnotationState = {
    itemsPerPage: 100,
    page: 1,
    dataRequest: {},
    tagFilter: 'All',
    filters: [{
        id: uuidv4(),
        filterType: {value: "", label: "Filter By"},
        filterValue: [{value: "", label: "Value"}],
        filterValues: [{value: "", label: ""}],
        filter_operator: {value: "", label: "Operator"},
        filter_operators: [{value: "", label: ""}],
        dataType: null
    }],
    filterCount: 0,
    sorts: [{
        id: uuidv4(),
        sortType: {value: "", label: "Order By"},
        sortValue: {value: OrderByDirection.ASC, label: 'Ascending'},
    }],
    sortCount: 0,
    imageType: {value:'',label:'- Plot Type -'}
}

const reducer = (state = initialState, action: AnnotationActions) => {
    switch (action.type) {
        case AnnotationActionTypes.SET_ANNOTATION_DATA_REQUEST:
            state = {...state, dataRequest: action.payload, page: 1}
            break

        case AnnotationActionTypes.SET_ANNOTATION_TAG_FILTER:
            state = {...state, tagFilter: action.payload}
            break

        case AnnotationActionTypes.SET_ANNOTATION_PAGE:
            state = {...state, page: action.payload}
            break

        case AnnotationActionTypes.SET_ANNOTATION_IPP:
            state = {...state, page: 1, itemsPerPage: action.payload}
            break

        case AnnotationActionTypes.ADD_ANNOTATION_ORDER_BY:
            const existingAddIndex = state.sorts.findIndex(sort => sort.sortType === action?.payload?.sortType)
            if (existingAddIndex !== -1) {
                const newOrder = JSON.parse(JSON.stringify(state.sorts)) as CommunitySegSort[]
                newOrder[existingAddIndex] = action.payload!
                state = {...state, sorts: newOrder}
                break
            }

            if (state.sortCount === 3) {
                toast.warn('Max sorts already applied.')
                state = {...state}
            } else {
                if (action.payload) {
                    if (state.sortCount === 0) {
                        state = {...state, sorts: [action.payload], sortCount: state.sortCount + 1}
                    } else {
                        state = {...state, sorts: [...state.sorts, action.payload], sortCount: state.sortCount + 1}
                    }
                } else {
                    state = {
                        ...state,
                        sorts: [...state.sorts, {
                            id: uuidv4(),
                            sortType: {value: "", label: "Order By"},
                            sortValue: {value: OrderByDirection.ASC, label: 'Ascending'},
                        }],
                    }
                }
            }
            break

        case AnnotationActionTypes.REMOVE_ANNOTATION_ORDER_BY:
            const newRemoveOrder = deepCopyState(state).sorts.filter(sort => sort.id !== action.payload)
            state = {...state, sorts: newRemoveOrder, sortCount: state.sortCount - 1}
            break

        case AnnotationActionTypes.RESET_ANNOTATION_ORDER_BY:
            const newRequest = {
                filters: state.dataRequest.filters,
                orders: []
            }

            state = {...state, sorts: initialState.sorts, sortCount: 0, dataRequest: newRequest}
            break

        case AnnotationActionTypes.ANNOTATION_ORDER_TYPE:
            const newTypeChangeOrder = deepCopyState(state).sorts
            if (newTypeChangeOrder.some(sort => String(sort.sortType.value) === String(action.payload.value.value))) {
                toast.warn('This sorting is already applied')
                break
            }
            const changedTypeIndex = newTypeChangeOrder.findIndex(sort => sort.id === action.payload.id)
            if (changedTypeIndex !== -1) {
                newTypeChangeOrder[changedTypeIndex].sortType = action.payload.value;
                newTypeChangeOrder[changedTypeIndex].sortValue = {value: OrderByDirection.ASC, label: 'Ascending'}
                state = {...state, sorts: newTypeChangeOrder, sortCount: newTypeChangeOrder.length}
            }
            break

        case AnnotationActionTypes.ANNOTATION_ORDER_VALUE:
            const newValueChangeOrder = deepCopyState(state).sorts
            const changedValueIndex = newValueChangeOrder.findIndex(sort => sort.id === action.payload.id)
            if (changedValueIndex !== -1) {
                newValueChangeOrder[changedValueIndex].sortValue = action.payload.value
                state = {...state, sorts: newValueChangeOrder}
            }
            break

        case AnnotationActionTypes.SET_ANNOTATION_FILTERS:
            state = {...state, filters: action.payload, filterCount: action.payload.length}
            break

        case AnnotationActionTypes.ADD_ANNOTATION_FILTER:
            const existingAddFilterIndex = state.filters.findIndex(filter => String(filter.filterType.value) === String(action?.payload?.filterType.value))
            if (existingAddFilterIndex !== -1) {
                const newFilters = JSON.parse(JSON.stringify(state.filters)) as AnnotationSegFilter[]
                newFilters[existingAddFilterIndex] = action.payload!

                const newDR: FilterRequest = {
                    filters: newFilters.map(filter => ({
                        filter_definition_id: Number(filter.filterType.value),
                        filter_operator: filter.filter_operator.value,
                        filter_value: filter.filterValue.map(el => el.value)
                    })),
                    orders: state.dataRequest.orders
                }

                state = {...state, filters: newFilters, page: 1, dataRequest: newDR}
                break
            }

            if (state.filters.length === 5) {
                toast.warn('Max filters already applied.')
                state = {...state}
            } else {

                if (action.payload) {
                    if (state.filterCount === 0) {
                        const newFilters = [action.payload]
                        const newDR: FilterRequest = {
                            filters: newFilters.map(filter => ({
                                filter_definition_id: Number(filter.filterType.value),
                                filter_operator: filter.filter_operator.value,
                                filter_value: filter.filterValue.map(el => el.value)
                            })),
                            orders: state.dataRequest.orders
                        }

                        state = {
                            ...state,
                            filters: newFilters,
                            filterCount: state.filterCount + 1,
                            page: 1,
                            dataRequest: newDR
                        }
                    } else {
                        const filters = [...state.filters].filter(isFilterFull)
                        filters.push(action.payload)

                        const newDR: FilterRequest = {
                            filters: filters.map(filter => ({
                                filter_definition_id: Number(filter.filterType.value),
                                filter_operator: filter.filter_operator.value,
                                filter_value: filter.filterValue.map(el => el.value)
                            })),
                            orders: state.dataRequest.orders
                        }

                        state = {
                            ...state,
                            filters,
                            filterCount: filters.length,
                            page: 1,
                            dataRequest: newDR
                        }
                    }
                } else {
                    state = {
                        ...state,
                        filters: [...state.filters, {
                            id: uuidv4(),
                            filterType: {value: "", label: "Filter By"},
                            filterValue: [],
                            filterValues: [{value: "", label: ""}],
                            filter_operator: {value: "", label: "Operator"},
                            filter_operators: [{value: "", label: "Value"}],
                            dataType: null
                        }]
                    }
                }
            }
            break

        case AnnotationActionTypes.RESET_ANNOTATION_FILTER:
            state = {
                ...state,
                filters: initialState.filters,
                filterCount: 0,
                dataRequest: {filters: [], orders: state.dataRequest.orders},
                page: 1,
            }
            break

        case AnnotationActionTypes.REMOVE_ANNOTATION_FILTER:
            if (state.filters.length === 1) {
                state = initialState
            } else {
                const newRemoveFilters = deepCopyState(state).filters.filter(filter => filter.id !== action.payload)
                const newCount = newRemoveFilters.reduce((prev, currEl) => Boolean(currEl.filterValue[0]?.value) ? prev + 1 : prev, 0)
                state = {...state, filters: newRemoveFilters, filterCount: newCount}
            }
            break

        case AnnotationActionTypes.CHANGE_ANNOTATION_FILTER_TYPE:
            const newTypeChangeFilters = deepCopyState(state).filters
            if (!action.payload.inDeletion && newTypeChangeFilters.some(filter => String(filter.filterType.value) === String(action.payload.newValue.value))) {
                toast.warn('This filter is already selected')
                break
            }
            const changedFilterTypeIndex = newTypeChangeFilters.findIndex(filter => filter.id === action.payload.id)
            if (changedFilterTypeIndex !== -1) {
                const valueOptions = action.payload.meta.filter_list?.map(option => ({
                    value: option.filter_name || '',
                    label: `${option.filter_name} (${option.filter_counter})`
                }))
                const operatorOptions = action.payload.meta.filter_operator_values?.map(option => ({
                    value: option,
                    label: option
                }))

                newTypeChangeFilters[changedFilterTypeIndex].filterType = action.payload.newValue;
                newTypeChangeFilters[changedFilterTypeIndex].filter_operators = operatorOptions ?? []
                newTypeChangeFilters[changedFilterTypeIndex].filter_operator = newTypeChangeFilters[changedFilterTypeIndex].filter_operators[0] || {
                    value: '',
                    label: 'Choose Operator'
                }
                newTypeChangeFilters[changedFilterTypeIndex].filterValue = []
                newTypeChangeFilters[changedFilterTypeIndex].filterValues = valueOptions ?? []
                newTypeChangeFilters[changedFilterTypeIndex].dataType = action.payload.meta.filter_data_type

                let newFilterCount = deepCopyState(state).filterCount
                if (newTypeChangeFilters[changedFilterTypeIndex].filterType.value !== '') {
                    newFilterCount = newTypeChangeFilters.filter(isFilterFull).length
                }

                state = {...state, filters: newTypeChangeFilters, filterCount: newFilterCount}
            }
            break

        case AnnotationActionTypes.CHANGE_ANNOTATION_FILTER_OPERATOR:
            const newOperatorChangeFilters = deepCopyState(state).filters
            const changedOperatorIndex = newOperatorChangeFilters.findIndex(filter => filter.id === action.payload.id)
            if (changedOperatorIndex !== -1) {
                newOperatorChangeFilters[changedOperatorIndex].filter_operator = action.payload.newValue
                newOperatorChangeFilters[changedOperatorIndex].filterValue = []
                state = {...state, filters: newOperatorChangeFilters}
            }
            break

        case AnnotationActionTypes.CHANGE_ANNOTATION_FILTER_VALUE:
            let newValueChangeFilters = deepCopyState(state).filters
            const changedFilterValueIndex = newValueChangeFilters.findIndex(filter => filter.id === action.payload.id)
            if (changedFilterValueIndex !== -1) {
                newValueChangeFilters[changedFilterValueIndex].filterValue = [action.payload.newValue].flat()
                if (!action.payload.inDeletion) {
                    newValueChangeFilters = newValueChangeFilters.slice(0, changedFilterValueIndex + 1)
                }
                state = {...state, filters: newValueChangeFilters, filterCount: newValueChangeFilters.length}
            }
            break

        case AnnotationActionTypes.CHANGE_ANNOTATION_FILTER_SA:
            const newSAChangeState = deepCopyState(state)
            let saChangeFilters = newSAChangeState.filters
            const changedValueSAIndex = saChangeFilters.findIndex(filter => filter.filterType.label === 'Community Index')
            let newIndex = newSAChangeState.filterCount

            const filterTypeValue = action.payload.options?.filter_definition_id
            const operatorsOptions = action.payload.options?.filter_operator_values?.map(option => ({
                value: option,
                label: option
            }))
            const valuesOptions = action.payload.options?.filter_list?.map(option => ({
                value: option.filter_name || '',
                label: `${option.filter_name} (${option.filter_counter})`
            }))
            const newValue = valuesOptions?.find(el => el.value === action.payload.newValue)

            const changedFilter = {
                id: uuidv4(),
                filterType: {value: String(filterTypeValue || ''), label: "Community Index"},
                filterValue: [newValue ?? {value: "", label: ""}],
                filterValues: valuesOptions ?? [{value: "", label: ""}],
                filter_operator: {value: "==", label: "=="},
                filter_operators: operatorsOptions ?? [{value: "", label: ""}],
                dataType: FilterDataType.NUMERIC
            }

            if (changedValueSAIndex === -1) {
                if (newIndex === 0) {
                    saChangeFilters[0] = changedFilter

                    newIndex = newIndex + 1
                } else {
                    if (state.filters.length === 5) {
                        toast.warn('Max filters already applied.')
                    } else {
                        saChangeFilters.push(changedFilter)
                        newIndex = newIndex + 1
                    }
                }
            } else {
                if (action.payload.newValue === '') {
                    saChangeFilters = saChangeFilters.filter((_data, index) => index !== changedValueSAIndex)
                    if (saChangeFilters.length === 0) {
                        state = {...state, dataRequest: {orders: state.dataRequest.orders}}
                        break
                    }
                    newIndex = newIndex - 1
                } else {
                    saChangeFilters[changedValueSAIndex] = changedFilter
                }
            }

            const newDR: FilterRequest = {
                filters: saChangeFilters.map(filter => ({
                    filter_definition_id: Number(filter.filterType.value),
                    filter_operator: filter.filter_operator.value,
                    filter_value: filter.filterValue.map(el => el.value)
                })),
                orders: state.dataRequest.orders
            }

            state = {...state, filters: saChangeFilters, filterCount: newIndex, page: 1, dataRequest: newDR}
            break

        case AnnotationActionTypes.CHANGE_ANNOTATION_IMAGE_TYPE:
            state = {...state, imageType: action.payload}
            break

        default:
            state = {...state}
    }

    return state
}

export default reducer

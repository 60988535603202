import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

//Auth

import login from './auth/reducer'

//Users

import users from './users/reducer'

//APPS

import appsReducer from './apps/reducer'

//CONFIG

import config from './config/reducer'

//COMMUNITY

import community from './community/reducer'

//COMMUNITY-FILTER

import communityFilter from './community-filters/reducer'

//COMMUNITY-SORT

import communitySort from  './community-sort/reducer'

//ANNOTATION

import annotation from './annotation/reducer'


const rootReducer = combineReducers({
    login,
    users,
    appsReducer,
    Layout,
    config,
    community,
    communityFilter,
    communitySort,
    annotation
})

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer

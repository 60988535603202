import React from "react";
import classes from './AnnotationTag.module.scss'
import {AnnotationClassResponse, UpdateAnnotationRequest} from "../../../../../../AUTO_GENERATED_TYPES";
import {toast} from "react-toastify";

interface AnnotationTagProps {
    annotationClass: AnnotationClassResponse,
    segId?: number
    isSelected?: boolean
    onClick: (request: Array<UpdateAnnotationRequest>) => void
}

const AnnotationTag: React.FC<AnnotationTagProps> = ({annotationClass, segId, isSelected, onClick}) => {
    const clickHandler = () => {
        if (isSelected) {
            toast.warn('Tag Already Applied')
        } else if(segId !== undefined){
            onClick([{seg_id_list: [segId], segment_tag: annotationClass.class_code}])
        }

    }

    return <div className={`${classes.tag} ${isSelected ? classes.tag__selected : ''}`}
                onClick={clickHandler}>{annotationClass.class_name}</div>
}

export default AnnotationTag

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum FilterDataType {
    STRING = 'STRING',
    NUMERIC = 'NUMERIC',
    JSON = 'JSON',
}

import {AuthActionTypes} from "../../types/StoreTypes";
import {Login, LoginFail} from "../../types/AuthTypes";
import {Body_login_for_access_token_token_login_post, Token} from "../../AUTO_GENERATED_TYPES";

export const login = (user : Body_login_for_access_token_token_login_post) :Login => ({
    type: AuthActionTypes.LOGIN,
    payload: {user}
})
export const loginSuccess = (user:Token, result:boolean) => ({
    type: AuthActionTypes.LOGIN_SUCCESS,
    payload: {user, result}
})
export const loginFail = (msg:string) :LoginFail => ({
    type: AuthActionTypes.LOGIN_FAIL,
    payload: msg
})


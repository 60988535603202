/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum WarningStatus {
    NEW = 'NEW',
    IGNORED = 'IGNORED',
    APPROVED = 'APPROVED',
}

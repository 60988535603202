import * as SagaEffects from "redux-saga/effects"
// Login Redux States
import {
    createConfigGroupSuccess,
    createConfigSuccess,
    deleteConfigGroupSuccess,
    deleteConfigSuccess,
    editConfigGroupSuccess,
    editConfigSuccess,
    getConfigSuccess,
    getDefaultGroupsSuccess,
} from "./actions"
//Static
import StaticValues from "../../../src/_metronic/helpers/StaticValues";

//Include Both Helper File with needed methods
import {
    createNewConfig,
    createNewConfigGroup,
    deleteConfig,
    deleteConfigGroup,
    editConfig,
    editConfigGroup,
    getConfigDefault,
    getGroupsDefault,
} from "../../_metronic/helpers/backend_helper"
import {changePreloader} from "../layout/actions";
import {
    ConfigGroupListResponse,
    ConfigListResponse,
    CreateConfigGroupRequest,
    CreateConfigRequest,
    GroupMode,
    UpdateConfigGroupRequest,
    UpdateConfigRequest
} from "../../AUTO_GENERATED_TYPES";
import {ConfigActionTypes} from "../../types/StoreTypes";
import {toast} from "react-toastify";

const {call, put, takeEvery, delay} = SagaEffects as any;

function* generalSagaError(error: any) {
    yield put(changePreloader(false))
    // toast.error(error.message)
}

function* getConfigSaga() {
    yield put(changePreloader(true))
    try {
        const response: ConfigListResponse = yield call(getConfigDefault)
        yield put(getConfigSuccess(response))
        yield put(changePreloader(false))
    } catch (error) {
        yield* generalSagaError(error)

    }
}

function* getGroupsSaga({
                            payload: {
                                groupMode,
                                archived
                            }
                        }: { payload: { groupMode?: GroupMode | undefined, archived?: boolean } }) {
    yield put(changePreloader(true))
    try {
        const response: ConfigGroupListResponse = yield call(getGroupsDefault, groupMode, archived)
        yield put(getDefaultGroupsSuccess(response))
        yield put(changePreloader(false))
    } catch (error) {
        yield* generalSagaError(error)

    }

}

function* createNewConfigSaga({payload: configData}: { payload: CreateConfigRequest }) {

    yield put(changePreloader(true))
    try {
        yield call(createNewConfig, configData)
        yield put(changePreloader(false))
        toast.success('New Config successfully created', {autoClose: StaticValues.waitingTimeForAlert})
        yield put(createConfigSuccess(true, ''))
        yield delay(StaticValues.waitingTimeForAlert)
        yield put(createConfigSuccess(false, ''))
    } catch (error) {
        yield* generalSagaError(error)

    }

}

function* createNewConfigGroupSaga({payload: configData}: { payload: CreateConfigGroupRequest }) {

    yield put(changePreloader(true))
    try {
        yield call(createNewConfigGroup, configData)
        yield put(changePreloader(false))
        toast.success('New Config group successfully created', {autoClose: StaticValues.waitingTimeForAlert})
        yield put(createConfigGroupSuccess(true, ''))
        yield delay(StaticValues.waitingTimeForAlert)
        yield put(createConfigGroupSuccess(false, ''))
    } catch (error) {
        yield* generalSagaError(error)

    }

}

function* editConfigSaga({
                             payload: {
                                 configData,
                                 configId
                             }
                         }: { payload: { configData: UpdateConfigRequest, configId: number } }) {
    yield put(changePreloader(true))
    try {
        yield call(editConfig, configData, configId)
        yield put(changePreloader(false))
        toast.success('Config successfully updated', {autoClose: StaticValues.waitingTimeForAlert})
        yield put(editConfigSuccess(true, ''))
        yield delay(StaticValues.waitingTimeForAlert)
        yield put(editConfigSuccess(false, ''))
    } catch (error) {
        yield* generalSagaError(error)

    }
}

function* editConfigGroupSaga({
                                  payload: {
                                      configData,
                                      configId
                                  }
                              }: { payload: { configData: UpdateConfigGroupRequest, configId: number } }) {
    yield put(changePreloader(true))
    try {
        yield call(editConfigGroup, configData, configId)
        yield put(changePreloader(false))
        toast.success('Config successfully updated', {autoClose: StaticValues.waitingTimeForAlert})
        yield put(editConfigGroupSuccess(true, ''))
        yield delay(StaticValues.waitingTimeForAlert)
        yield put(editConfigGroupSuccess(false, ''))
    } catch (error) {
        yield* generalSagaError(error)

    }

}

function* deleteConfigSaga({payload: configId}: { payload: number }) {
    yield put(changePreloader(true))
    try {
        yield call(deleteConfig, configId)
        yield put(changePreloader(false))
        toast.success('Config successfully deleted!', {autoClose: StaticValues.waitingTimeForAlert})
        yield put(deleteConfigSuccess(true, ''))
        yield delay(StaticValues.waitingTimeForAlert)
        yield put(deleteConfigSuccess(false, ''))
    } catch (error) {
        yield* generalSagaError(error)

    }

}

function* deleteConfigGroupSaga({payload: configId}: { payload: number }) {
    yield put(changePreloader(true))
    try {
        // Not aligned to BE, and not in use
        yield call(deleteConfigGroup, configId)
        yield put(changePreloader(false))
        toast.success('Config Group successfully deleted!', {autoClose: StaticValues.waitingTimeForAlert})
        yield put(deleteConfigGroupSuccess(true, ''))
        yield delay(StaticValues.waitingTimeForAlert)
        yield put(deleteConfigGroupSuccess(false, ''))
    } catch (error) {
        yield* generalSagaError(error)

    }

}


function* rollbackAppConfigSaga({payload: configHistId}: { payload: number }) {

    yield put(changePreloader(true))
    try {
        // yield call(rollBackAppConfig, configHistId)
        yield put(changePreloader(false))
        toast.success('Config settings have been reinitialized', {autoClose: StaticValues.waitingTimeForAlert})
    } catch (error) {
        yield* generalSagaError(error)
    }

}


function* configSaga() {
    yield takeEvery(ConfigActionTypes.GET_CONFIG_DEFAULT, getConfigSaga)
    yield takeEvery(ConfigActionTypes.GET_GROUPS_DEFAULT, getGroupsSaga)
    yield takeEvery(ConfigActionTypes.CREATE_CONFIG, createNewConfigSaga)
    yield takeEvery(ConfigActionTypes.CREATE_CONFIG_GROUP, createNewConfigGroupSaga)
    yield takeEvery(ConfigActionTypes.EDIT_CONFIG, editConfigSaga)
    yield takeEvery(ConfigActionTypes.EDIT_CONFIG_GROUP, editConfigGroupSaga)
    yield takeEvery(ConfigActionTypes.DELETE_CONFIG, deleteConfigSaga)
    yield takeEvery(ConfigActionTypes.DELETE_CONFIG_GROUP, deleteConfigGroupSaga)
    yield takeEvery(ConfigActionTypes.ROLLBACK_CONFIG, rollbackAppConfigSaga)
    // yield takeEvery(ConfigActionTypes.ROLLBACK_CONFIG, rollbackAppConfigSaga)

}

export default configSaga

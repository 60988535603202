import {useParams} from "react-router-dom";
import {useIsFetching, useQuery} from "react-query";
import QueryKeys from "../../../../../../react-query/QueryKeys";
import {
    getAnnotationFilters,
    getAnnotationFiltersDefinition,
    getAnnotationJobData
} from "../../../../../../_metronic/helpers/backend_helper";
//@ts-ignore
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
//@ts-ignore
import BootstrapTable from 'react-bootstrap-table-next';
//@ts-ignore
import paginationFactory from 'react-bootstrap-table2-paginator';
import React, {useEffect, useMemo, useState} from "react";
import {Button} from "react-bootstrap";
import {KTSVG, toAbsoluteUrl} from "../../../../../../_metronic/helpers";
import {Card, Col, Label} from "reactstrap";
import CreatableSelect from "react-select/creatable";
import tableClasses from "../../../../Community/pages/table.module.scss";
import {toast} from "react-toastify";
import usePreloader from "../../../../../../store/layout/usePreloader";
import useAnnotation from "../../../../../../store/annotation/useAnnotation";
import SegmentDetailsPagination from "../../../../Community/Components/SegmentDetailsPagination";
import AnnotationSort from "../AnnotationSort/AnnotationSort";
import AnnotationFilter from "../AnnotationFilter/AnnotationFilter";
import isFilterFull from "../../../../../../assets/utils/isFilterFull";
import {AnnotationSegFilter} from "../../../../../../types/AnnotationTypes";
import {v4 as uuidv4} from "uuid";
import {FilterDataType} from "../../../../../../AUTO_GENERATED_TYPES";

const dateFormatter = (cell: any, row: any) => {
    let date = row.creation_time
        .replace(/[-]/g, '/')
        .replace(/[T]/g, '\u00A0');
    let jsDate = date.slice(0, 16);
    if (row.creation_time) {
        return (
            <span>
                    <p style={{marginBottom: 0, marginLeft: 5}}>
                      {jsDate}
                    </p>
                </span>
        );
    }
}

const AnnotationSegmentTable = () => {
    const [showSort, setShowSort] = useState(false)
    const [showFilter, setShowFilter] = useState(false)
    const [svg, setSVG] = useState<any>()

    useEffect(() => {
        const prefetchSvg = async (url: string) => {
            const response = await fetch(url);
            const text = await response.text();

            setSVG(text)
        };

        prefetchSvg(toAbsoluteUrl('/media/icons/duotune/general/gen054.svg'));
    }, [])


    const {id} = useParams()
    const annotationId = Number(id)

    const {
        data: {dataRequest, tagFilter, page, itemsPerPage, sortCount, filterCount, filters},
        functions: {changePage, changeItemsPerPage, addFilter}
    } = useAnnotation()

    const onlyFullFilters = useMemo(() =>
        filters.filter(isFilterFull).map(filter => ({
            filter_definition_id: Number.parseInt(filter.filterType.value),
            filter_value: filter.filterValue.map(el => el.value),
            filter_operator: filter.filter_operator.value
        })), [filters])

    const isFetching = useIsFetching()
    const {SearchBar} = Search;
    const {
        data: filterList,
        isLoading: filtersLoading
    } = useQuery([QueryKeys.AnnotationFiltersDefinition, annotationId], () => getAnnotationFiltersDefinition(annotationId), {
        cacheTime: Infinity,
        staleTime: Infinity,

    })

    const {
        data,
        isLoading
    } = useQuery([QueryKeys.Annotation, QueryKeys.AnnotationJobData, annotationId, dataRequest, tagFilter, itemsPerPage, page], () => getAnnotationJobData(annotationId, dataRequest, tagFilter, itemsPerPage, page), {
        retry: false,
        refetchOnWindowFocus: false,
        staleTime: 60 * 1000,
        onError: () => toast('Error!')
    })

    usePreloader(Boolean(isFetching) || !Boolean(svg) || filtersLoading)

    const isTableLoading = isLoading || !Boolean(svg) || filtersLoading || isFetching

    const copyFormatter = (data: any, row: any, i: any, additionalData?: any) => {
        const isLinkable = filterList?.filters?.find(filter => filter.column_name === additionalData)
        const currentFilter = filterList?.filters?.find(filter => filter.column_name === additionalData)
        const filterIsJson = currentFilter?.data_type === FilterDataType.JSON


        const handleFilter = () => {
            if (isLinkable) {

                getAnnotationFilters(annotationId, isLinkable.id, {filters: onlyFullFilters}, tagFilter).then(response => {

                    const values = response.filter_list?.map(value => ({
                        value: value.filter_name ?? '',
                        label: `${value.filter_name} (${value.filter_counter})`
                    })) ?? [{value: "", label: ""}]

                    const value = [values.find(value => value.value === (filterIsJson ? JSON.stringify(data) : data)) ?? {
                        value: "",
                        label: ""
                    }]

                    const filterLabel = currentFilter?.short_name

                    const operators = response.filter_operator_values?.map(value => ({value, label: value})) ?? [{
                        value: "",
                        label: ""
                    }]

                    const filter: AnnotationSegFilter = {
                        id: uuidv4(),
                        filterType: {value: response.filter_definition_id + '', label: filterLabel ?? ''},
                        filterValue: value,
                        filterValues: values,
                        filter_operator: response.filter_data_type === FilterDataType.NUMERIC || response.filter_data_type === FilterDataType.JSON ? {
                            value: "==",
                            label: "=="
                        } : {value: "is in", label: "is in"},
                        filter_operators: operators,
                        dataType: response.filter_data_type
                    }

                    addFilter(filter)
                }).catch(() => toast.error('Something went wrong when applying the filter!'))


            }
        }

        const handleCopy = (e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation()
            navigator.clipboard.writeText(filterIsJson ? JSON.stringify(data) : String(data)).then(() => {
                toast.info('Copied to clipboard!')
            })
        }

        const dataIsArray = Array.isArray(data);
        let renderData = data

            if (typeof renderData === 'number') {
            renderData = renderData.toLocaleString()
        } else if (dataIsArray) {
            renderData = data.join(',')
        }

        return <div className={'d-flex justify-content-between'} title={renderData}
                    style={renderData && isLinkable ? {cursor: 'pointer'} : undefined} onClick={handleFilter}>
            <div className={'seg_long_copy'}>{renderData ?? 'N/A'}</div>
            {(!!data || data == 0) && <div className={'copy_btn'} onClick={handleCopy}>
                <KTSVG
                    path={svg}
                    className='svg-icon-edit svg-icon-2'
                    cache
                />
            </div>}

        </div>

    }


    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            editable: false,
            sort: true,
            hidden: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
        },
        {
            dataField: 'creation_time',
            text: 'Creation Time',
            editable: false,
            formatter: dateFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },

        },
        {
            dataField: 'tag',
            text: 'Tag',
            editable: false,
            formatter: copyFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'tag'
        },
        {
            dataField: 'comm_idx',
            text: 'Community Index',
            editable: false,
            formatter: copyFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'comm_idx'
        },
        {
            dataField: 'app_token',
            text: 'App Token',
            editable: false,
            formatter: copyFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'app_token'
        },
        {
            dataField: 'platform',
            text: 'Platform',
            editable: false,
            formatter: copyFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'platform'

        },
        {
            dataField: 'seg_name',
            text: 'Segment Name',
            editable: false,
            formatter: copyFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'seg_name'

        },
        {
            dataField: 'seg_id',
            text: 'Segment ID',
            editable: false,
            formatter: copyFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'seg_id'

        },
        {
            dataField: 'session_id',
            text: 'Session ID',
            editable: false,
            formatter: copyFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'session_id'

        },
        {
            dataField: 'user_id',
            text: 'User ID',
            editable: false,
            formatter: copyFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'user_id'

        },
        {
            dataField: 'additional_id',
            text: 'Additional ID',
            editable: false,
            formatter: copyFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'additional_id'

        },
        {
            dataField: 'cnt_segments',
            text: 'Segments',
            editable: false,
            formatter: copyFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'cnt_segments'
        }, {
            dataField: 'segment_ids',
            text: 'Segment IDs',
            editable: false,
            formatter: copyFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'segment_ids'

        }, {
            dataField: 'comm_indices',
            text: 'Community Indices',
            editable: false,
            formatter: copyFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'comm_indices'

        },
        {
            dataField: 'ip_address',
            text: 'IP Address',
            editable: false,
            formatter: copyFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'ip_address'

        },
        {
            dataField: 'country_code',
            text: 'Country Code',
            editable: false,
            formatter: copyFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'country_code'

        },
        {
            dataField: 'screen_width',
            text: 'Screen Width',
            editable: false,
            formatter: copyFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'screen_width'

        },
        {
            dataField: 'screen_height',
            text: 'Screen Height',
            editable: false,
            formatter: copyFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'screen_height'

        }, {
            dataField: 'auth_inference',
            text: 'Auth Inference',
            editable: false,
            formatter: copyFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'auth_inference'

        },
        {
            dataField: 'auth_models',
            text: 'Auth Models',
            editable: false,
            formatter: copyFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'auth_models'

        },
        {
            dataField: 'auth_score',
            text: 'Auth Score',
            editable: false,
            formatter: copyFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'auth_score'

        },
        {
            dataField: 'emu_inference',
            text: 'Emu Inference',
            editable: false,
            formatter: copyFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'emu_inference'

        },
        {
            dataField: 'emu_models',
            text: 'Emu Models',
            editable: false,
            formatter: copyFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'emu_models'
        },
        {
            dataField: 'emu_score',
            text: 'Emu Score',
            editable: false,
            formatter: copyFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'emu_score'

        },
        {
            dataField: 'input_devices_inference',
            text: 'Input Devices Inference',
            editable: false,
            formatter: copyFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'input_devices_inference'
        },
        {
            dataField: 'input_devices_found',
            text: 'Input Devices Found',
            editable: false,
            formatter: copyFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'input_devices_found'
        },
        {
            dataField: 'labeled_auth_label',
            text: 'Labeled Auth Label',
            editable: false,
            formatter: copyFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'labeled_auth_label'
        },
        {
            dataField: 'cnt_seq_motion',
            text: 'Count Sequential Motion',
            editable: false,
            formatter: copyFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'cnt_seq_motion'
        },
        {
            dataField: 'seg_duration_millis',
            text: 'Segment Duration Millis',
            editable: false,
            formatter: copyFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'seg_duration_millis'
        },
        {
            dataField: 'cnt_seq_acc',
            text: 'Count Sequential Acc',
            editable: false,
            formatter: copyFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'cnt_seq_acc'
        },
        {
            dataField: 'sdk_version_name',
            text: 'SDK Version Name',
            editable: false,
            formatter: copyFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'sdk_version_name'
        },
        {
            dataField: 'device',
            text: 'Device',
            editable: false,
            formatter: copyFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'device'
        },
        {
            dataField: 'device_id',
            text: 'Device ID',
            editable: false,
            formatter: copyFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'device_id'
        },
        {
            dataField: 'model',
            text: 'Model',
            editable: false,
            formatter: copyFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'model'
        },
        {
            dataField: 'system_version',
            text: 'System Version',
            editable: false,
            formatter: copyFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'system_version'
        },
        {
            dataField: 'ip_network',
            text: 'IP Network',
            editable: false,
            formatter: copyFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'ip_network'
        },
        {
            dataField: 'cnt_motion_down',
            text: 'Count Motion Down',
            editable: false,
            formatter: copyFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'cnt_motion_down'
        },
        {
            dataField: 'cnt_motion_move',
            text: 'Count Motion Move',
            editable: false,
            formatter: copyFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'cnt_motion_move'
        },
        {
            dataField: 'most_common_xy',
            text: 'Most Common XY',
            editable: false,
            formatter: copyFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'most_common_xy'
        },
        {
            dataField: 'seg_count',
            text: '# Seg In Session',
            editable: false,
            formatter: copyFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'seg_count'
        },
        {
            dataField: 'ts_start',
            text: 'TS Start',
            editable: false,
            formatter: copyFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'ts_start'
        },
        {
            dataField: 'comment',
            text: 'Comment',
            editable: false,
            formatter: copyFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
        },
        {
            dataField: 'key_values',
            text: 'Key Values',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'key_values'
        },
        {
            dataField: 'cnt_unique_xy',
            text: 'Count Unique XY',
            editable: false,
            sort: true,
            formatter: copyFormatter,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'cnt_unique_xy'
        },
        {
            dataField: 'additional_info',
            text: 'Additional Info',
            editable: false,
            sort: true,
            formatter: copyFormatter,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'additional_info'
        },
        {
            dataField: 'extended_info',
            text: 'Extended Info',
            editable: false,
            sort: true,
            formatter: copyFormatter,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'extended_info'
        },
        {
            dataField: 'bio_duration_millis',
            text: 'Bio Durations Millis',
            editable: false,
            sort: true,
            formatter: copyFormatter,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'bio_duration_millis'
        }, {
            dataField: 'auth_inference_canary',
            text: 'Auth Inference Canary',
            editable: false,
            sort: true,
            formatter: copyFormatter,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'auth_inference_canary'
        }, {
            dataField: 'comm_idx_canary',
            text: 'Comm Idx Canary',
            editable: false,
            sort: true,
            formatter: copyFormatter,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'comm_idx_canary'
        },

    ]

    return <ToolkitProvider
        keyField="id"
        data={data?.segments || []}
        columns={columns}
        search
        bootstrap4
    >
        {(props: any) => (
            <>
                <div className={'toolbar_table_community'}>
                    <div>
                        <SearchBar {...props.searchProps} />
                    </div>
                    <Button className={'position-relative'}
                            onClick={(e: any) => {
                                e.stopPropagation();
                                setShowFilter(prevState => !prevState)
                                setShowSort(false)
                            }}>
                        <KTSVG
                            path='/media/icons/duotune/general/gen031.svg'
                            className='svg-icon-edit svg-icon-2'
                        />
                        Filter {filterCount ? `(${filterCount})` : ''}
                        <div>
                            <AnnotationFilter isOpen={showFilter}
                                              onClose={() => setShowFilter(false)}
                                              isItemTable={true}/>

                        </div>
                    </Button>
                    <Button className={'position-relative'} onClick={(e) => {
                        e.stopPropagation()
                        setShowSort(prevState => !prevState)
                        setShowFilter(false)
                    }}>
                        <KTSVG
                            path='/media/icons/duotune/general/gen032.svg'
                            className='svg-icon-edit svg-icon-2'
                        />
                        Sort {Boolean(sortCount) && <span>({sortCount})
                        </span>}
                        <AnnotationSort isOpen={showSort} setIsOpen={setShowSort}/>

                    </Button>

                    <div className={'toolbar_table_community__perPage'}
                         style={{display: 'flex', alignItems: 'center', gap: '10px', zIndex: 10}}>
                        {data?.paging && <>
                            <Label style={{marginBottom: 0}}>Per Page</Label>
                            <CreatableSelect value={{
                                value: data?.paging?.items_per_page,
                                label: `${data?.paging?.items_per_page} (of ${data?.paging?.total_items})`
                            }}
                                             onChange={(e) => {
                                                 if (e) {
                                                     changeItemsPerPage(e.value)
                                                 }
                                             }}
                                             options={[10, 25, 50, 100].map(num => ({
                                                 value: num,
                                                 label: `${num} (of ${data?.paging?.total_items})`
                                             }))}
                                             formatCreateLabel={(inputValue: string) => `${inputValue} ${data?.paging?.total_items ? `(of ${data?.paging.total_items})` : ''}`}
                                             createOptionPosition={'first'}
                                             isValidNewOption={inputValue => !isNaN(parseInt(inputValue))}

                            />
                            <Label style={{marginBottom: 0}}>{data?.paging?.total_items || 0} Total</Label></>}

                    </div>
                </div>
                <br/>
                <Card>
                    <Col xl='12' className={tableClasses.table_wrapper}>
                        {data?.segments?.length !== 0 && !isTableLoading ? <BootstrapTable
                            {...props.baseProps}
                            keyField='id'
                            bordered={true}
                            responsive
                            stripped
                            headerWrapperClasses={"header-class"}
                        /> : <div className={'text-center'}>{isTableLoading ? '' : 'No Data Found'}</div>}
                    </Col>
                    {data?.segments?.length !== 0 &&
                        <SegmentDetailsPagination currentPage={page ?? 1} pageCount={data?.paging?.num_of_pages ?? 1}
                                                  handlePaginate={(({selected}) => changePage(selected))}/>}
                </Card>
            </>
        )}
    </ToolkitProvider>
}

export default AnnotationSegmentTable

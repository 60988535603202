import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../reducers";
import {useQuery} from "react-query";
import QueryKeys from "../../react-query/QueryKeys";
import {getOrderDefinitions} from "../../_metronic/helpers/backend_helper";
import {SegmentType} from "../../AUTO_GENERATED_TYPES";
import {setSortOptions} from "./actions";
import {useParams} from "react-router-dom";

const useCommunitySort = (enabled = true) => {
    const dispatch = useDispatch()
    const {jobId: cdId, type: segmentType} = useParams()

    useQuery([QueryKeys.SegmentOrders, cdId, segmentType], () => getOrderDefinitions(Number(cdId), segmentType === 'positive' ? SegmentType.POS : SegmentType.NEG), {
        cacheTime: Infinity,
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        enabled,
        onSuccess: (data) => {
            const newOptions = data.orders?.map(option => ({value: String(option.id), label: option.short_name})) ?? [{
                value: "",
                label: ""
            }]

            dispatch(setSortOptions(newOptions))
        },
    })
    const {sorts, sortCount, sortOptions} = useSelector((state: RootState) => ({
        sorts: state.communitySort.sorts,
        sortCount: state.communitySort.sortCount,
        sortOptions: state.communitySort.sortOptions
    }))

    return {sorts, sortCount, sortOptions}
}

export default useCommunitySort

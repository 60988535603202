import React, {useEffect, useLayoutEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {PageLink, PageTitle} from "../../../../_metronic/layout/core";
import {
    deployComm,
    getCommunityJob,
    getCommunityJobComm,
    getSummaryTable,
    getWarnings,
    preDeployComm,
    resetStore,
    updateCommDetails,
    updateDecision
} from "../../../../store/actions";
import {RootState} from "../../../../store/reducers";
import {Button, Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {Label} from "reactstrap";
import '../community.scss'
import StaticValues from "../../../../_metronic/helpers/StaticValues";
import LongFileModal from "../Modals/LongFileModal";
import {KTSVG} from "../../../../_metronic/helpers";
//@ts-ignore
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
//@ts-ignore
import BootstrapTable from 'react-bootstrap-table-next';
//@ts-ignore
import paginationFactory from 'react-bootstrap-table2-paginator';
//@ts-ignore
import alertStyle from "../../../general-styles/Alerts/alert-cards.module.scss";
import {
    CommunityDecision,
    CommunityDetectionStatus,
    CommunityType,
    JobCommunityResponse
} from "../../../../AUTO_GENERATED_TYPES";
import {Link, useNavigate} from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import SummaryTableModal from "../Modals/SummaryTableModal";
import {CSSTransition} from 'react-transition-group';
import tableClasses from './table.module.scss'
import {toast} from "react-toastify";
import {SessionStorage} from "../../../../constants/sessionStorage";


interface CommunityJobProps {
}

interface JobCtx {
    details?: string,
    page?: string,
    perPage?: string,
    status?: string,
    decision?: string,
    idx?: string
}


// @ts-ignore
const CommunityJob = ({}: CommunityJobProps) => {

    const path = window.location.pathname;
    const directories = path.split("/");
    const jobId = directories[(directories.length - 1)];

    const jobCtx = JSON.parse(sessionStorage.getItem(SessionStorage.cJob + jobId) ?? 'null') as (JobCtx | null)
    const dispatch = useDispatch()
    const {SearchBar} = Search;
    const navigate = useNavigate();

    const [commJobList, setCommJobList] = useState<(JobCommunityResponse | null)[]>([])
    const [commJobListFiltered, setCommJobListFiltered] = useState<any>()

    // const [commJobId, setCommJobId] = useState<any>()
    const [fileToWatch, setFileToWatch] = useState<any>()
    const [fileTitle, setFileTitle] = useState<string>('')
    const [confirmationModal, setConfirmationModal] = useState<boolean>(false)

    const [selectStatus, setSelectStatus] = useState<string>(jobCtx?.status || '0')
    const [selectDecision, setSelectDecision] = useState<string>(jobCtx?.decision || '0')
    const [selectIdx, setSelectIdx] = useState<string>(jobCtx?.idx || '0')
    const [page, setPage] = useState(+(jobCtx?.page || 1))
    const [perPage, setPerPage] = useState(+(jobCtx?.perPage || 25))


    const [selectedComment, setSelectedComment] = useState<any>('')
    const [isArchive, setIsArchive] = useState<boolean>(false)
    const [isFilterActive, setIsFilterActive] = useState<boolean>(false)
    const [jobStatus, setJobStatus] = useState<CommunityDetectionStatus>()
    const [oldJobStatus, setOldJobStatus] = useState<CommunityDetectionStatus>()

    const [tableState, setTableState] = useState<string>('POS')
    const [decision, setDecision] = useState<CommunityDecision>(CommunityDecision.REVIEW)
    const [deployText, setDeployText] = useState<any>()
    const [longFileModal, setLongFileModal] = useState<boolean>(false)
    const [targetArray, setTargetArray] = useState<number[]>([])
    const [communityIdx, setCommunityIdx] = useState<any[]>([])
    const [multipleSelection, setMultipleSelection] = useState<boolean>(false)
    const [warningPopup, setWarningPopup] = useState<boolean>(false)
    const [deployPopup, setDeployPopup] = useState<boolean>(false)
    const [fullList, setFullList] = useState<boolean>(false)
    const [negativeActions, setNegativeActions] = useState<boolean>(false)
    const [preDeployCommunity, setPreDeployCommunity] = useState<boolean>(false)
    const [isDeploying, setIsDeploying] = useState<boolean>(false)
    const [handleSummaryTable, setHandleSummaryTable] = useState<boolean>(false)
    const [showDetails, setShowDetails] = useState<boolean>(jobCtx?.details !== 'false')
    const [deployHasBeenMade, setDeployHasBeenMade] = useState<boolean>(false)
    const [firstRender, setFirstRender] = useState<boolean>(true)
    const [warningHasBeenSeen, setWarningHasBeenSeen] = useState<boolean>(false)

    useEffect(() => {
        if (jobCtx?.page) {
            setPage(Number(jobCtx.page))
        }
    }, [])

    const {
        communityJob,
        result,
        communityJobGeneral,
        communityJobCommList,
        preDeployWarning,
        summaryTable,
        deployResponse
    } = useSelector((state: RootState) => ({
        communityJob: state.community.communityJob,
        communityJobCommList: state.community.communityJobCommList,
        result: state.community.result,
        communityJobGeneral: state.community.communityJobGeneral,
        preDeployWarning: state.community.preDeployWarning,
        deployResponse: state.community.deployResponse,
        summaryTable: state.community.summaryTable,
    }))

//Use Effects for fetching data
    useEffect(() => {
        dispatch(resetStore())
        dispatch(getCommunityJob(Number(jobId)))
        dispatch(getCommunityJobComm(Number(jobId), CommunityType.POS))
        dispatch(getWarnings(Number(jobId)))
        dispatch(getSummaryTable(Number(jobId)))
        setFirstRender(false)
        localStorage.setItem('currentCommId', jobId)
    }, [])


    useEffect(() => {
        if (result == true) {
            dispatch(getCommunityJobComm(Number(jobId), tableState === 'POS' ? CommunityType.POS : CommunityType.NEG))
            dispatch(getCommunityJob(Number(jobId)))
            dispatch(getWarnings(Number(jobId)))
            dispatch(getSummaryTable(Number(jobId)))
        }
    }, [result]);

    useEffect(() => {
        setCommJobList(communityJobCommList)
    }, [communityJobCommList]);

    useEffect(() => {
        if (preDeployWarning && isDeploying) {
            setPreDeployCommunity(true)
            console.log(preDeployWarning)
        }
    }, [isDeploying])

//UseEffects for setting data
    useEffect(() => {
        if (communityJob.job_short_summary) {
            setJobStatus(communityJob?.job_short_summary.status as CommunityDetectionStatus)
            setOldJobStatus(communityJob?.job_short_summary.status as CommunityDetectionStatus)
            setSelectedComment(communityJob?.job_short_summary.comment)
            setCommunityIdx(communityJobCommList.map((dec: any) => dec.community_share.comm_detection_idx))
            setIsArchive(communityJob?.job_short_summary.archived)
        }
    }, [communityJob, communityJobCommList])

    useEffect(() => {
        setCommJobList(communityJobCommList ?? null)
    }, [communityJobCommList])

//Test UseEffects
    useEffect(() => {
        if (!firstRender) {
            if (communityJobGeneral.refresh_status) {
                if (communityJobGeneral.refresh_status != null && !warningHasBeenSeen) {
                    setWarningPopup(true)
                    setWarningHasBeenSeen(true)
                } else {
                    setWarningPopup(false)
                }
            } else {
                setWarningPopup(false)
            }
            if (deployResponse?.approved_existing_list && !deployHasBeenMade && result === true) {
                setDeployText(deployResponse)
                setDeployPopup(true)
                setDeployHasBeenMade(true)
            }
        }
    }, [communityJobGeneral.refresh_status, deployResponse, result, firstRender])


//UseEffect for multi selection
    useEffect(() => {
        if (communityJobCommList?.length > 0) {
            let inArray = communityJobCommList.some((comm: any) => targetArray.some((id) => id === comm.community_id))
            if (targetArray.length > 0 && inArray) {
                setMultipleSelection(true)
            } else {
                setMultipleSelection(false)
            }
        }
    }, [targetArray, communityJobCommList])


    //UseEffects for update comm details
    useEffect(() => {
        if (jobStatus !== oldJobStatus) {
            const updateStatus = setTimeout(() => {
                dispatch(updateCommDetails(Number(jobId), {"status": jobStatus}))
            }, 1000)
            return () => clearTimeout(updateStatus)
        }
    }, [jobStatus, communityJob])

    const EvidenceCommentHandleChange = (event: any) => {
        let commentValue = event.target.value;
        setSelectedComment(commentValue)
        setTimeout(() => {
            if (commentValue === event.target.value) {
                dispatch(updateCommDetails(Number(jobId), {"comment": commentValue}))
            }
        }, 1000);
    };

    const switchTable = () => {
        if (tableState === 'POS') {
            setTableState('NEG')
            dispatch(getCommunityJobComm(Number(jobId), CommunityType.NEG))
        } else {
            setTableState('POS')
            dispatch(getCommunityJobComm(Number(jobId), CommunityType.POS))
        }
    }

    const handleLongFile = (fileType: string) => {
        if (fileType === 'JOB') {
            setFileToWatch(communityJob?.job_params)
            setFileTitle('Job Params')
            setLongFileModal(true)
        } else if (fileType === 'MODEL') {
            setFileToWatch(communityJob?.model_info)
            setFileTitle('Model Info')
            setLongFileModal(true)
        } else if (fileType === 'DIFF') {
            setFileToWatch(communityJob?.diff_existing_and_new_models)
            setFileTitle('Diff Existing')
            setLongFileModal(true)
        }
    }

    const handleOnSelect = (row: any, isSelect: boolean) => {
        if (tableState === 'POS') {
            if (isSelect) {
                setTargetArray([...targetArray, Number(row.community_id)])
            } else {
                const selectedAcc = targetArray.filter((a: any) => {
                    return a !== Number(row.community_id);
                });
                setTargetArray([...selectedAcc]);
            }
        } else {
            setNegativeActions(true)
        }
    }

    const handleOnSelectAll = (isSelect: boolean, row: any) => {
        if (isSelect) {
            setTargetArray(row.map((rowUnity: any) => Number(rowUnity.community_id)))
        } else {
            setTargetArray([])
        }
    }

    const screenSizeFormatter = (cell: any, row: any) => {
        let screenSize = String(row.community_info.limit_to_screens)
        const result = screenSize.match(/.{1,4}/g) || [];
        if (row.community_info.limit_to_screens) {
            return (
                <span>
                    <p style={{marginBottom: 0}}>
                      {result[0]?.replace(/,/g, "") + 'X' + result[1]}
                    </p>
                </span>
            );
        }
    }

    const columns = [
        {
            dataField: 'community_id',
            hidden: false,
            text: '#',
            sort: true,
            headerStyle: (colum: any, colIndex: any) => {
                return {width: '35px', textAlign: 'center'};
            },
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            }
        },
        {
            dataField: 'community_type',
            text: 'Comm Type',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
        },
        {
            dataField: 'community_status',
            text: 'Comm Status',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
        },
        {
            dataField: 'community_decision',
            text: 'Comm Decision',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
        },
        {
            dataField: 'community_decision_updater',
            text: 'Decision Updater',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },

        },
        {
            dataField: 'community_share.comm_detection_idx',
            text: 'Comm Detection Index',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
        },
        {
            dataField: 'community_share.databricks_idx',
            text: 'DataBricks Idx',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
        },
        {
            dataField: 'community_share.cnt_segments',
            text: '# Segments',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cell: any, row: any) => (
                <>
                    <span>{row.community_share.cnt_segments.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </>
            )

        },
        {
            dataField: 'community_share.cnt_users',
            text: '# Users',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cell: any, row: any) => (
                <>
                    <span>{row.community_share.cnt_users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </>
            )
        },
        {
            dataField: 'community_share.cnt_device_ids',
            text: '#Device Ids',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
        },
        {
            dataField: 'community_share.cnt_ips',
            text: '# Ips',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cell: any, row: any) => (
                <>
                    <span>{row.community_share.cnt_ips.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </>
            )
        },
        {
            dataField: 'community_share.pct_emu',
            text: '% Emulators',
            editable: false,
            sort: true,
            headerStyle: (colum: any, colIndex: any) => {
                return {width: '180px', textAlign: 'center'};
            },
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
        },
        {
            dataField: 'community_info.distance_threshold',
            text: 'Distance Threshold',
            editable: false,
            sort: true,
            headerStyle: (colum: any, colIndex: any) => {
                return {width: '180px', textAlign: 'center'};
            },
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
        },
        {
            dataField: 'community_info.limit_to_emulators_only',
            text: 'Limit Emulators Only',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            }
        },
        {
            dataField: 'community_info.limit_to_screens',
            text: 'Limit to Screens',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: screenSizeFormatter
        },
        {
            dataField: 'community_info.limit_to_segments',
            text: 'Limit to Segments',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0',
            },
        },
        {
            dataField: 'community_share.avg_similarity',
            text: 'Avg Similarity',
            editable: false,
            headerStyle: (colum: any, colIndex: any) => {
                return {width: '190px', textAlign: 'center'};
            },
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
        },
        {
            dataField: 'community_info.embedding_len',
            text: 'Embedding Len',
            editable: false,
            headerStyle: (colum: any, colIndex: any) => {
                return {width: '180px', textAlign: 'center'};
            },
            sort: true,
            style: {
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cell: any, row: any) => (
                <>
                    <span>{row.community_info.embedding_len.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </>
            )
        },
        {
            dataField: 'community_share.cnt_sessions',
            text: '# Sessions',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            }
            , formatter: (cell: any, row: any) => (
                <>
                    <span>{row.community_share.cnt_sessions.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </>
            )

        },
        {
            dataField: 'community_info.max_motion_cnt',
            text: 'Max Motion',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
        },
        {
            dataField: 'community_info.min_dist_coords',
            text: 'Min Distance Coords',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
        },
        {
            dataField: 'community_info.max_dist_coords',
            text: 'Max Distance Coords',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
        },
        {
            dataField: 'community_share.min_similarity',
            text: 'Min Similarity',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
        },
        {
            dataField: 'community_share.max_similarity',
            text: 'Max Similarity',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
        },
        {
            dataField: 'community_share.screen_dist',
            text: `Screen${'\u00A0'}Dist`,
            editable: false,
            hidden: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cell: any, row: any) => (
                <>
                    <OverlayTrigger
                        placement='bottom'
                        overlay={<Tooltip>{row.community_share.screen_dist}</Tooltip>}
                        defaultShow={undefined}
                        delay={undefined}
                        flip={undefined}
                        onHide={undefined}
                        onToggle={undefined}
                        popperConfig={undefined}
                        show={undefined}
                        target={undefined}
                        trigger={undefined}>
                        <span>{row.community_share.screen_dist.slice(0, 10)}...</span>
                    </OverlayTrigger>
                </>
            )
        },
        {
            dataField: 'community_share.seg_name_dist',
            text: `Seg${'\u00A0'}Name${'\u00A0'}Dist`,
            editable: false,
            hidden: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cell: any, row: any) => (
                <>
                    <OverlayTrigger
                        placement='bottom'
                        overlay={<Tooltip>{row.community_share.seg_name_dist}</Tooltip>}
                        defaultShow={undefined}
                        delay={undefined}
                        flip={undefined}
                        onHide={undefined}
                        onToggle={undefined}
                        popperConfig={undefined}
                        show={undefined}
                        target={undefined}
                        trigger={undefined}>
                        <span>{row.community_share.seg_name_dist.slice(0, 10)}...</span>
                    </OverlayTrigger>
                </>
            )
        },
        {
            dataField: 'community_share.country_dist',
            text: `Country${'\u00A0'}Dist`,
            editable: false,
            hidden: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cell: any, row: any) => (
                <>
                    <OverlayTrigger
                        placement='bottom'
                        overlay={<Tooltip>{row.community_share?.country_dist}</Tooltip>}
                        defaultShow={undefined}
                        delay={undefined}
                        flip={undefined}
                        onHide={undefined}
                        onToggle={undefined}
                        popperConfig={undefined}
                        show={undefined}
                        target={undefined}
                        trigger={undefined}>
                        <span>{row.community_share?.country_dist?.slice(0, 10)}...</span>
                    </OverlayTrigger>
                </>
            )
        },
        {
            dataField: 'community_share.cnt_emu',
            text: '# Emulators',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cell: any, row: any) => (
                <>
                    <span>{row.community_share.cnt_emu.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </>
            )
        },
        {
            dataField: 'community_share.cnt_pos_before',
            text: '# Pos Before',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cell: any, row: any) => (
                <>
                    <span>{row.community_share.cnt_pos_before.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </>
            )

        },
        {
            dataField: 'community_share.cnt_pos_added',
            text: '# Pos Added',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cell: any, row: any) => (
                <>
                    <span>{row.community_share.cnt_pos_added.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </>
            )
        },
        {
            dataField: 'community_share.pct_pos_before',
            text: '% Pos Before',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
        },
        {
            dataField: 'community_share.pct_pos_added',
            text: '% Pos Added',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
        },
        {
            dataField: 'community_share.cnt_pos_comm_detection_before',
            text: '# Pos Comm Detection Before',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cell: any, row: any) => (
                <>
                    <span>{row.community_share.cnt_pos_comm_detection_before.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </>
            )

        },
        {
            dataField: 'community_share.cnt_pos_comm_detection_after',
            text: '# Pos Comm Detection After',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cell: any, row: any) => (
                <>
                    <span>{row.community_share.cnt_pos_comm_detection_after.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </>
            )

        },

        {
            dataField: 'community_share.pct_pos_comm_detection_before',
            text: '% Pos Comm Detect Before',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
        },
        {
            dataField: 'community_share.pct_pos_comm_detection_after',
            text: '% Pos Comm Detect After',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
        },
        {
            dataField: 'community_share.cnt_pos_input_devices',
            text: '# Pos Input Devices',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cell: any, row: any) => (
                <>
                    <span>{row.community_share.cnt_pos_input_devices.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </>
            )

        },
        {
            dataField: 'community_share.pct_pos_input_devices',
            text: '% Pos Input Devices',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
        },
        {
            dataField: 'community_share.pct_labeled_neg',
            text: '% Labeled Neg',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
        },
        {
            dataField: 'community_share.pct_labeled_pos',
            text: '% Labeled Pos',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
        },
        {
            dataField: 'community_share.cnt_additional_ids',
            text: 'Additionals Ids',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cell: any, row: any) => (
                <>
                    <span>{row.community_share.cnt_additional_ids.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </>
            )
        }, {
            dataField: 'community_share.cnt_labeled_neg',
            text: '# Labeled Neg',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cell: any, row: any) => (
                <>
                    <span>{row.community_share.cnt_labeled_neg.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </>
            )
        },
        {
            dataField: 'community_share.cnt_labeled_pos',
            text: '# Labeled Pos',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cell: any, row: any) => (
                <>
                    <span>{row.community_share.cnt_labeled_pos.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </>
            )
        },

        {
            dataField: 'community_share.cnt_pos_added_comm_detection',
            text: '# Pos Added Comm Detection',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cell: any, row: any) => (
                <>
                    <span>{row.community_share.cnt_pos_added_comm_detection.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </>
            )
        },
        {
            dataField: 'community_share.pct_pos_added_comm_detection',
            text: '% Pos Added Comm Detect',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
        },
        {
            dataField: 'tagging_summary.items',
            text: 'Items',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            }
        },
        {
            dataField: 'tagging_summary.untagged',
            text: 'Untagged',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            }
        },
        {
            dataField: 'tagging_summary.positive',
            text: 'Positive',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            }
        },
        {
            dataField: 'tagging_summary.negative',
            text: 'Negative',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            }
        },
        {
            dataField: 'tagging_summary.uncertain',
            text: 'Uncertain',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            }
        },
        {
            dataField: 'represented_segments',
            text: 'Represented Segments',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            sortFunc: (a: string, b: string, order: string) => {
                const regex = /\((.*?)\)/;
                const numberA = parseFloat(a.match(regex)?.at(0)?.replace("(", '').replace(")", '') ?? '')
                const numberB = parseFloat(b.match(regex)?.at(0)?.replace("(", '').replace(")", '') ?? '')

                if (order === 'asc') {
                    return numberB - numberA
                } else {
                    return numberA - numberB
                }
            }
        },

    ];

    const rowEvents = {
        onClick: (e: any, row: any, rowIndex: number) => {
            dispatch(resetStore())
            navigate(`/community/job/segment/${row.community_type === 'POS' ? 'positive' : 'negative'}/${jobId}/${row.community_share.comm_detection_idx}`)
        },
    };


    const communityJobBreadcrumbs: Array<PageLink> = [
        {
            title: 'Home /',
            path: '/dashboard/apps',
            isSeparator: false,
            isActive: false,
        },
        {
            title: 'Community Detection /',
            path: '/community/all',
            isSeparator: false,
            isActive: false,
        },
    ]


    useLayoutEffect(() => {
        sessionStorage.setItem(SessionStorage.cJob + jobId, JSON.stringify({...jobCtx, perPage: String(perPage)}))
    }, [perPage])

    if (!commJobList) {
        return null
    }

    const options = {
        page: page,
        sizePerPage: perPage,
        showTotal: true,
        disablePageTitle: true,
        sizePerPageList: [10, 25, 50],
        onPageChange: (value: number) => {
            setPage(value)
            sessionStorage.setItem(SessionStorage.cJob + jobId, JSON.stringify({...jobCtx, page: String(value)}))
        },
        onSizePerPageChange: (value: number) => {
            setPerPage(value)
            setPage(1)
            sessionStorage.setItem(SessionStorage.cJob + jobId, JSON.stringify({...jobCtx, page: '1'}))
        }
    };

    const selectRow = {
        mode: 'checkbox',
        dbClickToSelect: true,
        selected: targetArray,
        onSelect: handleOnSelect,
        onSelectAll: handleOnSelectAll,
        headerColumnStyle: {padding: '5px', paddingLeft: '10px'},
        selectColumnStyle: {
            padding: '5px',
            borderBottom: '.5px solid #d0d0d0',
            verticalAlign: 'middle',
            textAlign: "center",
            paddingLeft: '10px',
            paddingBottom: '10px'
        },
    };

    const dateFormatter = (date: any) => {
        let newDate = date
            .replace(/[-]/g, '/')
            .replace(/[T]/g, ' ');
        let jsDate = newDate.slice(0, 16);
        return jsDate
    }

    useEffect(() => {
        const filter: any = {};
        if (selectStatus !== '0' || selectDecision !== '0' || selectIdx !== '0') {
            setIsFilterActive(true)
        } else {
            setIsFilterActive(false)
        }
        if (selectStatus !== '0') {
            filter.community_status = selectStatus
        }
        if (selectDecision !== '0') {
            filter.community_decision = selectDecision
        }
        if (selectIdx !== '0') {
            filter.community_share = selectIdx
        }
        if (Object.keys(filter).length === 0) {
            return
        }
        const filteredCommList = communityJobCommList.filter((com) => {
            if (!com) {
                return false
            }
            return !Object.keys(filter).some(key => {
                if (key === 'community_share') {
                    return !(com[key]['comm_detection_idx'] === undefined || com[key]['comm_detection_idx'] === filter[key]);
                }
                if (key === "community_status" || key === 'community_decision') {
                    return !(com[key] === undefined || com[key] === filter[key]);
                }
            })
        })
        setCommJobListFiltered(filteredCommList)
    }, [selectStatus, selectDecision, selectIdx, communityJobCommList])


    return (
        <>
            <LongFileModal file={fileToWatch} handleCloseModal={() => setLongFileModal(false)} show={longFileModal}
                           title={fileTitle}/>
            <SummaryTableModal show={handleSummaryTable} handleCloseModal={() => setHandleSummaryTable(false)}
                               data={summaryTable}/>
            <PageTitle breadcrumbs={communityJobBreadcrumbs}>Community Job</PageTitle>

            {communityJob.job_short_summary && communityJobCommList ? (
                <>
                    <CSSTransition
                        in={showDetails}
                        timeout={300}
                        classNames="detail"
                        // onExited={() => setIsFadingOut(false)}
                        unmountOnExit
                    >
                        <>

                            <div className={'comm_details'}>
                                <div className={'comm_block_top_part'}>
                                    <h5>Community Details :</h5>
                                    <div>
                                        <Button onClick={() => {
                                            dispatch(preDeployComm(Number(jobId)))
                                            setIsDeploying(true)
                                        }} disabled={tableState === 'NEG'}>
                                            <KTSVG
                                                path='/media/icons/duotune/arrows/arr078.svg'
                                                className='svg-icon-edit svg-icon-1'
                                            />Deploy
                                        </Button>
                                        <Button style={{marginLeft: '15px'}} onClick={() => {
                                            setConfirmationModal(true)
                                        }} disabled={tableState === 'NEG'}>
                                            <KTSVG
                                                path={`/media/icons/duotune/files/fil01${communityJob?.job_short_summary?.archived ? '8' : '7'}.svg`}
                                                className='svg-icon-context svg-icon-2'
                                            />
                                            {communityJob?.job_short_summary?.archived ? 'UnArchive' : 'Archive'}
                                        </Button>
                                    </div>
                                </div>
                                <div className={'comm_details'}>
                                    <div className={'comm_block_title_2'}>
                                        <div>
                                            <Label>Date:</Label>
                                            <p>{dateFormatter(communityJob?.job_short_summary.creation_time)}</p>
                                        </div>
                                        <div>
                                            <Label>App Name:</Label>
                                            <p>{communityJob?.job_short_summary.customer_app_name}</p>
                                        </div>
                                        <div>
                                            <Label>Platform:</Label>
                                            <p>{communityJob?.job_short_summary.platform}</p>
                                        </div>
                                        <div>
                                            <Label>App Token:</Label>
                                            <p>{communityJob?.job_short_summary.customer_app_token}</p>
                                        </div>
                                        <div>
                                            <Label>Source Job:</Label>
                                            <br/>
                                            <a href={communityJob?.job_short_summary.source_job_url}>{communityJob?.job_short_summary.source_job_url.slice(0, 40)}</a>
                                        </div>
                                    </div>
                                    <div className={'comm_block_title'}>
                                        <div>
                                            <Label>Total Segments:</Label>
                                            <p>{communityJob?.job_short_summary.cnt_total}</p>
                                        </div>
                                        <div>
                                            <Label>Positive Before:</Label>
                                            <p>{communityJob?.job_short_summary.cnt_pos_before}</p>
                                        </div>
                                        <div>
                                            <Label>Positive After:</Label>
                                            <p>{communityJob?.job_short_summary.cnt_pos_after}</p>
                                        </div>
                                        <div>
                                            <Label>Community Detection Positive:</Label>
                                            <p>{communityJob?.job_short_summary.cnt_comm_detection_pos}</p>
                                        </div>
                                        <div>
                                            <Label>Positive Sessions:</Label>
                                            <p>{communityJob?.job_short_summary.cnt_sessions}</p>
                                        </div>
                                    </div>
                                    <div className={'comm_block_title_3'}>
                                        <div>
                                            <Label>Job Params:</Label>
                                            <p>{JSON.stringify(communityJob?.job_params).slice(0, 30)}
                                                <OverlayTrigger
                                                    placement='bottom'
                                                    overlay={<Tooltip>See More</Tooltip>}
                                                    defaultShow={undefined}
                                                    delay={undefined}
                                                    flip={undefined}
                                                    onHide={undefined}
                                                    onToggle={undefined}
                                                    popperConfig={undefined}
                                                    show={undefined}
                                                    target={undefined}
                                                    trigger={undefined}>
                                                    <b onClick={() => handleLongFile('JOB')}
                                                       className={'three_dots'}>...
                                                    </b>
                                                </OverlayTrigger>
                                            </p>
                                        </div>
                                        <div>
                                            <Label>Model Info:</Label>
                                            <div>
                                                <p>{JSON.stringify(communityJob?.model_info).slice(0, 40)}
                                                    <OverlayTrigger
                                                        placement='bottom'
                                                        overlay={<Tooltip>See More</Tooltip>}
                                                        defaultShow={undefined}
                                                        delay={undefined}
                                                        flip={undefined}
                                                        onHide={undefined}
                                                        onToggle={undefined}
                                                        popperConfig={undefined}
                                                        show={undefined}
                                                        target={undefined}
                                                        trigger={undefined}>
                                                        <b onClick={() => handleLongFile('MODEL')}
                                                           className={'three_dots'}>...
                                                        </b>
                                                    </OverlayTrigger>
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            <Label>Triggered By:</Label>
                                            <br/>
                                            <p>{communityJob.job_short_summary.source_job_name}</p>
                                        </div>
                                        <div>
                                            <Label>Run Name:</Label>
                                            <br/>
                                            <p>{communityJob.job_short_summary.run_name}</p>
                                        </div>
                                        <div>
                                            <Label>Diff Existing & New Models:</Label>
                                            <br/>
                                            <p>{communityJob?.diff_existing_and_new_models !== null ? JSON.stringify(communityJob?.diff_existing_and_new_models).slice(0, 40) : 'No Data'}
                                                {communityJob?.diff_existing_and_new_models !== null &&
                                                    <OverlayTrigger
                                                        placement='bottom'
                                                        overlay={<Tooltip>See More</Tooltip>}
                                                        defaultShow={undefined}
                                                        delay={undefined}
                                                        flip={undefined}
                                                        onHide={undefined}
                                                        onToggle={undefined}
                                                        popperConfig={undefined}
                                                        show={undefined}
                                                        target={undefined}
                                                        trigger={undefined}>
                                                        <b onClick={() => handleLongFile('DIFF')}
                                                           className={'three_dots'}>...
                                                        </b>
                                                    </OverlayTrigger>
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    <div className={'comm_block_title_4'}>
                                        <div className={'output_table_container'}>
                                            <Label>Output Table:</Label>
                                            <br/>
                                            <OverlayTrigger
                                                placement='bottom'
                                                overlay={<Tooltip>{communityJob.output_table}</Tooltip>}
                                                defaultShow={undefined}
                                                delay={undefined}
                                                flip={undefined}
                                                onHide={undefined}
                                                onToggle={undefined}
                                                popperConfig={undefined}
                                                show={undefined}
                                                target={undefined}
                                                trigger={undefined}>
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    flexDirection: 'row',
                                                }}>
                                                    <div className={'seg_long_copy'}>{communityJob.output_table}</div>
                                                    <div className={'copy_btn'} onClick={() => {
                                                        navigator.clipboard.writeText(communityJob.output_table).then(() => {
                                                            toast.info('Copied to clipboard!')
                                                        })

                                                    }}>
                                                        <KTSVG
                                                            path='/media/icons/duotune/general/gen054.svg'
                                                            className='svg-icon-edit svg-icon-2'
                                                            loader={false}
                                                        />
                                                    </div>
                                                </div>
                                            </OverlayTrigger>
                                        </div>
                                        <div>
                                            <Label>Status:</Label>
                                            <select style={{width: '200%'}} value={jobStatus}
                                                    onChange={(e) => setJobStatus(e.target.value as CommunityDetectionStatus)}>
                                                {StaticValues.communityStatus.map((status: any) => (
                                                    <option key={status.value}
                                                            value={status.value}>{status.label}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className={'comment_container'}>
                                            <Label>Comment</Label>
                                            <br/>
                                            <textarea value={selectedComment} onChange={EvidenceCommentHandleChange}
                                                      className="comment_section" name="" id="" cols={80}
                                                      rows={2}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br/>
                        </>
                    </CSSTransition>


                    <div className="comm_job_table">
                        <ToolkitProvider
                            keyField="community_id"
                            data={isFilterActive ? commJobListFiltered : commJobList ? commJobList : []}
                            columns={columns}
                            search
                            bootstrap4
                        >
                            {
                                (props: any) => (
                                    <div style={{padding: '15px'}}>
                                        <div>
                                            <div className={'toolbar_table'}>
                                                <div>
                                                    <Link to={`/community/job/segment/positive/${jobId}/1`}>
                                                        <Button
                                                            style={{marginRight: '10px'}}
                                                            onClick={() => dispatch(resetStore())}
                                                        >
                                                            <KTSVG
                                                                path='/media/icons/duotune/arrows/arr095.svg'
                                                                className='svg-icon-edit svg-icon-2'
                                                            />
                                                            Positive Segments</Button>
                                                    </Link>
                                                    <Link to={`/community/job/segment/negative/${jobId}/1`}>
                                                        <Button
                                                            onClick={() => dispatch(resetStore())}

                                                        >
                                                            <KTSVG
                                                                path='/media/icons/duotune/arrows/arr095.svg'
                                                                className='svg-icon-edit svg-icon-2'
                                                            />
                                                            Negative Segments</Button>
                                                    </Link>
                                                </div>
                                                <div>
                                                    <Button style={{marginRight: '10px'}}
                                                            onClick={() => setShowDetails(prevState => {
                                                                const newVal = !prevState
                                                                sessionStorage.setItem(SessionStorage.cJob + jobId, JSON.stringify({
                                                                    ...jobCtx,
                                                                    details: String(newVal)
                                                                }))
                                                                return newVal
                                                            })}>
                                                        <KTSVG
                                                            path='/media/icons/duotune/arrows/arr032.svg'
                                                            className='svg-icon-edit svg-icon-2'
                                                        />
                                                        Details</Button>
                                                    <Button onClick={() => setHandleSummaryTable(true)}
                                                            style={{marginRight: '10px'}}>
                                                        <KTSVG
                                                            path='/media/icons/duotune/files/fil002.svg'
                                                            className='svg-icon-edit svg-icon-2'
                                                        />
                                                        Summary Table</Button>
                                                    <Button
                                                        onClick={() => dispatch(getCommunityJobComm(Number(jobId), tableState === 'POS' ? CommunityType.POS : CommunityType.NEG))}
                                                        style={{marginRight: '10px'}}>
                                                        <KTSVG
                                                            path='/media/icons/duotune/arrows/arr029.svg'
                                                            className='svg-icon-edit svg-icon-2'
                                                        />
                                                        Refresh</Button>
                                                    <Button onClick={() => switchTable()}>
                                                        <KTSVG
                                                            path='/media/icons/duotune/abstract/abs015.svg'
                                                            className='svg-icon-edit svg-icon-2'
                                                        />
                                                        {tableState === 'POS' ? 'Show Negative Communities' : 'Show Positive Communities'}
                                                    </Button>
                                                </div>

                                            </div>
                                            <br/>
                                            <div className={'toolbar_table_community'}>
                                                <div>
                                                    <SearchBar {...props.searchProps} />
                                                </div>
                                                <div className="flex_row_space">
                                                    <div style={{marginRight: '10px'}}>
                                                        <select
                                                            onChange={(e) => {
                                                                setSelectStatus(e.target.value)
                                                                sessionStorage.setItem(SessionStorage.cJob + jobId, JSON.stringify({
                                                                    ...jobCtx,
                                                                    status: e.target.value,
                                                                }))
                                                                setPage(1)

                                                            }}
                                                            name="" id="" value={selectStatus}>
                                                            <option value="0">- Status -</option>
                                                            {StaticValues.communityJobStatus.map((dec: any) => (
                                                                <option key={dec.value}
                                                                        value={dec.value}>{dec.label}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div style={{marginRight: '10px'}}>
                                                        <select
                                                            onChange={(e) => {
                                                                setSelectDecision(e.target.value)
                                                                sessionStorage.setItem(SessionStorage.cJob + jobId, JSON.stringify({
                                                                    ...jobCtx,
                                                                    decision: e.target.value,
                                                                }))
                                                                setPage(1)

                                                            }}
                                                            name="" id="" value={selectDecision}>
                                                            <option value="0">- Decision -</option>
                                                            {StaticValues.communityDecision.map((dec: any) => (
                                                                <option key={dec.value}
                                                                        value={dec.value}>{dec.label}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div style={{marginRight: '10px'}}>
                                                        <select
                                                            onChange={(e) => {
                                                                setSelectIdx(e.target.value)
                                                                sessionStorage.setItem(SessionStorage.cJob + jobId, JSON.stringify({
                                                                    ...jobCtx,
                                                                    idx: e.target.value,
                                                                }))
                                                                setPage(1)
                                                            }}
                                                            name="" id="" value={selectIdx}>
                                                            <option value="0">- Comm Detection Idx -</option>
                                                            {communityIdx.sort().map((dec: any) => (
                                                                    <option key={dec} value={dec}>{dec}</option>
                                                                )
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="flex_row_space">
                                                    <div>
                                                        <select
                                                            onChange={(e) => setDecision(e.target.value as CommunityDecision)}
                                                            name="" id="">
                                                            {StaticValues.communityDecision.map((dec: any) => (
                                                                <option key={dec.value}
                                                                        value={dec.value}>{dec.label}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div>
                                                        <Button
                                                            style={{marginLeft: '10px'}}
                                                            disabled={!multipleSelection}
                                                            onClick={() => {
                                                                if (multipleSelection) {
                                                                    dispatch(updateDecision(Number(jobId), {
                                                                        "decision": decision,
                                                                        "update_list": targetArray
                                                                    }))
                                                                    setTargetArray([])
                                                                }
                                                            }}>
                                                            Update
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                            <br/>
                                        </div>
                                        {(commJobListFiltered?.length > 0) || (commJobList.length > 0 || !isFilterActive) ? (
                                            <Row>
                                                <Col xl='12'
                                                     className={`job_table ${tableClasses.table_wrapper}`}>
                                                    <BootstrapTable
                                                        {...props.baseProps}
                                                        keyField='community_id'
                                                        pagination={paginationFactory(options)}
                                                        bordered={true}
                                                        responsive
                                                        stickyHeader
                                                        stripped
                                                        headerWrapperClasses={"header-class"}
                                                        selectRow={selectRow}
                                                        rowEvents={rowEvents}
                                                    />
                                                </Col>
                                            </Row>

                                        ) : (
                                            <div className={'no_results'}>
                                                <div>
                                                    <h3>No results found</h3>
                                                </div>
                                            </div>
                                        )

                                        }
                                    </div>
                                )
                            }
                        </ToolkitProvider>
                    </div>

                </>
            ) : (
                <p>Loading...</p>
            )
            }
            {/*@ts-ignore*/}
            <SweetAlert
                show={confirmationModal}
                warning
                showCancel
                confirmBtnText="Yes"
                cancelBtnText="Cancel"
                confirmBtnBsStyle={'danger'}
                title={`Warning`}
                onConfirm={() => {
                    dispatch(updateCommDetails(Number(jobId), {"archived": !isArchive}))
                    setConfirmationModal(false)
                    setTimeout(() => navigate(`/community/all`), 500)
                }
                }
                onCancel={() => {
                    setConfirmationModal(false)
                }}
            >
                <p>Are you sure you want to {communityJob?.job_short_summary?.archived ? 'unarchive' : 'archive'} this
                    community detection job?</p>
            </SweetAlert>
            {/*@ts-ignore*/}
            <SweetAlert
                show={warningPopup}
                warning
                confirmBtnText="Okay"
                confirmBtnBsStyle={'danger'}
                title={`Updated Communities Status`}
                onConfirm={() => {
                    setWarningPopup(false)
                    setFirstRender(false)
                    setWarningHasBeenSeen(true)
                    dispatch(getCommunityJobComm(Number(jobId), tableState === 'POS' ? CommunityType.POS : CommunityType.NEG))
                }}
            >
                {communityJobGeneral &&
                    <>
                        {communityJobGeneral?.refresh_status?.existing_to_removed.length > 0 &&
                            <p>
                                <b>{communityJobGeneral?.refresh_status?.existing_to_removed.length}</b> {communityJobGeneral?.refresh_status?.existing_to_removed.length > 1 ? ' communities' : ' community'} status
                                changed
                                from “existing” to “new”
                                since {communityJobGeneral?.refresh_status?.existing_to_removed.length > 1 ? 'they were' : 'it was'} not
                                found in production. Decision changed to
                                “waiting for review”.
                                {communityJobGeneral?.refresh_status?.existing_to_removed.length > 1 ? ' Communities' : ' Community'}: <b>{JSON.stringify(communityJobGeneral?.refresh_status?.existing_to_removed)}</b>
                            </p>
                        }
                        {communityJobGeneral?.refresh_status?.new_to_existing.length > 0 &&
                            <p>
                                <b>{communityJobGeneral?.refresh_status?.new_to_existing.length}</b> {communityJobGeneral?.refresh_status?.new_to_existing.length > 1 ? ' communities' : ' community'} status
                                changed from “new” to “existing”.
                                {communityJobGeneral?.refresh_status?.new_to_existing.length > 1 ? ' Communities' : ' Community'}: <b>{JSON.stringify(communityJobGeneral?.refresh_status?.new_to_existing)}</b>
                            </p>
                        }
                    </>
                }
            </SweetAlert>
            {/*@ts-ignore*/}
            <SweetAlert
                show={deployPopup}
                success
                confirmBtnText="Great !"
                confirmBtnBsStyle={'primary'}
                title={`Community Deploy`}
                onConfirm={() => {
                    setDeployPopup(false)
                    setDeployText(null)
                    setFullList(false)
                }}
            >
                {deployText &&
                    <>
                        {deployText?.approved_new_list?.length > 0 &&
                            <p>
                                <b>{deployText?.approved_new_list?.length}</b> {deployText?.approved_new_list?.length > 1 ? 'communities have' : 'community has'} been
                                added to production :
                                {!fullList &&
                                    <b>{JSON.stringify(deployText?.approved_new_list?.slice(0, 30))}{deployText?.approved_new_list?.length > 30 ?
                                        <>
                                            <OverlayTrigger
                                                placement='right'
                                                overlay={<Tooltip className={'tt_sm'}>See More</Tooltip>}
                                                defaultShow={undefined}
                                                delay={undefined}
                                                flip={undefined}
                                                onHide={undefined}
                                                onToggle={undefined}
                                                popperConfig={undefined}
                                                show={undefined}
                                                target={undefined}
                                                trigger={undefined}>
                                                <span className={'see_more_dots'}
                                                      onClick={() => setFullList(true)}>...</span>
                                            </OverlayTrigger>
                                        </>
                                        : ''}</b>
                                }
                                {fullList &&
                                    <b>{JSON.stringify(deployText?.approved_new_list)}</b>
                                }
                            </p>
                        }
                        {deployResponse?.rejected_new_list?.length > 0 &&
                            <p>
                                <b>{deployText?.rejected_new_list?.length}</b> {deployText?.rejected_new_list?.length > 1 ? 'communities have' : 'community has'} been
                                rejected and removed from production
                                :
                                {!fullList &&
                                    <b>{JSON.stringify(deployText?.rejected_new_list.slice(0, 30))}{deployText?.rejected_new_list?.length > 30 ?
                                        <>
                                            <OverlayTrigger
                                                placement='right'
                                                overlay={<Tooltip className={'tt_sm'}>See More</Tooltip>}
                                                defaultShow={undefined}
                                                delay={undefined}
                                                flip={undefined}
                                                onHide={undefined}
                                                onToggle={undefined}
                                                popperConfig={undefined}
                                                show={undefined}
                                                target={undefined}
                                                trigger={undefined}>
                                                <span className={'see_more_dots'}
                                                      onClick={() => setFullList(true)}>...</span>
                                            </OverlayTrigger>
                                        </>
                                        : ''}</b>
                                }
                                {fullList &&
                                    <b>{JSON.stringify(deployText?.rejected_new_list)}</b>
                                }
                            </p>
                        }
                    </>
                }
            </SweetAlert>
            {/*@ts-ignore*/}
            <SweetAlert
                show={negativeActions}
                warning
                confirmBtnText="Okay"
                confirmBtnBsStyle={'danger'}
                title={`Action Forbidden`}
                onConfirm={() => {
                    setNegativeActions(false)
                }
                }
            >
                <p>Negative Data cannot be modified !</p>
            </SweetAlert>
            {/*@ts-ignore*/}
            <SweetAlert
                show={preDeployCommunity}
                warning
                showCancel
                disabled={preDeployWarning?.rejected_new_list?.length == 0 && preDeployWarning?.approved_new_list?.length == 0}
                confirmBtnText="Great, Deploy !"
                cancelBtnText="Cancel"
                confirmBtnBsStyle={'success'}
                cancelBtnBsStyle={'danger'}
                title={`Deploy Warning`}
                onConfirm={() => {
                    setPreDeployCommunity(false)
                    dispatch(deployComm(Number(jobId)))
                    setIsDeploying(false)
                    setDeployHasBeenMade(result)
                    setFullList(false)
                }
                }
                onCancel={() => {
                    setPreDeployCommunity(false)
                    setIsDeploying(false)
                    setFullList(false)
                }}
            >
                {preDeployWarning.approved_existing_list &&
                    <>
                        {preDeployWarning?.rejected_new_list.length > 0 &&
                            <p>
                                <b>{preDeployWarning?.rejected_new_list.length}</b> existing {preDeployWarning?.rejected_new_list.length > 1 ? 'communities are' : 'community is'} about
                                to
                                be removed from production
                                {!fullList &&
                                    <b>{[JSON.stringify(preDeployWarning?.rejected_new_list.slice(0, 30))]}{preDeployWarning?.rejected_new_list.length > 30 ?
                                        <>
                                            <OverlayTrigger
                                                placement='right'
                                                overlay={<Tooltip className={'tt_sm'}>See More</Tooltip>}
                                                defaultShow={undefined}
                                                delay={undefined}
                                                flip={undefined}
                                                onHide={undefined}
                                                onToggle={undefined}
                                                popperConfig={undefined}
                                                show={undefined}
                                                target={undefined}
                                                trigger={undefined}>
                                                <span className={'see_more_dots'}
                                                      onClick={() => setFullList(true)}>...</span>
                                            </OverlayTrigger>
                                        </>
                                        : ''}</b>
                                }
                                {fullList &&
                                    <>
                                        <b>{[JSON.stringify(preDeployWarning?.rejected_new_list)]}</b>
                                    </>
                                }
                            </p>
                        }
                        {preDeployWarning?.approved_new_list.length > 0 &&
                            <p>
                                <b>{preDeployWarning?.approved_new_list.length}</b> new {preDeployWarning?.approved_new_list.length > 1 ? 'communities are' : 'community is'} about
                                to
                                be added to production
                                {!fullList &&
                                    <b>{[JSON.stringify(preDeployWarning?.approved_new_list.slice(0, 30))]}{preDeployWarning?.approved_new_list.length > 30 ?
                                        <>
                                            <OverlayTrigger
                                                placement='right'
                                                overlay={<Tooltip className={'tt_sm'}>See More</Tooltip>}
                                                defaultShow={undefined}
                                                delay={undefined}
                                                flip={undefined}
                                                onHide={undefined}
                                                onToggle={undefined}
                                                popperConfig={undefined}
                                                show={undefined}
                                                target={undefined}
                                                trigger={undefined}>
                                                <span className={'see_more_dots'}
                                                      onClick={() => setFullList(true)}>...</span>
                                            </OverlayTrigger>
                                        </>
                                        : ''}</b>
                                }
                                {fullList &&
                                    <>
                                        <b>{[JSON.stringify(preDeployWarning?.approved_new_list)]}</b>
                                    </>
                                }
                            </p>
                        }
                        {preDeployWarning?.rejected_new_list.length == 0 && preDeployWarning?.approved_new_list.length == 0 &&
                            <p>No approved nor rejected communities have been found for deployment</p>
                        }
                    </>
                }
            </SweetAlert>
        </>
    )
}

export default CommunityJob

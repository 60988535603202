import React, {useEffect, useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import {KTSVG} from "../../../../_metronic/helpers";
import {MultiSelect} from "react-multi-select-component";
import StaticValues from "../../../../_metronic/helpers/StaticValues";
import './apps-modals.scss'
import styles from '../../../general-styles/Modals/modals.module.scss'
import {Label} from "reactstrap";
import {createApp, updateApp} from "../../../../store/actions";
import {useDispatch} from "react-redux";
import {AppMode, AppPlan, AppResponse, IntegrationType} from "../../../../AUTO_GENERATED_TYPES";


interface ValueAndLabel {
    value: number | string,
    label:string
}



interface NewAppProps {
    show: boolean,
    handleClose: () => void,
    isEdit?: boolean,
    currentApp?: AppResponse
}
const staticValues = new StaticValues()

const NewAppModal = ({show, handleClose, isEdit, currentApp}: NewAppProps) => {

    const dispatch = useDispatch();
    const options = StaticValues.appCategory
    const optionsPlatform = StaticValues.SDKType

    const [appName, setAppName] = useState<string>('')
    const [appToken, setAppToken] = useState<string>('')
    const [autoToken, setAutoToken] = useState<boolean>(false)
    const [verify, setVerify] = useState<boolean>(false)
    const [disable, setDisable] = useState<boolean>(true)
    const [validImage, setValidImage] = useState<boolean>(true)
    const [company, setCompany] = useState<string | undefined>('')
    const [plan, setPlan] = useState<AppPlan | undefined >(AppPlan.BASIC)
    const [mode, setMode] = useState<AppMode | undefined >(AppMode.TESTING)
    const [integration, setIntegration] = useState<IntegrationType | undefined >(IntegrationType.BASIC)
    const [selectedCategory, setSelectedCategory] = useState<(ValueAndLabel | '' | undefined)[]>([]);
    const [selectedPlatform, setSelectedPlatform] = useState<(ValueAndLabel | '' | undefined)[]>([]);
    const [category, setCategory] = useState<any>([]);
    const [platform, setPlatform] = useState<any>([]);
    const [selectedImg, setSelectedImg] = useState<any>();
    const [fileName, setFileName] = useState<string | null>('');
    const [existingImg, setExistingImg] = useState<boolean>(false);

    useEffect(() => {
        if (isEdit &&  currentApp ) {
            setAppName(currentApp.app_name)
            setSelectedCategory(currentApp!.app_category!.map((i: any) => staticValues.getAppCategory(i)))
            setSelectedPlatform(currentApp!.platforms!.map((i: any) => staticValues.getSDKType(i)))
            setMode(currentApp?.app_mode ?? undefined)
            setCompany(currentApp?.company_name ?? undefined)
            setPlan(currentApp?.plan ?? undefined)
            setIntegration(currentApp?.integration_type ?? undefined)
            setAppToken(currentApp.app_token)
            if (currentApp.app_image) {
                setExistingImg(true)
            }
        }
    }, [currentApp,isEdit]);

    useEffect(() => {
        setCategory(selectedCategory.map((cat: any) => cat.value))
        setPlatform(selectedPlatform.map((plat: any) => plat.value))
    }, [selectedCategory, selectedPlatform])

    useEffect(() => {
        if (appName.length > 0 && (appToken.length > 0 || autoToken) && platform.length > 0) {
            setVerify(false)
            setDisable(false)
        } else {
            setDisable(true)
        }
    }, [appName, appToken, platform, autoToken])


    const convertToBase64 = (file: any) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        });
    };

    const handleFileUpload = async (e: any) => {
        if (e.target.files[0]) {
            setFileName(e.target.files[0].name)
            const file = e.target.files[0];
            const fileSize = e.target.files[0].size / 1024 / 1024
            if (fileSize < 1) {
                setValidImage(false)
                const base64 = await convertToBase64(file);
                setSelectedImg(base64);
            } else {
                setValidImage(true)
                alert('Image format not supported')
                setSelectedImg('')
            }
        } else {
            setValidImage(false)
        }

    };

    const handleCloseModal = () => {
        setAutoToken(false)
        setVerify(false)
        setValidImage(true)
        setFileName(null)
        handleClose()
    }

    const resetAllStates = React.useCallback(()=>{
        setAppName('')
        setAppToken('')
        setAutoToken(false)
        setVerify(false)
        setDisable(true)
        setValidImage(true)
        setCompany('')
        setPlan(AppPlan.BASIC)
        setMode(AppMode.TESTING)
        setIntegration(IntegrationType.BASIC)
        setSelectedCategory([])
        setSelectedPlatform([])
        setCategory([])
        setPlatform([])
        setSelectedImg(null)
        setFileName(null)
        setExistingImg(false)
    },[])

    const newApp = (e: any) => {
        e.preventDefault()
        if (isEdit) {
            let updateAppData = {
                'app_id': currentApp!.app_id,
                "app_name": appName,
                "platforms": platform,
                "app_token": appToken,
                "app_category": category,
                "company_name": company,
                "plan": plan,
                "app_image": selectedImg,
                "app_mode": mode,
                "integration_type": integration,
            }
            dispatch(updateApp(updateAppData as AppResponse, updateAppData.app_id))
            handleCloseModal()
        } else {
            let appData = {
                "app_name": appName,
                "platforms": platform,
                "app_token": autoToken ? undefined : appToken,
                "app_category": category,
                "company_name": company,
                "app_image": selectedImg,
                "plan": plan,
                "app_mode": mode,
                "integration_type": integration
            }
            dispatch(createApp(appData as AppResponse))
            handleCloseModal()
            resetAllStates()
        }
    }

    return (
        <>
            <Modal
                show={show}
                onHide={handleCloseModal}
                centered
                backdrop="static"
            >
                <div>
                    <div className='modal-content'>
                        <div className={styles.flex_row_between}>
                            <div className={styles.title_close_space}>
                                <h2>{isEdit ? <span style={{fontWeight: 400}}>Update {appName} </span> :
                                    <span style={{fontWeight: 400}}>Create a new App</span>}</h2>
                            </div>
                            <div className='modal_close_btn'>
                                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                                </div>
                            </div>
                        </div>
                        <form className={'form_container'}>
                            <div className={styles.modal_block_general}>
                                <Label className={styles.label_font_size}>App Name <span className={styles.mandatory_star}>*</span></Label>
                                <input
                                    onBlur={() => setVerify(true)}
                                    onChange={(e) => setAppName(e.target.value)}
                                    className={appName === "" && verify ? styles.borderRed : styles.classic_input}
                                    required
                                    value={appName || ''}
                                    type="text"
                                />
                                <Label className={styles.label_font_size}>App Company</Label>
                                <input value={company || ''} className={styles.classic_input}
                                       onChange={(e) => setCompany(e.target.value)} type="text"/>

                                <Label className={styles.label_font_size}>Platform <span className={styles.mandatory_star}>*</span></Label>
                                <div onBlur={() => setVerify(true)}
                                     className={platform.length <= 0 && verify ? styles.borderRed2 : styles.classic_input}>
                                    <MultiSelect
                                        options={optionsPlatform}
                                        value={selectedPlatform as any}
                                        onChange={setSelectedPlatform}
                                        labelledBy='Ex'
                                        ArrowRenderer={() =>
                                            <div>
                                                <p className={'custom-arrow'}>    &#8964; </p>
                                            </div>
                                        }
                                    />
                                </div>
                                <Label className={styles.label_font_size}>Mode <span className={styles.mandatory_star}>*</span></Label>
                                <select
                                    className={styles.classic_input}
                                    value={mode || ''}
                                    onChange={(e) => setMode(e.target.value as AppMode)}
                                    name=""
                                    id="">
                                    {StaticValues.modeType.map((mode: any) => (
                                        <option key={mode.value} value={mode.value}>{mode.label}</option>
                                    ))}
                                </select>
                                <Label className={styles.label_font_size}>Category</Label>
                                <div className={styles.margin_b_5}>
                                    <MultiSelect
                                        options={options}
                                        value={selectedCategory as any}
                                        onChange={setSelectedCategory}
                                        labelledBy='Ex'
                                        ArrowRenderer={() =>
                                            <div>
                                                <p className={'custom-arrow'}>  &#8964; </p>
                                            </div>
                                        }
                                    />
                                </div>
                                <Label className={'label_font_size'}>Plan <span className={'mandatory_star'}>*</span></Label>
                                <select className='margin_b_5 classic_input' value={plan || ''}
                                        onChange={(e) => setPlan(e.target.value as AppPlan)} name="" id="">
                                    {StaticValues.planType.map((plan: any) => (
                                        <option key={plan.value} value={plan.value}>{plan.label}</option>
                                    ))}
                                </select>

                                <Label className={styles.label_font_size}>Image</Label>
                                <input id='img' className='classic_input' style={{ display:'none'}} onChange={(e) => {
                                    handleFileUpload(e)
                                }} type="file"/>
                                <div className="img_field_border">
                                    <Label className={'img_field_label'} for="img">{(existingImg) ? 'Click here to change' : (validImage)  ? 'No file chosen' : "Click here to change"}</Label>
                                    {!existingImg ? (
                                        <p className={'file_name_text'}>{fileName ? fileName : ''}</p>
                                    ):(
                                        <p className={'file_name_text'}>{fileName ? fileName :'img-logo.png'}</p>
                                    )}
                                </div>
                                <Label className={'label_font_size'}>Integration <span
                                    className={'mandatory_star'}>*</span></Label>
                                <select className='margin_b_5 classic_input'
                                        value={integration || ''} onChange={(e) => setIntegration(e.target.value as IntegrationType)}
                                        name="" id="">
                                    {StaticValues.integrationType.map((integration: any) => (
                                        <option key={integration.value} value={integration.value}>{integration.label}</option>
                                    ))}
                                </select>
                                <div className={'token_field'}>
                                    <Label className={styles.label_font_size}>Token <span
                                        className={styles.mandatory_star}>*</span></Label>
                                    {!isEdit &&
                                        <div className={'auto_token_layout'}>
                                            <input style={{marginRight: '10px'}}
                                                   onChange={() => setAutoToken(prevState => !prevState)}
                                                   type="checkbox"/>
                                            <Label className={'auto_token_label'}>Auto Generate
                                                Token</Label>
                                        </div>
                                    }
                                </div>
                                <input
                                    disabled={isEdit || autoToken}
                                    value={appToken || ''}
                                    required
                                    onBlur={() => setVerify(true)}
                                    className={appToken === "" && verify && !autoToken ? styles.borderRed : styles.classic_input}
                                    style={{marginBottom: '8px'}}
                                    onChange={(e) => setAppToken(e.target.value)}
                                    type="text"/>
                                <div className={styles.confirm_btn}>
                                    <Button disabled={disable} onClick={(e) => newApp(e)}
                                            type='submit'>{isEdit ? 'Update' : 'Create'}
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default NewAppModal

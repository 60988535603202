/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum AppFilter {
    ACTIVE = 'ACTIVE',
    ARCHIVED = 'ARCHIVED',
    ALL = 'ALL',
}

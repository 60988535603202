import {getFilterList} from "../../../../_metronic/helpers/backend_helper";
import {FilterDefinitionResponse, SegmentType} from "../../../../AUTO_GENERATED_TYPES";
import {useQuery} from "react-query";
import QueryKeys from "../../../../react-query/QueryKeys";

const createFilterListOptions = (filters?: Array<FilterDefinitionResponse>): { label: string, value: number | '' }[] => {
    if (!filters) {
        return [{label: '', value: ''}]
    }
    return filters.map(filter => ({label: filter.short_name, value: filter.id}))
}


const useGetFilterList = (cdId: number, segmentType: string, enabled = true) => {
    const {data: filterList} = useQuery([QueryKeys.FilterDefinition, segmentType], () => getFilterList(cdId, segmentType === 'positive' ? SegmentType.POS : SegmentType.NEG), {
        cacheTime: Infinity,
        staleTime: Infinity,
        enabled
    })


    if (!filterList) {
        return {definitions: null, getOperators: null}
    }

    const createOperatorsOptions = (columnName: string) => {
        return filterList.filters?.find(filter => filter.column_name === columnName)?.operators.map(operator => ({
            label: operator,
            value: operator
        }))
    }

    return {definitions: createFilterListOptions(filterList.filters), getOperators: createOperatorsOptions, filterList}
}

export default useGetFilterList

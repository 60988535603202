/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum GroupMode {
    ALL = 'ALL',
    LIVE = 'LIVE',
    TEST = 'TEST',
    DEMO = 'DEMO',
}

import axios from "axios"

//Static
import StaticValues from "./StaticValues";
import {toast} from "react-toastify";

//apply base url for axios

axios.interceptors.request.use(function (config) {
    // Set the timeout to 5 seconds
    config.timeout = 300000;
    return config;
});

let path;
axios.interceptors.request.use(function (config) {
    path = new URL(config.url).pathname;
    console.log(`Request path: ${path}`);
    return config;
});

// axios.interceptors.responseError.use(function (error) {
//     if (error.response) {
//         // The request was made and the server responded with a status code
//         // that falls out of the range of 2xx
//         console.log(error.response.data);
//         console.log(error.response.status);
//         console.log(error.response.headers);
//     } else if (error.request) {
//         // The request was made but no response was received
//         // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
//         // http.ClientRequest in node.js
//         console.log(error.request);
//     } else {
//         // Something happened in setting up the request that triggered an Error
//         console.log('Error', error.message);
//     }
//     console.log(error.config);
//
//     // You can return a new promise here
//     return Promise.reject(error);
// });


axios.interceptors.response.use(
    response => {
        return response;
    }, error => {
        console.log({error})
        if (error.response.timeout && error.code === 'ERR_NETWORK')
        {
            toast.error("Connection Failed to host: " + 'http://localhost:8080' +  " Please retry later. ")
            localStorage.removeItem(StaticValues.AuthUserQuago)
            setTimeout(() => window.location.href = "/auth",3000)
        }else {
            toast.error(error.response.data.detail, {autoClose:StaticValues.waitingTimeForAlert})
            if (error.response.status === 401) {
                if(path === '/token/login'){
                    console.log('LOGIN ERROR')
                    console.log(error.response)
                }else{
                    console.log('TOKEN OUTDATED')
                    console.log(error.response)
                    localStorage.removeItem(StaticValues.AuthUserQuago)
                    window.location.href = "/auth"
                }
            }
        }
        return Promise.reject(error);
    }
)




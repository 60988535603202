/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum OrderByDirection {
    ASC = 'ASC',
    DESC = 'DESC',
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {login} from '../../../../store/actions'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import {useDispatch, useSelector} from "react-redux";
import qs from "qs";
import {RootState} from "../../../../store/reducers";
import {Button} from "reactstrap";
import './login.scss'



const loginSchema = Yup.object().shape({
  email: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login(props:any) {
  const [loading, setLoading] = useState<boolean>(false)
  const [togglePassword, setTogglePassword] = useState<string>('password')
  const {saveAuth, setCurrentUser} = useAuth()
  const dispatch = useDispatch();

  const {result, errorMsg} = useSelector((state: RootState) => ({
    result: state.login.result,
    errorMsg: state.login.errorMsg,
  }))

  const handleTogglePassword = (e:any) => {
    e.preventDefault()
    if (togglePassword === 'password'){
      setTogglePassword('text')
  }else{
    setTogglePassword('password')
  }
}


  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {

      try {
        const loginValues = {username:values.email,
        password: values.password}

          setLoading(true)
          dispatch(login(loginValues))
          setLoading(false)
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Sign In to <span style={{fontStyle:'italic'}}>DeepDive</span></h1>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : null}

      {errorMsg && <span style={{color:'red', fontWeight:'bold'}}>{errorMsg}</span>}

      {/* begin::Form group */}
      <br/>
      <br/>
      <div className='fv-row mb-10'>
        <label style={{paddingLeft:'10px'}} className='form-label fs-6 fw-bolder text-dark'>Username</label>
        <div className={'input_pass_container_2'}>
        <input
          placeholder='Username'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid input_username',
            {'is-invalid': errorMsg},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='name'
          name='email'
          autoComplete='off'
        />
        </div>
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label style={{paddingLeft:'10px'}} className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
            {/* end::Label */}
            {/* begin::Link */}
            <Link
              to='/auth/forgot-password'
              className='link-primary fs-6 fw-bolder'
              style={{marginLeft: '5px'}}
            >
              Forgot Password ?
            </Link>
            {/* end::Link */}
          </div>
        </div>
        <div className={'input_pass_container'}>
          <input
              type={togglePassword}
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': errorMsg,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
              )}
          >
          </input>
          <div className="input-group-btn">
          {/*<Button className="pass_toggle" onClick={handleTogglePassword}>*/}
            { togglePassword === "password"? <i  onClick={handleTogglePassword} className="bi bi-eye-slash pass_toggle"></i> :<i  onClick={handleTogglePassword} className="bi bi-eye pass_toggle"></i> }
          {/*</Button>*/}
        </div>
        </div>

        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          onClick={()=> setLoading(true)}
          id='kt_sign_in_submit'
          style={{width:'379.5px'}}
          className='btn btn-lg btn-primary mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Login</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

      </div>
      {/* end::Action */}
    </form>
  )
}

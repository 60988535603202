/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import StaticValues from "../../../helpers/StaticValues";

export function AsideMenuMain() {
    const intl = useIntl()
    // @ts-ignore
    const role: any = JSON.parse(localStorage.getItem(StaticValues.AuthUserQuago))?.user_role
    // console.log(role)
    return (
        <>
            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Menu</span>
                </div>
            </div>
            <AsideMenuItemWithSub
                to='/dashboard'
                title='Dashboard'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/general/gen001.svg'
            >
                <AsideMenuItem
                    to='/dashboard/apps'
                    title='Apps'
                />
            </AsideMenuItemWithSub>

            {role === 'ADMIN' &&
                <>
                    <AsideMenuItemWithSub
                        to='/crafted/pages'
                        title='Config Manager'
                        fontIcon='bi-archive'
                        icon='/media/icons/duotune/general/gen019.svg'
                    >
                        <AsideMenuItem to='/config/config-group' title='Config Group' hasBullet={false}/>
                        <AsideMenuItemWithSub
                            to='/crafted/pages'
                            title='Config Items'
                            fontIcon='bi-archive'
                        >
                            <AsideMenuItem to='/config/config-default' title='Config Defaults' hasBullet={false}/>
                            <AsideMenuItem to='/config/config-app' title='Config Apps' hasBullet={false}/>
                        </AsideMenuItemWithSub>
                        <AsideMenuItem to='/config/audits' title='Config Audits' hasBullet={false}/>
                    </AsideMenuItemWithSub>
                    <AsideMenuItemWithSub
                        to='/crafted/pages'
                        title='Admin'
                        fontIcon='bi-archive'
                        icon='/media/icons/duotune/general/gen047.svg'
                    >
                        <AsideMenuItem to='/admin/users' title='Users' hasBullet={false}/>
                        <AsideMenuItem to='/admin/roles' title='Roles' hasBullet={false}/>
                        <AsideMenuItem to='/admin/permissions' title='Permissions' hasBullet={false}/>
                    </AsideMenuItemWithSub>
                </>

            }
            <AsideMenuItemWithSub
                to='/crafted/pages'
                title='Community'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/files/fil001.svg'
            >
                <AsideMenuItem to='/community/all' title='Community Detection' hasBullet={false}/>
                <AsideMenuItem to='/community/deployment-history' title='Deployment History' hasBullet={false}/>
            </AsideMenuItemWithSub>
            <AsideMenuItemWithSub
                to='/crafted/pages'
                title='Annotation'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/communication/com012.svg'
            >
                <AsideMenuItem to='/annotation' title='Annotation Jobs' hasBullet={false}/>
            </AsideMenuItemWithSub>
        </>
    )
}

import {PageLink, PageTitle} from "../../../../_metronic/layout/core";
import React, {useEffect, useState} from "react";
import AnnotationJobHeader from "./Components/AnnotationJobHeader/AnnotationJobHeader";
import AnnotationJobData from "./Components/AnnotationJobData/AnnotationJobData";
import {useIsFetching, useQueryClient} from "react-query";
import usePreloader from "../../../../store/layout/usePreloader";
import {CSSTransition} from "react-transition-group";
import AnnotationControls from "./Components/AnnotationControls/AnnotationControls";
import {SessionStorage} from "../../../../constants/sessionStorage";
import useAnnotationJobHeader from "../Hooks/useAnnotationJobHeader";
import QueryKeys from "../../../../react-query/QueryKeys";
import {useParams} from "react-router-dom";
import {getAnnotationClasses} from "../../../../_metronic/helpers/backend_helper";
import ROUTE_PATHS from "../../../routing/RoutePaths";

const AnnotationSegments = () => {
    const {id} = useParams()
    const annotationId = Number(id)
    const initialShowDetails = sessionStorage.getItem(SessionStorage.annotationJobsDetails) !== 'false'
    const [showDetails, setShowDetails] = useState(initialShowDetails)

    const queryClient = useQueryClient()

    useEffect(() => {
        queryClient.prefetchQuery({
            queryKey: [QueryKeys.AnnotationJobClasses, annotationId],
            queryFn: () => getAnnotationClasses(annotationId),
            retry: false,
            staleTime: 5 * (60 * 1000)
        })
    }, [])

    const toggleDetails = () => setShowDetails(prevState => !prevState)

    useAnnotationJobHeader(!initialShowDetails)

    useEffect(() => {
        sessionStorage.setItem(SessionStorage.annotationJobsDetails, showDetails + '')
    }, [showDetails])

    const isFetching = useIsFetching()

    const configBreadcrumbs: Array<PageLink> = [
        {
            title: 'Home /',
            path: '/dashboard/apps',
            isSeparator: false,
            isActive: false,
        },
        {
            title: 'Annotations Jobs/',
            path: `${ROUTE_PATHS.ANNOTATION.JOBS}`,
            isSeparator: false,
            isActive: false,
        },
    ]

    usePreloader(Boolean(isFetching))

    return <div>
        <PageTitle breadcrumbs={configBreadcrumbs}>Segments</PageTitle>
        <CSSTransition
            in={showDetails}
            timeout={300}
            classNames="detail"
            nodeRef={null}
            unmountOnExit
        >
            <AnnotationJobHeader/>
        </CSSTransition>
        <AnnotationControls showDetails={showDetails} toggleDetails={toggleDetails}/>
        <AnnotationJobData />

    </div>
}

export default AnnotationSegments

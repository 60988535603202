import classes from './AnnotationJobHeader.module.scss'
import {useNavigate, useParams} from "react-router-dom";
import {useMutation, useQueryClient} from "react-query";
import {updateAnnotationJobHeader} from "../../../../../../_metronic/helpers/backend_helper";
import QueryKeys from "../../../../../../react-query/QueryKeys";
import {Card, Input, UncontrolledTooltip} from "reactstrap";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import {KTSVG} from "../../../../../../_metronic/helpers";
import React, {useState} from "react";
import * as moment from 'moment'
import {UpdateAnnotationHeaderRequest} from "../../../../../../AUTO_GENERATED_TYPES";
import {toast} from "react-toastify";
import {debounce} from "lodash";
import useAnnotationJobHeader from "../../../Hooks/useAnnotationJobHeader";
import SweetAlertImport from "react-bootstrap-sweetalert";
const SweetAlert = SweetAlertImport as any


const useUpdateHeader = (annotationId: number) => {
    const navigate = useNavigate()

    const queryClient = useQueryClient()
    const {mutate} = useMutation(({requestBody} : { requestBody: UpdateAnnotationHeaderRequest ,isArchive?:boolean}) => updateAnnotationJobHeader({
        annotationId,
        requestBody
    }), {
        onSuccess: (variable,variables) => {
            if(variables.isArchive){
                queryClient.invalidateQueries([QueryKeys.Annotation]).then(()=>{
                    navigate('/annotation')
                })
                toast.success('Archive mode updated successfully')
            }else{
            toast.success('Comment updated successfully')
            queryClient.invalidateQueries([QueryKeys.Annotation, QueryKeys.AnnotationJobHeader])
            }

        }
    })

    const updateArchive = (newMode: boolean, currentComment: string) => {
        mutate({requestBody:{archived: newMode, comment: currentComment},isArchive:true})
    }

    const unDebouncedUpdateComment = (newComment: string, currentMode: boolean) => {
        mutate({requestBody:{archived: currentMode, comment: newComment}})
    }

    const updateComment = debounce(unDebouncedUpdateComment, 500)

    return {updateArchive, updateComment}
}

const AnnotationJobHeader = () => {
    const [showArchiveModal,setShowArchiveModal] = useState(false)

    const toggleArchive = ()=>{
        setShowArchiveModal(prevState => !prevState)
    }

    const { id } = useParams()
    const annotationId = Number(id)
    const {data, isLoading} = useAnnotationJobHeader()

    const {updateArchive, updateComment} = useUpdateHeader(annotationId)

    const platformMapping: { [key: number]: string } = {
        1: 'Android',
        2: 'iOS',
        3: 'UnityDesktop',
        4: 'WEB_GL'
    };

    const getPlatformNames = (platformNumbers?: number[]): string[] => {
        if (!platformNumbers) return [];
        return platformNumbers.map(num => platformMapping[num] || 'Unknown');
    };

    const platformNames = getPlatformNames(data?.platforms);
    

    if (isLoading) {
        return null
    }

    if (!data) {
        return <div>No Data Found</div>
    }

    return <>
        <Card className={classes.container}>
            <h4>Job Details:</h4>
            <div className={classes.tagging_summary}>
                <div><span className={'fw-bold'}>Items:</span> {data.tagging_summary.items}</div>
                <div><span className={'fw-bold'}>Tagged:</span> {data.tagging_summary.tagged}</div>
                {data.tagging_summary.tagging_list?.map((tag, i) => <div key={i}><span
                    className={'fw-bold'}>{tag.tag_name}:</span> {tag.counter}</div>)}
            </div>
            <span>&nbsp;</span>
            <div className={classes.data_container}>
            <div>
                    <div>
                        <div className={'fw-bold'}>Platforms:</div>
                        <div id={'PTS'} className={'seg_long_copy'}>{platformNames.join(', ')}</div>
                        <UncontrolledTooltip target={'PTS'}
                                             placement={'bottom'}>{platformNames.join(', ')}</UncontrolledTooltip>
                    </div>
                </div>
                <div>
                    <div className={'fw-bold'}>Run Name:</div>
                    <OverlayTrigger
                        placement='bottom'
                        overlay={<Tooltip>{data.run_name}</Tooltip>}
                        defaultShow={undefined}
                        delay={undefined}
                        flip={undefined}
                        onHide={undefined}
                        onToggle={undefined}
                        popperConfig={undefined}
                        show={undefined}
                        target={undefined}
                        trigger={undefined}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'left',
                            flexDirection: 'row',
                        }}>
                            <div className={'seg_long_copy'}>{data.run_name}</div>
                            <div className={'copy_btn'} onClick={() => {
                                navigator.clipboard.writeText(data.run_name).then(() => {
                                    toast.info('Copied to clipboard!')
                                })

                            }}>
                                <KTSVG
                                    path='/media/icons/duotune/general/gen054.svg'
                                    className='svg-icon-edit svg-icon-2'
                                    loader={false}
                                />
                            </div>
                        </div>
                    </OverlayTrigger>
                </div>
                <div>
                    <div className={'fw-bold'}>Creation Time:</div>
                    {moment.default(data.creation_time).format('YYYY/MM/DD hh:mm')}</div>
                <div>
                    <div className={'fw-bold'}>App Token:</div>
                    {data.app_token}</div>
                <div>
                    <div className={'fw-bold'}>Actual App Tokens:</div>
                    <div id={'AAT'} className={'seg_long_copy'}>{data.actual_app_tokens?.join(', ')}</div>
                    <UncontrolledTooltip target={'AAT'}
                                         placement={'bottom'}>{data.actual_app_tokens?.join(', ')}</UncontrolledTooltip>
                </div>
                <div>
                    <div className={'fw-bold'}>Interface Type:</div>
                    {data.inference_type}</div>
                <div>
                    <div className={'fw-bold'}>Researcher:</div>
                    {data.researcher}</div>
                <div>
                    <div className={'fw-bold'}># Images:</div>
                    {data.cnt_images}</div>
                <div>
                    <div className={'fw-bold'}># Segments:</div>
                    {data.cnt_segments}</div>
            </div>
            <div className={classes.tagging_summary__comment}>
                <Input type={'textarea'} defaultValue={data.comment} placeholder={'Comment'} onBlur={(e) => {
                    if(e.target.value !== data.comment){
                        updateComment(e.target.value, data.archive)
                    }
                }}/></div>
            <div className={classes.tagging_summary__button}><Button onClick={toggleArchive}>
                <KTSVG
                    path={`/media/icons/duotune/files/fil01${data.archive ? '8' : '7'}.svg`}
                    className='svg-icon-context svg-icon-2'
                />
                {data.archive ? 'UnArchive' : 'Archive'}
            </Button></div>
            <Button onClick={(e) => {
                e.currentTarget.querySelector('a')?.click()
            }}
                    className={classes.tagging_summary__link}>
                <a href={`/annotation/${annotationId}/table`} onClick={(e) => e.stopPropagation()} target={'_blank'}><KTSVG
                    path='/media/icons/duotune/files/fil002.svg'
                    className='svg-icon-edit svg-icon-2'
                />
                    Items Table</a>
            </Button>
        </Card>
        <SweetAlert show={showArchiveModal}
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    confirmBtnBsStyle={'danger'}
                    title={`Warning`}
                    onConfirm={() => {
                        updateArchive(!data.archive, data.comment ?? '')
                        toggleArchive()
                    }
                    }
                    onCancel={toggleArchive}
        >
            <p>Are you sure you want to {data.archive ? 'UnArchive' : 'Archive'} this job?</p>
        </SweetAlert>
    </>
}

export default AnnotationJobHeader

export enum UserActionTypes {
    GET_USERS = "GET_USERS",
    GET_USERS_SUCCESS = "GET_USERS_SUCCESS",
    GET_USERS_FAIL = "GET_USERS_FAIL",
    CREATE_NEW_USER = "CREATE_NEW_USER",
    CREATE_NEW_USER_SUCCESS = "CREATE_NEW_USER_SUCCESS",
    CREATE_NEW_USER_FAIL = "CREATE_NEW_USER_FAIL",
    UPDATE_USER = "UPDATE_USER",
    UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS",
    UPDATE_USER_FAIL = "UPDATE_USER_FAIL",
    DELETE_USER = "DELETE_USER",
    DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS",
    DELETE_USER_FAIL = "DELETE_USER_FAIL",
    RESET_PASSWORD = "RESET_PASSWORD",
    RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS",
    RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL",
}

export enum LayoutActionTypes {
    CHANGE_LAYOUT = "CHANGE_LAYOUT",
    CHANGE_LAYOUT_WIDTH = "CHANGE_LAYOUT_WIDTH",
    CHANGE_SIDEBAR_THEME = "CHANGE_SIDEBAR_THEME",
    CHANGE_SIDEBAR_THEME_IMAGE = "CHANGE_SIDEBAR_THEME_IMAGE",
    CHANGE_SIDEBAR_TYPE = "CHANGE_SIDEBAR_TYPE",
    CHANGE_TOPBAR_THEME = "CHANGE_TOPBAR_THEME",
    SHOW_SIDEBAR = "SHOW_SIDEBAR",
    TOGGLE_LEFTMENU = "TOGGLE_LEFTMENU",
    SHOW_RIGHT_SIDEBAR = "SHOW_RIGHT_SIDEBAR",
    CHANGE_PRELOADER = "CHANGE_PRELOADER",
    ERROR_MODAL = "ERROR_MODAL",
    CLOSE_ERROR_MODAL = "CLOSE_ERROR_MODAL",
    CHANGE_DIRECTION = 'CHANGE_DIRECTION',
}

export enum AppsActionTypes {
    GET_APPS = "GET_APPS",
    GET_APPS_SUCCESS = "GET_APPS_SUCCESS",
    GET_APPS_FAIL = "GET_APPS_FAIL",
    CREATE_APP = "CREATE_APP",
    CREATE_APP_SUCCESS = "CREATE_APP_SUCCESS",
    CREATE_APP_FAIL = "CREATE_APP_FAIL",
    UPDATE_APP = "UPDATE_APP",
    UPDATE_APP_SUCCESS = "UPDATE_APP_SUCCESS",
    UPDATE_APP_FAIL = "UPDATE_APP_FAIL",
    DELETE_APP = "DELETE_APP",
    DELETE_APP_SUCCESS = "DELETE_APP_SUCCESS",
    DELETE_APP_FAIL = "DELETE_APP_FAIL",
    ARCHIVE_APP = "ARCHIVE_APP",
    ARCHIVE_APP_SUCCESS = "ARCHIVE_APP_SUCCESS",
    ARCHIVE_APP_FAIL = "ARCHIVE_APP_FAIL",
    UNARCHIVE_APP = "UNARCHIVE_APP",
    UNARCHIVE_APP_SUCCESS = "UNARCHIVE_APP_SUCCESS",
    UNARCHIVE_APP_FAIL = "UNARCHIVE_APP_FAIL",
    CLONE_APP = "CLONE_APP",
    CLONE_APP_SUCCESS = "CLONE_APP_SUCCESS",
    CLONE_APP_FAIL = "CLONE_APP_FAIL",
    UNARCHIVE_APP_LIST = "UNARCHIVE_APP_LIST",
    UNARCHIVE_APP_LIST_SUCCESS = "UNARCHIVE_APP_LIST_SUCCESS",
    UNARCHIVE_APP_LIST_FAIL = "UNARCHIVE_APP_LIST_FAIL",
    DELETE_APP_LIST = "DELETE_APP_LIST",
    DELETE_APP_LIST_SUCCESS = "DELETE_APP_LIST_SUCCESS",
    DELETE_APP_LIST_FAIL = "DELETE_APP_LIST_FAIL",
    ARCHIVE_APP_LIST = "ARCHIVE_APP_LIST",
    ARCHIVE_APP_LIST_SUCCESS = "ARCHIVE_APP_LIST_SUCCESS",
    ARCHIVE_APP_LIST_FAIL = "ARCHIVE_APP_LIST_FAIL",
    GET_APP_CONFIG = "GET_APP_CONFIG",
    GET_APP_CONFIG_SUCCESS = "GET_APP_CONFIG_SUCCESS",
    GET_APP_CONFIG_FAIL = "GET_APP_CONFIG_FAIL",
    UPDATE_APP_CONFIG = "UPDATE_APP_CONFIG",
    UPDATE_APP_CONFIG_SUCCESS = "UPDATE_APP_CONFIG_SUCCESS",
    UPDATE_APP_CONFIG_FAIL = "UPDATE_APP_CONFIG_FAIL",
    DELETE_APP_CONFIG = "DELETE_APP_CONFIG",
    DELETE_APP_CONFIG_SUCCESS = "DELETE_APP_CONFIG_SUCCESS",
    DELETE_APP_CONFIG_FAIL = "DELETE_APP_CONFIG_FAIL",
}

export enum AuthActionTypes {
    LOGIN = "LOGIN",
    LOGIN_SUCCESS = "LOGIN_SUCCESS",
    LOGIN_FAIL = "LOGIN_FAIL",
}

export enum ConfigActionTypes {
    GET_CONFIG_DEFAULT = "GET_CONFIG_DEFAULT",
    GET_CONFIG_DEFAULT_SUCCESS = "GET_CONFIG_DEFAULT_SUCCESS",
    GET_CONFIG_DEFAULT_FAIL = "GET_CONFIG_DEFAULT_FAIL",
    GET_GROUPS_DEFAULT = "GET_GROUPS_DEFAULT",
    GET_GROUPS_DEFAULT_SUCCESS = "GET_GROUPS_DEFAULT_SUCCESS",
    GET_GROUPS_DEFAULT_FAIL = "GET_GROUPS_DEFAULT_FAIL",
    CREATE_CONFIG = "CREATE_CONFIG",
    CREATE_CONFIG_SUCCESS = "CREATE_CONFIG_SUCCESS",
    CREATE_CONFIG_FAIL = "CREATE_CONFIG_FAIL",
    CREATE_CONFIG_GROUP = "CREATE_CONFIG_GROUP",
    CREATE_CONFIG_GROUP_SUCCESS = "CREATE_CONFIG_GROUP_SUCCESS",
    CREATE_CONFIG_GROUP_FAIL = "CREATE_CONFIG_GROUP_FAIL",
    EDIT_CONFIG = "EDIT_CONFIG",
    EDIT_CONFIG_SUCCESS = "EDIT_CONFIG_SUCCESS",
    EDIT_CONFIG_FAIL = "EDIT_CONFIG_FAIL",
    EDIT_CONFIG_GROUP = "EDIT_CONFIG_GROUP",
    EDIT_CONFIG_GROUP_SUCCESS = "EDIT_CONFIG_GROUP_SUCCESS",
    EDIT_CONFIG_GROUP_FAIL = "EDIT_CONFIG_GROUP_FAIL",
    DELETE_CONFIG = "DELETE_CONFIG",
    DELETE_CONFIG_SUCCESS = "DELETE_CONFIG_SUCCESS",
    DELETE_CONFIG_FAIL = "DELETE_CONFIG_FAIL",
    DELETE_CONFIG_GROUP = "DELETE_CONFIG_GROUP",
    DELETE_CONFIG_GROUP_SUCCESS = "DELETE_CONFIG_GROUP_SUCCESS",
    DELETE_CONFIG_GROUP_FAIL = "DELETE_CONFIG_GROUP_FAIL",
    ROLLBACK_CONFIG = "ROLLBACK_CONFIG",
    ROLLBACK_CONFIG_SUCCESS = "ROLLBACK_CONFIG_SUCCESS",
    ROLLBACK_CONFIG_FAIL = "ROLLBACK_CONFIG_FAIL",


}

export enum CommunityTypes {
    GET_ALL_COMMUNITY = "GET_ALL_COMMUNITY",
    GET_ALL_COMMUNITY_SUCCESS = "GET_ALL_COMMUNITY_SUCCESS",
    GET_ALL_COMMUNITY_FAIL = "GET_ALL_COMMUNITY_FAIL",
    GET_COMMUNITY_JOB = "GET_COMMUNITY_JOB",
    GET_COMMUNITY_JOB_SUCCESS = "GET_COMMUNITY_JOB_SUCCESS",
    GET_COMMUNITY_JOB_FAIL = "GET_COMMUNITY_JOB_FAIL",
    GET_COMMUNITY_JOB_COMM = "GET_COMMUNITY_JOB_COMM",
    GET_COMMUNITY_JOB_COMM_SUCCESS = "GET_COMMUNITY_JOB_COMM_SUCCESS",
    GET_COMMUNITY_JOB_COMM_FAIL = "GET_COMMUNITY_JOB_COMM_FAIL",
    GET_APPS_NAMES = "GET_APPS_NAMES",
    GET_APPS_NAMES_SUCCESS = "GET_APPS_NAMES_SUCCESS",
    GET_APPS_NAMES_FAIL = "GET_APPS_NAMES_FAIL",
    GET_SEGMENT_HEADER = "GET_SEGMENT_HEADER",
    GET_SEGMENT_HEADER_SUCCESS = "GET_SEGMENT_HEADER_SUCCESS",
    GET_SEGMENT_HEADER_FAIL = "GET_SEGMENT_HEADER_FAIL",
    GET_SEGMENT_DATA = "GET_SEGMENT_DATA",
    GET_SEGMENT_DATA_SUCCESS = "GET_SEGMENT_DATA_SUCCESS",
    GET_SEGMENT_DATA_FAIL = "GET_SEGMENT_DATA_FAIL",
    PATCH_DECISION_COMM = "PATCH_DECISION_COMM",
    PATCH_DECISION_COMM_SUCCESS = "PATCH_DECISION_COMM_SUCCESS",
    PATCH_DECISION_COMM_FAIL = "PATCH_DECISION_COMM_FAIL",
    PATCH_COMM_DETAILS = "PATCH_COMM_DETAILS",
    PATCH_COMM_DETAILS_SUCCESS = "PATCH_COMM_DETAILS_SUCCESS",
    PATCH_COMM_DETAILS_FAIL = "PATCH_COMM_DETAILS_FAIL",
    PATCH_SEG_DETAILS = "PATCH_SEG_DETAILS",
    PATCH_SEG_DETAILS_SUCCESS = "PATCH_SEG_DETAILS_SUCCESS",
    PATCH_SEG_DETAILS_FAIL = "PATCH_SEG_DETAILS_FAIL",
    GET_WARNINGS = "GET_WARNINGS",
    GET_WARNINGS_SUCCESS = "GET_WARNINGS_SUCCESS",
    GET_WARNINGS_FAIL = "GET_WARNINGS_FAIL",
    UPDATE_WARNINGS = "UPDATE_WARNINGS",
    UPDATE_WARNINGS_SUCCESS = "UPDATE_WARNINGS_SUCCESS",
    UPDATE_WARNINGS_FAIL = "UPDATE_WARNINGS_FAIL",
    PRE_DEPLOY_COMM = "PRE_DEPLOY_COMM",
    PRE_DEPLOY_COMM_SUCCESS = "PRE_DEPLOY_COMM_SUCCESS",
    PRE_DEPLOY_COMM_FAIL = "PRE_DEPLOY_COMM_FAIL",
    DEPLOY_COMM = "DEPLOY_COMM",
    DEPLOY_COMM_SUCCESS = "DEPLOY_COMM_SUCCESS",
    DEPLOY_COMM_FAIL = "DEPLOY_COMM_FAIL",
    SEGMENT_TAGGING = "SEGMENT_TAGGING",
    SEGMENT_TAGGING_SUCCESS = "SEGMENT_TAGGING_SUCCESS",
    SEGMENT_TAGGING_FAIL = "SEGMENT_TAGGING_FAIL",
    GET_SUMMARY_TABLE = "GET_SUMMARY_TABLE",
    GET_SUMMARY_TABLE_SUCCESS = "GET_SUMMARY_TABLE_SUCCESS",
    GET_SUMMARY_TABLE_FAIL = "GET_SUMMARY_TABLE_FAIL",
    GET_SEGMENTS_FILTERS = "GET_SEGMENTS_FILTERS",
    GET_SEGMENTS_FILTERS_SUCCESS = "GET_SEGMENTS_FILTERS_SUCCESS",
    GET_SEGMENTS_FILTERS_FAIL = "GET_SEGMENTS_FILTERS_FAIL",
    RESET = "RESET",

}

export enum CommunityFilterActionTypes {
    SET_COM_FILTERS = "SET_COM_FILTERS",
    ADD_COM_FILTERS = "ADD_COM_FILTERS",
    ADD_COM_FILTERS_DETAILS = "ADD_COM_FILTERS_DETAILS",
    REMOVE_COM_FILTERS = "REMOVE_COM_FILTERS",
    REMOVE_COM_FILTERS_SUCCESS = "REMOVE_COM_FILTERS_SUCCESS",
    RESET_COM_FILTERS = "RESET_COM_FILTERS",
    TYPE_CHANGE = "TYPE_CHANGE",
    OPERATOR_CHANGE = "OPERATOR_CHANGE",
    VALUE_CHANGE = "VALUE_CHANGE",
    SA_CHANGE = "SA_CHANGE"
}

export enum CommunitySortActionTypes {
    SET_COM_SORT = "SET_COM_SORT",
    ADD_COM_SORT = "ADD_COM_SORT",
    REMOVE_COM_SORT = "REMOVE_COM_SORT",
    RESET_COM_SORT = "RESET_COM_SORT",
    SORT_TYPE_CHANGE = "SORT_TYPE_CHANGE",
    SORT_VALUE_CHANGE = "SORT_VALUE_CHANGE",
    SET_SORT_OPTIONS = "SET_SORT_OPTIONS"
}

export enum AnnotationActionTypes {
    SET_ANNOTATION_FILTERS = "SET_ANNOTATION_FILTERS",
    ADD_ANNOTATION_FILTER = "ADD_ANNOTATION_FILTER",
    RESET_ANNOTATION_FILTER = "RESET_ANNOTATION_FILTER",
    REMOVE_ANNOTATION_FILTER = "REMOVE_ANNOTATION_FILTER",
    CHANGE_ANNOTATION_FILTER_TYPE = "CHANGE_ANNOTATION_FILTER_TYPE",
    CHANGE_ANNOTATION_FILTER_OPERATOR = "CHANGE_ANNOTATION_FILTER_OPERATOR",
    CHANGE_ANNOTATION_FILTER_VALUE = "CHANGE_ANNOTATION_FILTER_VALUE",
    CHANGE_ANNOTATION_FILTER_SA = "CHANGE_ANNOTATION_FILTER_SA",

    SET_ANNOTATION_DATA_REQUEST = "SET_ANNOTATION_DATA_REQUEST",
    SET_ANNOTATION_TAG_FILTER = "SET_ANNOTATION_TAG_FILTER",

    ADD_ANNOTATION_ORDER_BY = "ADD_ANNOTATION_ORDER_BY",
    RESET_ANNOTATION_ORDER_BY = "RESET_ANNOTATION_ORDER_BY",
    REMOVE_ANNOTATION_ORDER_BY = "REMOVE_ANNOTATION_ORDER_BY",
    ANNOTATION_ORDER_TYPE = "ANNOTATION_ORDER_TYPE",
    ANNOTATION_ORDER_VALUE = "ANNOTATION_ORDER_VALUE",

    SET_ANNOTATION_PAGE = "SET_ANNOTATION_PAGE",
    SET_ANNOTATION_IPP = "SET_ANNOTATION_IPP",

    CHANGE_ANNOTATION_IMAGE_TYPE = "CHANGE_ANNOTATION_IMAGE_TYPE"
}

export interface MessageResponsePayload {
    result: boolean,
    msg: string
}

function isValidBoolean(value: any): boolean {
    if (typeof value === 'boolean') {
        return true;
    }
    if (typeof value === 'string' && (value.toLowerCase() === 'true' || value.toLowerCase() === 'false')) {
        return true;
    }
    return false;
}

export default isValidBoolean

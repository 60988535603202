/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_login_for_access_token_token_login_post } from '../models/Body_login_for_access_token_token_login_post';
import type { Token } from '../models/Token';
import type { User } from '../models/User';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AuthService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Login For Access Token
     * @param formData 
     * @returns Token Successful Response
     * @throws ApiError
     */
    public loginForAccessTokenTokenLoginPost(
formData: Body_login_for_access_token_token_login_post,
): CancelablePromise<Token> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/token/login',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Verify Token
     * @returns any Successful Response
     * @throws ApiError
     */
    public verifyTokenTokenVerifyGet(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/token/verify',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Get Token Details
     * @returns User Successful Response
     * @throws ApiError
     */
    public getTokenDetailsTokenMePost(): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/token/me',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

}

import {PageLink, PageTitle} from "../../../../_metronic/layout/core";
import React, {useState} from "react";
import {Col, Row} from "react-bootstrap";
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import tableClasses from "../../Community/pages/table.module.scss";
import Moment from "moment";
import BootstrapTable from 'react-bootstrap-table-next';
import {useIsFetching, useQuery} from "react-query";
import QueryKeys from "../../../../react-query/QueryKeys";
import {
    getAllAudits,
    getAppsNames,
    getAuditsGroups,
    getGroupsDefault,
} from "../../../../_metronic/helpers/backend_helper";
import caseInsensitiveSort from "../../../helpers/utils/sorting/caseInsensitiveSort";
import useSVG from "../../../helpers/hooks/useSVG";
import TaggingTooltip from "../../Community/Components/TaggingTooltip";
import {AppMode, GroupMode} from "../../../../AUTO_GENERATED_TYPES";
import usePreloader from "../../../../store/layout/usePreloader";
import Select from "react-select";
import getGroupModeLabel from "../utils/getGroupModeLabel";
import {Option} from "../../../../types/CommunitySortTypes";
import {Input} from "reactstrap";
import {debounce} from "lodash";

const ConfigAudits = () => {
    const [groupMode, setGroupMode] = useState<GroupMode>(GroupMode.ALL)
    const [filterGroups, setFilterGroups] = useState<Option[]>([])
    const [filterApps, setFilterApps] = useState<Option | null>(null)
    const [filterConfigName, setFilterConfigName] = useState('')

    const filterNameChangeHandler = debounce((e: React.ChangeEvent<HTMLInputElement>)=>setFilterConfigName(e.target.value),1000)

    const rollbackSVG = useSVG('/media/icons/duotune/arrows/arr029.svg')
    const createSVG = useSVG('/media/icons/duotune/general/gen041.svg')
    const cogSVG = useSVG('/media/icons/duotune/coding/cod001.svg')
    const resetSVG = useSVG('/media/icons/duotune/arrows/arr043.svg')
    const trashSVG = useSVG('/media/icons/duotune/general/gen027.svg')

    const loadedSVG = rollbackSVG && createSVG && cogSVG && resetSVG && trashSVG

    const {SearchBar} = Search;

    const isFetching = useIsFetching()

    usePreloader(Boolean(isFetching))

    const {
        data: allAudits,
    } = useQuery([QueryKeys.AllAudits, groupMode, filterGroups, filterApps, filterConfigName], () => {
        const groups = filterGroups.map(filter => filter.value).join(',')
        const appId = filterApps?.value ? Number(filterApps.value) : undefined


        return getAllAudits(groupMode, groups, appId, filterConfigName)
    }, {
        retry: false,
        refetchOnWindowFocus: false,
        placeholderData: null,
    })

    const {
        data: groupsList,
        refetch: refetchGroups,
        isLoading
    } = useQuery([QueryKeys.AuditsGroups, groupMode], () => getAuditsGroups(groupMode), {
        retry: false,
        refetchOnWindowFocus: false,
    })

    const {
        data: appNamesList,
        isLoading: isLoadingAppNames
    } = useQuery([QueryKeys.AppNames], () => getAppsNames(AppMode.ALL), {
        retry: false,
        refetchOnWindowFocus: false,
    })

    const historyData = allAudits?.audit_all

    const groupsOption = groupsList?.groups?.map(group => ({
        value: String(group.id),
        label: group.group_name
    })) ?? null

    const appsOptions = appNamesList?.apps?.map(app => ({
        value: String(app.app_id),
        label: app.app_name
    })) ?? null

    const columns = [
        {
            dataField: 'config_hist_id',
            hidden: true,
            text: '#',
            sort: true,
        },
        {
            dataField: 'config_name',
            text: 'Config Name',
            editable: false,
            sort: true,
            style: {
                borderBottom: '.5px solid #d0d0d0',
                textAlign: 'center',
                minWidth: '150px'

            },
        },
        {
            dataField: 'creation_time',
            text: 'Timestamp',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0',
                minWidth: '150px'

            },
            formatter: ((cellContent: string) => {
                return <div style={{minWidth: 'max-content'}}>{Moment(cellContent).format('YYYY/MM/DD HH:mm')}</div>
            })
        },
        {
            dataField: 'app_name',
            text: 'App Name',
            editable: false,
            sort: true,
            style: {
                borderBottom: '.5px solid #d0d0d0',
                textAlign: 'center',
                minWidth: '150px'

            },
        },
        {
            dataField: 'app_token',
            text: 'App Token',
            editable: false,
            sort: true,
            style: {
                borderBottom: '.5px solid #d0d0d0',
                textAlign: 'center',
                minWidth: '150px'

            },
        },
        {
            dataField: 'config_group_name',
            text: 'Config Group Name',
            editable: false,
            sort: true,
            style: {
                borderBottom: '.5px solid #d0d0d0',
                textAlign: 'center',
                minWidth: '150px'

            },
        },
        {
            dataField: 'config_group_mode',
            text: 'Config Group Mode',
            editable: false,
            sort: true,
            style: {
                borderBottom: '.5px solid #d0d0d0',
                textAlign: 'center',
                minWidth: '150px'

            },
        },
        {
            dataField: 'config_group_app_related',
            text: 'App Related',
            editable: false,
            sort: true,
            style: {
                borderBottom: '.5px solid #d0d0d0',
                textAlign: 'center',
                minWidth: '150px'

            },
        },
        {
            dataField: 'config_src',
            text: 'Config Source',
            editable: false,
            sort: true,
            style: {
                borderBottom: '.5px solid #d0d0d0',
                textAlign: 'center',
                minWidth: '150px'

            },
        },
        {
            dataField: 'config_src_before',
            text: 'Source Before',
            editable: false,
            sort: true,
            style: {
                borderBottom: '.5px solid #d0d0d0',
                textAlign: 'center',
                minWidth: '150px'

            },
        },
        {
            dataField: 'config_src_after',
            text: 'Source After',
            editable: false,
            sort: true,
            style: {
                borderBottom: '.5px solid #d0d0d0',
                textAlign: 'center',
                minWidth: '150px'

            },
        },
        {
            dataField: 'config_value_before',
            text: 'Value Before',
            editable: false,
            sort: true,
            style: {
                borderBottom: '.5px solid #d0d0d0',
                textAlign: 'center',
                minWidth: '150px'
            },
            formatter: ((cellContent: string) => <TaggingTooltip placement={'bottom'}
                                                                 tooltip={cellContent?.split(',').map(value => <div
                                                                     key={value}>{value}</div>)}>
                <div className={'seg_long_copy'}>{cellContent}</div>
            </TaggingTooltip>)
        },
        {
            dataField: 'config_value_after',
            text: 'Value After',
            editable: false,
            sort: true,
            style: {
                borderBottom: '.5px solid #d0d0d0',
                textAlign: 'center',
                minWidth: '150px'
            },
            formatter: ((cellContent: string) => <TaggingTooltip placement={'bottom'}
                                                                 tooltip={cellContent?.split(',').map(value => <div
                                                                     key={value}>{value}</div>)}>
                <div className={'seg_long_copy'}>{cellContent}</div>
            </TaggingTooltip>)
        },
        {
            dataField: 'config_operation',
            text: 'Config Operation',
            editable: false,
            sort: true,
            style: {
                borderBottom: '.5px solid #d0d0d0',
                textAlign: 'center',
                minWidth: '150px'

            },
        },
        {
            dataField: 'user_name',
            text: 'User',
            editable: false,
            sort: true,
            sortFunc: caseInsensitiveSort,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0',
                minWidth: '150px'
            },
        },
        // {
        //     dataField: "actions",
        //     isDummyField: true,
        //     editable: false,
        //     style: {
        //         textAlign: 'center',
        //         borderBottom: '.5px solid #d0d0d0'
        //     },
        //     text: "Actions",
        //     formatter: (cellContent: any, row: ConfigHistoryResponse, rowIndex: any, extraData: any) => (
        //         <>
        //             <div className={'position-relative'}>
        //                 <div className={'table_action'} style={{gap: '10px'}}>
        //                     <IconWithBubble>
        //                         <h6>{row.user_name} - {Moment(row.creation_time).format('YYYY/MM/DD HH:mm')}</h6>
        //                        <div
        //                             onClick={() => {
        //
        //                             }}
        //                            style={{cursor: 'not-allowed',filter:'grayScale(100%)'}}
        //                         >{rollbackSVG && <KTSVG path={rollbackSVG!}
        //                                                 className='svg-icon-context svg-icon-1'
        //                                                 loader={!rollbackSVG}/>} Rollback</div>
        //
        //                        <div
        //                             onClick={() => {
        //
        //                             }}
        //                            style={{cursor: 'not-allowed',filter:'grayScale(100%)'}}
        //                         >{createSVG && <KTSVG
        //                            path={createSVG || ''}
        //                            className='svg-icon-context svg-icon-1'
        //                            loader={!createSVG}
        //                        />} Create</div>
        //
        //                        <div
        //                             onClick={() => {
        //
        //                             }}
        //                            style={{cursor: 'not-allowed',filter:'grayScale(100%)'}}
        //                         >{cogSVG && <KTSVG
        //                            path={cogSVG || ''}
        //                            className='svg-icon-context svg-icon-1'
        //                            loader={!cogSVG}
        //                        />} Update</div>
        //
        //                        <div
        //                             onClick={() => {
        //
        //                             }}
        //                            style={{cursor: 'not-allowed',filter:'grayScale(100%)'}}
        //                         >{resetSVG && <KTSVG
        //                            path={resetSVG || ''}
        //                            className='svg-icon-context svg-icon-1'
        //                            loader={!resetSVG}
        //                        />} Reset</div>
        //
        //                        <div
        //                             onClick={() => {
        //
        //                             }}
        //                            style={{cursor: 'not-allowed',filter:'grayScale(100%)'}}
        //                         >{trashSVG && <KTSVG
        //                            path={trashSVG || ''}
        //                            className='svg-icon-context svg-icon-1'
        //                            loader={!trashSVG}
        //                        />} Delete</div>
        //                     </IconWithBubble>
        //                 </div>
        //             </div>
        //         </>
        //     ),
        // },
    ];


    const configBreadcrumbs: Array<PageLink> = [
        {
            title: 'Home /',
            path: '/dashboard/apps',
            isSeparator: false,
            isActive: false,
        },
    ]


    if (!loadedSVG) {
        return null
    }

    return <>
        <PageTitle breadcrumbs={configBreadcrumbs}>Config's Audits</PageTitle>
        <ToolkitProvider
            keyField="config_hist_id"
            data={historyData ?? []}
            columns={columns}
            search
            bootstrap4
        >
            {
                (props: any) => (
                    <div style={{ padding: '15px'}}>
                        <div>
                            <br/>
                            <div className={'toolbar_table'}>
                                <div>
                                    <SearchBar {...props.searchProps} />
                                </div>
                                <div className={'filter_toolbar'} style={{zIndex: 20}}>
                                    <Select value={{value: groupMode, label: getGroupModeLabel(groupMode)}}
                                            onChange={(option) => {
                                                setGroupMode(option!.value as GroupMode)
                                                setFilterGroups([])
                                                setFilterApps(null)
                                                // setPage(1)
                                            }} options={[
                                        {value: GroupMode.ALL, label: '- Group Mode -'},
                                        {value: GroupMode.LIVE, label: 'Live'},
                                        {value: GroupMode.DEMO, label: 'Demo'},
                                        {value: GroupMode.TEST, label: 'Test'}
                                    ]}
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    borderRadius: '8px',
                                                    minWidth: '200px'
                                                }),
                                            }}
                                    />

                                    {groupsOption && <Select isMulti placeholder={'Groups'}
                                                             value={filterGroups.length > 0 ? filterGroups : null}
                                                             options={groupsOption}
                                                             onChange={(e) => {
                                                                 setFilterGroups(e as Option[])
                                                             }}
                                                             styles={{
                                                                 control: (baseStyles) => ({
                                                                     ...baseStyles,
                                                                     borderRadius: '8px',
                                                                     minWidth: '200px'
                                                                 }),
                                                             }}/>}

                                    {appsOptions && <Select placeholder={'Filter By App'}
                                                            value={filterApps}
                                                            isClearable={true}
                                                            options={appsOptions}
                                                            onChange={(e) => {
                                                                setFilterApps(e as Option)
                                                            }}
                                                            styles={{
                                                                control: (baseStyles) => ({
                                                                    ...baseStyles,
                                                                    borderRadius: '8px',
                                                                    minWidth: '200px'
                                                                }),
                                                            }}/>}
                                    <Input onChange={filterNameChangeHandler} placeholder={'Filter by config name'} />

                                    {/*<Button className={'add_btn'}*/}
                                    {/*        onClick={() => {*/}
                                    {/*            setNewConfigModal(true)*/}
                                    {/*        }}*/}
                                    {/*>*/}
                                    {/*    <div style={{marginRight: "10px"}}>*/}
                                    {/*        <KTSVG*/}
                                    {/*            path='/media/icons/duotune/arrows/arr052.svg'*/}
                                    {/*            className='svg-icon svg-icon-2x'*/}
                                    {/*        />*/}
                                    {/*    </div>*/}
                                    {/*</Button>*/}
                                </div>
                            </div>

                            <br/>
                        </div>
                        <Row>
                            <Col xl='12'
                                 className={`${tableClasses.table_wrapper} ${tableClasses['table_wrapper--community']}`}>
                                {historyData && historyData?.length > 0 ? (
                                    <BootstrapTable
                                        {...props.baseProps}
                                        keyField='config_hist_id'
                                        pagination={paginationFactory()}
                                        bordered={true}
                                        responsive
                                        stripped
                                        headerWrapperClasses={"header-class"}
                                    />) : isFetching ? (
                                    <>
                                        <p>Loading....</p>
                                    </>
                                ) : <p>No Audits Found</p>
                                }
                            </Col>
                        </Row>
                    </div>
                )
            }
        </ToolkitProvider>
    </>
}

export default ConfigAudits;

class StaticValues{

    static AuthUserQuago = "authUserQuago";
    static userName = "userName";
    static appCategory = [
        {value: "ART_DESIGN", label: "Art & Design"},
        {value: "AUTO_VEHICLES", label: "Auto & Vehicles"},
        {value: "BEAUTY", label: "Beauty"},
        {value: "BOOKS_REFERENCE", label: "Books & Reference"},
        {value: "BUSINESS", label: "Business"},
        {value: "COMICS", label: "Comics"},
        {value: "COMMUNICATION", label: "Communication"},
        {value: "DATING", label: "Dating"},
        {value: "EDUCATION", label: "Education"},
        {value: "ENTERTAINMENT", label: "Entertainment"},
        {value: "EVENTS", label: "Events"},
        {value: "FINANCE", label: "Finance"},
        {value: "FOOD_DRINK", label: "Food & Drink"},
        {value: "GAMING", label: "Gaming"},
        {value: "HEALTH_FITNESS", label: "Health & Fitness"},
        {value: "HOUSE_HOME", label: "House & Home"},
        {value: "LIBRARIES_DEMO", label: "Libraries & Demo"},
        {value: "LIFESTYLE", label: "Lifestyle"},
        {value: "MAPS_NAVIGATION", label: "Maps & Navigation"},
        {value: "MEDICAL", label: "Medical"},
        {value: "MUSIC_AUDIO", label: "Music & Audio"},
        {value: "NEWS_MAGAZINES", label: "News & Magazines"},
        {value: "PARENTING", label: "Parenting"},
        {value: "PERSONALIZATION", label: "Personalization"},
        {value: "PHOTOGRAPHY", label: "Photography"},
        {value: "PRODUCTIVITY", label: "Productivity"},
        {value: "SHOPPING", label: "Shopping"},
        {value: "SOCIAL", label: "Social"},
        {value: "SPORTS", label: "Sports"},
        {value: "TOOLS", label: "Tools"},
        {value: "TRAVEL_LOCAL", label: "Travel & Local"},
        {value: "VIDEOPLAYERS_EDITORS", label: "Video Players & Editors"},
        {value: "WEATHER", label: "Weather"},
    ]
    static SDKType = [
        { value: 0, label: "Web" },
        { value: 1, label: "Android" },
        { value: 2, label: "iOS" },
        { value: 3, label: "Desktop" },
        { value: 4, label: "Unity" },
    ];

    static filterType = [
        { value: 'ACTIVE', label: "Active" },
        { value: 'ARCHIVED', label: "Archive" },
        { value: 'ALL', label: "All" }
    ]
    static modeType = [
        { value: 'TESTING', label: "Testing" },
        { value: 'LIVE', label: "Live" },
        { value: 'DEMO', label: "Demo" },
    ]

    static configType = [
        { value: 'INT', label: "Integer" },
        { value: 'FLOAT', label: "Float" },
        { value: 'BOOLEAN', label: "Boolean" },
        { value: 'JSON', label: "Json" },
        { value: 'URL', label: "Url" },
        { value: 'STR', label: "String" },
        { value: 'ENUM', label: "Enum" },
    ]

    static modeFilter = {
        modeLive: 'LIVE',
        modeDemo: 'DEMO',
        modeTesting: 'TESTING',
        modeAll: 'ALL',
        filterActive:'ACTIVE',
        filterDeleted:'INACTIVE',
        filterArchived:'ARCHIVED',
        filterAll:'ALL',
    }

    static integrationType = [
        {value:'BASIC', label:'Basic'},
        {value:'TAILORED', label:'Tailored'}
    ]

    static rolesType = [
        {value:'ADMIN', label:'Admin'},
        {value:'SUPERUSER', label:'Super User'},
        {value:'EXPLORER', label:'Explorer'}
    ]

    static planType = [
        {value:'BASIC', label:'Basic'},
        {value:'PREMIUM', label:'Premium'}
    ]

    static AppMode = [
        {value: 'LIVE' , label: 'LIVE'},
        {value:'DEMO' , label: 'DEMO'},
        {value:'TESTING' , label: 'TESTING'},
        {value:'ALL' , label: 'ALL'},
    ]

    static communityStatus = [
        {value:'NEW', label:'New'},
        {value:'IN_REVIEW', label:'In Review'},
        {value:'DEPLOYED', label:'Deployed'},
    ]

    static communityDecision = [
        {value:'REVIEW', label:'Review'},
        {value:'APPROVED', label:'Approved'},
        {value:'REJECTED', label:'Rejected'},
        {value:'UNCERTAIN', label:'Uncertain'},
        {value:'WAITING_FOR_REVIEW', label:'Waiting For Review'},
    ]

    static communityJobStatus = [
        {value:'NEW', label:'New'},
        {value:'EXISTING', label:'Existing'},
        {value:'REMOVED', label:'Removed'}
    ]

    static segmentsTags = [
        {value:'POS', label:'Positive'},
        {value:'NEG', label:'Negative'},
        {value:'UN_CERTAIN', label:'Uncertain'},
        {value:'UN_TAG', label:'Untag'},
    ]

    static segmentsFilter = [
        {value:'ALL', label:'All'},
        {value:'TAGGED', label:'Tagged'},
        {value:'NEW', label:'New'},
        {value:'POS', label:'Positive'},
        {value:'NEG', label:'Negative'},
        {value:'UN_CERTAIN', label:'Uncertain'},
        {value:'UN_TAG', label:'Untag'},
    ]

    // static segmentFilterTypes = [
    //     {value: SegmentFilterType.SEG_ID, label: "Segment Id"},
    //     {value: SegmentFilterType.SEG_NAME, label: "Segment Name"},
    //     {value: SegmentFilterType.USER_ID, label: "User Id"},
    //     {value: SegmentFilterType.ADDITIONAL_ID, label: "Additional Id"},
    //     {value: SegmentFilterType.SESSION_ID, label: "Session Id"},
    //     {value: SegmentFilterType.IP, label: "IP"},
    //     {value: SegmentFilterType.COUNTRY, label: "Country"},
    //     {value: SegmentFilterType.COMMUNITY_ID, label: "Community Id"},
    //     {value: SegmentFilterType.MODEL, label: "Model"},
    //     {value: SegmentFilterType.ANDROID_ID, label: "Android Id"},
    //     {value: SegmentFilterType.CPU_VENDOR, label: "CPU Vendor"},
    //     {value: SegmentFilterType.RELEASE, label: "Release"},
    //     {value: SegmentFilterType.SCREEN_WIDTH, label: "Screen Width"},
    //     {value: SegmentFilterType.SCREEN_HEIGHT, label: "Screen Height"},
    //     {value: SegmentFilterType.IP_NETWORK, label: "IP Network"},
    // ]

    static waitingTimeForAlert = 4000

    getAppCategory(value) {
        if(value) {
            let result = StaticValues.appCategory.find(option => option.value === value);
            return result;
        }
        return "";
    }
    getSDKType(value) {
        if(value || value === 0) {
            let result = StaticValues.SDKType.find(option => option.value === value);
            return result;
        }
        return "";

    }

}

export default StaticValues

import {
    AddFilter,
    AddFilterFromDetails,
    CommunitySegFilter,
    FilterOperatorChange,
    FilterTypeChange,
    FilterValueChange,
    Option,
    RemoveFilter,
    RemoveFilterSuccess,
    ResetFilters,
    SAValueChange,
    SetFilters
} from "../../types/CommunityFilterTypes";
import {CommunityFilterActionTypes} from "../../types/StoreTypes";
import {FilterListResponse, FilterRequest, SegmentType, TagFilter} from "../../AUTO_GENERATED_TYPES";

export const setFilters = (filters: CommunitySegFilter[]): SetFilters => ({
    type: CommunityFilterActionTypes.SET_COM_FILTERS,
    payload: filters
})

export const addFilter = (filter?: CommunitySegFilter): AddFilter => ({
    type: CommunityFilterActionTypes.ADD_COM_FILTERS,
    payload: filter
})

export const removeFilter = (id: string, segType: string, segId: number,tagFilter :TagFilter): RemoveFilter => ({
    type: CommunityFilterActionTypes.REMOVE_COM_FILTERS,
    payload: {id, segType, segId,tagFilter}
})

export const removeFilterSuccess = (id: string): RemoveFilterSuccess => ({
    type: CommunityFilterActionTypes.REMOVE_COM_FILTERS_SUCCESS,
    payload: id
})

export const resetFilters = (): ResetFilters => ({type: CommunityFilterActionTypes.RESET_COM_FILTERS})

export const filterTypeChange = (id: string, newValue: Option, meta: FilterListResponse,inDeletion?:boolean): FilterTypeChange => ({
    type: CommunityFilterActionTypes.TYPE_CHANGE,
    payload: {id, newValue, meta,inDeletion}
})

export const filterOperatorChange = (id: string, newValue: Option): FilterOperatorChange => ({
    type: CommunityFilterActionTypes.OPERATOR_CHANGE,
    payload: {id, newValue}
})

export const filterValueChange = (id: string, newValue: Option,inDeletion?:boolean): FilterValueChange => ({
    type: CommunityFilterActionTypes.VALUE_CHANGE,
    payload: {id, newValue,inDeletion}
})

export const saValueChange = (newValue: string, options: FilterListResponse | undefined): SAValueChange => ({
    type: CommunityFilterActionTypes.SA_CHANGE,
    payload: {newValue, options}
})

export const addFilterFromDetails = (filter: CommunitySegFilter, fetchData: { cdId: number, segType: SegmentType, body: FilterRequest, itemPp: number, page: number, tag?: TagFilter | undefined }): AddFilterFromDetails => ({
    type: CommunityFilterActionTypes.ADD_COM_FILTERS_DETAILS,
    payload: {filter, fetchData}
})

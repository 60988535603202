import {takeEvery} from "redux-saga/effects";
import {CommunitySortActionTypes} from "../../types/StoreTypes";


function* addFromDetailsSaga() {
    //
}

function* communitySortSaga() {

    yield takeEvery(CommunitySortActionTypes.ADD_COM_SORT as any, addFromDetailsSaga)
}

export default communitySortSaga

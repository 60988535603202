import {useQuery, useQueryClient} from "react-query";
import {useParams} from "react-router-dom";
import useAnnotation from "../../../../store/annotation/useAnnotation";
import {useMemo} from "react";
import isFilterFull from "../../../../assets/utils/isFilterFull";
import {FilterDefinitionListResponse, FilterListResponse} from "../../../../AUTO_GENERATED_TYPES";
import QueryKeys from "../../../../react-query/QueryKeys";
import {getAnnotationFilters} from "../../../../_metronic/helpers/backend_helper";
import {AnnotationSegFilter} from "../../../../types/AnnotationTypes";
import {v4 as uuidv4} from "uuid";

const useCreateFilterLink = ( type: string, data: string | any[]) => {
    const {id} = useParams()
    const annotationId = Number(id)
    const queryClient = useQueryClient()

    const {data: {filters,tagFilter}} = useAnnotation()

    const onlyFullFilters = useMemo(() =>
        filters.filter(isFilterFull).map(filter => ({
            filter_definition_id: Number.parseInt(filter.filterType.value),
            filter_value: filter.filterValue.map(el => el.value),
            filter_operator: filter.filter_operator.value
        })), [filters])


    const filterListResponse = queryClient.getQueryData<FilterDefinitionListResponse>([QueryKeys.AnnotationFiltersDefinition, annotationId])
    const currentFilter = filterListResponse?.filters?.find(filter => filter.column_name === type)
    const filterId = currentFilter?.id
    const oldFilterList = queryClient.getQueryData<FilterListResponse>([QueryKeys.AnnotationFilters, annotationId, filterId, onlyFullFilters])

    const {data: newFilterList} = useQuery([QueryKeys.AnnotationFilters, annotationId, filterId, onlyFullFilters,tagFilter], () => {

        return getAnnotationFilters(annotationId, filterId!, {filters: []},tagFilter)
    }, {
        refetchOnWindowFocus: false,
        staleTime: 60 * 1000,
        enabled: (!Boolean(oldFilterList) && Boolean(filterId))
    })

    const finalList = oldFilterList || newFilterList;


    if (!finalList) {
        return null
    }

    const filterLabel = currentFilter?.short_name

    const operators = finalList.filter_operator_values?.map(value => ({value, label: value})) ?? [{
        value: "",
        label: ""
    }]

    const values = finalList.filter_list?.map(value => ({
        value: value.filter_name ?? '',
        label: `${value.filter_name} (${value.filter_counter})`
      })) ?? [{ value: "", label: "" }];
      

    const value = (typeof data === "string" 
        ? [values?.find(value => value?.value === data) ?? { value: "", label: "" }]
        : values?.filter(value => data && value.value in data)) ?? [{ value: "", label: "" }];
      

    const filter: AnnotationSegFilter = {
        id: uuidv4(),
        filterType: {value: finalList.filter_definition_id + '', label: filterLabel ?? ''},
        filterValue: value,
        filterValues: values,
        filter_operator: ['NUMERIC','JSON'].includes(finalList.filter_data_type)  ? {value: "==", label: "=="} : {value: "is in", label: "is in"},
        filter_operators: operators,
        dataType: finalList.filter_data_type
    }

    return filter
}

export default useCreateFilterLink

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AllUsersResponse } from '../models/AllUsersResponse';
import type { ChangePasswordRequest } from '../models/ChangePasswordRequest';
import type { CreateUserRequest } from '../models/CreateUserRequest';
import type { ResetPasswordResponse } from '../models/ResetPasswordResponse';
import type { UpdateUserRequest } from '../models/UpdateUserRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create User
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public createUserV1UserCreatePost(
requestBody: CreateUserRequest,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/user/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Get All Users
     * @returns AllUsersResponse Successful Response
     * @throws ApiError
     */
    public getAllUsersV1UserAllGet(): CancelablePromise<AllUsersResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/user/all',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Delete User
     * @param userId 
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteUserV1UserUserIdDeleteDelete(
userId: number,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/user/{user_id}/delete',
            path: {
                'user_id': userId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Update User
     * @param userId 
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateUserV1UserUserIdUpdatePatch(
userId: number,
requestBody: UpdateUserRequest,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/user/{user_id}/update',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Change Password
     * @param userId 
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public changePasswordV1UserUserIdPasswordChangePatch(
userId: number,
requestBody: ChangePasswordRequest,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/user/{user_id}/password/change',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Reset Password
     * @param userId 
     * @returns ResetPasswordResponse Successful Response
     * @throws ApiError
     */
    public resetPasswordV1UserUserIdPasswordResetPatch(
userId: number,
): CancelablePromise<ResetPasswordResponse> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/user/{user_id}/password/reset',
            path: {
                'user_id': userId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

}
